import { Component, OnInit, ErrorHandler, OnDestroy } from '@angular/core'
import { Validators, FormControl, FormGroup, ValidatorFn, AbstractControl, ValidationErrors, FormArray } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { Maksutapa, Kuitti, FirestoreTosite, FirestoreTositteenAlkuperainenTiedosto, SAHKOISET_LASKUT_FAKE_MAKSUTAPA, PaymentStatus } from '../_jaettu/model/tosite'

import { TositeService } from '../_angular/service/tosite/tosite.service'
import { KayttajaService } from '../_angular/service/kayttaja.service'

import { SimplifiedUploadData, TiedostojenLataamisService } from '../_jaettu-angular/service/tiedostojen-lataamis.service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { FormValidationService } from '../_jaettu-angular/service/form-validation.service'
import { TimestampService } from '../_jaettu-angular/service/timestamp-service'

import { DateService } from '../_shared-core/service/date.service'
import { CurrencyService } from '../_shared-core/service/currency.service'

import { Observable, Subject } from 'rxjs'
import { map, takeUntil, tap } from 'rxjs/operators'
import { TositeKuvaCacheService } from '../tositteet/kuvat/tosite-kuva-cache.service'
import { TositeDatasourceService } from '../_angular/service/tosite/tosite-datasource.service'
import { FirebaseLemonaid } from '../_angular/service/firebase-lemonaid.service'
import { TositeKopioija } from '../_jaettu/service/tosite/tosite.kopioija'
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop'
import { IbanService } from 'app/_shared-core/service/iban.service'
import { BicService } from 'app/_shared-core/service/bic.service'
import { ViitenumeroService } from 'app/_shared-core/service/viitenumero.service'
import { TositeLuoUusiComponentData } from 'app/_angular/_resolvers/tosite.resolve'
import { QrTaiViivakoodi } from 'app/_angular/service/barcode/barcode-detector.service'
import { KirjanpidonPeruste } from 'app/_jaettu/model/kayttaja'
import { AsiakasJaettuLemonaidService } from 'app/_jaettu/service/asiakas-jaettu-lemonaid.service'
import { StringService } from 'app/_shared-core/service/string.service'
interface MaksutForm {
  tositeArray: FormArray<FormGroup<MaksuFormGroup>>
}

interface MaksuFormGroup {
  // maksutapa: FormControl<number>
  pvm: FormControl<Date>
  erapvm: FormControl<Date>
  summa: FormControl<number>
  viite: FormControl<string>
  viesti: FormControl<string>
  lisatiedot: FormControl<string>
  iban: FormControl<string>
  bic: FormControl<string>
  saaja: FormControl<string>
  toimituspvm: FormControl<Date>
}

@Component({
  templateUrl: './maksut-uusi.component.html',
  styleUrls: ['./maksut-uusi.component.css']
})
export class MaksutUusiComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject<void>()

  maxDate: Date = new Date(2099, 11, 31)
  minDate: Date = new Date(2023, 0, 1)

  form: FormGroup<MaksutForm>

  uploadTasks: SimplifiedUploadData[] = []
  kuitit: FirestoreTosite[] = []
  virheviestiLatausOnKesken: string = null
  virheviestiPysyva: string = null
  muokataan: boolean = false
  paivitaValidaatiot = {}
  donePercentageObservable: Observable<number>
  latausvirheetObservable: Observable<string[]>
  naytaYhdistaKuitit: boolean = false
  onkoLaskuperusteinenObservable: Observable<boolean>

  kuvat: string[][] = []
  nakyvatKuvat: string[] = []

  otsikko: string

  namename = 'asfasf' + Math.random()

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _tositeService: TositeService,
    private _ladataanService: LadataanService,
    private _dateService: DateService,
    private _validationService: FormValidationService,
    private _timestampService: TimestampService,
    private _currencyService: CurrencyService,
    private _kayttajaService: KayttajaService,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _translationService: LemonTranslationService,
    private _errorHandler: ErrorHandler,
    private _tositeKuvaCacheService: TositeKuvaCacheService,
    private _tositeDatasourceService: TositeDatasourceService,
    private _tositeKopioija: TositeKopioija,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _ibanService: IbanService,
    private _bicService: BicService,
    private _viitenumeroService: ViitenumeroService,
    private _formValidationService: FormValidationService,
    private _asiakasJaettuService: AsiakasJaettuLemonaidService,
    private _stringService: StringService
  ) {
    this.maxDate = this._dateService.lisaaKuukausia(new Date(), 12)
    this.minDate = this._dateService.lisaaKuukausia(new Date(), -24)
  }

  ngOnInit() {

    this.form = new FormGroup<MaksutForm>({
      'tositeArray': new FormArray<FormGroup<MaksuFormGroup>>([], [this._vahintaaYksiRiviValidator])
    })

    this._route.data.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async (data: { data: TositeLuoUusiComponentData }) => {

      if (!data || !data.data || !data.data.tiedostot || data.data.tiedostot.length < 1) {
        this.peruuta()
        return
      }

      // this.naytaYhdistaKuitit = data.data.tiedostot.length > 1

      //   // Separate display settings for different tosite types
      //   const lahde = this.tositeService.getMaksutapaNameForRouter(data.data?.maksutapa?.i)
      //   this.setTextsAndFieldsForToTositeType(lahde)
      //   await this.tositeService.setLastViewedKuitinMaksutapa(data.data?.maksutapa?.i)

      const kuitti = this._tositeService.annaUusiKuitti(SAHKOISET_LASKUT_FAKE_MAKSUTAPA)
      kuitti.currency = 'EUR'
      kuitti.type = 'payment'
      kuitti.p = null
      kuitti.localPvm = null
      kuitti.pvm = null
      const formGroup = this._annaMaksunFormGroup(kuitti)
      this.kuitit.push(kuitti)
      this.tositeArray.push(formGroup)
      kuitti.maksutiedot = this._tositeKopioija.annaMaksutiedot(kuitti)

      // Tämä implementaatio tekee yhden maksun, jossa kaikki kuvat
      await this._alusta(kuitti, formGroup, data.data.tiedostot)

      // const kayttajanTiedot = await this._kayttajaService.getKayttajanTiedot()
      //   for (const tiedosto of data.data.tiedostot) {

      //     const kuitti = this.tositeService.annaUusiKuitti(data.data.maksutapa)
      //     if (data.data.maksutapa) {
      //       kuitti.maksutapa = data.data.maksutapa.i
      //     }
      //     const tositeGroup = this.annaKuitinFormGroup(kuitti)

      //     this.kuitit.push(kuitti)
      //     this.tositeArray.push(tositeGroup)

      //     const fileEnding = this.tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(tiedosto)
      //     const file = await this.tiedostojenLataamisService.getFile(tiedosto.fileEntry as FileSystemFileEntry)

      //     const alkuperainen: FirestoreTositteenAlkuperainenTiedosto = {
      //       avain: this._firebaseLemonaid.firestoreCreateId(),
      //       kuvakansio: kuitti.kuvakansio,
      //       nimi: tiedosto.relativePath,
      //       fileEnding: fileEnding,
      //       kasitelty: false
      //     }
      //     kuitti.alkuperaiset[alkuperainen.avain] = alkuperainen

      //     const uploadData = this.tiedostojenLataamisService.tallennaTositetiedosto(this._firebaseLemonaid, kayttajanTiedot.asiakasId + '', alkuperainen, file)
      //     this.uploadTasks.push(uploadData)

      //     const purettuTiedosto = await this.tositeService.puraYksiTiedosto(kuitti, file, tiedosto, fileEnding, uploadData, alkuperainen, kayttajanTiedot)
      //     if (purettuTiedosto.viivakoodi) {
      //       tositeGroup.get('summa').setValue(this.currencyService.muutaBigDecimalRahaksi(purettuTiedosto.viivakoodi.summa))
      //     }
      //     if (purettuTiedosto.virhe) {
      //       this.virheviestiPysyva = this.virheviestiPysyva ? this.virheviestiPysyva + ' ' + purettuTiedosto.virhe : purettuTiedosto.virhe
      //     }
      //     if (purettuTiedosto.kasitellytKuvat) {
      //       for (const kuva of purettuTiedosto.kasitellytKuvat) {
      //         kuitti.kuvat[kuva.avain] = kuva
      //       }
      //     }

      //     const tamanKuitinKuvat = this.tositeKuvaCacheService.annaKuvienUrlit(kuitti, kayttajanTiedot, 'full')
      //     this.kuvat.push(tamanKuitinKuvat)
      //     this.nakyvatKuvat.push(tamanKuitinKuvat.length > 0 ? tamanKuitinKuvat[0] : 'asdfdsfdsfdfsasdffss')
      //   }

      //   this.donePercentageObservable = this.tiedostojenLataamisService.annaKokonaisprosentti(this.uploadTasks).pipe(
      //     tap(percentage => {
      //       if (percentage > 99.99) {
      //         this.virheviestiLatausOnKesken = null
      //       }
      //     })
      //   )
      //   this.latausvirheetObservable = this.tiedostojenLataamisService.annaKaikkiVirheet(this.uploadTasks)

      //   this.asetaOletusprosentit(this.oletusAlv, this.tositeArray, this.kuitit)

    }, error => {
      this._errorHandler.handleError(error)
    })

    this.onkoLaskuperusteinenObservable = this._kayttajaService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {

        if (!asiakas?.kirjanpidonPerusteet?.length) {
          return false
        }
        const currentLocalDate = this._dateService.currentLocalDate()
        const peruste = this._asiakasJaettuService.annaKirjanpidonPeruste(asiakas, currentLocalDate)

        return peruste === KirjanpidonPeruste.LASKU
      })
    )

    // const apixConfigObservable = this._kayttajaService.kayttajaObservable.pipe(
    //   switchMap(kayttaja => {
    //     if (!kayttaja) {
    //       return of<ApixReceivedInvoiceConfig>(null)
    //     }
    //     return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>('customers/' + kayttaja.asiakasAvain + '/apix-received-invoice-config/' + kayttaja.asiakasAvain).listen()
    //   })
    // )

  }

  private async _alusta(kuitti: FirestoreTosite, kuitinFormGroup: FormGroup<MaksuFormGroup>, tiedostot: NgxFileDropEntry[]) {

    const kayttajanTiedot = await this._kayttajaService.getKayttajanTiedot()
    let koodiPromise: Promise<QrTaiViivakoodi> = Promise.resolve({})
    for (const tiedosto of tiedostot) {

      const fileEnding = this._tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(tiedosto)
      const file = await this._tiedostojenLataamisService.getFile(tiedosto.fileEntry as FileSystemFileEntry)

      const alkuperainen: FirestoreTositteenAlkuperainenTiedosto = {
        avain: this._firebaseLemonaid.firestoreCreateId(),
        kuvakansio: kuitti.kuvakansio,
        nimi: tiedosto.relativePath,
        fileEnding: fileEnding,
        kasitelty: false
      }
      kuitti.alkuperaiset[alkuperainen.avain] = alkuperainen

      const uploadData = this._tiedostojenLataamisService.tallennaTositetiedostoSimplified(this._firebaseLemonaid, kayttajanTiedot.asiakasId + '', alkuperainen, file)
      this.uploadTasks.push(uploadData)

      const purettuTiedosto = await this._tositeService.puraYksiTiedostoLokaalisti(kuitti, file, tiedosto, fileEnding, uploadData, alkuperainen, kayttajanTiedot, true, koodiPromise)
      koodiPromise = purettuTiedosto.koodi
      if (purettuTiedosto.virhe) {
        if (purettuTiedosto.virhe === 'purkuvirhe') {
          // Aloita back-end käsittely kaikille tositteille, jotka tarvitsevat sitä
          // Älä blokkaa!
          this._tositeService.puraYksiTiedostoBackendissa(kuitti, uploadData, alkuperainen, kayttajanTiedot, true, Promise.resolve({})).then(purettuTiedosto => {
            if (purettuTiedosto.virhe) {
              const virheviesti = this._annaVirhe(purettuTiedosto.virhe, uploadData.filename)
              this.virheviestiPysyva = this.virheviestiPysyva ? this.virheviestiPysyva + ' ' + virheviesti : virheviesti
            } else {
              if (purettuTiedosto.kasitellytKuvat) {
                for (const kuva of purettuTiedosto.kasitellytKuvat) {
                  kuitti.kuvat[kuva.avain] = kuva
                }
              }
              const tamanKuitinKuvat = this._tositeKuvaCacheService.annaKuvienUrlit(kuitti, kayttajanTiedot, 'full')
              this.kuvat.push(tamanKuitinKuvat)
              this.nakyvatKuvat.push(tamanKuitinKuvat.length > 0 ? tamanKuitinKuvat[0] : 'asdfdsfdsfdfsasdffss')
            }
          })
        } else {
          const virheviesti = this._annaVirhe(purettuTiedosto.virhe, uploadData.filename)
          this.virheviestiPysyva = this.virheviestiPysyva ? this.virheviestiPysyva + ' ' + virheviesti : virheviesti
        }
      } else if (purettuTiedosto.kasitellytKuvat) {
        for (const kuva of purettuTiedosto.kasitellytKuvat) {
          kuitti.kuvat[kuva.avain] = kuva
        }
        const tamanKuitinKuvat = this._tositeKuvaCacheService.annaKuvienUrlit(kuitti, kayttajanTiedot, 'full')
        this.kuvat.push(tamanKuitinKuvat)
        this.nakyvatKuvat.push(tamanKuitinKuvat.length > 0 ? tamanKuitinKuvat[0] : 'asdfdsfdsfdfsasdffss')
      }
    }

    koodiPromise.then(koodi => {
      // console.log('KOODIT TÄSSÄ', koodi)
      if (koodi.qr) {
        kuitinFormGroup.get('summa').setValue(this._currencyService.roundHalfUp(koodi.qr.summa, 2))
        kuitinFormGroup.get('iban').setValue(koodi.qr.iban)
        kuitinFormGroup.get('erapvm').setValue(this._dateService.localDateToDate(koodi.qr.erapaiva))
        kuitinFormGroup.get('viite').setValue(this._viitenumeroService.muotoileViitenumero(koodi.qr.viitenumero))
        kuitinFormGroup.get('saaja').setValue(koodi.qr.maksunSaajaNimi)
      } else if (koodi.viiva) {
        kuitinFormGroup.get('summa').setValue(this._currencyService.muutaBigDecimalRahaksi(koodi.viiva.summa))
        kuitinFormGroup.get('iban').setValue(koodi.viiva.iban)
        kuitinFormGroup.get('erapvm').setValue(this._dateService.localDateToDate(koodi.viiva.erapvm))
        kuitinFormGroup.get('viite').setValue(this._viitenumeroService.muotoileViitenumero(koodi.viiva.viitenumero))
        // kuitinFormGroup.get('saaja').setValue(koodi.qr.maksunSaajaNimi)
      }
    })

    this.donePercentageObservable = this._tiedostojenLataamisService.annaKokonaisprosenttiSimplified(this.uploadTasks).pipe(
      tap(percentage => {
        if (percentage > 99.99) {
          this.virheviestiLatausOnKesken = null
        }
      })
    )
    this.latausvirheetObservable = this._tiedostojenLataamisService.annaKaikkiVirheetSimplified(this.uploadTasks)

  }

  private _annaVirhe(virhe: string, tiedostonNimi: string): string {
    if (virhe === 'purkuvirhe') {
      return this._translationService.lokalisoi('kuitit.muokkaa.validation.purkuvirhe-myos-backend', { file_name: tiedostonNimi })
    }
    if (virhe === 'latausvirhe') {
      return this._translationService.lokalisoi('kuitit.muokkaa.validation.latausvirhe', { file_name: tiedostonNimi })
    }
    return this._translationService.lokalisoi('kuitit.muokkaa.validation.tuntematon-virhe', { file_name: tiedostonNimi })
  }

  get tositeArray(): FormArray<FormGroup<MaksuFormGroup>> {
    return this.form.get('tositeArray') as FormArray<FormGroup<MaksuFormGroup>>
  }

  private _annaMaksunFormGroup(kuitti: FirestoreTosite): FormGroup<MaksuFormGroup> {

    const paymentData = this._tositeKopioija.annaMaksutiedot(kuitti)

    const viiteControl = new FormControl<string>(paymentData.reference)
    const viestiControl = new FormControl<string>(paymentData.message, [Validators.maxLength(60), this._validateViestiTaiViite(viiteControl)])
    viiteControl.setValidators([this._validateViestiTaiViite(viestiControl), this._validateViitenumero])
    const tositeGroup = new FormGroup<MaksuFormGroup>({
      // maksutapa: new FormControl<number>({ value: kuitti.maksutapa, disabled: true }, [Validators.required]),
      pvm: new FormControl<Date>(this._dateService.localDateToDate(kuitti.localPvm), [Validators.required]),
      erapvm: new FormControl<Date>(this._dateService.numberToDate(paymentData.dueDate), [Validators.required]),
      summa: new FormControl(paymentData.amount, [Validators.required, Validators.min(0.01), Validators.max(999999.99)]),
      viite: viiteControl,
      viesti: viestiControl,
      lisatiedot: new FormControl<string>(kuitti.selite, []),
      iban: new FormControl<string>(paymentData.iban, [Validators.required, this._validateIban]),
      bic: new FormControl<string>(paymentData.bic, [Validators.required, this._validateBic]),
      saaja: new FormControl<string>(paymentData.maksunSaajanNimi, [Validators.required]),
      toimituspvm: new FormControl<Date>(null)
    })

    tositeGroup.get('pvm').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: Date) => {
      kuitti.pvm = this._timestampService.dateToTimestamp(value)
      kuitti.localPvm = this._dateService.dateToLocalDate(value)
      kuitti.p = this._dateService.dateToNumber(value)
    })
    tositeGroup.get('erapvm').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: Date) => {
      kuitti.dueDate = this._dateService.dateToNumber(value)
      kuitti.maksutiedot.dueDate = kuitti.dueDate
    })
    tositeGroup.get('summa').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.summa = value
      kuitti.sum = value
      kuitti.maksutiedot.amount = value
    })
    tositeGroup.get('lisatiedot').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.selite = value
    })
    viiteControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.viitenumero = this._stringService.removeAllWhiteSpaces(value)
      kuitti.maksutiedot.reference = this._stringService.removeAllWhiteSpaces(value)
      viestiControl.updateValueAndValidity({ emitEvent: false })
    })
    viestiControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.maksutiedot.message = value
      viiteControl.updateValueAndValidity({ emitEvent: false })
    })
    tositeGroup.get('iban').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.iban = this._stringService.removeAllWhiteSpaces(value)
      kuitti.maksutiedot.iban = this._stringService.removeAllWhiteSpaces(value)
      if (this._ibanService.isValidIban(kuitti.iban) && !kuitti.bic?.trim()) {
        tositeGroup.get('bic').setValue(this._bicService.annaBicKoodi(kuitti.iban))
      }
    })
    tositeGroup.get('bic').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.bic = this._stringService.removeAllWhiteSpaces(value)
      kuitti.maksutiedot.bic = this._stringService.removeAllWhiteSpaces(value)
    })
    tositeGroup.get('saaja').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.seller = value
      kuitti.maksutiedot.maksunSaajanNimi = value
    })
    tositeGroup.get('toimituspvm').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: Date) => {
      kuitti.deliveryDate = this._dateService.dateToNumber(value)
    })
    return tositeGroup
  }

  private _validateViestiTaiViite(other: AbstractControl): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
      if (
        (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') &&
        (other.value == null || other.value === undefined || other.value.trim() === '')
      ) {
        return { viestitaiviite: true }
      }
      return null
    }
  }

  private _validateIban: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (!this._ibanService.isValidIban(ctrl.value)) {
      return { invalidiban: true }
    }
  }


  private _validateBic: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (!this._bicService.validoiBic(ctrl.value)) {
      return { invalidbic: true }
    }
  }

  private _validateViitenumero: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (!this._viitenumeroService.onkoViitenumeroValidi(ctrl.value)) {
      return { viitenumero: true }
    }
    return null
  }

  vaihdaNakyvaKuva(index: number, url: string) {
    this.nakyvatKuvat[index] = url
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  public valitseMaksutapa(maksutapa: Maksutapa, kuitti: Kuitti) {
    kuitti.p = maksutapa.i
  }

  private _saveInFlight = false
  async save() {

    if (this._saveInFlight) {
      return
    }
    this._saveInFlight = true

    try {

      const onkoTallentaminenEstetty = await this._tositeService.saveIsPreventedSimplified(this.form, this.latausvirheetObservable, this.uploadTasks, this.virheviestiPysyva)
      if (onkoTallentaminenEstetty && onkoTallentaminenEstetty.tallennusEstetty) {
        if (onkoTallentaminenEstetty.lataaminenKesken) {
          this.virheviestiLatausOnKesken = this._translationService.lokalisoi('kuitit.muokkaa.validation.ladataan')
        }
        this._validationService.merkitseKokoLomakeKosketuksi(this.form)
        setTimeout(() => { this._formValidationService.naytaEnsimmainenVirhe() }, 50)
        return
      }

      if (!this.form.valid) {
        this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
        setTimeout(() => { this._formValidationService.naytaEnsimmainenVirhe() }, 50)
        this._saveInFlight = false
        return
      }

      this._ladataanService.aloitaLataaminen()

      for (const kuitti of this.kuitit) {
        const eraantynyt = this._dateService.compareNumberDates(kuitti.dueDate, '<', this._dateService.currentNumberDate())
        kuitti.paymentStatus = eraantynyt ? PaymentStatus.ERAANTYNYT : PaymentStatus.AVOIN
      }

      await this._tositeService.tallennaKuitit(this.kuitit)
      this._router.navigate(['maksut'])

    } catch (error) {
      this.virheviestiPysyva = this._translationService.lokalisoi('tositteet.yhteiset.tallentaminen-epaonnistui')
      this._errorHandler.handleError(error)
    } finally {
      this._saveInFlight = false
      this._ladataanService.lopetaLataaminen()
    }

  }

  peruuta() {
    this._router.navigate(['maksut'])
  }

  private _vahintaaYksiRiviValidator = (c: FormArray<FormGroup>) => {
    if (c.length < 1) {
      return { min: 'true' }
    }
    return null
  }

}
