<div style="margin: 30px 0;">
  <ng-container *ngIf="viewDataObservable | async as viewData else loadingmetadata">
    <ng-container *ngIf="viewData.tilintarkastusDone else tilintarkastusnotdone">
      <ng-container *ngIf="viewData.tilinpaatosSigned else tilinpaatosnotsigned">

        <ng-container *ngIf="viewData.mode === 'edit'">

          <div>{{ 'reports-lemonaid.voit-luoda-ja-allekirjoittaa' | translate | async}}</div>

          <form [formGroup]="form">
            <div class="heading light-blue-text">{{ 'reports-lemonaid.perustiedot' | translate | async}}</div>

            <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
              <mat-form-field style="width: 245px; margin-right: 10px;">
                <mat-label>{{ 'reports-lemonaid.kokouksen-aika' | translate | async }}</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="meetingDate" required />
                <mat-error *ngIf="meetingDate?.errors?.minDate && (meetingDate?.dirty || meetingDate?.touched)">{{ 'reports-lemonaid.kokouksen-aika-min-date' | translate | async }}</mat-error>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <div class="time-container">
                <mat-form-field style="width: 30px">
                  <input #startHours timeFormat [timeType]="'hours'" class="time-input" maxlength="2" type="text" formControlName="meetingHours" matInput autocomplete="kirjnpitomekiarki" cdkFocusInitial />
                </mat-form-field>
                <div class="sep-colon">:</div>
                <mat-form-field style="width: 30px">
                  <input timeFormat [timeType]="'minutes'" class="time-input" maxlength="2" type="text" formControlName="meetingMinutes" matInput autocomplete="kirjnpitomekiarki" />
                </mat-form-field>
              </div>

              <mat-form-field style="width: 245px; margin-right: 20px;">
                <mat-label>{{ 'reports-lemonaid.kokouspaikka' | translate | async }}</mat-label>
                <input matInput formControlName="kokouspaikka" required />
                <mat-hint>{{ 'reports-lemonaid.kokouspaikka-esimerkki' | translate | async }}</mat-hint>
              </mat-form-field>

              <mat-form-field style="max-width: 180px; margin-right: 20px;">
                <mat-label>{{ 'reports-lemonaid.poytakirjan-numero' | translate | async }}</mat-label>
                <input matInput formControlName="poytakirjanNumero" required />
              </mat-form-field>

            </div>

            <div class="heading light-blue-text">{{ 'reports-lemonaid.lasnaolijat' | translate | async}}</div>

            <div class="light-gray-text small-header">
              <div class="name-col">{{ 'reports-lemonaid.nimi' | translate | async}}</div>
              <div>{{ 'reports-lemonaid.osakkeet' | translate | async}}</div>
            </div>
            <div formArrayName="lasnaolijat">
              <div *ngFor="let lasnaolijatFormGroup of lasnaolijatFormGroupit; let i = index;" [formGroupName]="i">
                <mat-checkbox formControlName="selected">
                  <div style="display: flex;">
                    <div class="name-col">{{lasnaolijatFormGroup.get('name').value}}</div>
                    <div>{{lasnaolijatFormGroup.get('osakkeita').value}}</div>
                  </div>
                </mat-checkbox>
              </div>
            </div>

            <div class="heading light-blue-text">{{ 'reports-lemonaid.kokouksen-avaus' | translate | async}}</div>

            <div class="small-header">{{ 'reports-lemonaid.kokouksen-avasi' | translate | async }}</div>
            <mat-radio-group style="display: flex; flex-direction: column;" formControlName="kokouksenAvasi" required>
              <mat-radio-button *ngFor="let user of selectedParticipants" [value]="user.name">
                {{ user.name }}
              </mat-radio-button>
            </mat-radio-group>

            <div class="heading light-blue-text">{{ 'reports-lemonaid.kokouksen-jarjestaytyminen' | translate | async }}</div>

            <div class="signer-roles">

              <div style="margin-right: 100px;">
                <div class="small-header">{{ 'reports-lemonaid.puheenjohtaja' | translate | async }}</div>
                <mat-radio-group style="display: flex; flex-direction: column;" formControlName="puheenjohtaja" required>
                  <mat-radio-button *ngFor="let user of selectedParticipants" [value]="user" [disabled]="!user?.lemonaidUid">
                    {{ user.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>

              <!-- <div style="margin-right: 100px;">
              <div class="small-header">{{ 'reports-lemonaid.sihteeri' | translate | async }}</div>
              <mat-radio-group style="display: flex; flex-direction: column;" formControlName="sihteeri" required>
                <mat-radio-button *ngFor="let user of selectedParticipants" [value]="user">
                  {{ user.name }}
                </mat-radio-button>
              </mat-radio-group>
            </div> -->

              <div style="margin-right: 100px;">
                <div class="small-header">{{ 'reports-lemonaid.poytakirjan-tarkastaja' | translate | async }}</div>
                <mat-radio-group style="display: flex; flex-direction: column;" formControlName="poytakirjanTarkastaja" required>
                  <mat-radio-button *ngFor="let user of selectedParticipants" [value]="user" [disabled]="!user?.lemonaidUid">
                    {{ user.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>

            </div>

            <div *ngIf="usersWithoutLemonaidAccountSelected" style="margin-top: 10px; font-size: 12px;">{{ 'reports-lemonaid.henkiloita-ei-voi-valita' | translate | async }}</div>

            <div class="heading light-blue-text">{{ 'reports-lemonaid.osinkojen-jakaminen' | translate | async}}</div>

            <mat-radio-group style="display: flex; flex-direction: column" formControlName="osinkojaJaetaan" required>
              <mat-radio-button [value]="false"> {{ 'reports-lemonaid.osinkoa-ei-jaeta' | translate | async }} </mat-radio-button>
              <mat-radio-button *ngIf="osinkojaJaetaanYhteensaObservable | async as osinkoSumAsString" [value]="true">
                <div style="white-space: pre-wrap;">{{ 'reports-lemonaid.osinkoa-jaetaan' | translate: {sum: osinkoSumAsString } | async }}</div>
              </mat-radio-button>
            </mat-radio-group>

          </form>

          <div class="button-container">
            <button mat-button class="primarybutton" style="padding-left: 1rem; padding-right: 1rem;" (click)="createPreviewMoveToSigning()">{{ 'reports-lemonaid.esikatsele-ja-allekirjoita' | translate | async}}</button>
          </div>

          <div class="red-text common-error" style="margin-top: 20px; text-align: center;" *ngIf="commonError">{{commonError}}</div>

        </ng-container>

        <div *ngIf="viewData.mode === 'view'">

          <button *ngIf="viewData.pdfPages?.length" mat-button class="secondarybutton" style="margin: 25px" (click)="downloadPdf()">{{'reports-lemonaid.lataa-asiakirja' | translate | async}}</button>
          <div *ngFor="let page of viewData.pdfPages" class="lemon-mat-elevation-z4" style="margin: 1em;">
            <img *ngIf="page.getImageObservable() | async as kuva else loadingimage" [src]="kuva" style="width: 100%" />
          </div>

          <div *ngIf="viewData.ptkirjaMissingSignatures">
            <div class="heading light-blue-text">{{ 'reports-lemonaid.allekirjoitukset' | translate | async}}</div>

            <div class="btn-on-right-container">
              <button mat-button class="gray-text-btn" (click)="editPoytakirja()">{{ 'reports-lemonaid.muokkaa-poytakirjaa' | translate | async }}</button>
            </div>

            <div class="signers-container">

              <div *ngFor="let signer of signers" class="signer-box">
                <!-- Top text if signed -->
                <div *ngIf="!!signer.signedAt">
                  <div style="display: flex; flex-direction: row; color: gray">
                    <mat-icon style="font-size: 40px; width: 2.5rem; height: 2.5rem;">lock</mat-icon>
                    <div>
                      <div>{{ 'reports-lemonaid.allekirjoitettu-sahkoisesti' | translate | async }}</div>
                      <div>{{ signer.name }} {{ signer.signedAt | ltimestamp | async }} </div>
                    </div>
                  </div>
                </div>

                <!-- Top text if not signed and current user -->
                <div *ngIf="!signer.signedAt && signer.isCurrentUser">
                  <div style="display: flex; flex-direction: row; color: gray">
                    <button mat-button class="primarybutton" style="padding-left: 1rem; padding-right: 1rem;" (click)="saveSignature()">{{ 'reports-lemonaid.allekirjoita' | translate | async}}</button>
                  </div>
                </div>

                <!-- Top text if not signed and not current user -->
                <div *ngIf="!signer.signedAt && !signer.isCurrentUser">
                  <div style="display: flex; flex-direction: row; color: gray; height: 44px; align-items: center; ">
                    <div class="red-text">{{ 'reports-lemonaid.allekirjoitus-puuttuu' | translate | async}}</div>
                  </div>
                </div>


                <!-- Border and underline text -->
                <div class="underline-info">
                  <div>{{ signer.name }} </div>
                  <div style="white-space: pre-wrap;">{{ signer.rolesLocalized }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #tilintarkastusnotdone>
  <div style="text-align: center; padding: 50px 0;">
    <div style="text-align: center; padding: 20px 0;">{{ 'reports-lemonaid.tilitarkastetaan.tilinpaatos-pitaa-tilintarkastaa' | translate | async}}</div>
  </div>
</ng-template>

<ng-template #tilinpaatosnotsigned>
  <div style="text-align: center; padding: 50px 0;">
    <div style="text-align: center; padding: 20px 0;">{{ 'reports-lemonaid.ei-tilitarkasteta.tilinpaatos-pitaa-allekirjoittaa' | translate | async}}</div>
  </div>
</ng-template>

<ng-template #loadingmetadata>
  <div style="text-align: center; padding: 50px 0;">
    <mat-spinner style="display: inline-block;"></mat-spinner>
    <div style="text-align: center; padding: 20px 0;">{{ 'reports-lemonaid.yhtiokokouksen-poytakirjan-tietoja-ladataan' | translate | async}}</div>
  </div>
</ng-template>

<ng-template #loadingimage>
  <div style="text-align: center; padding: 50px 0;">
    <mat-spinner style="display: inline-block;"></mat-spinner>
  </div>
</ng-template>

<ng-template #loadingdocument>
  <div style="text-align: center; padding: 50px 0;">
    <mat-spinner style="display: inline-block;"></mat-spinner>
    <div style="text-align: center; padding: 20px 0;">{{ 'reports-lemonaid.yhtiokokouksen-poytakirjaa-ladataan' | translate | async }}</div>
  </div>
</ng-template>