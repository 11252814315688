<!-- {{'tositteet.laskujen-vastaanotto.vastaanotto-ja-valityspalvelu' | translate | async}} -->
<div class="kapea" style="width: 100%;" *ngIf="lataaminenValmis else loading">
  <div class="center-container">
    <ng-container *ngIf="viewState == 'maksaminen'">
      <form [formGroup]="maksuForm" novalidate>
        <div style="display: grid; padding-top: 10px;">
          <div style="display: flex; font-size: 20px; line-height: 40px; justify-self: center; grid-row-start: 1; grid-column-start: 1;">
            <button mat-button style="cursor: pointer;" (click)="vaihdaValilehtiVastaanottoon()">
              <span style="font-size: 20px;">{{'maksut.laskujen-vastaanotto' | translate | async}}</span>
            </button>
            <div style="margin: 0; width: 2px; background-color: black; height: 40px;"></div>
            <button mat-button style="margin: 0; cursor: pointer;" (click)="vaihdaValilehtiMaksamiseen()">
              <span style="font-size: 20px;" class="light-blue-text">{{'maksut.laskujen-maksaminen' | translate | async}}</span>
            </button>
          </div>
        </div>

        <h2 class="title">{{'maksut.asetukset.laskujen-maksaminen' | translate | async}}</h2>

        <div class="text-part">
          <p>{{'maksut.asetukset.voit-maksaa-sahkoiset-ostolaskut' | translate | async}}</p>
          <p>{{'maksut.asetukset.maksuominaisuuden-aktivointi' | translate | async}}</p>
          <p>{{'maksut.asetukset.maksuominaisuus-maksaa' | translate | async}}</p>
          <p>{{'maksut.asetukset.toteutuneet-maksut-veloitetaan' | translate |async}}</p>

          <div style="text-align: center;">
            <div *ngIf="vastaanotonTila === 'vastaanotto-ei-paalla'" style="margin-top: 50px; font-weight: bold;">{{'maksut.asetukset.maksuominaisuus-pyynto-ostolaskujen-vastaanotto-puuttuu' | translate | async}} <span class="link-like" (click)="vaihdaValilehtiVastaanottoon()">{{'maksut.asetukset.maksuominaisuus-pyynto-ostolaskujen-vastaanotto-puuttuu-linkki' | translate | async}}</span>.</div>

            <mat-checkbox formControlName="maksuominaisuusKayttoon" style="margin: calc(3px + 2em)" *ngIf="maksamisenTila === 'maksaminen-ei-paalla'">
              <span class="apk-checkbox-label">{{'maksut.asetukset.haluan-ottaa-maksuominaisuuden-kayttoon' | translate | async}}</span>
            </mat-checkbox>

            <div class="enabled-sign" *ngIf="maksamisenTila === 'maksaminen-tilattu'">
              <i class="fa fa-check green-text" aria-hidden="true" style="margin-right: .5em;"></i>
              <span>{{'maksut.asetukset.pyynto-lahetetty' | translate | async}}</span>
            </div>

            <p style="font-size: 80%;" *ngIf="maksamisenTila !== 'maksaminen-paalla'" [class.gray-text]="!maksuominaisuusKayttoonFormControl.value">{{'maksut.asetukset.tekninen-asiakaspalvelu-ottaa-yhteytta' | translate | async}}</p>

            <ng-container *ngIf="maksamisenTila === 'maksaminen-paalla'">

              <div class="enabled-sign">
                <i class="fa fa-check green-text" aria-hidden="true" style="margin-right: .5em;"></i>
                <span>{{'maksut.asetukset.maksuominaisuus-on-paalla' | translate | async}}</span>
              </div>

              <p>{{'maksut.asetukset.lopeta-ominaisuuden-kaytto' | translate | async}}</p>

            </ng-container>

          </div>

        </div>

        <button class="primarybutton" *ngIf="maksamisenTila === 'maksaminen-ei-paalla'" mat-button [disabled]="!maksuominaisuusKayttoonFormControl.value" (click)="tallennaMaksamisenAktivointi()" type="button">{{'yleiset.tallenna' | translate | async}}</button>
      </form>
    </ng-container>
    <ng-container *ngIf="viewState == 'vastaanotto'">
      <form [formGroup]="vastaanottoForm" novalidate>
        <div style="display: grid; padding-top: 10px;">
          <div style="display: flex; font-size: 20px; line-height: 40px; justify-self: center; grid-row-start: 1; grid-column-start: 1;">
            <button mat-button style="cursor: pointer;" (click)="vaihdaValilehtiVastaanottoon()">
              <span style="font-size: 20px;" class="light-blue-text">{{'maksut.laskujen-vastaanotto' | translate | async}}</span>
            </button>
            <div style="margin: 0; width: 2px; background-color: black; height: 40px;"></div>
            <button mat-button style="margin: 0; cursor: pointer;" (click)="vaihdaValilehtiMaksamiseen()">
              <span style="font-size: 20px;">{{'maksut.laskujen-maksaminen' | translate | async}}
                <span *ngIf="showNewFeatureSignAtMaksutMaksaminenObservable | async" class="uutta-sign">{{'app.uutta' | translate | async}}</span>
              </span>
            </button>
          </div>
        </div>

        <ng-container *ngIf="vastaanotonTila === 'vastaanotto-ei-paalla'">

          <h2 class="title">{{'maksut.laskujen-vastaanotto' | translate | async}}</h2>

          <div class="text-part">
            <p>{{'maksut.asetukset.vastaanota-verkkolaskuja' | translate | async}}</p>
            <!-- <p [innerHTML]="'maksut.asetukset.aktivoi-laskujen-maksuominaisuus' | translate | async"></p> -->
            <div style="font-size: 120%; padding-top: 20px;">
              <p>{{'maksut.asetukset.vastaanottamisen-aloittaminen' | translate | async}}:</p>

              <ol>
                <li>{{'maksut.asetukset.aktivoi-laskujen-vastaanottopalvelu' | translate | async}}</li>
                <li>{{'maksut.asetukset.lataa-verkkolaskuosoite' | translate | async}}</li>
              </ol>

              <p style="font-size: 60%; margin-top: 20px;">{{'maksut.asetukset.palvelu-maksaa' | translate | async}}</p>

            </div>

            <div style="text-align: center;">
              <mat-slide-toggle aria-label="{{'maksut.asetukset.aktivoi-laskujen-vastaanottopalvelu' | translate | async}}" style="transform: scale(1.2); margin: 30px 0px 0px; text-align: left;" formControlName="vastaanottoKayttoon">{{'maksut.asetukset.aktivoi-laskujen-vastaanottopalvelu' | translate | async}}</mat-slide-toggle>
            </div>

          </div>

          <button class="primarybutton" style="margin-bottom: 2em;" mat-button (click)="tallennaVastaanotonAktivointi()" [disabled]="!vastaanottoKayttoonFormControl.value" type="button">{{'yleiset.tallenna' | translate | async}}</button>

        </ng-container>

        <ng-container *ngIf="vastaanotonTila === 'vastaanotto-paalla'">

          <div class="text-part" style="padding-top: 3em;">

            <div style="text-align: center;">
              <mat-slide-toggle aria-label="Aktivoi palvelu" style="transform: scale(1.4); margin: 30px 0px 0px; text-align: left;" formControlName="vastaanottoKayttoon">{{'maksut.asetukset.vastaanotto' | translate | async}}</mat-slide-toggle>
              <p *ngIf="vastaanottoKayttoonFormControl.value" style="font-size: 80%; margin-top: 2em; margin-bottom: 4em;" class="gray-text">{{'maksut.asetukset.laskujen-vastaanotto-on-paalla' | translate | async}}</p>
              <p *ngIf="!vastaanottoKayttoonFormControl.value" style="font-size: 80%; margin-top: 2em; margin-bottom: 3em;" class="gray-text">{{'maksut.asetukset.laskujen-vastaanotto-lopetetaan' | translate | async}}</p>
            </div>

            <button class="primarybutton" style="margin: auto auto 3em; display: block;" *ngIf="!vastaanottoKayttoonFormControl.value" mat-button (click)="tallennaVastaanotonLopetus()" type="button">{{'yleiset.tallenna' | translate | async}}</button>

            <p>{{'maksut.asetukset.sahkoisten-laskujen-vastaanotto-on-paalla' | translate | async}}</p>

            <div class="osoite-info" *ngIf="apixLaskuosoiteObservable | async; let apixLaskuosoite">
              <div>{{'tositteet.laskujen-vastaanotto.verkkolaskuosoite' | translate | async}}: {{ apixLaskuosoite.sahkoinenOsoite }}</div>
              <div>{{'tositteet.laskujen-vastaanotto.valittaja' | translate | async}}: {{ apixLaskuosoite.sahkoinenValittajaNimi ? apixLaskuosoite.sahkoinenValittajaNimi + ' (' + apixLaskuosoite.sahkoinenValittaja + ')' : apixLaskuosoite.sahkoinenValittaja }}</div>
            </div>

            <div>{{'tositteet.laskujen-vastaanotto.voit-myos-antaa' | translate | async}} <span class="link-like" (click)="downloadPdf('fi')">{{'tositteet.laskujen-vastaanotto.lataa-pdf' | translate | async}}</span></div>

          </div>

        </ng-container>

      </form>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <div style="height: 100vh; width: 100%; padding: 0 20%; display: flex; align-items: center; justify-content: center;flex-direction: column;">
    <div>{{'yleiset.ladataan' | translate | async}}...</div>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>


<!-- <div class="settings-container">
  <button mat-button class="secondarybutton" (click)="editAddress()">{{'tositteet.laskujen-vastaanotto.asetukset' | translate | async}}</button>
</div> -->