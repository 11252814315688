import { Injectable } from '@angular/core'
import { BaseFirebaseService } from '../../_jaettu-angular/base-firebase.service'
import { environment } from 'environments/environment'

@Injectable()
export class FirebaseLemonaid extends BaseFirebaseService {
  constructor() {
    // Disable app check for now: , environment.recaptchaSiteId
    super(environment.lemonaidFirebaseConfig, 'lemonaid', null)
  }
}
