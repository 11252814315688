import { DateService } from '../../../_shared-core/service/date.service'
import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { TimestampProviderBase } from '../../../_shared-core/service/timestamp-provider.interface'

import { LaskuSharedService } from './lasku-shared.service'

import { LaskuBase, Lasku, AsiakkaanLahetettyLasku, LaskunTila, LaskunLahetystapa, LaskunumeroTyyppi } from '../../model/lasku'

export class LaskuLahetystietojenLuoja {

  constructor(
    private dateService: DateService,
    private currencyService: CurrencyService,
    private shared: LaskuSharedService,
    private timestampProvider: TimestampProviderBase
  ) {

  }

  luoLahetystiedot(lasku: Lasku): AsiakkaanLahetettyLasku[] {

    const lahetystiedot: AsiakkaanLahetettyLasku[] = []

    const lahetystapa = this.annaLahetystapa(lasku)
    if (!lahetystapa) {
      return lahetystiedot
    }

    const mitatoity = lasku.tila === LaskunTila.mitatoity
    const juurilaskuNro = this.shared.annaMuotoiltuLaskunumero(lasku, lasku)

    const summat = this.shared.annaLaskunSummat(lasku)
    const paalaskunTiedot: AsiakkaanLahetettyLasku = {
      lahetetty: this.timestampProvider.now(),
      juurilaskuAvain: lasku.avain,
      juurilaskuNro: juurilaskuNro,
      kanava: lahetystapa,
      kasiteltavaAvain: lasku.avain,
      kasiteltavanrotyyppi: lasku.nrotyyppi,
      kasiteltavaNro: juurilaskuNro,
      verollinensumma: mitatoity ? 0 : this.currencyService.muutaBigDecimalRahaksi(summat.yhteensaKaikki),
      verotonsumma: mitatoity ? 0 : this.currencyService.muutaBigDecimalRahaksi(summat.yhteensaIlmanAlv),
      laskunAsiakkaanAvain: lasku.asiakas.avain,
      hakupvm: Number(this.dateService.muotoileTallennusPaivamaara(lasku.pvml)),
      paiva: lasku.pvml.day,
      kuukausi: lasku.pvml.month,
      vuosi: lasku.pvml.year,
      laskunLahetystyyppi: lasku.lahetystyyppi
    }

    lahetystiedot.push(paalaskunTiedot)

    if (lasku.korvaus) {
      let edellinenTavallinen: LaskuBase = lasku
      for (const korvaava of lasku.korvaus) {

        const korvaavanlahetystapa = this.annaLahetystapa(korvaava)
        if (!korvaavanlahetystapa) {
          continue
        }

        const korvaavanSummat = this.shared.annaLaskunSummat(korvaava)

        let verollinen = korvaavanSummat.yhteensaKaikki
        let veroton = korvaavanSummat.yhteensaIlmanAlv
        if (korvaava.nrotyyppi === LaskunumeroTyyppi.KORJAUS || korvaava.nrotyyppi === LaskunumeroTyyppi.TAVALLINEN) {
          const edellisenSummat = this.shared.annaLaskunSummat(edellinenTavallinen)
          verollinen = verollinen.minus(edellisenSummat.yhteensaKaikki)
          veroton = veroton.minus(edellisenSummat.yhteensaIlmanAlv)
          edellinenTavallinen = korvaava
        } else if (korvaava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
          const kulutJaKorot = this.shared.annaMuistutuslaskunKulutTalleLaskulle(lasku, korvaava)
          verollinen = kulutJaKorot.korot.plus(kulutJaKorot.kulut)
          veroton = verollinen
        }

        const korvaavanTiedot: AsiakkaanLahetettyLasku = {
          lahetetty: this.timestampProvider.now(),
          juurilaskuAvain: lasku.avain,
          juurilaskuNro: juurilaskuNro,
          kanava: korvaavanlahetystapa,
          kasiteltavaAvain: korvaava.avain,
          kasiteltavanrotyyppi: korvaava.nrotyyppi,
          kasiteltavaNro: this.shared.annaMuotoiltuLaskunumero(lasku, korvaava),
          verollinensumma: mitatoity ? 0 : this.currencyService.muutaBigDecimalRahaksi(verollinen),
          verotonsumma: mitatoity ? 0 : this.currencyService.muutaBigDecimalRahaksi(veroton),
          laskunAsiakkaanAvain: korvaava.asiakas.avain,
          hakupvm: Number(this.dateService.muotoileTallennusPaivamaara(korvaava.pvml)),
          paiva: korvaava.pvml.day,
          kuukausi: korvaava.pvml.month,
          vuosi: korvaava.pvml.year,
          laskunLahetystyyppi: lasku.lahetystyyppi
        }

        lahetystiedot.push(korvaavanTiedot)

      }
    }
    return lahetystiedot
  }

  private annaLahetystapa(lasku: LaskuBase): LaskunLahetystapa {
    if (lasku.email && lasku.email.start) {
      return LaskunLahetystapa.SAHKOPOSTI
    } else if (lasku.print && lasku.print.start) {
      return LaskunLahetystapa.ITSE
    } else if (lasku.sahkoinen && lasku.sahkoinen.start) {
      return LaskunLahetystapa.SAHKOINEN
    } else if (this.shared.onkoUlkoinenLasku(lasku)) {
      return LaskunLahetystapa.MUUALLA
    }
    return null
  }

}
