import { Injectable } from '@angular/core'
import { TilinpaatosMetadata } from '../_jaettu/model/tilinpaatos'
import { TuettuKieli } from 'app/_shared-core/model/common'

@Injectable()
export class ReportImagesService {

  constructor() { }

  public getTilinpaatosPdfUrl(asiakasAvain: string, metadata: TilinpaatosMetadata, lang: TuettuKieli): string {
    const currentVersionKey = metadata.viimeisinTilinpaatosVersioAvain
    const supportedLanguages = metadata.tilinpaatosVersionTuetutKielet[currentVersionKey]

    if (supportedLanguages.find(supported => supported === lang)) {
      const fileName = metadata.tilinpaatosPdfienNimet[currentVersionKey + '_' + lang]

      return 'tilinpaatokset/' + asiakasAvain + '/fi/' + fileName
    }
  }

}
