import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { catchError, firstValueFrom, fromEvent, map, mergeWith, Observable, of, switchMap } from 'rxjs'
import { FirebaseLemonaid } from './firebase-lemonaid.service'
import { User } from 'firebase/auth'
import { environment } from 'environments/environment'
import { EnvironmentType } from 'app/app.environment'

const getCfUrl = (): string => {
  if (environment.environment === EnvironmentType.PRODUCTION || environment.environment === EnvironmentType.BETA) {
    return 'https://europe-west1-eu-lemonaid.cloudfunctions.net'
  } else if (environment.environment === EnvironmentType.DEV || environment.environment === EnvironmentType.LOCAL_DEV) {
    return 'https://europe-west1-dev-lemonaid.cloudfunctions.net'
  }
  throw new Error('Unknown environment ' + environment.environment)
}

export const LEMONAID_CF_API = getCfUrl()
type API = typeof LEMONAID_CF_API

@Injectable()
export class LemonHttpService {

  internetYhteysObservable: Observable<boolean>

  constructor(
    private http: HttpClient,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {

    const onlineEventObservable = fromEvent(window, 'online').pipe(map(() => true))
    const offlineEventObservable = fromEvent(window, 'offline').pipe(map(() => false))

    this.internetYhteysObservable = of(navigator.onLine).pipe(
      mergeWith(onlineEventObservable, offlineEventObservable),
      switchMap(online => {
        if (online) {
          return http.get('https://europe-west1-eu-lemonaid.cloudfunctions.net/onlineCheck').pipe(
            map(() => true),
            catchError(err => of(false))
          )
        } else {
          return of(false)
        }
      })
    )

  }

  protected getUser(api: string): Promise<User> {
    return firstValueFrom(this._firebaseLemonaid.authUserObservable)
  }

  getIdTokenPromise(): Promise<string> {
    // if (api === LEMONATOR_HTTPS_API || api === LEMONATOR_HTTPS_IMAGES_API) {
    return firstValueFrom(this._firebaseLemonaid.authUserObservable.pipe(
      switchMap(user => user ? this._firebaseLemonaid.authGetIdToken(user) : null)
    ))
    // }
    //  else if (api === Api.LEMONAID) {
    //   return this.lemonaidAuth.idToken
    // }
    // throw new Error('Unknown API ' + api)
  }

  // getIdTokenPromise(api: Api): Promise<string> {
  //   if (api === LEMONATOR_HTTPS_API) {
  //     return this.auth.auth.currentUser.getIdToken()
  //   } else if (api === Api.LEMONTREE) {
  //     return this.lemontreeAuth.auth.currentUser.getIdToken()
  //   }
  //   throw new Error('Unknown API ' + api)
  // }

  // public getBinaryObservableForAccessToken(uri: string, idToken: string, api: Api): Observable<Blob | null> {
  //   // Headers
  //   const headers = new HttpHeaders({ 'X-L': idToken })
  //   // console.log('getBinaryObservable this.http.get')
  //   return this.http.get(api + uri, { headers: headers, responseType: 'blob' })
  // }

  // public getBinaryObservable(uri: string, api?: Api): Observable<Blob | null> {
  //   const p = api ? api : LEMONATOR_HTTPS_API
  //   return this.getIdTokenObservable(p).pipe(
  //     switchMap(idToken => {
  //       if (idToken) {
  //         return this.getBinaryObservableForAccessToken(uri, idToken, p)
  //       }
  //       // console.log('getBinaryObservable return null')
  //       return of<Blob | null>(null)
  //     })
  //   )
  // }

  public async postBinaryGetBinary(uri: string, payload: FormData, api: API): Promise<Blob> {
    const token = await this.getIdTokenPromise()
    if (!token) {
      return Promise.reject(new Error('The token is null.'))
    }
    // Headers
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-L': token
    })
    return firstValueFrom(this.http.post(api + uri, payload, { headers: headers, responseType: 'blob' }))
  }

  public async getBinary(uri: string, api: API, headers?: { [key: string]: string }): Promise<Blob> {
    const token = await this.getIdTokenPromise()
    if (!token) {
      return Promise.reject(new Error('The token is null.'))
    }
    const hdrs = new HttpHeaders({ ...(headers ?? {}), 'X-L': token })
    return firstValueFrom(this.http.get(api + uri, { headers: hdrs, responseType: 'blob' }))
  }

  public async postJsonGetBinary(uri: string, payload: any, api: API): Promise<Blob> {
    const token = await this.getIdTokenPromise()
    if (!token) {
      return Promise.reject(new Error('The token is null.'))
    }
    // Headers
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-L': token
    })
    return firstValueFrom(this.http.post(api + uri, payload, { headers: headers, responseType: 'blob' }))
  }

  public async postJsonGetJsonWithCredentials<T, P>(uri: string, payload: P, api: API): Promise<T> {
    const token = await this.getIdTokenPromise()
    if (!token) {
      return Promise.reject(new Error('The token is null.'))
    }
    // Headers
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-L': token
    })
    return firstValueFrom(this.http.post<T>(api + uri, payload, { headers: headers, responseType: 'json', withCredentials: true }))
  }

  public async getJson<T>(uri: string): Promise<T> {
    // Headers
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Accept': 'application/json'
    })
    return firstValueFrom(this.http.get<T>(uri, { headers: headers, responseType: 'json' }))
  }

  // public getJsonWithCredentials<T>(uri: string, api: API): Observable<T> {
  //   return this.getIdTokenObservable().pipe(
  //     switchMap(idToken => {
  //       if (idToken) {
  //         // Headers
  //         const headers = new HttpHeaders({
  //           'Content-Type': 'application/json',
  //           'X-L': idToken
  //         })
  //         return this.http.get<T>(api + uri, { headers: headers, responseType: 'json', withCredentials: true })
  //       }
  //       return of<T>(null)
  //     })
  //   )
  // }

}
