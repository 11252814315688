import { Injectable } from '@angular/core'
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop'

import { FirestoreTositteenAlkuperainenTiedosto } from '../../_jaettu/model/tosite'
import { LaskunLiitetiedosto } from '../../_jaettu/model/lasku'

import { TositeUriService } from '../../_jaettu/service/tosite/tosite-uri.service'
import { LaskuUriService } from '../../_jaettu/service/lasku/lasku-uri.service'

import { Observable, BehaviorSubject, combineLatest, of, ReplaySubject } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { StorageError, UploadTask, UploadTaskSnapshot } from 'firebase/storage'
import { StorageUploader } from '../base-firebase.service'

export interface SimplifiedUploadData {
  filename: string
  uploadUri: string
  statusObservable: Observable<UploadStatus>
}

interface UploadStatus {
  donePercentage: number
  done: boolean
  error?: StorageError
}

export interface UploadData {
  uploadTask: UploadTask
  doneObservable: Observable<boolean>
  donePercentage: Observable<number>
  uploadUri: string
  error?: StorageError
  done?: boolean
}

@Injectable()
export class TiedostojenLataamisService {

  constructor(
    private tositeUriService: TositeUriService,
    private laskuUriService: LaskuUriService
  ) { }

  public simplifiedUpload(storageUploader: StorageUploader, uploadUri: string, file: File, bucket?: string): SimplifiedUploadData {

    const uploadTask = storageUploader.storageUpload(uploadUri, file, undefined, bucket)

    const statusObservable = new BehaviorSubject<UploadStatus>({ donePercentage: 0, done: false })

    const uploadData: SimplifiedUploadData = {
      filename: file.name,
      uploadUri: uploadUri,
      statusObservable: statusObservable
    }

    uploadTask.on('state_changed', (snapshot: UploadTaskSnapshot) => {
      statusObservable.next({
        done: false,
        donePercentage: (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      })
    }, error => {
      statusObservable.next({
        done: true,
        donePercentage: statusObservable.value.donePercentage,
        error: error
      })
      // BN! NOTE! OBS! HUOM! Do not call complete here even if it feels counter intuitive.
      // Calling complete will unsubscribe everything from the subject and then later subscriptions will fail.
      // This causes problems down the line.
      // statusObservable.complete()
    }, () => {
      const status: UploadStatus = {
        done: true,
        donePercentage: statusObservable.value.donePercentage
      }
      if (statusObservable.value.error) {
        status.error = statusObservable.value.error
      }
      statusObservable.next(status)
      // BN! NOTE! OBS! HUOM! Do not call complete here even if it feels counter intuitive.
      // Calling complete will unsubscribe everything from the subject and then later subscriptions will fail.
      // This causes problems down the line.
      // statusObservable.complete()
    })

    return uploadData
  }

  public upload(storageUploader: StorageUploader, uploadUri: string, file: File, bucket?: string): UploadData {

    const uploadTask = storageUploader.storageUpload(uploadUri, file, undefined, bucket)

    const doneObservable = new ReplaySubject<boolean>()
    const donePercentage = new BehaviorSubject(0)

    const uploadData: UploadData = {
      uploadTask: uploadTask,
      doneObservable: doneObservable,
      donePercentage: donePercentage,
      uploadUri: uploadUri
    }

    uploadTask.on('state_changed', (snapshot: UploadTaskSnapshot) => {
      donePercentage.next((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
    }, error => {
      uploadData.error = error
      doneObservable.error(error)

      // BN! NOTE! OBS! HUOM! Do not call complete here even if it feels counter intuitive.
      // Calling complete will unsubscribe everything from the subject and then later subscriptions will fail.
      // This causes problems down the line.
      // doneObservable.complete()
    }, () => {
      uploadData.done = true
      doneObservable.next(true)
      // BN! NOTE! OBS! HUOM! Do not call complete here even if it feels counter intuitive.
      // Calling complete will unsubscribe everything from the subject and then later subscriptions will fail.
      // This causes problems down the line.
      // doneObservable.complete()
    })

    return uploadData
  }

  public annaKokonaisprosentti(datas: UploadData[]): Observable<number> {
    if (datas) {
      return combineLatest(datas.map(a => a.donePercentage)).pipe(
        map(arvot => {
          if (arvot) {
            let summa = 0
            for (const arvo of arvot) {
              summa += arvo
            }
            return Math.round(summa / arvot.length)
          }
          return 0
        })
      )
    }
    return of(0)
  }

  public annaKokonaisprosenttiSimplified(datas: SimplifiedUploadData[]): Observable<number> {
    if (datas) {
      return combineLatest(datas.map(a => a.statusObservable)).pipe(
        map(arvot => {
          if (arvot) {
            let summa = 0
            for (const arvo of arvot) {
              summa += arvo.donePercentage
            }
            return Math.round(summa / arvot.length)
          }
          return 0
        })
      )
    }
    return of(0)
  }

  public annaYhdenVirhe(data: SimplifiedUploadData): Observable<string> {
    return data.statusObservable.pipe(
      map(status => status.error ? this.annaVirheteksti(status.error) : null)
    )
  }


  public annaKaikkiVirheetSimplified(datas: SimplifiedUploadData[]): Observable<string[]> {
    if (datas?.length) {
      return combineLatest(datas.map(a => {
        return this.annaYhdenVirhe(a)
      })).pipe(
        // Note that filtering must be done here using array's filter method, and not
        // rxjs filter method, as that leads to emitting EMPTY, which explodes
        // firstValueFrom()
        map(errors => errors.filter(str => !!str))
      )
    }
    return of([])
  }

  public annaKaikkiVirheet(datas: UploadData[]): Observable<string[]> {
    if (datas?.length) {
      return combineLatest(datas.map(a => {
        return a.doneObservable.pipe(
          catchError(err => of(this.annaVirheteksti(err))),
          map(() => null as string)
        )
      })).pipe(
        // Note that filtering must be done here using array's filter method, and not
        // rxjs filter method, as that leads to emitting EMPTY, which explodes
        // firstValueFrom()
        map(errors => errors.filter(str => !!str))
      )
    }
    return of([])
  }

  annaVirheteksti(error: any): string {
    if (error && error.message) {
      return error.message
    }
    return JSON.stringify(error)
  }

  /**
   * Tallenna laskun liitetiedosto palvelimelle.
   * @param storage käytettävä storage
   * @param asiakasAvain asiakas, jolle tallennetaan
   * @param liitetiedosto metadata
   * @param file Tallennettava blob
   */
  public tallennaLaskunliitetiedosto(storageUploader: StorageUploader, asiakasAvain: string, liitetiedosto: LaskunLiitetiedosto, file: File, bucket?: string): SimplifiedUploadData {
    const uploadUri = this.laskuUriService.annaLiitetiedostonCloudStorageUri(asiakasAvain, liitetiedosto)
    return this.simplifiedUpload(storageUploader, uploadUri, file, bucket)

    // const error = new Error('PERKELE!')
    // const doneObservable = new BehaviorSubject(false)
    // const donePercentage = new BehaviorSubject(0)
    // const errorObservable = new BehaviorSubject(error)

    // const uploadData: UploadData = {
    //   done: false,
    //   doneObservable: doneObservable,
    //   donePercentage: donePercentage,
    //   errorObservable: errorObservable,
    //   uploadUri: uploadUri,
    //   error: error,
    //   angularTask: null
    // }
    // return uploadData

  }

  /**
     * Tallenna tositetiedosto palvelimelle.
     * @param storage käytettävä storage
     * @param asiakasId asiakas, jolle tallennetaan
     * @param alkuperainen metadata
     * @param file Tallennettava blob
     */
  public tallennaTositetiedostoSimplified(storageUploader: StorageUploader, asiakasId: string, alkuperainen: FirestoreTositteenAlkuperainenTiedosto, file: File, bucket?: string): SimplifiedUploadData {
    const uploadUri = this.tositeUriService.annaCloudStorageAlkuperainenUriAsiakasIdlle(asiakasId, alkuperainen)
    return this.simplifiedUpload(storageUploader, uploadUri, file, bucket)

    // TESTAAA VIRHETTÄ LATAUKSESSA!!
    // const error = new Error('PERKELE!')
    // const doneObservable = new BehaviorSubject(false)
    // const donePercentage = new BehaviorSubject(0)
    // const errorObservable = new BehaviorSubject(error)

    // const uploadData: UploadData = {
    //   done: false,
    //   doneObservable: doneObservable,
    //   donePercentage: donePercentage,
    //   errorObservable: errorObservable,
    //   uploadUri: uploadUri,
    //   error: error,
    //   angularTask: null
    // }
    // return uploadData

  }

  /**
   * Tallenna tositetiedosto palvelimelle.
   * @param storage käytettävä storage
   * @param asiakasId asiakas, jolle tallennetaan
   * @param alkuperainen metadata
   * @param file Tallennettava blob
   */
  public tallennaTositetiedosto(storageUploader: StorageUploader, asiakasId: string, alkuperainen: FirestoreTositteenAlkuperainenTiedosto, file: File, bucket?: string): UploadData {
    const uploadUri = this.tositeUriService.annaCloudStorageAlkuperainenUriAsiakasIdlle(asiakasId, alkuperainen)
    return this.upload(storageUploader, uploadUri, file, bucket)

    // TESTAAA VIRHETTÄ LATAUKSESSA!!
    // const error = new Error('PERKELE!')
    // const doneObservable = new BehaviorSubject(false)
    // const donePercentage = new BehaviorSubject(0)
    // const errorObservable = new BehaviorSubject(error)

    // const uploadData: UploadData = {
    //   done: false,
    //   doneObservable: doneObservable,
    //   donePercentage: donePercentage,
    //   errorObservable: errorObservable,
    //   uploadUri: uploadUri,
    //   error: error,
    //   angularTask: null
    // }
    // return uploadData

  }

  public getFileSize(fileEntry: FileSystemFileEntry): Promise<number> {
    return this.getFile(fileEntry).then(file => {
      return file.size
    })
  }

  public getFileEndingFromNgxFileDropEntry(entry: NgxFileDropEntry): string {
    const kokonimi = entry ? entry.relativePath : ''
    return this.getFileEndingFromFileName(kokonimi)
  }

  public getFileEndingFromFile(file: File): string {
    const kokonimi = file ? file.name : ''
    return this.getFileEndingFromFileName(kokonimi)
  }

  // TODO: TÄMÄ ON MYÖS NODE-FILE.SERVICE.TS:SSÄ...
  public getFileEndingFromFileName(kokonimi: string): string {
    if (kokonimi && kokonimi.indexOf('.') > -1) {
      const start = kokonimi.lastIndexOf('.') + 1
      if (start < kokonimi.length) {
        return kokonimi.substring(start)
      }
    }
    return 'unknown'
  }

  public getFile(fileEntry: FileSystemFileEntry): Promise<File> {
    return new Promise((resolve, reject) => {
      try {
        fileEntry.file(file => {
          resolve(file)
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  public getAsUint8Array(blob: Blob | File): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = reader.result as ArrayBuffer
        const uint8Array = new Uint8Array(data)
        resolve(uint8Array)
      }
      reader.onerror = (e) => {
        reject(reader.error)
      }
      reader.readAsArrayBuffer(blob)
    })
  }

  public getAsDataUrl(blob: Blob | File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = reader.result as string
        resolve(data)
      }
      reader.onerror = (e) => {
        reject(reader.error)
      }
      reader.readAsDataURL(blob)
    })
  }

  public fileListToNgxFileDropEntries(list: FileList): NgxFileDropEntry[] {

    const tiedostot: NgxFileDropEntry[] = []
    for (let i = 0; i < list.length; i++) {

      const file: File = list.item(i)

      const entry: FileSystemFileEntry = {
        isDirectory: false,
        isFile: true,
        name: file.name,
        file: <T>(callback: (filea: File) => T) => callback(file)
      }

      const tiedosto: NgxFileDropEntry = {
        relativePath: file.name,
        fileEntry: entry
      }

      tiedostot.push(tiedosto)

    }

    return tiedostot

  }

}
