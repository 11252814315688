<div class="blue-background">
  <div style="margin-left: auto; margin-right: auto">

    <div class="ylapalkki">

      <button class="sulje" mat-button (click)="peruuta()" type="button">
        <i style="font-size: 60px; width: 60px; height: 60px; line-height: 60px; display: inline-block;" class="icon ion-md-arrow-back"></i>
      </button>

      <h2 style="margin: 0; width: calc(100% - 18vw); text-align: center;">
        {{ otsikko }}
      </h2>

    </div>

  </div>
</div>

<div *ngIf="tositteetJaTiedotObservable | async let tositteetJaTiedot else loading">

  <div class="kuitti-container">

    <ul style="margin-bottom: 80px;" *ngIf="initialLoadDoneObservable | async">
      <ng-container *ngFor="let bakkarissa of tositteetJaTiedotBackendissaObservable | async">
        <ng-container *ngFor="let t of bakkarissa.tiedostotSubject | async">
          <li *ngIf="t.tila === 'backend'">
            {{ 'kuitit.muokkaa.validation.purkuvirhe-lahetetty-backend' | translate: { file_name: t.uploadData.filename } | async }}
          </li>
          <li *ngIf="t.tila === 'purkuvirhe'">
            {{ 'kuitit.muokkaa.validation.purkuvirhe-myos-backend' | translate: { file_name: t.uploadData.filename } | async }}
            <ng-container *ngIf="tositteetJaTiedot?.length > 0">{{ 'kuitit.muokkaa.validation.purkuvirhe-myos-backend-voit-tallentaa-muut' | translate | async }}</ng-container>
          </li>
          <li *ngIf="t.tila === 'latausvirhe'">
            {{ 'kuitit.muokkaa.validation.latausvirhe' | translate: { file_name: t.uploadData.filename } | async }}
          </li>
          <li *ngIf="t.tila !== 'latausvirhe' && t.tila !== 'purkuvirhe' && t.tila !== 'backend'">
            {{ 'kuitit.muokkaa.validation.tuntematon-virhe' | translate: { file_name: t.uploadData.filename } | async }}
          </li>
        </ng-container>
      </ng-container>
    </ul>

    <div *ngIf="tositteetJaTiedot.length > 1" style="margin-bottom: 10px;">
      <mat-checkbox (change)="yhdistaKuitit($event)" style="font-size: 14px;">{{ 'kuitit.muokkaa.yhdista' | translate | async }}</mat-checkbox>
    </div>

    <div *ngIf="tositteetJaTiedot.length < 1 && (initialLoadDoneObservable | async)" class="error-text" style="margin: 2em;">
      {{ 'kuitit.muokkaa.validation.ei-yhtaan-purettua' | translate | async }}
    </div>

    <div>

      <div *ngFor="let tositeJaTiedot of tositteetJaTiedot; let i = index;" class="tosite-container">

        <div class="tosite-tiedot">

          <form [formGroup]="tositeJaTiedot.formGroup" novalidate autocomplete="kirjanpitoaineistoarkki" style="width: 100%;">

            <h1 *ngIf="tositteetJaTiedot.length > 1" style="font-size: 28px; line-height: 34px; text-align: left;" class="light-blue-text">{{ 'kuitit.muokkaa.tosite' | translate | async }} {{i+1}}</h1>

            <div *ngIf="showMaksutapa" style="padding-bottom: 5px;">
              <div class="mat-form-field" style="font-size: 10.5px;">{{ 'kuitit.muokkaa.maksettu' | translate | async }} *</div>
              <app-kuitit-maksutapa formControlName="maksutapa" [filterNonActive]="true" [error]="tositeJaTiedot.formGroup.get('maksutapa')?.errors?.length"></app-kuitit-maksutapa>
              <mat-error *ngIf="tositeJaTiedot.formGroup.get('maksutapa'); let n">
                <ng-container *ngIf="(n?.dirty || n?.touched)">
                  <ng-container *ngIf="n.errors?.required">{{ 'kuitit.muokkaa.validation.maksutapa.required' | translate | async }}</ng-container>
                </ng-container>
              </mat-error>
            </div>

            <ng-container *ngIf="showPvm">
              <ng-container *ngIf="!showPvmAsMonth; else monthInput">
                <mat-form-field>
                  <mat-label>{{ pvmText }}</mat-label>
                  <input numberDatePicker matInput formControlName="pvm" #pvmVar="matInput" [name]="namename" [matDatepicker]="dayPicker" [min]="minDate" [max]="maxDate" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
                  <mat-error *ngIf="(pvmVar?.ngControl?.dirty || pvmVar?.ngControl?.touched) && pvmVar.ngControl?.errors?.required">{{ 'kuitit.muokkaa.validation.pvm.required' | translate | async }}</mat-error>
                  <mat-error *ngIf="(pvmVar?.ngControl?.dirty || pvmVar?.ngControl?.touched) && pvmVar.ngControl?.errors?.matDatepickerParse">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerParse' | translate | async }}</mat-error>
                  <mat-error *ngIf="(pvmVar?.ngControl?.dirty || pvmVar?.ngControl?.touched) && pvmVar.ngControl?.errors?.matDatepickerMax">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerMax' | translate | async }}</mat-error>
                  <mat-error *ngIf="(pvmVar?.ngControl?.dirty || pvmVar?.ngControl?.touched) && pvmVar.ngControl?.errors?.matDatepickerMin">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerMin' | translate | async }}</mat-error>
                  <mat-datepicker-toggle matSuffix [for]="dayPicker"></mat-datepicker-toggle>
                  <mat-datepicker #dayPicker></mat-datepicker>
                </mat-form-field>
              </ng-container>
              <ng-template #monthInput>
                <mat-form-field>
                  <mat-label>{{ pvmText }}</mat-label>
                  <input numberDatePicker monthPicker matInput formControlName="pvm" #pvmVar="matInput" [name]="namename" [matDatepicker]="monthPicker" [min]="minDate" [max]="maxDate" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
                  <ng-container *ngIf="tositeJaTiedot.formGroup.get('pvm'); let n">
                    <ng-container *ngIf="(n?.dirty || n?.touched)">
                      <mat-error *ngIf="(pvmVar?.ngControl?.dirty || pvmVar?.ngControl?.touched) && pvmVar.ngControl?.errors?.required">{{ 'kuitit.muokkaa.validation.pvm.required' | translate | async }}</mat-error>
                      <mat-error *ngIf="(pvmVar?.ngControl?.dirty || pvmVar?.ngControl?.touched) && pvmVar.ngControl?.errors?.matDatepickerParse">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerParse' | translate | async }}</mat-error>
                      <mat-error *ngIf="(pvmVar?.ngControl?.dirty || pvmVar?.ngControl?.touched) && pvmVar.ngControl?.errors?.matDatepickerMax">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerMax' | translate | async }}</mat-error>
                      <mat-error *ngIf="(pvmVar?.ngControl?.dirty || pvmVar?.ngControl?.touched) && pvmVar.ngControl?.errors?.matDatepickerMin">{{ 'kuitit.muokkaa.validation.pvm.matDatepickerMin' | translate | async }}</mat-error>
                    </ng-container>
                  </ng-container>
                  <mat-datepicker-toggle matSuffix [for]="monthPicker"></mat-datepicker-toggle>
                  <mat-datepicker #monthPicker></mat-datepicker>
                </mat-form-field>
              </ng-template>
            </ng-container>

            <br />

            <!-- 'tuote.hinta' | translate | async -->
            <mat-form-field *ngIf="showSumma">
              <mat-label>{{ summaText }}</mat-label>
              <input decimalNumberField matInput class="alignLeft" formControlName="summa" #summaVar="matInput" [name]="namename" autocomplete="kirjnpitomekiarki" data-lpignore="true" required>
              <mat-error *ngIf="(summaVar?.ngControl?.dirty || summaVar?.ngControl?.touched) && summaVar.ngControl?.errors?.required">{{ 'kuitit.muokkaa.validation.summa.required' | translate | async }}</mat-error>
            </mat-form-field>

            <br />

            <ng-container *ngIf="showAlv">
              <div *ngIf="alvPortaatObservable | async let alvPortaat">
                <mat-form-field>
                  <mat-label>{{ 'kuitit.muokkaa.alv' | translate | async }}</mat-label>
                  <mat-select formControlName="alv">
                    <mat-option *ngFor="let porras of alvPortaat" [value]="porras">
                      {{ porras }} %
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <br>
              </div>
            </ng-container>

            <mat-form-field>
              <mat-label>{{ 'kuitit.muokkaa.lisatiedot' | translate | async }}</mat-label>
              <textarea matInput formControlName="lisatiedot" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="35" autocomplete="kirjnpitomekiarki"></textarea>
            </mat-form-field>

            <div class="button-container">

              <div *ngIf="tositeJaTiedot.naytaTallenna">

                <div *ngIf="virheviestiLatausOnKeskenObservable | async let viesti" class="error-text" style="padding-bottom: 2em;">
                  {{ viesti }}
                </div>

                <div *ngIf="(latausvirheetObservable | async)?.length > 0" class="error-text" style="padding-bottom: 2em; text-align: left;">
                  {{'lasku.tiedoston-lahettaminen-epaonnistui-alku' | translate | async}}
                  <ul>
                    <li *ngFor="let virhe of latausvirheetObservable | async">{{virhe}}</li>
                  </ul>
                  {{'lasku.tiedoston-lahettaminen-epaonnistui-loppu' | translate | async}}
                </div>

                <button class="primarybutton" mat-button (click)="save()">{{'yleiset.tallenna' | translate | async}}</button>
                <br />

                <div *ngIf="1 > (latausvirheetObservable | async)?.length && 100 > (donePercentageObservable | async)" style="width:50%; margin-top: 2em; text-align: center; display: inline-block">
                  <mat-progress-bar mode="determinate" [value]="donePercentageObservable | async"></mat-progress-bar>
                  <div style="padding-top: 3px; font-size: 12px">{{ 'kuitit.muokkaa.ladataan' | translate | async }}</div>
                </div>

              </div>
            </div>

          </form>

        </div>

        <div class="tosite-sivut">

          <!-- <div *ngIf="(tositeJaTiedot.tiedostotSubject | async)?.length < 1">
            LIIAN VÄHÄN TIEDOSTOJA!
          </div> -->

          <ng-container *ngIf="tositeJaTiedot.tiedostotSubject | async let tiedostot">
            <div class="tosite-tiedosto" *ngFor="let tiedosto of tiedostot; let i = index">

              <h1 *ngIf="tiedostot.length > 1" style="font-size: 28px; line-height: 34px; text-align: left;" class="light-blue-text">{{ 'kuitit.muokkaa.tiedosto' | translate | async }} {{i+1}}</h1>

              <!-- <button class="poista-btn mat-elevation-z4" mat-icon-button (click)="poistaTiedosto(tositeJaTiedot, tiedosto)" type="button">
                <mat-icon class="poista-icon">close</mat-icon>
              </button> -->

              <div class="image-change-page-buttons-container" *ngIf="tiedosto.kuvat.length > 1">
                <div *ngFor="let kuva of tiedosto.kuvat; let a = index;" class="image-change-page-button lemon-mat-elevation-z4" [class.selected-image]="kuva == tiedosto.nakyvaKuva" (click)="vaihdaNakyvaKuva(tiedosto, kuva)">
                  {{a + 1}}
                </div>
              </div>

              <!-- *ngIf="!(tiedosto?.uploadData?.statusObservable | async)?.error && 100 > (tiedosto?.uploadData?.statusObservable | async)?.donePercentage" -->

              <ng-container *ngIf="tiedosto?.uploadData?.statusObservable | async let status">
                <table *ngIf="!status.error" style="width: 100%;" cellPadding="0" cellSpacing="0">
                  <tr>
                    <td width="*">
                      <div *ngIf="status.donePercentage < 100" style="padding-top: 0px; font-size: 12px; line-height: 14px;">{{ 'kuitit.muokkaa.ladataan' | translate | async }}</div>
                      <div *ngIf="status.donePercentage > 99.99" style="padding-top: 0px; font-size: 12px; line-height: 14px;">{{ 'kuitit.muokkaa.lataus-valmis' | translate | async }}</div>
                      <mat-progress-bar mode="determinate" [value]="status.donePercentage"></mat-progress-bar>
                    </td>
                    <td style="width: 20px;"><mat-icon *ngIf="status.donePercentage > 99.99" style="height: 18px; width: 18px;" class="green-text">done</mat-icon></td>
                  </tr>
                </table>
              </ng-container>

              <div app-tosite-kuva [url]="tiedosto.nakyvaKuva" class="mat-elevation-z4" style="width: 1080px; max-width: 100%; margin-bottom: 8cqw; position: relative; min-height: 50px;"></div>

            </div>
          </ng-container>

        </div>

      </div>

    </div>

  </div>

</div>

<ng-template #loading>
  <div style="text-align: center; padding: 50px 0;">
    <mat-spinner style="display: inline-block;"></mat-spinner>
    <div style="text-align: center; padding: 20px 0;">{{ 'kuitit.muokkaa.kasitellaan' | translate | async}}</div>
  </div>
</ng-template>