<div class="list-page kapea ostotositteet-listaus-page" style="padding: 0;" [class.dragging]="isDragging">

  <form [formGroup]="form" novalidate class="ylaosan-kontrollit">

    <div class="lisaa-nappula-container">
      <button mat-button (click)="naytaTiedostonValintaDialogi()" type="button" class="primarybutton mat-elevation-z4">
        <i class="icon ion-ios-cloud-upload mat-icon"></i>
        {{'tositteet.osto.listaus.lisaa' | translate | async}}
      </button>
    </div>

    <div class="hakukontrollit">

      <mat-form-field>
        <mat-icon matPrefix class="listaus-haku-ikoni">search</mat-icon>
        <mat-label>{{ 'kuitit.etsi' | translate | async }}</mat-label>
        <input type="text" formControlName="nimiFirestore" matInput />
      </mat-form-field>

    </div>

  </form>

  <div *ngIf="kuvavirheAvain" class="red-text" style="text-align: center; padding-left: 10vw; padding-right: 10vw;">{{kuvavirheAvain | translate: kuvavirheParametrit | async}}</div>

  <div style="padding-top: 20px;">

    <div *ngIf="!etsitaan">
      <table style="margin-left: auto; margin-right: auto;">
        <tr>
          <td>
            <button mat-icon-button (click)="edellinenKuukausi()" class="dark-gray-text large-icon-button" style="padding-right: 10px;">
              <i class="icon ion-ios-arrow-back mat-icon"></i>
            </button>
          </td>
          <td style="font-size: 28px; line-height: 34px; text-align: center;" class="light-blue-text">
            {{ otsikkoObservable | async }}
          </td>
          <td>
            <button mat-icon-button (click)="seuraavaKuukausi()" class="dark-gray-text large-icon-button" style="padding-left: 10px;">
              <i class="icon ion-ios-arrow-forward mat-icon"></i>
            </button>
          </td>
        </tr>
      </table>
      <div class="kuukausi-summa dark-gray-text">
        {{ kuukaudenSummaObservable | async | lmoney: 'EUR' | async }}
      </div>
      <!-- <button mat-icon-button>
        <svg class="mat-datepicker-toggle-default-icon ng-star-inserted" fill="currentColor" focusable="false" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path></svg>
      </button>  -->
    </div>


    <div style="display: flex; width: 100%; flex-flow: row wrap; align-items: top; justify-content: center; padding-top: 25px;">

      <app-selvitettavat-maksutapa *ngIf="naytaSelvitettaviaObservable | async" style="margin: 20px; align-self: flex-start;" [insertValues]="selvitettavienTiedotObservable | async" [selvitettavatValittu]="selvitettavatValittuObservable | async" (valitsiSelvitettavat)="onkoSelvitettavatValittu()">
      </app-selvitettavat-maksutapa>

      <ngx-file-drop #droppi *ngFor="let maksutapaJaKuva of maksutavatJaKuvatObservable | async" dropZoneClassName="maksutapa-container" [class.valittu-maksutapa]="maksutapaJaKuva.valittu && (!isDragging || !maksutapaJaKuva.maksutapaanVoiRaahata)" (click)="valitseMaksutapa(maksutapaJaKuva)" (onFileOver)="fileOver($event, maksutapaJaKuva)" (onFileDrop)="fileDrop($event, maksutapaJaKuva)"
        (onFileLeave)="fileLeave($event, maksutapaJaKuva)" class="droppi" [class.voi-raahata]="maksutapaJaKuva.maksutapaanVoiRaahata">
        <ng-template ngx-file-drop-content-tmp>
          <div class="maksutapa-laatikko" [class.lemon-mat-elevation-z4]="(!isDragging || !maksutapaJaKuva.maksutapaanVoiRaahata)">

            <div class="kuittien-maara-maksutavalle" *ngIf="(!isDragging || !maksutapaJaKuva.maksutapaanVoiRaahata) " [class.light-gray-text]="!maksutapaJaKuva.valittu" [class.light-blue-text]=" maksutapaJaKuva.valittu">
              {{ maksutapaJaKuva.lukumaara }}
            </div>

            <img [src]="maksutapaJaKuva.kuva | async" [class.light-gray-background]="(!maksutapaJaKuva.valittu && (!isDragging || !maksutapaJaKuva.maksutapaanVoiRaahata)) || (!droppi.isDraggingOverDropZone && isDragging && maksutapaJaKuva.maksutapaanVoiRaahata)"
              [class.light-blue-background]="( maksutapaJaKuva.valittu && (!isDragging || !maksutapaJaKuva.maksutapaanVoiRaahata)) || ( droppi.isDraggingOverDropZone && isDragging && maksutapaJaKuva.maksutapaanVoiRaahata)" />

            <div class="maksutavan-nimi-container" [class.light-gray-text]="(!maksutapaJaKuva.valittu && (!isDragging || !maksutapaJaKuva.maksutapaanVoiRaahata)) || (!droppi.isDraggingOverDropZone && isDragging && maksutapaJaKuva.maksutapaanVoiRaahata)"
              [class.light-blue-text]="( maksutapaJaKuva.valittu && (!isDragging || !maksutapaJaKuva.maksutapaanVoiRaahata)) || ( droppi.isDraggingOverDropZone && isDragging && maksutapaJaKuva.maksutapaanVoiRaahata)">
              <span style="display: inline-block; vertical-align: middle;">
                {{maksutapaJaKuva.maksutapa.l}}
              </span>
            </div>

          </div>

          <div class="maksutapa-summa" *ngIf="(!isDragging || !maksutapaJaKuva.maksutapaanVoiRaahata)" [class.light-gray-text]="!maksutapaJaKuva.valittu" [class.light-blue-text]="maksutapaJaKuva.valittu">
            {{ maksutapaJaKuva.summa | lmoney: 'EUR' | async }}
          </div>

          <div class="veda-tanne light-blue-text" *ngIf="isDragging && maksutapaJaKuva.maksutapaanVoiRaahata">
            {{'kuitit.veda-tanne' | translate | async}}
          </div>
        </ng-template>
      </ngx-file-drop>
    </div>

  </div>

  <div>

    <ng-container *ngIf="!(naytaSelvitettaviaObservable | async) || !(selvitettavatValittuObservable | async); else selvitettavatTositteet">
      <div class="kortti-container">
        <div *ngFor="let kuitti of naytettavatKuititObservable | async; trackBy: trackKuitti">
          <div class="kuitti-laatikko lemon-mat-elevation-z4" (click)="katsele(kuitti)">
            <mat-icon *ngIf="vihreaPilvi(kuitti); else keltsupilvi" style="position: absolute; right: 5px; top: 5px;" class="green-text">cloud_done</mat-icon>
            <ng-template #keltsupilvi>
              <mat-icon style="position: absolute; right: 8px; top: 5px;" class="yellow-text flashit">cloud_upload</mat-icon>
            </ng-template>

            <div app-tosite-kuva [url]="kuitti.ensimmainenKuva" class="kuva-container" [thumbnail]="true"></div>

            <div class="tiedot-container">
              <div class="pvm dark-gray-text">{{ kuitti.localPvm | lokaalipvm | async }}</div>
              <div class="summa light-blue-text">{{ kuitti.sum ?? kuitti.summa | lmoney: 'EUR' | async }}</div>
              <div class="viiva light-gray-background"></div>
              <div class="el-kommentti" *ngIf="kuitti?.el || kuitti?.seller">{{kuitti.el || kuitti.seller}}</div>
              <div class="selite dark-gray-text">{{ kuitti.selite }}</div>
              <div [@slide] *ngIf="kuitti.naytaLisaa" (click)="toggleNaytaLisaa($event, kuitti)">
                <div class="tosite-api-msg">{{kuitti.userApiMessage}}</div>
              </div>
              <div *ngIf="kuitti.userApiMessage" (click)="toggleNaytaLisaa($event, kuitti)" class="nayta-lisaa dark-gray-text">{{( kuitti.naytaLisaa ? 'kuitit.nayta-vahemman' : 'kuitit.nayta-lisaa' ) | translate | async}} <span class="arrow">{{ kuitti.naytaLisaa ? '▲' : '▼' }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #selvitettavatTositteet>
      <app-selvitettavat-tositteet (selvitettavatDone)="openDefaultMaksutapa()"></app-selvitettavat-tositteet>
    </ng-template>

    <div class="table-ladataan" style="position: relative; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="(naytaEiYhtaanObservable | async) && !(selvitettavatValittuObservable | async)">
      <div class="dark-gray-text" style="width: 100%; padding-top: 50px; text-align: center; margin: 10px 40px;">
        {{'kuitit.hakuehdoilla-ei-yhtaan-tulosta' | translate | async}}
      </div>
    </div>
    <div class="table-ladataan" style="position: relative; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="(lataaObservable | async) && !(selvitettavatValittuObservable | async)">
      <div class="dark-gray-text" style="width: 100%; padding-top: 50px; text-align: center; margin: 10px 40px; position:relative;">
        <div lemon-loading [show]="true"></div>
      </div>
    </div>
  </div>

</div>

<input style="visibility:hidden; height: 1px;" (change)="fileChanged($event)" type="file" #fileInput multiple="multiple" />
<!-- accept="image/*,application/pdf" -->