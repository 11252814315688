import { extractBIC, isValidBIC } from 'ibantools'

export type Bic = 'HANDFIHH' | 'ESSEFIHX' | 'DABAFIHX' | 'SBANFIHH' | 'DNBAFIHX' | 'SWEDFIHH' | 'HELSFIHH' | 'BIGKFIH1' | 'POPFFI22' | 'HOLVFIHH' | 'CITIFIHX' | 'NARYFIH2' | 'ITELFIHH' | 'VPAYFIH2' | 'EVSEFIHH' | 'NDEAFIHH' | 'OKOYFIHH' | 'AABAFI22' | 'DABAFIHH' | 'REVOLT21XXX'
// http://www.finanssiala.fi/maksujenvalitys/dokumentit/Suomalaiset_rahalaitostunnukset_ja_BIC-koodit.pdf
export class BicService {

  public annaBicKoodi(iban: string): Bic | null {

    const maanKoodi = this.annaMaanKoodi(iban)

    if (!maanKoodi) {
      return null
    }

    if (maanKoodi === 'LT') {
      if (this.annaPankinKoodi(iban) === '32500') {
        return 'REVOLT21XXX'
      }
    }

    if (maanKoodi !== 'FI') {
      return null
    }

    const pankinKoodi = this.annaPankinKoodi(iban)
    if (pankinKoodi && pankinKoodi.length > 0) {
      const eka = pankinKoodi.substring(0, 1)

      // Handelsbanken 31 HANDFIHH
      // Skandinaviska Enskilda Banken (SEB) 33 ESSEFIHX
      // Danske Bank 34 DABAFIHX
      // S-Pankki 36 SBANFIHH
      // DNB Bank ASA, Finland Branch 37 DNBAFIHX
      // Swedbank 38 SWEDFIHH
      // S-Pankki 39 SBANFIHH
      if (eka === '3') {
        const koodi = pankinKoodi.substring(0, 2)
        switch (koodi) {
          case '31': { return 'HANDFIHH' }
          case '33': { return 'ESSEFIHX' }
          case '34': { return 'DABAFIHX' }
          case '36': { return 'SBANFIHH' }
          case '37': { return 'DNBAFIHX' }
          case '38': { return 'SWEDFIHH' }
          case '39': { return 'SBANFIHH' }
        }
      } else if (eka === '4' || eka === '7') {

        // Aktia Pankki 405, 497 HELSFIHH
        // Bigbank, Finland Branch 717 BIGKFIH1
        // POP Pankit (POP) 470-478 POPFFI22
        // Bonum Pankki 479 POPFFI22
        // Citibank 713 CITIFIHX
        // Holvi 799 HOLVFIHH
        // Säästöpankkien Keskuspankki, Säästöpankit (Sp) ja
        // Oma Säästöpankki
        // 715, 400, 402, 403,
        // 406-408, 410-
        // 412,414-421,
        // 423-432,435-
        // 452,454-464,
        // 483-493,495-496
        // ITELFIHH

        const koodi = pankinKoodi.substring(0, 3)
        switch (koodi) {
          case '405': { return 'HELSFIHH' }
          case '497': { return 'HELSFIHH' }
          case '717': { return 'BIGKFIH1' }
          case '470': { return 'POPFFI22' }
          case '471': { return 'POPFFI22' }
          case '472': { return 'POPFFI22' }
          case '473': { return 'POPFFI22' }
          case '474': { return 'POPFFI22' }
          case '475': { return 'POPFFI22' }
          case '476': { return 'POPFFI22' }
          case '477': { return 'POPFFI22' }
          case '478': { return 'POPFFI22' }
          case '479': { return 'POPFFI22' }
          case '713': { return 'CITIFIHX' }
          case '799': { return 'HOLVFIHH' }

          case '796': { return 'NARYFIH2' }

          case '798': { return 'VPAYFIH2' }

          // 714, Fellow Pankki
          case '714': { return 'EVSEFIHH' }


          // 715
          case '715': { return 'ITELFIHH' }

          // 400
          case '400': { return 'ITELFIHH' }

          // 402
          case '402': { return 'ITELFIHH' }

          // 403,
          case '403': { return 'ITELFIHH' }

          // 406-408
          case '406': { return 'ITELFIHH' }
          case '407': { return 'ITELFIHH' }
          case '408': { return 'ITELFIHH' }

          // 410-412
          case '410': { return 'ITELFIHH' }
          case '411': { return 'ITELFIHH' }
          case '412': { return 'ITELFIHH' }

          // 414-421
          case '414': { return 'ITELFIHH' }
          case '415': { return 'ITELFIHH' }
          case '416': { return 'ITELFIHH' }
          case '417': { return 'ITELFIHH' }
          case '418': { return 'ITELFIHH' }
          case '419': { return 'ITELFIHH' }
          case '420': { return 'ITELFIHH' }
          case '421': { return 'ITELFIHH' }

          // 423-432
          case '423': { return 'ITELFIHH' }
          case '424': { return 'ITELFIHH' }
          case '425': { return 'ITELFIHH' }
          case '426': { return 'ITELFIHH' }
          case '427': { return 'ITELFIHH' }
          case '428': { return 'ITELFIHH' }
          case '429': { return 'ITELFIHH' }
          case '430': { return 'ITELFIHH' }
          case '431': { return 'ITELFIHH' }
          case '432': { return 'ITELFIHH' }

          // 435-452
          case '435': { return 'ITELFIHH' }
          case '436': { return 'ITELFIHH' }
          case '437': { return 'ITELFIHH' }
          case '438': { return 'ITELFIHH' }
          case '439': { return 'ITELFIHH' }
          case '440': { return 'ITELFIHH' }
          case '441': { return 'ITELFIHH' }
          case '442': { return 'ITELFIHH' }
          case '443': { return 'ITELFIHH' }
          case '444': { return 'ITELFIHH' }
          case '445': { return 'ITELFIHH' }
          case '446': { return 'ITELFIHH' }
          case '447': { return 'ITELFIHH' }
          case '448': { return 'ITELFIHH' }
          case '449': { return 'ITELFIHH' }
          case '450': { return 'ITELFIHH' }
          case '451': { return 'ITELFIHH' }
          case '452': { return 'ITELFIHH' }

          // 454-464
          case '454': { return 'ITELFIHH' }
          case '455': { return 'ITELFIHH' }
          case '456': { return 'ITELFIHH' }
          case '457': { return 'ITELFIHH' }
          case '458': { return 'ITELFIHH' }
          case '459': { return 'ITELFIHH' }
          case '460': { return 'ITELFIHH' }
          case '461': { return 'ITELFIHH' }
          case '462': { return 'ITELFIHH' }
          case '463': { return 'ITELFIHH' }
          case '464': { return 'ITELFIHH' }

          // 483-493
          case '483': { return 'ITELFIHH' }
          case '484': { return 'ITELFIHH' }
          case '485': { return 'ITELFIHH' }
          case '486': { return 'ITELFIHH' }
          case '487': { return 'ITELFIHH' }
          case '488': { return 'ITELFIHH' }
          case '489': { return 'ITELFIHH' }
          case '490': { return 'ITELFIHH' }
          case '491': { return 'ITELFIHH' }
          case '492': { return 'ITELFIHH' }
          case '493': { return 'ITELFIHH' }

          // 495-496
          case '495': { return 'ITELFIHH' }
          case '496': { return 'ITELFIHH' }
        }
      } else {

        // Nordea Pankki (Nordea) 1 ja 2 NDEAFIHH
        // OP Ryhmä 5 OKOYFIHH
        // Ålandsbanken (ÅAB) 6 AABAFI22
        // Danske Bank 8 DABAFIHH

        switch (eka) {
          case '1': { return 'NDEAFIHH' }
          case '2': { return 'NDEAFIHH' }
          case '5': { return 'OKOYFIHH' }
          case '6': { return 'AABAFI22' }
          case '8': { return 'DABAFIHH' }
        }
      }

    }

    return null
  }

  public annaPankinNimi(iban: string): string | null {

    const maanKoodi = this.annaMaanKoodi(iban)

    if (!maanKoodi) {
      return null
    }

    if (maanKoodi === 'LT') {
      if (this.annaPankinKoodi(iban) === '32500') {
        return 'Revolut'
      }
    }

    if (maanKoodi !== 'FI') {
      return null
    }
    const pankinKoodi = this.annaPankinKoodi(iban)
    if (pankinKoodi && pankinKoodi.length > 0) {
      const eka = pankinKoodi.substring(0, 1)

      // Handelsbanken 31 HANDFIHH
      // Skandinaviska Enskilda Banken (SEB) 33 ESSEFIHX
      // Danske Bank 34 DABAFIHX
      // S-Pankki 36 SBANFIHH
      // DNB Bank ASA, Finland Branch 37 DNBAFIHX
      // Swedbank 38 SWEDFIHH
      // S-Pankki 39 SBANFIHH
      if (eka === '3') {
        const koodi = pankinKoodi.substring(0, 2)
        switch (koodi) {
          case '31': { return 'Handelsbanken' }
          case '33': { return 'Skandinaviska Enskilda Banken (SEB)' }
          case '34': { return 'Danske Bank' }
          case '36': { return 'S-Pankki' }
          case '37': { return 'DNB Bank ASA, Finland Branch' }
          case '38': { return 'Swedbank' }
          case '39': { return 'S-Pankki' }
        }
      } else if (eka === '4' || eka === '7') {

        // Aktia Pankki 405, 497 HELSFIHH
        // Bigbank, Finland Branch 717 BIGKFIH1
        // POP Pankit (POP) 470-478 POPFFI22
        // Bonum Pankki 479 POPFFI22
        // Citibank 713 CITIFIHX
        // Holvi 799 HOLVFIHH
        // Säästöpankkien Keskuspankki, Säästöpankit (Sp) ja
        // Oma Säästöpankki
        // 715, 400, 402, 403,
        // 406-408, 410-
        // 412,414-421,
        // 423-432,435-
        // 452,454-464,
        // 483-493,495-496
        // ITELFIHH

        const koodi = pankinKoodi.substring(0, 3)
        switch (koodi) {
          case '405': { return 'Aktia Pankki' }
          case '497': { return 'Aktia Pankki' }
          case '717': { return 'Bigbank, Finland Branch' }
          case '470': { return 'POP Pankit (POP)' }
          case '471': { return 'POP Pankit (POP)' }
          case '472': { return 'POP Pankit (POP)' }
          case '473': { return 'POP Pankit (POP)' }
          case '474': { return 'POP Pankit (POP)' }
          case '475': { return 'POP Pankit (POP)' }
          case '476': { return 'POP Pankit (POP)' }
          case '477': { return 'POP Pankit (POP)' }
          case '478': { return 'POP Pankit (POP)' }
          case '479': { return 'Bonum Pankki' }
          case '713': { return 'Citibank' }
          case '799': { return 'Holvi' }
          case '796': { return 'Narvi Payments' }
          case '798': { return 'Viva Payment Services S.A., Suomen sivuliike' }

          // 714, Fellow Pankki
          case '714': { return 'Fellow Pankki' }

          // 715
          case '715': { return 'Säästöpankki' }

          // 400
          case '400': { return 'Säästöpankki' }

          // 402
          case '402': { return 'Säästöpankki' }

          // 403,
          case '403': { return 'Säästöpankki' }

          // 406-408
          case '406': { return 'Säästöpankki' }
          case '407': { return 'Säästöpankki' }
          case '408': { return 'Säästöpankki' }

          // 410-412
          case '410': { return 'Säästöpankki' }
          case '411': { return 'Säästöpankki' }
          case '412': { return 'Säästöpankki' }

          // 414-421
          case '414': { return 'Säästöpankki' }
          case '415': { return 'Säästöpankki' }
          case '416': { return 'Säästöpankki' }
          case '417': { return 'Säästöpankki' }
          case '418': { return 'Säästöpankki' }
          case '419': { return 'Säästöpankki' }
          case '420': { return 'Säästöpankki' }
          case '421': { return 'Säästöpankki' }

          // 423-432
          case '423': { return 'Säästöpankki' }
          case '424': { return 'Säästöpankki' }
          case '425': { return 'Säästöpankki' }
          case '426': { return 'Säästöpankki' }
          case '427': { return 'Säästöpankki' }
          case '428': { return 'Säästöpankki' }
          case '429': { return 'Säästöpankki' }
          case '430': { return 'Säästöpankki' }
          case '431': { return 'Säästöpankki' }
          case '432': { return 'Säästöpankki' }

          // 435-452
          case '435': { return 'Säästöpankki' }
          case '436': { return 'Säästöpankki' }
          case '437': { return 'Säästöpankki' }
          case '438': { return 'Säästöpankki' }
          case '439': { return 'Säästöpankki' }
          case '440': { return 'Säästöpankki' }
          case '441': { return 'Säästöpankki' }
          case '442': { return 'Säästöpankki' }
          case '443': { return 'Säästöpankki' }
          case '444': { return 'Säästöpankki' }
          case '445': { return 'Säästöpankki' }
          case '446': { return 'Säästöpankki' }
          case '447': { return 'Säästöpankki' }
          case '448': { return 'Säästöpankki' }
          case '449': { return 'Säästöpankki' }
          case '450': { return 'Säästöpankki' }
          case '451': { return 'Säästöpankki' }
          case '452': { return 'Säästöpankki' }

          // 454-464
          case '454': { return 'Säästöpankki' }
          case '455': { return 'Säästöpankki' }
          case '456': { return 'Säästöpankki' }
          case '457': { return 'Säästöpankki' }
          case '458': { return 'Säästöpankki' }
          case '459': { return 'Säästöpankki' }
          case '460': { return 'Säästöpankki' }
          case '461': { return 'Säästöpankki' }
          case '462': { return 'Säästöpankki' }
          case '463': { return 'Säästöpankki' }
          case '464': { return 'Säästöpankki' }

          // 483-493
          case '483': { return 'Säästöpankki' }
          case '484': { return 'Säästöpankki' }
          case '485': { return 'Säästöpankki' }
          case '486': { return 'Säästöpankki' }
          case '487': { return 'Säästöpankki' }
          case '488': { return 'Säästöpankki' }
          case '489': { return 'Säästöpankki' }
          case '490': { return 'Säästöpankki' }
          case '491': { return 'Säästöpankki' }
          case '492': { return 'Säästöpankki' }
          case '493': { return 'Säästöpankki' }

          // 495-496
          case '495': { return 'Säästöpankki' }
          case '496': { return 'Säästöpankki' }
        }
      } else {

        // Nordea Pankki (Nordea) 1 ja 2 NDEAFIHH
        // OP Ryhmä 5 OKOYFIHH
        // Ålandsbanken (ÅAB) 6 AABAFI22
        // Danske Bank 8 DABAFIHH

        switch (eka) {
          case '1': { return 'Nordea' }
          case '2': { return 'Nordea' }
          case '5': { return 'Osuuspankki' }
          case '6': { return 'Ålandsbanken' }
          case '8': { return 'Danske Bank' }
        }
      }

    }

    return null
  }

  public annaMaanKoodi(iban: string): string | null {

    if (!iban) {
      return null
    }

    const valilyonnitPoistettu = iban.replace(/\s/g, '')
    if (valilyonnitPoistettu.length > 1) {
      return valilyonnitPoistettu.substring(0, 2).toUpperCase()
    }

    return null

  }

  public annaPankinKoodi(iban: string): string | null {

    if (!iban) {
      return null
    }

    const valilyonnitPoistettu = iban.replace(/\s/g, '')
    // 15 is the shortest possible IBAN (https://www.iban.com/structure)
    if (!valilyonnitPoistettu || valilyonnitPoistettu.length < 15) {
      return null
    }
    const maa = this.annaMaanKoodi(valilyonnitPoistettu)

    if (maa === 'FI') {
      return valilyonnitPoistettu.substring(4, 7)
    }

    if (maa === 'LT') {
      return valilyonnitPoistettu.substring(4, 9)
    }

    return null

  }

  public validoiBic(bic: string): boolean | null {
    if (!bic) {
      return null
    }
    const valilyonnitPoistettu = bic.replace(/\s/g, '')
    return isValidBIC(valilyonnitPoistettu)
  }

  /**
   * 
   * @param bic 
   * @returns The two letter country code or null if bic doesn't exist or if it doesn't validate.
   */
  public getCountryCode(bic: string): string {
    if (!bic) {
      return null
    }
    const extracted = extractBIC(bic)
    if (
      extracted &&
      extracted.valid &&
      extracted.countryCode
    ) {
      return extracted.countryCode
    }
    return null
  }

}


// Bigbank, Finland Branch 717 BIGKFIH1
// POP Pankit (POP) 470-478 POPFFI22
// Bonum Pankki 479 POPFFI22
// Citibank 713 CITIFIHX
// Danske Bank 8 DABAFIHH
// Danske Bank 34 DABAFIHX
// DNB Bank ASA, Finland Branch 37 DNBAFIHX
// Handelsbanken 31 HANDFIHH
// Holvi 799 HOLVFIHH

// OP Ryhmä 5 OKOYFIHH
// Skandinaviska Enskilda Banken (SEB) 33 ESSEFIHX
// S-Pankki 39 SBANFIHH
// S-Pankki 36 SBANFIHH
// Swedbank 38 SWEDFIHH
// Säästöpankkien Keskuspankki, Säästöpankit (Sp) ja
// Oma Säästöpankki
// 715, 400, 402, 403,
// 406-408, 410-
// 412,414-421,
// 423-432,435-
// 452,454-464,
// 483-493,495-496
// ITELFIHH
// Ålandsbanken (ÅAB) 6 AABAFI22
