import { Component, OnInit, ErrorHandler, OnDestroy, ViewChild, ChangeDetectionStrategy, ElementRef, ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { Kayttaja, TositteenLisaysprosessinOsanTunniste, KayttajanTiedot } from '../../_jaettu/model/kayttaja'
import { Maksutapa, Kuitti, FirestoreTosite, FirestoreTositteenAlkuperainenTiedosto, MYYNTITOSITE_MAKSUTAPA_ID, TILIOTETOSITE_MAKSUTAPA_ID, PALKKATOSITE_MAKSUTAPA_ID, FirestoreTositteenKuva, TositeAutokirjaustyyppi, SAHKOISET_LASKUT_MAKSUTAPA_ID } from '../../_jaettu/model/tosite'
import { LocalMonth } from '../../_shared-core/model/common'

import { TositeService, PurettuTiedosto, AlvPortaatJaoletus } from '../../_angular/service/tosite/tosite.service'
import { KayttajaService } from '../../_angular/service/kayttaja.service'
import { TositeMuokkaaComponentData } from '../../_angular/_resolvers/tosite.resolve'

import { SimplifiedUploadData, TiedostojenLataamisService } from '../../_jaettu-angular/service/tiedostojen-lataamis.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'
import { DragAndDropService } from '../../_jaettu-angular/service/drag-and-drop.service'
import { DateService } from '../../_shared-core/service/date.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { TositeDatasourceService } from '../../_angular/service/tosite/tosite-datasource.service'
import { TositeKuvaCacheService } from '../kuvat/tosite-kuva-cache.service'
import { KopioijaPalvelu } from '../../_jaettu/service/kopioija.service'

import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop'

import { Observable, Subject, of as observableOf, combineLatest, BehaviorSubject } from 'rxjs'
import { takeUntil, tap, map, switchMap, startWith } from 'rxjs/operators'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

interface TositeForm {
  maksutapa: FormControl<number>
  tositteenPvm: FormControl<Date>
  summa: FormControl<number>
  lisatiedot: FormControl<string>
  alvVahennysoikeus: FormControl<number>
}

export interface KuvaAvainJaUrl {
  avain: string
  url: string
  lukittu: boolean
  jarjestys: number
}
export interface KuvaAvainUrlJaJarjestys {
  avain: string
  url: string
  jarjestys: number
}

export interface TositteenPdfKuvatData {
  kuvat: KuvaAvainUrlJaJarjestys[]
}

@Component({
  templateUrl: './tositteen.muokkaus.component.html',
  styleUrls: ['./tositteen.muokkaus.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TositteenMuokkausComponent implements OnInit, OnDestroy {

  maxDate: Date = new Date(2099, 11, 31)
  minDate: Date = new Date(2010, 0, 1)

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>

  private ngUnsubscribe = new Subject<void>()

  private _uploadTasksSubject: BehaviorSubject<SimplifiedUploadData[]> = new BehaviorSubject([])

  form: FormGroup<TositeForm> = null
  maksutapa: FormControl<number>
  tositteenPvm: FormControl<Date>
  summa: FormControl<number>
  lisatiedot: FormControl<string>
  alvVahennysoikeus: FormControl<number>

  kuvavirheAvain: string = ''
  kuvavirheParametrit: Object = {}

  hideSum: boolean = false
  showPvm: boolean = true
  dateSelectionType: 'day' | 'month' = 'day'
  isDraggingObservable: Observable<boolean>
  pvmText: string
  summaText: string

  kuitti: FirestoreTosite = null
  alvPortaat: number[] = null
  virheviestiLatausOnKesken: string = null
  virheviestiPysyva: string = null
  virheviestiEiKuvia: string = null
  muokataan: boolean = false
  showMaksutavat: boolean = true

  kuvatDisplay: KuvaAvainJaUrl[] = []
  pdfKuvatDisplay: { alkuperaisenAvain: string, kuvat: KuvaAvainUrlJaJarjestys[], lukittu: boolean }[] = []

  newAlkuperaisetCache: FirestoreTositteenAlkuperainenTiedosto[] = []
  newKuvatCache: FirestoreTositteenKuva[] = []

  private onkoVielaSelvitettaviaListalla: boolean
  private startingMaksutapa: number
  private startingMonth: LocalMonth

  alvPortaatObservable: Observable<AlvPortaatJaoletus>

  donePercentageObservable: Observable<number>
  latausvirheetObservable: Observable<string[]>
  kirjanpitajanKommentti: string
  maksajaTaiMaksunSaaja: string

  private oletusAlv: number = null
  private poistetuksiMerkitytKuvat: string[] = []

  namename = 'asfasf' + Math.random()

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _tositeService: TositeService,
    private _ladataanService: LadataanService,
    private _dateService: DateService,
    private _validationService: FormValidationService,
    private _timestampService: TimestampService,
    private _currencyService: CurrencyService,
    private _dndService: DragAndDropService,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _translationService: LemonTranslationService,
    private _errorHandler: ErrorHandler,
    private _kayttajaService: KayttajaService,
    private _tositeDatasourceService: TositeDatasourceService,
    private _tositeKuvaCacheService: TositeKuvaCacheService,
    private _changeDetectionRef: ChangeDetectorRef,
    private _copyService: KopioijaPalvelu,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    this.maxDate = this._dateService.lisaaKuukausia(new Date(), 12)
    this.minDate = this._dateService.lisaaKuukausia(new Date(), -48)
  }

  ngOnInit() {

    const tositeDataObservable = this._route.data.pipe(
      map(routeData => (routeData?.data || null) as TositeMuokkaaComponentData)
    )

    combineLatest([this._kayttajaService.kayttajanTiedotObservable, tositeDataObservable]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: async ([kayttajanTiedot, data]) => {

        this.poistetuksiMerkitytKuvat = []

        this.onkoVielaSelvitettaviaListalla = data?.onkoVielaSelvitettavia
        this.startingMaksutapa = data.tosite.maksutapa
        this.startingMonth = { year: data.tosite.localPvm.year, month: data.tosite.localPvm.month }

        if (data?.tosite) {

          this.kuvatDisplay = []
          const pdfKuvatMap: { [alkuperaisenAvain: string]: KuvaAvainUrlJaJarjestys[] } = {}
          for (const kuvaData of Object.values(data.tosite.kuvat || {})) {
            if (kuvaData.poistettu) {
              continue
            }
            if (
              kuvaData.alkuperaisenAvain &&
              data.tosite.alkuperaiset &&
              data.tosite.alkuperaiset[kuvaData.alkuperaisenAvain] &&
              data.tosite.alkuperaiset[kuvaData.alkuperaisenAvain].fileEnding &&
              data.tosite.alkuperaiset[kuvaData.alkuperaisenAvain].fileEnding.toLocaleLowerCase() === 'pdf'
            ) {
              const pdfKuvanUrl = this._tositeKuvaCacheService.annaKuvanUrl(kayttajanTiedot, data.tosite, kuvaData, 'full')
              if (!pdfKuvatMap[kuvaData.alkuperaisenAvain]) {
                pdfKuvatMap[kuvaData.alkuperaisenAvain] = []
              }
              pdfKuvatMap[kuvaData.alkuperaisenAvain].push({ avain: kuvaData.avain, url: pdfKuvanUrl, jarjestys: kuvaData.jarjestys })
            } else {
              const tavallisenKuvanUrl = this._tositeKuvaCacheService.annaKuvanUrl(kayttajanTiedot, data.tosite, kuvaData, 'full')
              this.kuvatDisplay.push({ avain: kuvaData.avain, url: tavallisenKuvanUrl, jarjestys: kuvaData.jarjestys, lukittu: !!kuvaData?.lukittu })
            }
          }

          const avaimet = Object.keys(pdfKuvatMap || {}).sort((a, b) => a.localeCompare(b))
          for (const alkuperaisenAvain of avaimet) {
            const alkuperainen = data.tosite.alkuperaiset[alkuperaisenAvain]
            const kuvat = pdfKuvatMap[alkuperaisenAvain].sort((a, b) => {
              return a.jarjestys - b.jarjestys
            })
            this.pdfKuvatDisplay.push({ alkuperaisenAvain: alkuperaisenAvain, kuvat: kuvat, lukittu: !!alkuperainen?.lukittu })
          }
          this.kuitti = this._copyService.cloneDeep(data.tosite)

          this.kuvatDisplay.sort((a, b) => {
            return a.jarjestys - b.jarjestys
          })

          this.maksutapa = new FormControl<number>(null, [Validators.required])
          this.tositteenPvm = new FormControl<Date>(this._dateService.timestampToDate(this.kuitti.pvm), [Validators.required])

          this.summa = new FormControl<number>(this.kuitti.sum ?? this.kuitti.summa, [Validators.required, Validators.min(0.01), Validators.max(999999.99)])
          this.lisatiedot = new FormControl<string>(this.kuitti.selite, [])
          this.alvVahennysoikeus = new FormControl<number>(this.kuitti.alvVahennysoikeus, [])

          this.maksutapa.valueChanges.pipe(
            takeUntil(this.ngUnsubscribe)
          ).subscribe(value => {
            this.kuitti.maksutapa = value
            this.setDisplayForSelectedMaksutapa(value)
          })

          // Delay value set to start first emit
          this.maksutapa.setValue(this.kuitti.maksutapa)

          if (this.kuitti.x) {
            // If selvitettävä tosite, don't allow changing maksutapa, sum or date
            this.showMaksutavat = false
            this.maksutapa.disable()
            this.summa.disable()
            this.tositteenPvm.disable()
            this.alvVahennysoikeus.disable()
          }

          if (this.kuitti.maksutapa === SAHKOISET_LASKUT_MAKSUTAPA_ID) {
            // If sähköinen lasku, don't allow changing maksutapa, sum or date
            this.showMaksutavat = false
            this.maksutapa.disable()
            this.summa.disable()
            this.tositteenPvm.disable()
            this.alvVahennysoikeus.disable()
          }

          this.tositteenPvm.valueChanges.pipe(
            takeUntil(this.ngUnsubscribe)
          ).subscribe((value: Date) => {
            this.kuitti.pvm = this._timestampService.dateToTimestamp(value)
            this.kuitti.localPvm = this._dateService.dateToLocalDate(value)
            this.kuitti.p = this._dateService.dateToNumber(value)
          })

          this.summa.valueChanges.pipe(
            takeUntil(this.ngUnsubscribe)
          ).subscribe(value => {
            this.kuitti.summa = Math.abs(value)
            this.kuitti.sum = value
          })
          this.lisatiedot.valueChanges.pipe(
            takeUntil(this.ngUnsubscribe)
          ).subscribe(value => {
            this.kuitti.selite = value
          })
          this.alvVahennysoikeus.valueChanges.pipe(
            takeUntil(this.ngUnsubscribe)
          ).subscribe(value => {
            this.kuitti.alvVahennysoikeus = value
          })

          // Initialize form
          this.form = new FormGroup<TositeForm>({
            maksutapa: this.maksutapa,
            tositteenPvm: this.tositteenPvm,
            summa: this.summa,
            lisatiedot: this.lisatiedot,
            alvVahennysoikeus: this.alvVahennysoikeus
          })

          this.kirjanpitajanKommentti = this.kuitti.m || ''
          this.maksajaTaiMaksunSaaja = this.kuitti.w || this.kuitti.el || ''

        }
      }, error: error => {
        this._errorHandler.handleError(error)
      }
    })

    this.donePercentageObservable = this._uploadTasksSubject.pipe(
      switchMap(tasks => {
        if (!tasks || tasks.length < 1) {
          return observableOf<number>(null)
        }
        return this._tiedostojenLataamisService.annaKokonaisprosenttiSimplified(tasks).pipe(
          tap(percentage => {
            if (percentage > 99.99) {
              this.virheviestiLatausOnKesken = null
            }
          })
        )
      })
    )
    this.latausvirheetObservable = this._uploadTasksSubject.pipe(
      switchMap(tasks => this._tiedostojenLataamisService.annaKaikkiVirheetSimplified(tasks))
    )

    this.alvPortaatObservable = combineLatest([
      this.maksutapa.valueChanges.pipe(startWith(this.kuitti.maksutapa)),
      this._kayttajaService.kayttajaObservable
    ]).pipe(
      map(([maksutapa, kayttaja]) => {
        // Only shown for ostotositteet
        if (maksutapa === TILIOTETOSITE_MAKSUTAPA_ID ||
          maksutapa === MYYNTITOSITE_MAKSUTAPA_ID ||
          maksutapa === PALKKATOSITE_MAKSUTAPA_ID) {
          return null
        }
        if (!kayttaja?.alvVahennysoikeusPortaat || !kayttaja.prosessinOsat) {
          return null
        }
        const prosessissaAlv = kayttaja.prosessinOsat.filter(a => { return a.tunniste === TositteenLisaysprosessinOsanTunniste.ALV_VAHENNYSOIKEUS }).length > 0
        if (!prosessissaAlv) {
          return null
        }
        const portaat: number[] = []

        let def: number | null = null
        for (const porras of kayttaja.alvVahennysoikeusPortaat) {
          if (!isNaN(porras.arvo)) {
            if (porras.oletus) {
              def = porras.arvo
            }
            portaat.push(porras.arvo)
          }
        }
        portaat.sort((a, b) => a - b)
        return portaat.length > 0 ? { portaat: portaat, oletusProsentti: def } : null
      })
    )

    this.alvPortaatObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(portaatJaOletus => {
      this.oletusAlv = portaatJaOletus?.oletusProsentti
      this.alvPortaat = portaatJaOletus?.portaat
      if (this.oletusAlv && (this.kuitti.alvVahennysoikeus === null || this.kuitti.alvVahennysoikeus === undefined)) {
        this.alvVahennysoikeus.setValue(this.oletusAlv)
      }
    }, error => {
      this._errorHandler.handleError(error)
    })

    this.isDraggingObservable = this._dndService.isDraggingInWindowObservable

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  valitseMaksutapa(maksutapa: Maksutapa, kuitti: Kuitti) {
    kuitti.p = maksutapa.i
  }
  uploadKuva() {
    this.fileInput.nativeElement.click()
  }

  async fileChanged(event: Event) {

    const target: HTMLInputElement = event.target as HTMLInputElement
    if (!target?.files?.length) {
      return
    }

    const list: FileList = target.files
    const tiedostot: NgxFileDropEntry[] = this._tiedostojenLataamisService.fileListToNgxFileDropEntries(list)

    const voidaanLadata = await this.tiedostoVoidaanLadata(tiedostot)
    if (!voidaanLadata) {
      return
    }
    const kayttaja = await this._kayttajaService.getKayttaja()
    return this.fileDropTiedostoToKuva(tiedostot, kayttaja)
  }

  fileOver(event: Event) {

  }
  fileLeave(event: Event) {

  }

  async fileDrop(tiedostot: NgxFileDropEntry[]): Promise<void> {

    this._dndService.setDragging(false)

    const voidaanLadata = await this.tiedostoVoidaanLadata(tiedostot)
    if (!voidaanLadata) {
      return
    }

    const kayttaja = await this._kayttajaService.getKayttaja()
    return this.fileDropTiedostoToKuva(tiedostot, kayttaja)
  }

  async fileDropTiedostoToKuva(tiedostot: NgxFileDropEntry[], kayttaja: Kayttaja) {

    this.virheviestiEiKuvia = null

    for (const tiedosto of tiedostot) {

      const fileEnding = this._tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(tiedosto)
      const file = await this._tiedostojenLataamisService.getFile(tiedosto.fileEntry as FileSystemFileEntry)

      const alkuperainen: FirestoreTositteenAlkuperainenTiedosto = {
        avain: this._firebaseLemonaid.firestoreCreateId(),
        kuvakansio: this.kuitti.kuvakansio,
        nimi: tiedosto.relativePath,
        fileEnding: fileEnding,
        kasitelty: false
      }
      this.newAlkuperaisetCache.push(alkuperainen)

      const uploadData = this._tiedostojenLataamisService.tallennaTositetiedostoSimplified(this._firebaseLemonaid, kayttaja.asiakasId + '', alkuperainen, file)
      this._uploadTasksSubject.next([...this._uploadTasksSubject.value, uploadData])

      const kayttajanTiedot: KayttajanTiedot = {
        uid: kayttaja.avain,
        asiakasAvain: kayttaja.asiakasAvain,
        asiakasId: '' + kayttaja.asiakasId
      }
      const purettuTiedosto: PurettuTiedosto = await this._tositeService.puraYksiTiedostoLokaalisti(this.kuitti, file, tiedosto, fileEnding, uploadData, alkuperainen, kayttajanTiedot, false, Promise.resolve({}))
      if (purettuTiedosto.virhe) {
        this.virheviestiPysyva = this.virheviestiPysyva ? this.virheviestiPysyva + ' ' + purettuTiedosto.virhe : purettuTiedosto.virhe
      }
      if (purettuTiedosto.kasitellytKuvat) {
        if (purettuTiedosto.type === 'img') {
          for (const kuva of purettuTiedosto.kasitellytKuvat) {
            this.newKuvatCache.push(kuva)
            this.kuvatDisplay.push({ avain: kuva.avain, url: this._tositeKuvaCacheService.annaKuvanUrl(kayttajanTiedot, this.kuitti, kuva, 'full'), jarjestys: this.kuvatDisplay.length + 1, lukittu: !!kuva.lukittu })
            this._changeDetectionRef.markForCheck()
          }
        } else if (purettuTiedosto.type === 'pdf') {
          const pdfKuvat: { avain: string, url: string, jarjestys: number }[] = []
          for (const pdfKuva of purettuTiedosto.kasitellytKuvat) {
            this.newKuvatCache.push(pdfKuva)
            pdfKuvat.push({ avain: pdfKuva.avain, url: this._tositeKuvaCacheService.annaKuvanUrl(kayttajanTiedot, this.kuitti, pdfKuva, 'full'), jarjestys: pdfKuva.jarjestys })
          }
          this.pdfKuvatDisplay.push({ alkuperaisenAvain: alkuperainen.avain, kuvat: pdfKuvat, lukittu: false })
          this._changeDetectionRef.markForCheck()
        }
      }
    }
  }

  poistaKuva(kuvaAvainJaUrl: KuvaAvainJaUrl) {
    const kuvaInCache: FirestoreTositteenKuva = this.newKuvatCache.find(cached => cached.avain === kuvaAvainJaUrl.avain)
    const kuvaInCacheIndex: number = this.newKuvatCache.findIndex(cached => cached.avain === kuvaAvainJaUrl.avain)

    // If the img was just added, delete it from kuvat & alkuperaiset cache. Otherwise mark it as deleted
    if (kuvaInCacheIndex > -1) {
      const cachedAlkuperaisenIndex = this.newAlkuperaisetCache.findIndex(alkup => alkup.avain === kuvaInCache?.alkuperaisenAvain)
      this.newAlkuperaisetCache.splice(cachedAlkuperaisenIndex, 1)
      this.newKuvatCache.splice(kuvaInCacheIndex, 1)
    } else {
      this.poistetuksiMerkitytKuvat.push(kuvaAvainJaUrl.avain) // Remember so as to save deletes later
    }
    // Delete img from displayed kuvat
    const kuvaIndex = this.kuvatDisplay.findIndex(kuva => kuva.avain === kuvaAvainJaUrl.avain)
    if (kuvaIndex > -1) {
      this.kuvatDisplay.splice(kuvaIndex, 1)
    }
  }

  poistaPdf(pdfData: { alkuperaisenAvain: string, kuvat: KuvaAvainUrlJaJarjestys[] }) {

    const alkuperainenInCacheIndex = this.newAlkuperaisetCache.findIndex(cached => cached.avain === pdfData.alkuperaisenAvain)

    // If the PDF was just added, delete it from kuvat & alkuperaiset cache. Otherwise mark its kuvat as deleted
    if (alkuperainenInCacheIndex > -1) {
      this.newAlkuperaisetCache.splice(alkuperainenInCacheIndex, 1)
      this.newKuvatCache = this.newKuvatCache.filter(cached => cached.alkuperaisenAvain !== pdfData.alkuperaisenAvain)
    } else {
      for (const kuva of pdfData.kuvat) {
        this.poistetuksiMerkitytKuvat.push(kuva.avain) // Remember so as to save deletes later
      }
    }
    // Delete PDF from displayed data
    const pdfIndex = this.pdfKuvatDisplay.findIndex(pdf => pdf.alkuperaisenAvain === pdfData.alkuperaisenAvain)
    if (pdfIndex > -1) {
      this.pdfKuvatDisplay.splice(pdfIndex, 1)
    }
  }

  private async tiedostoVoidaanLadata(tiedostot: NgxFileDropEntry[]): Promise<boolean> {

    this.kuvavirheAvain = ''
    this.kuvavirheParametrit = {}

    const supportedImageTypes = ['jpeg', 'jpg', 'png', 'webp', 'tiff', 'gif', 'svg', 'pdf', 'heic', 'heif']
    for (const tiedosto of tiedostot) {

      const file = tiedosto.fileEntry as FileSystemFileEntry

      const fileEnding = this._tiedostojenLataamisService.getFileEndingFromFileName(file.name)
      const fileSize = file ? await this._tiedostojenLataamisService.getFileSize(file) : -1

      if (!fileEnding || supportedImageTypes.indexOf(fileEnding.toLowerCase()) < 0) {
        this.kuvavirheParametrit = {
          tuetutMuodot: supportedImageTypes.join(', ')
        }
        this.kuvavirheAvain = 'kuitit.lataaminen.virheet.vaara-muoto'
        return false
      }

      const maxKoko = 25 * 1024 * 1024
      if (fileSize > maxKoko) {
        const kokoMegatavuissaLokalisoitu = this._currencyService.formatoiDesimaali((fileSize / 1024 / 1024), 2, this._translationService.nykyinenKieli)
        const maxKokoLokalisoitu = this._currencyService.formatoiDesimaali((maxKoko / 1024 / 1024), 2, this._translationService.nykyinenKieli)
        this.kuvavirheParametrit = {
          kokoMax: maxKokoLokalisoitu,
          kokoNyt: kokoMegatavuissaLokalisoitu
        }
        this.kuvavirheAvain = 'kuitit.lataaminen.virheet.liian-suuri'
        return false
      }

    }

    return true

  }

  private _saveInFlight: boolean
  async save() {

    if (this._saveInFlight) {
      return
    }
    this._saveInFlight = true

    try {

      const onkoTallentaminenEstetty = await this._tositeService.saveIsPreventedSimplified(this.form, this.latausvirheetObservable, this._uploadTasksSubject.value, this.virheviestiPysyva)
      if (onkoTallentaminenEstetty && onkoTallentaminenEstetty.tallennusEstetty) {
        if (onkoTallentaminenEstetty.lataaminenKesken) {
          this.virheviestiLatausOnKesken = this._translationService.lokalisoi('kuitit.muokkaa.validation.ladataan')
        }
        this._validationService.merkitseKokoLomakeKosketuksi(this.form)
        return
      }
      if (!this.onkoLisattaviaKuvia()) {
        this.virheviestiEiKuvia = this._translationService.lokalisoi('kuitit.muokkaa.validation.ei-kuvia')
        this._validationService.merkitseKokoLomakeKosketuksi(this.form)
        return
      }


      this._ladataanService.aloitaLataaminen()

      const tositeOliSelvitettava = this.kuitti.x

      // Fill kuitti with the kuva data from newly added images
      for (const cachedAlkuperainen of this.newAlkuperaisetCache) {
        if (!this.kuitti.alkuperaiset) {
          this.kuitti.alkuperaiset = {}
        }
        this.kuitti.alkuperaiset[cachedAlkuperainen.avain] = cachedAlkuperainen
      }
      for (const cachedKuva of this.newKuvatCache) {
        if (!this.kuitti.kuvat) {
          this.kuitti.kuvat = {}
        }
        this.kuitti.kuvat[cachedKuva.avain] = cachedKuva
      }

      for (const merkittyPoistetuksi of this.poistetuksiMerkitytKuvat) {
        if (this.kuitti.kuvat[merkittyPoistetuksi]) {
          this.kuitti.kuvat[merkittyPoistetuksi].poistettu = true
        }
      }

      const sortedImages = Object.values(this.kuitti.kuvat ?? {})
        .filter(k => !k.poistettu)
        .sort((a, b): number => {
          if (a.alkuperaisenAvain !== b.alkuperaisenAvain) {
            if (a.alkuperaisenAvain && b.alkuperaisenAvain) {
              return a.alkuperaisenAvain.localeCompare(b.alkuperaisenAvain)
            } else if (a.alkuperaisenAvain) {
              return 1
            } else {
              return -1
            }
          }
          return a.jarjestys < b.jarjestys ? -1 : 1
        })

      let jarjestys = 1
      for (const image of sortedImages) {
        image.jarjestys = jarjestys
        jarjestys++
      }

      if (this.kuitti.b) {
        delete this.kuitti.b[TositeAutokirjaustyyppi.OMALLA_RAHALLA]
      }

      await this._tositeService.tallennaKuitti(this.kuitti)

      if (tositeOliSelvitettava && this.onkoVielaSelvitettaviaListalla) {

        // Set last viewed maksutapa to null to preselect selvitettävät at list view
        await this._tositeService.setLastViewedKuitinMaksutapa(null)
        const prevLocation = this._tositeService.getMaksutapaNameForRouter(this.startingMaksutapa)

        this._router.navigate(['tositteet', prevLocation])
      } else {

        // Remember maksutapa to pre-select that when returning
        await this._tositeService.setLastViewedKuitinMaksutapa(this.kuitti.maksutapa)

        this._tositeDatasourceService.changeVuosiKk(this.kuitti.localPvm.year, this.kuitti.localPvm.month - 1)
        const targetLocation = this._tositeService.getMaksutapaNameForRouter(this.kuitti.maksutapa)
        this._router.navigate(['tositteet', targetLocation])
      }

    } catch (error) {
      this.virheviestiPysyva = this._translationService.lokalisoi('tositteet.yhteiset.tallentaminen-epaonnistui')
      this._errorHandler.handleError(error)
    } finally {
      this.poistetuksiMerkitytKuvat = []
      this._saveInFlight = false
      this._ladataanService.lopetaLataaminen()
    }

  }

  async peruuta() {

    this.poistetuksiMerkitytKuvat = []

    if (!this.kuitti.x) {
      // If not selvitettävä, remember maksutapa to pre-select that when returning
      await this._tositeService.setLastViewedKuitinMaksutapa(this.startingMaksutapa)
    } else {
      await this._tositeService.setLastViewedKuitinMaksutapa(null)
    }
    this._tositeDatasourceService.changeVuosiKk(this.startingMonth.year, this.startingMonth.month - 1)
    const targetLocation = this._tositeService.getMaksutapaNameForRouter(this.startingMaksutapa)
    this._router.navigate(['tositteet', targetLocation])

  }
  private setDisplayForSelectedMaksutapa(maksutapaId: number) {
    if (maksutapaId === TILIOTETOSITE_MAKSUTAPA_ID) {

      this.showPvm = true

      this.pvmText = this._translationService.lokalisoi('tositteet.tiliote.muokkaa.pvm')

      // If tiliote, don't show sum, allow only a monthly date
      this.hideSum = true
      this.summa.disable()

      this.tositteenPvm.enable()
      this.dateSelectionType = 'month'

      // If palkkatosite, don't show sum, show the current day as date (but don't allow change)
    } else if (maksutapaId === PALKKATOSITE_MAKSUTAPA_ID) {

      this.showPvm = false

      this.pvmText = this._translationService.lokalisoi('tositteet.palkka.muokkaa.pvm')

      this.hideSum = true
      this.dateSelectionType = 'day'
      this.summa.disable()
      this.tositteenPvm.disable()

    } else if (maksutapaId === MYYNTITOSITE_MAKSUTAPA_ID) {

      this.showPvm = true

      this.pvmText = this._translationService.lokalisoi('tositteet.myynti.muokkaa.pvm')
      this.summaText = this._translationService.lokalisoi('tositteet.myynti.muokkaa.summa')

      this.summa.enable()
      this.hideSum = false

      this.tositteenPvm.enable()
      this.dateSelectionType = 'day'

    } else {

      this.showPvm = true

      // All other maksutavat are under ostot
      this.pvmText = this._translationService.lokalisoi('kuitit.muokkaa.maksupvm')
      this.summaText = this._translationService.lokalisoi('kuitit.muokkaa.summa')

      this.summa.enable()
      this.hideSum = false

      this.tositteenPvm.enable()
      this.dateSelectionType = 'day'

    }
  }
  private onkoLisattaviaKuvia() {
    return !!this.kuvatDisplay?.length || !!this.pdfKuvatDisplay?.length
  }
}
