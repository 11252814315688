import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { Validators, AbstractControl, ValidatorFn, ValidationErrors, FormControl, FormGroup } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

import {
  Lasku,
  UUDEN_LASKUN_AVAIN,
  LaskuBase,
  LaskunTila,
  Laskuasetukset,
  EmailLahetysStatus,
  EmailLahetysStatusKoodi,
  LaskuToimintaloki,
  LaskunToimintalokiTyyppi,
  LaskunToimintalokiSpostiParametrit,
  LaskuReskontra,
  LaskunLiitetiedosto,
  LaskunAsiakas,
  LaskuSahkoinenLahetysStatusKoodi,
  LaskunToimintalokiSahkoinenParametrit,
  LaskunumeroTyyppi,
  LaskunLahetystapa,
  PostmarkBounceWebHookData,
  LaskunTyypit
} from '../_jaettu/model/lasku'

import { LaskuService } from '../_angular/service/lasku/lasku.service'
import { LEMONAID_CF_API, LemonHttpService } from '../_angular/service/lemon-http.service'
import { LaskuComponentDataResolve, LaskuKatseleComponentData, LaskuComponentExistingData, LaskuKatseleComponentDataResolve, LaskuKatseleComponentExistingData } from '../_angular/_resolvers/lasku.resolve'
import { KayttajaService } from '../_angular/service/kayttaja.service'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { TimestampService } from '../_jaettu-angular/service/timestamp-service'
import { LaskuPdfEsikatselutiedot } from '../_jaettu-angular/laskut/esikatselu/pdf.perinteinen.component'
import { VasenValikkoService } from '../_jaettu-angular/service/vasen-valikko.service'
import { FormValidationService } from '../_jaettu-angular/service/form-validation.service'

import { Subject, Observable, of, BehaviorSubject, firstValueFrom, combineLatest, from } from 'rxjs'
import { takeUntil, startWith, map, switchMap, tap, filter, debounceTime } from 'rxjs/operators'

import { LaskuHaluatkoVarmastiMitatoidaLaskunDialogData, LaskuHaluatkoVarmastiMitatoidaLaskunDialog } from './dialogit/lasku.haluatko-varmasti-mitatoida-laskun.dialog'
import { LaskuLataaDialog, LaskuLataaDialogData } from './dialogit/lasku.lataa.dialog'
import { LaskuKokoVirheDialog, LaskuKokoVirheDialogData } from './dialogit/lasku.koko-virhe.dialog'
import { LaskuMerkitseOsittainMaksetuksiDialog, LaskuMerkitseOsittainMaksetuksiDialogData } from './dialogit/lasku.merkitse-osittain-maksetuksi.dialog'

import { LaskuSharedService } from '../_jaettu/service/lasku/lasku-shared.service'
import { CurrencyService } from '../_shared-core/service/currency.service'
import { DateService } from '../_shared-core/service/date.service'
import { StringService } from '../_shared-core/service/string.service'
import { LaskuKopioija } from '../_jaettu/service/lasku/lasku.kopioija'
import { LaskuUriService } from '../_jaettu/service/lasku/lasku-uri.service'
import { SahkoisenLaskunValittajaService, Verkkolaskuoperaattori } from '../_jaettu/service/lasku/sahkoisen-laskun-valittaja.service'

import { LaskunLahetysdata, LaskunLahetystiedot } from './laheta.component'

import { environment } from 'environments/environment'
import { EnvironmentType } from 'app/app.environment'
import { LaskuSpostiService } from 'app/_jaettu/service/lasku/lasku-sposti.service'
import { AlvService } from 'app/_jaettu-angular/service/alv.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { LaskuMuokkausEstettyDialog } from './dialogit/lasku.muokkaus-estetty.dialog'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { LaskuPerintaSpostiDialog, LaskuPerintaSpostiDialogData } from './dialogit/lasku-perinta.sposti.dialog'
import { LaskuPerintaPeruutusAreYouSureDialog, LaskuPerintaPeruutusAreYouSureDialogData } from './dialogit/lasku-perinta-peruutus-are-you-sure.dialog'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { AnnaLemonaidKayttajienTiedotRequest, AnnaLemonaidKayttajienTiedotResponse } from 'app/_jaettu/model/kayttaja'
import { TuettuKieli } from 'app/_shared-core/model/common'

interface KasiteltyLokitieto {
  parametrit: any
  loki: LaskuToimintaloki
}

interface NakyvyysTiedot {
  onkoMuokkaaNakyvissa: boolean
  onkoMuokkaaInfoNakyvissa: boolean
  onkoPoistoLuonnosNakyvissa: boolean
  onkoLataaLaskuNakyvissa: boolean
  onkoHyvitaNakyvissa: boolean
  onkoKopioiUusiLaskuNakyvissa: boolean
  onkoMerkitseOsittainMaksetuksiNakyvissa: boolean
  onkoMaksumuistutusNakyvissa: boolean
  onkoPeruutaLuottotappioMerkintaNakyvissa: boolean
  onkoMerkitseLuottotappioksiNakyvissa: boolean
  onkoViePerintaanEnabled: boolean
  onkoViePerintaanNakyvissa: boolean
  onkoPeruutaPerintaNakyvissa: boolean
  onkoPerintaAloittamatta: boolean
}

interface LaskunVersiot {
  lasku: Lasku
  kasiteltava: LaskuBase
  onkoLataaLaskuNakyvissa: boolean
}

type SummatekstinVari = 'blue' | 'green' | 'red' | 'purple' | 'yellow'
interface SummanNayttamistiedot {
  summa: number
  summaColor: SummatekstinVari
  otsikko: string
  maksuaikaa: number
  naytaMaksuaikaa: boolean
}

export interface KorjattavanEmailOsoitteenTiedot {
  emailLahetysStatusLaskulta: EmailLahetysStatus
  vanhaEmail: string
  uusiEmail: string
  virhe: PostmarkBounceWebHookData
}

export interface SahkoinenForm {
  ytunnus: FormControl<string>
  osoite: FormControl<string>
  valittaja: FormControl<string>
}

@Component({
  selector: 'app-lasku',
  templateUrl: './lasku.katsele.component.html',
  styleUrls: ['./lasku.katsele.component.css']
})
export class LaskuKatseleMuokattavaComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject<void>()

  reskontraObservable: Observable<LaskuReskontra[]>
  reskontraLokiObservable: Observable<{ data: LaskuReskontra, teksti: string }[]>
  reskontraaLadataanObservable: Observable<boolean>
  lokitiedotObservable: Observable<KasiteltyLokitieto[]>
  lokitietojaLadataanObservable: Observable<boolean>

  verkkolaskuoperaattorit: Verkkolaskuoperaattori[]
  nakyvyystiedotObservable: BehaviorSubject<NakyvyysTiedot> = new BehaviorSubject(null)
  aktiivinenValilehti: BehaviorSubject<number> = new BehaviorSubject(0)
  lahetysdata: BehaviorSubject<LaskunLahetysdata> = new BehaviorSubject(null)

  korjattavatEmailOsoitteet: KorjattavanEmailOsoitteenTiedot[] = []
  versiot: LaskunVersiot[] = []
  juurilasku: Lasku = null
  kasiteltava: LaskuBase = null
  asetukset: Laskuasetukset = null
  esikatselutiedot: LaskuPdfEsikatselutiedot = null
  namename = 'asfrtegoq' + Math.random()

  isLemontreeAdmin = false
  naytaPerintaritari = false
  perintaOnLopetettu = false

  sahkoinenForm: FormGroup<SahkoinenForm>
  otsikko = ''

  summatiedotObservable: BehaviorSubject<SummanNayttamistiedot> = new BehaviorSubject(null)

  private _buttonPushInflight: boolean = false
  peppolValidationObservable: BehaviorSubject<{ error: boolean, message: string }> = new BehaviorSubject(null)

  annaOsoitteenValidointiFunktio(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const ctrlValue = this._stringService.removeAllWhiteSpaces(control.value)

        if (this.kasiteltava?.asiakas?.nimi?.toLowerCase()?.split(' ')?.includes('apix')) {
          // Special exception to enable sending invoices to Apix
          const apixTunnus = '003723327487'
          const apixValittuValittajana = this.valittajaControl.value?.trim() === apixTunnus

          if (apixValittuValittajana && control.value === apixTunnus) {
            return null
          }
        }

        const valittajat = this.verkkolaskuoperaattorit || []
        for (const valittaja of valittajat) {
          const valittajaTunnus = this._stringService.removeAllWhiteSpaces(valittaja.tunnus)
          if (valittajaTunnus === ctrlValue) {
            return { 'valittaja': true }
          }
        }
      }
      return null
    }
  }

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _laskuService: LaskuService,
    private _laskuKopioija: LaskuKopioija,
    private _errorHandler: ErrorHandler,
    private _laskuSharedService: LaskuSharedService,
    private _dialog: MatDialog,
    private _laskuComponentDataResolve: LaskuComponentDataResolve,
    private _laskuKatseleComponentDataResolve: LaskuKatseleComponentDataResolve,
    private _dateService: DateService,
    private _http: LemonHttpService,
    private _lemonTranslation: LemonTranslationService,
    private _currencyService: CurrencyService,
    private _timestampService: TimestampService,
    private _ladataanService: LadataanService,
    // private reskontraService: ReskontraService,
    private _kayttajaService: KayttajaService,
    private _laskuUriService: LaskuUriService,
    private _sahkoisenLaskunValittajaService: SahkoisenLaskunValittajaService,
    private _vasenValikkoService: VasenValikkoService,
    private _stringService: StringService,
    private _validationService: FormValidationService,
    private _laskuSpostiService: LaskuSpostiService,
    private _alvService: AlvService,
    private _fileSaverService: FileSaverService,
    private _translationService: LemonTranslationService,
    private _firebase: FirebaseLemonaid
  ) { }

  // private _filter(value): Verkkolaskuoperaattori[] {
  //   if (!value) {
  //     return []
  //   }
  //   const tarkasteltavaArvo = (value + '').trim().toLowerCase()
  //   if (tarkasteltavaArvo === '') {
  //     return []
  //   }
  //   return this.sahkoisenLaskunValittajaService.annaKaikkiValittajat().filter(operaattori => operaattori.nimi.toLowerCase().includes(tarkasteltavaArvo) || operaattori.tunnus.toLowerCase().includes(tarkasteltavaArvo))
  // }

  ngOnInit() {

    this.sahkoinenForm = new FormGroup<SahkoinenForm>({
      'ytunnus': new FormControl<string>('', []),
      'osoite': new FormControl<string>('', [Validators.required, this.annaOsoitteenValidointiFunktio()]),
      'valittaja': new FormControl<string>('', [Validators.required])
    })

    this.verkkolaskuoperaattorit = this._sahkoisenLaskunValittajaService.annaKaikkiValittajat()
    // this.sahkoinenForm.get('ytunnus').valueChanges.subscribe(value => {
    //   this.sahkoinen.ytunnus = value
    // })
    this.osoiteControl.valueChanges.subscribe(value => {
      this.kasiteltava.sahkoinen.osoite.sahkoinenOsoite = value?.trim()
    })
    this.valittajaControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(300)
    ).subscribe(value => {
      this.kasiteltava.sahkoinen.osoite.sahkoinenValittaja = value?.trim()
    })


    // Lataa lokitiedot
    this.lokitiedotObservable = this._route.data.pipe(
      takeUntil(this.ngUnsubscribe),
      switchMap((data: { data: LaskuKatseleComponentData }) => {
        if (data && data.data && data.data.juurilasku && data.data.juurilasku.avain) {
          return this._laskuService.getLaskuToimintalokiObservable(data.data.juurilasku.avain)
        } else {
          return of<LaskuToimintaloki[]>([])
        }
      }),
      map(kaikkiLokit => {
        const tyyppi = this.kasiteltava.nrotyyppi
        const lokit: KasiteltyLokitieto[] = []

        if (tyyppi === LaskunumeroTyyppi.HYVITYS || tyyppi === LaskunumeroTyyppi.MUISTUTUS) {
          for (const loki of kaikkiLokit) {
            if (loki.toiminto === LaskunToimintalokiTyyppi.LAHETETTY_SAHKOISESTI_TOISEN_HYVAKSYNTAJONOSTA_HYVAKSYNNAN_JALKEEN) {
              continue
            }
            if (loki.lasku_uid === this.kasiteltava.avain) {
              lokit.push({ loki: loki, parametrit: this.annaLokiparametrit(loki) })
              if (lokit.length === 1) {
                loki.toiminto = LaskunToimintalokiTyyppi.LUOTU
              }
            }
          }
        } else {
          const sallitutAvaimet = this._laskuSharedService.annaLaskuryppaanPaalaskunEriVersiot(this.juurilasku).map(versio => { return versio.avain })
          for (const loki of kaikkiLokit) {
            if (loki.toiminto === LaskunToimintalokiTyyppi.LAHETETTY_SAHKOISESTI_TOISEN_HYVAKSYNTAJONOSTA_HYVAKSYNNAN_JALKEEN) {
              continue
            }
            if (sallitutAvaimet.includes(loki.lasku_uid)) {
              lokit.push({ loki: loki, parametrit: this.annaLokiparametrit(loki) })
            }
          }
        }
        return lokit
      }),
      lemonShare()
    )

    this.lokitietojaLadataanObservable = this.lokitiedotObservable.pipe(
      map(() => { return false }),
      startWith(true)
    )

    // Lataa reskontratiedot
    this.reskontraObservable = this._route.data.pipe(
      switchMap((data: { data: LaskuKatseleComponentData }) => {
        if (data && data.data && data.data.juurilasku && data.data.juurilasku.avain) {
          return this._laskuService.getLaskuReskontraObservable(data.data.juurilasku.avain)
        } else {
          return of<LaskuReskontra[]>([])
        }
      }),
      filter(reskontra => !!reskontra),
      lemonShare()
    )

    this.reskontraaLadataanObservable = this.reskontraObservable.pipe(
      map(() => { return false }),
      startWith(true)
    )

    this._route.data.pipe(
      tap((data: { data: LaskuKatseleComponentData }) => {
        this.juurilasku = data.data.juurilasku
        this.kasiteltava = data.data.kasiteltava
        this.asetukset = data.data.asetukset

        this.alustaKokoNakyma(this.juurilasku, this.kasiteltava)

        if (data.data.tulosta) {
          setTimeout(() => { this.lataaLasku() }, 25)
        }
      }),
      switchMap((data: { data: LaskuKatseleComponentData }) => {
        return this._laskuService.getLaskuObservable(data.data.juurilasku.avain).pipe(
          map(juurilasku => {
            if (juurilasku) {
              const kasiteltava = this._laskuSharedService.annaKasiteltavaLasku(juurilasku, this.kasiteltava.avain)
              return { juurilasku: juurilasku, kasiteltava: kasiteltava }
            }
            return null
          })
        )
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(tiedot => {
      if (tiedot) {

        this.juurilasku = tiedot.juurilasku
        this.kasiteltava = tiedot.kasiteltava
        this.alustaKokoNakyma(tiedot.juurilasku, tiedot.kasiteltava)

        this.versiot = []
        this.versiot.push({ kasiteltava: this.juurilasku, lasku: this.juurilasku, onkoLataaLaskuNakyvissa: this._onkoLataaLaskuNakyvissa(this.juurilasku) })
        if (this.juurilasku.korvaus && this.juurilasku.korvaus.length > 0) {
          for (const korvaava of this.juurilasku.korvaus) {
            this.versiot.push({ kasiteltava: korvaava, lasku: this.juurilasku, onkoLataaLaskuNakyvissa: this._onkoLataaLaskuNakyvissa(korvaava) })
          }
        }

      }
    })

    const kayttajienNimetMapObservable: Observable<Map<string, string>> = from(this._getKayttajienNimetMap()).pipe(lemonShare())

    this.reskontraLokiObservable = combineLatest([this.reskontraObservable, this._translationService.currentLanguageObservable, kayttajienNimetMapObservable]).pipe(
      map(([reskontrat, kieli, kayttajienNimetMap]) => {
        if (!reskontrat?.length) {
          return null
        }
        return reskontrat.map(reskontra => {
          return { data: reskontra, teksti: this._annaReskontraLokinSelite(reskontra, this.kasiteltava?.valuutta, kayttajienNimetMap, kieli) }
        })
      })
    )

    combineLatest([
      this.osoiteControl.valueChanges,
      this.valittajaControl.valueChanges
    ]).pipe(
      debounceTime(500),
      switchMap(([osoite, valittaja]) => {

        if (valittaja !== 'PEPPOL') {
          return of(null)
        }

        if (!osoite || osoite.trim().length < 9) {
          return of(null)
        }

        return from(this._laskuService.validoiPeppolOsoite(osoite, this._lemonTranslation.nykyinenKieli)).pipe(
          startWith({ error: false, message: this._lemonTranslation.lokalisoi('lasku.peppol-validaatio.tarkistetaan', this._lemonTranslation.nykyinenKieli) })
        )
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      this.peppolValidationObservable.next(value)
    })

    this._kayttajaService.kayttajanTiedotObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kayttajanTiedot => {
      if (environment.environment === EnvironmentType.BETA || environment.environment === EnvironmentType.DEV) {
        this.isLemontreeAdmin = true
        this.naytaPerintaritari = true
      } else {
        if (kayttajanTiedot) {
          this.isLemontreeAdmin =
            kayttajanTiedot.asiakasAvain === 'jzATU474P4BRCkEANZ1H' && (
              kayttajanTiedot.uid === 'i5LZqopeAGVbPCOx0UYnW9ClwPd2' || // Jon
              kayttajanTiedot.uid === 'HNm14labLGhpIT3s10Ka2birbwe2' || // Valtteri
              kayttajanTiedot.uid === 'cTzQaFHdXoSgycGqPQBdoVaFIii2' || // Ville
              kayttajanTiedot.uid === 'UcmHOiVNGDN5ac2Wg2WSG1aI5Ws2' || // Eva Hakola
              kayttajanTiedot.uid === 'ojIj9nhtYaRBD7VFJ3UH6WZSjjo2' || // Perintäritarit Juha
              kayttajanTiedot.uid === 'biK8DUZtjpd9lIKdRj3HDU79w9N2' || // Annika Toivonen
              kayttajanTiedot.uid === 'khVxRekpcPPDveEwQxhl8XKyFtR2' || // heidi.lehtonen@lemontree.fi
              kayttajanTiedot.uid === 'rTOrrPIXaINKjmBXT80ORXgk7Y12' || // Lauri
              kayttajanTiedot.uid === 'xTpeI7IS1lN8AxiOiuuIXxJ0PXI2' // emma.laatikainen@lemontree.fi
            )
          this.naytaPerintaritari = this.isLemontreeAdmin // kayttajanTiedot.uid === '6CcFCl9796ZJDzElpuunao6k0kp1' // Johanna Hämäläinen
        } else {
          this.isLemontreeAdmin = false
          this.naytaPerintaritari = false
        }
      }
    })

    window.scroll({ top: 0, left: 0 })

  }

  private _kotimaanMyynti(tyyppi: string): boolean {
    return tyyppi === LaskunTyypit.TAVALLINEN.avain || tyyppi === LaskunTyypit.RAKENNUSALA.avain
  }

  valittajaDisplayFn(operaattori?: Verkkolaskuoperaattori): string | undefined {
    if (operaattori && operaattori.nimi) {
      return operaattori.nimi + ' (' + operaattori.tunnus + ')'
    }
    if (operaattori instanceof String || typeof operaattori === 'string') {
      return operaattori as any as string
    }
    return undefined
  }

  private alustaKokoNakyma(juurilasku: Lasku, kasiteltava: LaskuBase) {
    this.korjattavatEmailOsoitteet = []
    if (kasiteltava.email && kasiteltava.email.vastaanottajat) {
      for (const vastaanottaja of kasiteltava.email.vastaanottajat) {
        if (vastaanottaja.status === EmailLahetysStatusKoodi.LAHETYS_EPAONNISTUI) {
          this.korjattavatEmailOsoitteet.push({
            emailLahetysStatusLaskulta: vastaanottaja,
            uusiEmail: vastaanottaja.email,
            vanhaEmail: vastaanottaja.email,
            virhe: vastaanottaja.viesti
          })
        }
      }
    }
    if (kasiteltava.sahkoinen && kasiteltava.sahkoinen.osoite) {
      this.osoiteControl.setValue(kasiteltava.sahkoinen.osoite.sahkoinenOsoite)

      // This form field used to be a free-text input, so an extra comparison is needed here to catch invalid operator ID-s in legacy data.
      const haettuValittaja: Verkkolaskuoperaattori = this._sahkoisenLaskunValittajaService.annaValittaja(kasiteltava.sahkoinen.osoite.sahkoinenValittaja)
      const confirmedValittajaTunnus = haettuValittaja?.tunnus ?? null
      this.valittajaControl.setValue(confirmedValittajaTunnus)
    }
    this.esikatselutiedot = {
      asetukset: this.asetukset,
      juurilasku: juurilasku,
      kasiteltava: kasiteltava
    }

    if (kasiteltava.nrotyyppi === LaskunumeroTyyppi.TAVALLINEN || kasiteltava.nrotyyppi === LaskunumeroTyyppi.KORJAUS) {
      const summanNayttamistiedot: SummanNayttamistiedot = {
        otsikko: this._lemonTranslation.lokalisoi('lasku.tila.' + juurilasku.tila),
        summa: juurilasku.avoinnaRypas,
        maksuaikaa: this._annaMaksuaikaa(kasiteltava),
        naytaMaksuaikaa: juurilasku.tila === LaskunTila.avoin || juurilasku.tila === LaskunTila.eraantynyt,
        summaColor: this._annaSummatekstinVari(juurilasku, kasiteltava)
      }
      this.summatiedotObservable.next(summanNayttamistiedot)
    } else {
      this.summatiedotObservable.next(null)
    }

    if (kasiteltava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
      const muistutuksenNumero = this._laskuSharedService.annaMuistutuslaskunNumero(juurilasku, kasiteltava)
      this.otsikko = this._laskuSharedService.annaMuotoiltuLaskunumero(juurilasku, kasiteltava) + ' Muistutus ' + muistutuksenNumero
    } else if (kasiteltava.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
      const hyvityksenNumero = this._laskuSharedService.annaHyvityslaskunNumero(juurilasku, kasiteltava)
      this.otsikko = this._laskuSharedService.annaMuotoiltuLaskunumero(juurilasku, kasiteltava) + ' Hyvitys ' + hyvityksenNumero
    } else {
      this.otsikko = this._laskuSharedService.annaMuotoiltuLaskunumero(juurilasku, kasiteltava) + ' ' + kasiteltava.asiakas.nimi
    }

    // Käyttöoikeustiedot
    const naytaMuokkausnappulat = this._naytaMuokkausnappulat(juurilasku, kasiteltava)
    const naytaMuokkaaNappula = this._naytaMuokkaaNappula(juurilasku, kasiteltava)
    const allowedAlwaysForThisSpecificInvoice = juurilasku?.avain === 'n02Y8NNM4rH52J1x745t'
    // console.log(this._laskuSharedService.onkoKasiteltavaLuonnos(juurilasku, kasiteltava), naytaMuokkaaNappula, naytaMuokkausnappulat)
    const nakyyvyystiedot: NakyvyysTiedot = {
      onkoMuokkaaNakyvissa: (naytaMuokkausnappulat && naytaMuokkaaNappula) || allowedAlwaysForThisSpecificInvoice,
      onkoMuokkaaInfoNakyvissa: naytaMuokkausnappulat && !naytaMuokkaaNappula,
      onkoHyvitaNakyvissa: naytaMuokkausnappulat && this._onkoHyvitaNakyvissa(juurilasku, kasiteltava),
      onkoKopioiUusiLaskuNakyvissa: naytaMuokkausnappulat && this._onkoKopioiUusiLaskuNakyvissa(juurilasku, kasiteltava),
      onkoLataaLaskuNakyvissa: this._laskuSharedService.onkoKasiteltavaMuuKuinLuonnos(juurilasku, kasiteltava),
      onkoMaksumuistutusNakyvissa: naytaMuokkausnappulat && this._onkoMaksumuistutusNakyvissa(juurilasku, kasiteltava),
      onkoMerkitseLuottotappioksiNakyvissa: naytaMuokkausnappulat && this._onkoMerkitseLuottotappioksiNakyvissa(juurilasku, kasiteltava),
      onkoMerkitseOsittainMaksetuksiNakyvissa: naytaMuokkausnappulat && this._onkoMerkitseOsittainMaksetuksiNakyvissa(juurilasku, kasiteltava),
      onkoPeruutaLuottotappioMerkintaNakyvissa: naytaMuokkausnappulat && this._onkoPeruutaLuottotappioMerkintaNakyvissa(juurilasku, kasiteltava),
      onkoPoistoLuonnosNakyvissa: naytaMuokkausnappulat && this._onkoPoistoLuonnosNakyvissa(juurilasku, kasiteltava),
      onkoViePerintaanEnabled: naytaMuokkausnappulat && this._onkoViePerintaanEnabled(juurilasku, kasiteltava),
      onkoViePerintaanNakyvissa: naytaMuokkausnappulat && this._onkoViePerintaanNakyvissa(juurilasku, kasiteltava),
      onkoPeruutaPerintaNakyvissa: naytaMuokkausnappulat && this._onkoPeruutaPerintaNakyvissa(juurilasku, kasiteltava),
      onkoPerintaAloittamatta: naytaMuokkausnappulat && this._onkoPerintaAloittamatta(juurilasku, kasiteltava)
    }
    this.nakyvyystiedotObservable.next(nakyyvyystiedot)
  }

  private _annaSummatekstinVari(juurilasku: Lasku, kasiteltava: LaskuBase): SummatekstinVari {

    if (juurilasku.tila === LaskunTila.eraantynyt) {
      return 'red'
    } else if (juurilasku.tila === LaskunTila.maksettuLiikaa) {
      return 'purple'
    } else if (juurilasku.tila === LaskunTila.maksettu) {
      return 'green'
    } else if (juurilasku.tila === LaskunTila.avoin) {
      if (
        this._vertaaEkaPienempiKuinToka(kasiteltava.avoinna, kasiteltava.summa) &&
        this._vertaaEkaPienempiKuinToka(0, kasiteltava.avoinna)
      ) {
        return 'yellow'
      } else if (this._vertaaYhtaSuuret(kasiteltava.avoinna, kasiteltava.summa)) {
        return 'blue'
      }
    }

    return null

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  // private _reskontrasumma(juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]): number {

  //   // if (juurilasku.tila === LaskunTila.maksettu || juurilasku.tila === LaskunTila.maksettuLiikaa) {
  //   //   const maksettu = this.reskontraService.annaReskontranSumma(juurilasku.reskontra)
  //   //   if (juurilasku.tila === LaskunTila.maksettu) {
  //   //     return this.currencyService.muutaBigDecimalRahaksi(maksettu)
  //   //   }
  //   //   return this.currencyService.muutaBigDecimalRahaksi(maksettu.minus(kasiteltava.summa))
  //   //   // return 0
  //   // }

  //   const summat = this._laskuSharedService.annaLaskuryppaanSummatLaskulle(juurilasku, reskontra)
  //   return this._currencyService.muutaBigDecimalRahaksi(summat.avoinna)

  // }

  async lataaLiitetiedosto(liitetiedosto: LaskunLiitetiedosto) {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {
      this._ladataanService.aloitaLataaminen()
      const kayttaja = await this._kayttajaService.getKayttajanTiedot()
      const liitetiedostoUri = this._laskuUriService.annaLiitetiedostonCloudStorageUri(kayttaja.asiakasAvain, liitetiedosto)
      const result = await this._http.getBinary('/laskuLataaLiitetiedosto?a=' + encodeURIComponent('/api/1/laskut/lataaLiitetiedosto/' + liitetiedostoUri) + '&time=' + encodeURIComponent(new Date().getTime()), LEMONAID_CF_API)
      this._fileSaverService.saveAs(result, liitetiedosto.nimi)
    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }
  }

  // async asetaTurvakeksi(): Promise<boolean> {
  //   const userPromise = this.auth.currentUser
  //   if (userPromise) {
  //     const token = await userPromise.then(user => user?.getIdToken())
  //     if (token) {
  //       const d = new Date()
  //       d.setTime(d.getTime() + (2 * 60 * 1000)) // 2 minute timeout
  //       document.cookie = '__session="' + encodeURIComponent(token) + '"; Secure; Domain=lemonaid.lemontree.fi; Path=/; Expires=' + d.toUTCString() + '; SameSite=Strict'
  //       // document.cookie = '__session="' + token + '"; Path="/api/1/laskut/lataaPdf"; Expires=' + d.toUTCString()
  //       return true
  //     }
  //   }
  //   return false
  // }

  // lataaLasku2() {
  //   if (
  //     (this.kasiteltava.print && this.kasiteltava.print.done) ||
  //     (this.kasiteltava.email && this.kasiteltava.email.done) ||
  //     (this.kasiteltava.sahkoinen && this.kasiteltava.sahkoinen.done)
  //   ) {
  //     this.ladataanService.aloitaLataaminen()
  //     return Promise.all([this.asetaTurvakeksi(), this.laskuService.getPdfUrl(this.kasiteltava)]).then(([turvakeksinAsetusOnnistui, pdfUrl]) => {
  //       console.log(turvakeksinAsetusOnnistui, pdfUrl)
  //       if (turvakeksinAsetusOnnistui) {
  //         const nimi = this.laskuService.annaPdfTiedostonNimi(this.juurilasku, this.kasiteltava, this.lemonTranslation.nykyinenKieli)
  //         const encodedNimi = encodeURIComponent(nimi)
  //         window.location.href = 'https://lemonaid.lemontree.fi/api/1/laskut/lataaPdf/' + pdfUrl + '?time=' + new Date().getTime() + '&nimi=' + encodedNimi
  //       }
  //     })
  //   } else {
  //     const data: LaskuLataaDialogData = {
  //       juurilasku: this.juurilasku,
  //       kasiteltava: this.kasiteltava
  //     }
  //     const dialogRef = this.dialog.open(LaskuLataaDialog, { 'data': data, panelClass: 'ilman-paddingia' })
  //   }
  // }

  async lataaLasku() {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {
      await this.lataaLaskuKasiteltavalle(this.juurilasku, this.kasiteltava, false)
    } finally {
      this._buttonPushInflight = false
    }
  }

  lataaLaskuKasiteltavalle(juurilasku: Lasku, kasiteltava: LaskuBase, versiolataus: boolean) {
    if (
      (kasiteltava.print && kasiteltava.print.done) ||
      (kasiteltava.email && kasiteltava.email.done) ||
      (kasiteltava.sahkoinen && kasiteltava.sahkoinen.done)
    ) {
      this._ladataanService.aloitaLataaminen()
      return this._laskuService.getPdfUrl(kasiteltava).then(url => {
        return this._http.getBinary('/laskuLataaPdf?a=' + encodeURIComponent('/api/1/laskut/lataaPdf/' + url) + '&time=' + encodeURIComponent(new Date().getTime()), LEMONAID_CF_API)
      }).then(result => {
        const nimi = versiolataus ? this._laskuService.annaPdfTiedostonNimiVersiolle(juurilasku, kasiteltava, this._lemonTranslation.nykyinenKieli) : this._laskuService.annaPdfTiedostonNimi(juurilasku, kasiteltava, this._lemonTranslation.nykyinenKieli)
        this._ladataanService.lopetaLataaminen()
        this._fileSaverService.saveAs(result, nimi)
      }).catch(err => {
        this._ladataanService.lopetaLataaminen()
        this._errorHandler.handleError(err)
      })
    } else {
      const data: LaskuLataaDialogData = {
        juurilasku: juurilasku,
        kasiteltava: kasiteltava
      }
      const dialogRef = this._dialog.open(LaskuLataaDialog, { 'data': data, panelClass: 'ilman-paddingia' })
    }
  }

  private _annaMaksuaikaa(kasiteltava: LaskuBase): number {
    if (kasiteltava?.erapvm) {
      return this._dateService.paiviaValissaTimestamp(kasiteltava.erapvm, this._timestampService.now())
    }
    return 0
  }

  naytaMuokkausEstettyDialogi() {
    this._dialog.open(LaskuMuokkausEstettyDialog)
  }

  async poistaLuonnos() {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {
      await this._laskuService.poistaLuonnos(this.juurilasku, this.kasiteltava).then(() => {
        this._router.navigate(['/laskutus/laskut'])
      })
    } finally {
      this._buttonPushInflight = false
    }
  }

  async merkitseLuottotappioksi() {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {
      await this._laskuService.merkitseLuottotappioksi(this.juurilasku)
    } finally {
      this._buttonPushInflight = false
    }
  }

  async peruutaLuottotappiomerkinta() {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {
      await this._laskuService.peruutaLuottotappiomerkinta(this.juurilasku)
    } finally {
      this._buttonPushInflight = false
    }
  }

  async merkitseOsittainMaksetuksi() {

    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {

      const reskontra = await firstValueFrom(this.reskontraObservable)
      const data: LaskuMerkitseOsittainMaksetuksiDialogData = {
        juurilasku: this.juurilasku,
        kasiteltava: this.kasiteltava,
        reskontra: reskontra
      }

      const settings: MatDialogConfig = {
        'data': data,
        panelClass: 'ilman-paddingia'
        // ,
        // maxWidth: '1600px',
        // maxHeight: korkeus,
        // width: leveys
      }
      const dialogRef = this._dialog.open(LaskuMerkitseOsittainMaksetuksiDialog, settings)
    } finally {
      this._buttonPushInflight = false
    }
  }

  async kopioiUusiLasku() {

    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {
      const data: LaskuComponentExistingData = {
        juurilasku: this.juurilasku,
        asetukset: this.asetukset
      }
      this._laskuComponentDataResolve.asetaOlemassaolevaData(data)

      this._router.navigate(['/laskutus/laskut/', this.juurilasku.avain, 'kopioiuusi'])
    } finally {
      this._buttonPushInflight = false
    }

  }

  muokkaa() {

    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {

      // console.log(this.juurilasku.tila, this.juurilasku.tila === LaskunTila.luonnos)

      // if (this.kasiteltava.print && this.kasiteltava.print.start) {

      //   const data: LaskuMuokkaaTulostettuaDialogData = {
      //     juurilasku: this.juurilasku,
      //     kasiteltava: this.kasiteltava,
      //     asetukset: this.asetukset
      //   }
      //   const settings: MatDialogConfig = {
      //     data: data
      //   }
      //   this.dialog.open(LaskuMuokkaaTulostettuaDialog, settings)

      // } else {

      const data: LaskuComponentExistingData = {
        juurilasku: this.juurilasku,
        asetukset: this.asetukset
      }
      this._laskuComponentDataResolve.asetaOlemassaolevaData(data)
      if (this._laskuSharedService.onkoKasiteltavaLuonnos(this.juurilasku, this.kasiteltava) || this.kasiteltava.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
        this._router.navigate(['/laskutus/laskut/', this.juurilasku.avain, this.kasiteltava.avain])
      } else {
        this._router.navigate(['/laskutus/laskut/', this.juurilasku.avain, UUDEN_LASKUN_AVAIN])
      }

      // }
    } finally {
      this._buttonPushInflight = false
    }

  }

  hyvita() {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {
      const data: LaskuComponentExistingData = {
        juurilasku: this.juurilasku,
        asetukset: this.asetukset
      }
      this._laskuComponentDataResolve.asetaOlemassaolevaData(data)

      this._router.navigate(['/laskutus/laskut/', this.juurilasku.avain, 'hyvita'])
    } finally {
      this._buttonPushInflight = false
    }
  }

  mitatoi() {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    try {
      const data: LaskuHaluatkoVarmastiMitatoidaLaskunDialogData = {
        juurilasku: this.juurilasku,
        kasiteltava: this.kasiteltava
      }

      const settings: MatDialogConfig = {
        data: data
      }

      const dialogi = this._dialog.open(LaskuHaluatkoVarmastiMitatoidaLaskunDialog, settings)
      dialogi.afterClosed().pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(result => {
        if (result) {
          const uusiKasiteltava = result as LaskuBase
          this._router.navigate(['/laskutus/laskut/', this.juurilasku.avain, uusiKasiteltava.avain, 'katsele'])
        }
      })
    } finally {
      this._buttonPushInflight = false
    }
  }

  private _onkoLataaLaskuNakyvissa(kasiteltava: LaskuBase): boolean {
    return kasiteltava &&
      (!!kasiteltava.print?.done || !!kasiteltava.email?.done || !!kasiteltava.sahkoinen?.done)
  }

  private _onkoPoistoLuonnosNakyvissa(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return kasiteltava &&
      juurilasku.tila === LaskunTila.luonnos
  }

  private _onkoViePerintaanEnabled(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return kasiteltava &&
      juurilasku.tila === LaskunTila.eraantynyt
      && !juurilasku.perintatiedot?.lahetettyFrontend
      && this._laskuSharedService.annaLaskuryppaanMuistutuslaskut(juurilasku).length > 0
  }

  private _onkoViePerintaanNakyvissa(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return kasiteltava &&
      this._onkoPerintaAloittamatta && this._kotimaanMyynti(juurilasku.tyyppi)
  }

  private _onkoPeruutaPerintaNakyvissa(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return kasiteltava &&
      juurilasku.perintatiedot?.lahetettyFrontend && !juurilasku.perintatiedot?.lahetettyPeruutusFrontend
  }

  private _onkoPerintaAloittamatta(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return kasiteltava &&
      !juurilasku.perintatiedot?.lahetettyFrontend
  }

  // private onkoMitatoiNakyvissa(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
  //   return kasiteltava &&
  //   (
  //     juurilasku.tila === LaskunTila.avoin ||
  //     juurilasku.tila === LaskunTila.maksettuLiikaa ||
  //     juurilasku.tila === LaskunTila.maksettu ||
  //     juurilasku.tila === LaskunTila.eraantynyt
  //   )
  // }

  private _onkoHyvitaNakyvissa(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return kasiteltava &&
      kasiteltava.nrotyyppi !== LaskunumeroTyyppi.HYVITYS &&
      (
        juurilasku.tila === LaskunTila.avoin ||
        juurilasku.tila === LaskunTila.maksettuLiikaa ||
        juurilasku.tila === LaskunTila.maksettu ||
        juurilasku.tila === LaskunTila.eraantynyt
      )
  }

  private _onkoKopioiUusiLaskuNakyvissa(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return kasiteltava &&
      kasiteltava.nrotyyppi !== LaskunumeroTyyppi.HYVITYS &&
      kasiteltava.nrotyyppi !== LaskunumeroTyyppi.MUISTUTUS &&
      this._laskuSharedService.onkoKasiteltavaMuuKuinLuonnos(juurilasku, kasiteltava)
  }

  private _onkoPeruutaLuottotappioMerkintaNakyvissa(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return kasiteltava &&
      juurilasku.tila === LaskunTila.luottotappio
  }

  private _onkoMerkitseOsittainMaksetuksiNakyvissa(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return kasiteltava &&
      kasiteltava.nrotyyppi !== LaskunumeroTyyppi.HYVITYS &&
      juurilasku.tila !== LaskunTila.luottotappio &&
      this._laskuSharedService.onkoKasiteltavaMuuKuinLuonnos(juurilasku, kasiteltava)
  }

  private _onkoMerkitseLuottotappioksiNakyvissa(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return kasiteltava &&
      kasiteltava.nrotyyppi !== LaskunumeroTyyppi.HYVITYS &&
      juurilasku.tila !== LaskunTila.luottotappio &&
      juurilasku.tila !== LaskunTila.maksettu &&
      juurilasku.tila !== LaskunTila.maksettuLiikaa &&
      this._laskuSharedService.onkoKasiteltavaMuuKuinLuonnos(juurilasku, kasiteltava)
  }

  private _onkoMaksumuistutusNakyvissa(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return kasiteltava &&
      kasiteltava.nrotyyppi !== LaskunumeroTyyppi.HYVITYS &&
      juurilasku.tila !== LaskunTila.luottotappio &&
      juurilasku.tila !== LaskunTila.maksettu &&
      juurilasku.tila !== LaskunTila.maksettuLiikaa &&
      this._laskuSharedService.onkoKasiteltavaMuuKuinLuonnos(juurilasku, kasiteltava)
  }

  private _naytaMuokkausnappulat(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    if (!kasiteltava) {
      return false
    }
    if (!kasiteltava.avain) {
      return false
    }
    if (kasiteltava.nrotyyppi !== LaskunumeroTyyppi.MUISTUTUS) {
      return this._laskuSharedService.onkoKasiteltavaMuuKuinMitatoity(juurilasku, kasiteltava)
    }
    return false
  }

  private _naytaMuokkaaNappula(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {

    // Luonnosta voi muokata aina
    if (this._laskuSharedService.onkoKasiteltavaLuonnos(juurilasku, kasiteltava)) {
      return true
    }

    // Muita ei voi muokata
    return false

  }

  naytaEpaonnistunutSahkoinen(): boolean {

    if (!this.kasiteltava || !this.kasiteltava.sahkoinen) {
      return false
    }

    if (
      this.kasiteltava.sahkoinen.status !== LaskuSahkoinenLahetysStatusKoodi.LAHETETTY_VASTAANOTTAJALLE &&
      this.kasiteltava.sahkoinen.status !== LaskuSahkoinenLahetysStatusKoodi.PROSESSOIDAAN
    ) {
      return true
    }

    return false

  }

  naytaEpaonnistunutSposti(): boolean {

    if (!this.kasiteltava || !this.kasiteltava.email || !this.kasiteltava.email.vastaanottajat) {
      return false
    }

    for (const vastaanottaja of this.kasiteltava.email.vastaanottajat) {
      if (vastaanottaja.status === EmailLahetysStatusKoodi.LAHETYS_EPAONNISTUI) {
        return true
      }
    }

    return false

  }

  naytaKokoVirhe(korjatutTiedot: KorjattavanEmailOsoitteenTiedot) {
    const dialogData: LaskuKokoVirheDialogData = {
      virhe: korjatutTiedot.virhe
    }
    this._dialog.open(LaskuKokoVirheDialog, { 'data': dialogData, panelClass: 'ilman-paddingia' })
  }

  async lahetaEmailUudelleen(korjatutTiedot: KorjattavanEmailOsoitteenTiedot) {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    this._ladataanService.aloitaLataaminen()
    try {
      korjatutTiedot.emailLahetysStatusLaskulta.email = korjatutTiedot.uusiEmail
      await this._laskuService.lahetaEmailUudelleen(this.juurilasku, this.kasiteltava, korjatutTiedot.emailLahetysStatusLaskulta, korjatutTiedot.vanhaEmail)
    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }
  }

  async lahetaSahkoinenUudelleen() {

    if (!this.sahkoinenForm.valid) {

      const formErrors = Object.values(this.sahkoinenForm.controls).filter(control => control.errors)

      const peppolErrorObject = await firstValueFrom(this.peppolValidationObservable)
      const peppolError = !!peppolErrorObject?.error
      if ((!peppolError && formErrors.length > 0) || (peppolError && formErrors.length > 1)) {
        this._validationService.merkitseKokoLomakeKosketuksi(this.sahkoinenForm)
        return
      }

    }

    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    this._ladataanService.aloitaLataaminen()
    try {
      await this._laskuService.lahetaSahkoinenUudelleen(this.juurilasku, this.kasiteltava)
    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }
  }

  async lahetaMuistutuslasku() {

    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true

    // this.ladataanService.aloitaLataaminen()
    try {
      const muokattavaksiJuuri = this._laskuKopioija.copyLasku(this.juurilasku)
      const viimeisinTavallinen = this._laskuSharedService.annaViimeisinTavallinenLasku(muokattavaksiJuuri)
      const [asiakas, reskontra] = await Promise.all([this.haeAsiakas(viimeisinTavallinen), firstValueFrom(this.reskontraObservable)])

      const laskunTyyppi = LaskunTyypit.annaLaskunTyyppi(viimeisinTavallinen.tyyppi)
      if (!laskunTyyppi) {
        throw new Error('Ei LaskunTyyppiä avaimella: ' + viimeisinTavallinen.tyyppi)
      }

      const alvt = await this._alvService.annaLaskutyypinAlvt(laskunTyyppi, asiakas.maa)
      if (!alvt?.length) {
        throw new Error('Ei ALV-määrityksiä tyypille ' + laskunTyyppi + ' ja maalle ' + asiakas.maa)
      }

      const muokattavaksiKasiteltava = this._laskuKopioija.kopioiLaskuMuistutusLaskuksi(muokattavaksiJuuri, viimeisinTavallinen, asiakas, alvt)
      if (!muokattavaksiJuuri.korvaus) {
        muokattavaksiJuuri.korvaus = []
      }
      muokattavaksiJuuri.korvaus.push(muokattavaksiKasiteltava.kopioitu)

      const data: LaskunLahetysdata = {
        juurilasku: muokattavaksiJuuri,
        asetukset: this.asetukset,
        kasiteltava: muokattavaksiKasiteltava.kopioitu,
        reskontra: reskontra,
        huomautuskulu: muokattavaksiKasiteltava.huomautuskulu,
        korkokulu: muokattavaksiKasiteltava.korkokulu,
        muistutus: true
      }
      this.lahetysdata.next(data)

      if (this.kasiteltava.asiakas.viimeisinLaskuLahetetty) {
        switch (this.kasiteltava.asiakas.viimeisinLaskuLahetetty) {
          case LaskunLahetystapa.SAHKOPOSTI:
            this.aktiivinenValilehti.next(0)
            break
          case LaskunLahetystapa.SAHKOINEN:
            this.aktiivinenValilehti.next(1)
            break
          case LaskunLahetystapa.ITSE:
            this.aktiivinenValilehti.next(2)
            break
          case LaskunLahetystapa.PAPERI:
            this.aktiivinenValilehti.next(3)
            break
          default:
            this.aktiivinenValilehti.next(0)
        }
      } else {
        this.aktiivinenValilehti.next(0)
      }

      setTimeout(() => {
        this._vasenValikkoService.paivitaAuki(false)
      }, 25)

      setTimeout(() => {
        const scrollable = document.querySelector('[cdk-scrollable]')
        if (scrollable) {
          scrollable.scrollTop = 0
        } else {
          window.scrollTo(0, 0)
        }
      }, 50)

      // TODO: MODAA KUN: https://github.com/angular/material2/issues/8493 on korjattu
      // PULL JOKA KORJAA: https://github.com/angular/material2/pull/9236
      // const kaytettavaLeveys = window.innerWidth
      // const kaytettavaKorkeus = window.innerHeight
      // const leveys = kaytettavaLeveys > 900 ? '90vw' : '98vw'
      // const korkeus = kaytettavaKorkeus > 1100 ? '80vh' : kaytettavaKorkeus > 750 ? '85vh' : '92vh'

      // const data: LaskuMaksumuistutusSpostiDialogData = {
      //   juurilasku: muokattavaksiJuuri,
      //   kasiteltava: muokattavaksiKasiteltava.kopioitu,
      //   asetukset: this.asetukset,
      //   huomautuskulu: muokattavaksiKasiteltava.huomautuskulu,
      //   korkokulu: muokattavaksiKasiteltava.korkokulu
      // }

      // const settings: MatDialogConfig = {
      //   data: data,
      //   maxWidth: '1600px',
      //   maxHeight: korkeus,
      //   width: leveys
      // }
      // this.ladataanService.lopetaLataaminen()
      // const dialogRef = this.dialog.open(LaskuMaksumuistutusSpostiDialog, settings)
      // dialogRef.afterClosed().subscribe(result => {
      //   if (result) {
      //     this.router.navigate(['/laskutus/laskut/', muokattavaksiJuuri.avain, muokattavaksiKasiteltava.kopioitu.avain, 'katsele'])
      //   }
      // })
    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      // this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }

  }

  async lahetaHyvityslaskuUudelleenSahkopostiin() {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true

    this._ladataanService.aloitaLataaminen()
    try {

      const [asiakas, reskontra] = await Promise.all([this.haeAsiakas(this.kasiteltava), firstValueFrom(this.reskontraObservable)])

      const laskunTyyppi = LaskunTyypit.annaLaskunTyyppi(this.kasiteltava.tyyppi)
      if (!laskunTyyppi) {
        throw new Error('Ei LaskunTyyppiä avaimella: ' + this.kasiteltava.tyyppi)
      }

      const alvt = await this._alvService.annaLaskutyypinAlvt(laskunTyyppi, asiakas.maa)
      if (!alvt?.length) {
        throw new Error('Ei ALV-määrityksiä tyypille ' + laskunTyyppi + ' ja maalle ' + asiakas.maa)
      }

      const data: LaskunLahetysdata = {
        juurilasku: this.juurilasku,
        asetukset: this.asetukset,
        kasiteltava: this.kasiteltava,
        reskontra: reskontra,
        huomautuskulu: null,
        korkokulu: null,
        muistutus: false
      }
      this.lahetysdata.next(data)
      this.aktiivinenValilehti.next(0)

      setTimeout(() => {
        this._vasenValikkoService.paivitaAuki(false)
      }, 25)

      setTimeout(() => {
        const scrollable = document.querySelector('[cdk-scrollable]')
        if (scrollable) {
          scrollable.scrollTop = 0
        } else {
          window.scrollTo(0, 0)
        }
      }, 50)

    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }

  }

  async lahetaPerintaan() {

    const data: LaskuPerintaSpostiDialogData = {
      juurilasku: this.juurilasku,
      kasiteltava: this.juurilasku,
      asetukset: this.asetukset
    }

    this._dialog.open(LaskuPerintaSpostiDialog, {
      data: data
    })

  }

  async lahetaPerintaritarimuistutuslaskuHallinto() {

    if (!this.naytaPerintaritari) {
      return
    }

    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true

    this._ladataanService.aloitaLataaminen()
    try {

      const muokattavaksiJuuri = this._laskuKopioija.copyLasku(this.juurilasku)
      const viimeisinTavallinen = this._laskuSharedService.annaViimeisinTavallinenLasku(muokattavaksiJuuri)
      const asiakas = await this.haeAsiakas(viimeisinTavallinen)

      const laskunTyyppi = LaskunTyypit.annaLaskunTyyppi(viimeisinTavallinen.tyyppi)
      if (!laskunTyyppi) {
        throw new Error('Ei LaskunTyyppiä avaimella: ' + viimeisinTavallinen.tyyppi)
      }

      const alvt = await this._alvService.annaLaskutyypinAlvt(laskunTyyppi, asiakas.maa)
      if (!alvt?.length) {
        throw new Error('Ei ALV-määrityksiä tyypille ' + laskunTyyppi + ' ja maalle ' + asiakas.maa)
      }

      const muokattavaksiKasiteltava = this._laskuKopioija.kopioiLaskuMuistutusLaskuksi(muokattavaksiJuuri, viimeisinTavallinen, asiakas, alvt)
      if (!muokattavaksiJuuri.korvaus) {
        muokattavaksiJuuri.korvaus = []
      }
      muokattavaksiJuuri.korvaus.push(muokattavaksiKasiteltava.kopioitu)

      this._ladataanService.aloitaLataaminen()
      const vastaanottajat: EmailLahetysStatus[] = [{
        email: 'lemontree@perintaritari.com',
        status: EmailLahetysStatusKoodi.PROSESSOIDAAN,
        viesti: null
      }]

      const spostiasetukset = this._laskuSpostiService.annaSpostiAsetukset(muokattavaksiKasiteltava.kopioitu, true, this.asetukset)

      const postmarkTemplateId = this._laskuSpostiService.annaSahkopostipohjanPostmarkNumero(spostiasetukset.template, muokattavaksiKasiteltava.kopioitu.kieli)
      const aihe = spostiasetukset.aihe
      const otsikko = spostiasetukset.otsikko
      const teksti = spostiasetukset.teksti

      await this._laskuService.merkitseLaskunMaksumuistutusLahetetyksiSpostilla(postmarkTemplateId, muokattavaksiJuuri, muokattavaksiKasiteltava.kopioitu, vastaanottajat, aihe, otsikko, teksti, this.asetukset.slogan, false)
      this.peruuta()

    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }

  }

  async peruutaLaskunPerinta() {
    const data: LaskuPerintaPeruutusAreYouSureDialogData = {
      juurilasku: this.juurilasku
    }

    this._dialog.open(LaskuPerintaPeruutusAreYouSureDialog, {
      data: data
    })
    this.perintaOnLopetettu = true
  }

  kasitteleLahetys(event: LaskunLahetystiedot) {
    if (!event.error && event.tapa) {
      const olemassaoleva: LaskuKatseleComponentExistingData = {
        juurilasku: event.lahetettyJuuri,
        kasiteltava: event.lahetettyKasiteltava
      }
      this._laskuKatseleComponentDataResolve.asetaOlemassaolevaData(olemassaoleva)
      this.lahetysdata.next(null)
      if (event.tapa === LaskunLahetystapa.ITSE) {
        this._router.navigate(['/laskutus/laskut/', event.lahetettyJuuri.avain, event.lahetettyKasiteltava.avain, 'tulosta'])
      } else {
        this._router.navigate(['/laskutus/laskut/', event.lahetettyJuuri.avain, event.lahetettyKasiteltava.avain, 'katsele'])
      }
    } else {
      if (event.error) {
        this._ladataanService.naytaVirheDialog(event.error)
      }
    }
    setTimeout(() => {
      this._vasenValikkoService.paivitaAuki(true)
    }, 25)
  }

  private haeAsiakas(kasiteltava: LaskuBase): Promise<LaskunAsiakas | null> {
    if (kasiteltava && kasiteltava.asiakas && kasiteltava.asiakas.avain) {
      return firstValueFrom(this._laskuService.getAsiakasObservable(kasiteltava.asiakas.avain))
    }
    return Promise.resolve(null)
  }

  private annaLokiparametrit(loki: LaskuToimintaloki) {

    const paluuarvo: any = {}

    paluuarvo.lasku_nro = this._laskuSharedService.annaMuotoiltuLaskunumero(this.juurilasku, this.kasiteltava)

    if (
      loki.toiminto === LaskunToimintalokiTyyppi.LAHETETTY_SPOSTI ||
      loki.toiminto === LaskunToimintalokiTyyppi.LAHETETTY_MAKSUMUISTUTUS_SPOSTI
    ) {
      if (loki.parametrit) {
        const parametrit = loki.parametrit as LaskunToimintalokiSpostiParametrit
        paluuarvo.osoitteet = parametrit.emailit ? parametrit.emailit.join(', ') : ''
      } else {
        paluuarvo.osoitteet = '?'
      }
    }
    if (
      loki.toiminto === LaskunToimintalokiTyyppi.LAHETETTY_SAHKOINEN ||
      loki.toiminto === LaskunToimintalokiTyyppi.LAHETETTY_TULOSTA_TOISEN_HYVAKSYNTAJONOON_ODOTTAMAAN_HYVAKSYNTAA ||
      loki.toiminto === LaskunToimintalokiTyyppi.LAHETETTY_SAHKOISESTI_TOISEN_HYVAKSYNTAJONOSTA_HYVAKSYNNAN_JALKEEN
    ) {
      if (loki.parametrit) {
        const parametrit = loki.parametrit as LaskunToimintalokiSahkoinenParametrit
        const tunnus = parametrit.osoite && parametrit.osoite.sahkoinenValittaja ? parametrit.osoite.sahkoinenValittaja : ''
        const kaytettevaTunnus = (tunnus as any).tunnus ? (tunnus as any).tunnus : tunnus
        // console.log(kaytettevaTunnus)
        const nimi = this._sahkoisenLaskunValittajaService.annaValittajanNimi(kaytettevaTunnus)
        const valittaja = kaytettevaTunnus + (nimi ? ' (' + nimi + ')' : '')
        // console.log(valittaja)
        paluuarvo.sahkoinenOsoite = parametrit.osoite && parametrit.osoite.sahkoinenOsoite ? parametrit.osoite.sahkoinenOsoite : ''
        paluuarvo.sahkoinenValittaja = valittaja
      } else {
        paluuarvo.sahkoinenOsoite = '?'
        paluuarvo.sahkoinenValittaja = '?'
      }
    }

    return paluuarvo
  }

  // naytaMerkinta(merkinta: LaskuReskontra) {
  //   if (this.isLemontreeAdmin) {
  //     this._dialog.open(LaskuReskontraTiedotDialog, { data: merkinta })
  //   }
  // }

  peruuta() {
    this._router.navigate(['/laskutus/laskut'])
  }

  private _vertaaYhtaSuuret(yksi: number, kaksi: number): boolean {
    return Math.round(yksi * 100) === Math.round(kaksi * 100)
  }

  private _vertaaEkaPienempiKuinToka(pienempi: number, suurempi: number): boolean {
    return Math.round(pienempi * 100) < Math.round(suurempi * 100)
  }


  vaihdaValilehti(indeksi: number) {
    this.aktiivinenValilehti.next(indeksi)
  }

  peruutaLahettaminen() {
    this.lahetysdata.next(null)
    setTimeout(() => {
      this._vasenValikkoService.paivitaAuki(true)
    }, 25)
  }

  get osoiteControl(): AbstractControl {
    return this.sahkoinenForm.get('osoite')
  }
  get valittajaControl(): AbstractControl {
    return this.sahkoinenForm.get('valittaja')
  }

  async paivitaTilaJaIndeksoiUudelleen() {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    this._ladataanService.aloitaLataaminen()
    try {
      await this._laskuService.paivitaTilaJaIndeksoiUudelleen(this.juurilasku)
    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }
  }

  async laskeReskontraUudelleen() {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    this._ladataanService.aloitaLataaminen()
    try {
      await this._laskuService.laskeReskontraUudelleen(this.juurilasku)
    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }
  }

  async lahetaUudelleen() {
    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true
    this._ladataanService.aloitaLataaminen()
    try {
      this._ladataanService.aloitaLataaminen()
      await this._laskuService.lahetaLaskuUudelleen(this.juurilasku, this.kasiteltava)
    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._buttonPushInflight = false
    }
  }

  private _getKayttajienNimetMap() {
    const output: Map<string, string> = new Map()
    return this._firebase.functionsCall<AnnaLemonaidKayttajienTiedotRequest, AnnaLemonaidKayttajienTiedotResponse>('annaAsiakkaanKayttajienTiedot', {}).then(resp => {
      if (!resp || resp.e) {
        console.error('Käyttäjien nimien haku epäonnistui 1 ' + (resp.e || 'No response'))
        return output
      }
      for (const kayttaja of resp.avaimetJaNimet) {
        output.set(kayttaja.avain, (kayttaja.etunimi + ' ' + kayttaja.sukunimi))
      }
      return output

    }).catch(err => {
      console.error('Käyttäjien nimien haku epäonnistui 1 ' + err)
      return output
    })
  }

  private _annaReskontraLokinSelite(reskontra: LaskuReskontra, valutta: string, kayttajienNimetMap: Map<string, string>, kieli: TuettuKieli): string {
    if (reskontra.tekijaUid === 'lemonator_system') {
      const maksajaPart = reskontra.maksaja ? (' (' + reskontra.maksaja + ')') : ''
      return `${this._dateService.muotoile(reskontra.luotu.toDate(), 'dd.MM.yyyy HH:mm:ss')} ${this._translationService.lokalisoi('lasku.katsele-muokattava-dialog.automaattimerkinta-tilitapahtumasta')}: ${this._currencyService.formatoiRaha(reskontra.suoritus, valutta.toLowerCase(), kieli)}${maksajaPart}`
    }
    const kayttajanNimi = kayttajienNimetMap.get(reskontra.tekijaUid)
    return `${this._dateService.muotoile(reskontra.luotu.toDate(), 'dd.MM.yyyy HH:mm:ss')} ${this._translationService.lokalisoi('lasku.katsele-muokattava-dialog.reskontraan-merkittiin-kasin')}: ${this._currencyService.formatoiRaha(reskontra.suoritus, valutta.toLowerCase(), kieli)} ${kayttajanNimi ? ('(' + kayttajanNimi + ')') : ''}`
  }

}
