/** 
 * Banks
 * Add new banks when necessary but don't modify/remove the existing enum values.
 */
export enum Bank {
  AKTIA = 1,
  DANSKE = 2,
  NORDEA = 3,
  OMASP = 4,
  OP = 5,
  SAASTOPANKKI = 6,
  POPPANKKI = 7
}

/** 
 * Supported banks for payments.
 * Add new banks when necessary but don't modify/remove the existing enum values.
 */
export enum PaymentSupportedBank {
  AKTIA = Bank.AKTIA,
  DANSKE = Bank.DANSKE,
  NORDEA = Bank.NORDEA,
  OMASP = Bank.OMASP,
  OP = Bank.OP,
  SAASTOPANKKI = Bank.SAASTOPANKKI,
  POPPANKKI = Bank.POPPANKKI
}

const PaymentSupportedBanksRecord: Record<PaymentSupportedBank, { nimi: string }> = {
  [PaymentSupportedBank.AKTIA]: { nimi: 'Aktia' },
  [PaymentSupportedBank.DANSKE]: { nimi: 'Danske' },
  [PaymentSupportedBank.NORDEA]: { nimi: 'Nordea' },
  [PaymentSupportedBank.OMASP]: { nimi: 'OmaSP' },
  [PaymentSupportedBank.OP]: { nimi: 'OP' },
  [PaymentSupportedBank.SAASTOPANKKI]: { nimi: 'Säästöpankki' },
  [PaymentSupportedBank.POPPANKKI]: { nimi: 'POP pankki' },
}

export const getPaymentSupportedBanks = (): { nimi: string, bank: PaymentSupportedBank }[] => {
  return Object.entries(PaymentSupportedBanksRecord).map(([key, supportedBankData]) => (
    { nimi: supportedBankData.nimi, bank: Number(key) as PaymentSupportedBank }
  ))
}

export const validBICsForBank: Record<PaymentSupportedBank, string[]> = {
  [PaymentSupportedBank.AKTIA]: ['HELSFIHH'],
  [PaymentSupportedBank.DANSKE]: ['DABAFIHH'],
  [PaymentSupportedBank.NORDEA]: ['NDEAFIHH'],
  [PaymentSupportedBank.OMASP]: ['ITELFIHH'],
  [PaymentSupportedBank.OP]: ['OKOYFIHH'],
  [PaymentSupportedBank.SAASTOPANKKI]: ['ITELFIHH'],
  [PaymentSupportedBank.POPPANKKI]: ['POPFFI22'],
}

/**
 * Rejection errors that could be helpful for the user.
 * Add more known reasons when necessary.
 */
export enum PaymentRejectionReason {
  UNKNOWN = 1,

  WS_AGREEMENT = 2,
  INSUFFICIENT_FUNDS = 3,
  LIMIT_EXCEEDED = 4,
  // ...
}

/**
 * Additional rejection information from the payments project that can be exposed to UI
 */
export interface PaymentRejection {
  reason: PaymentRejectionReason
  /** Used for PaymentRejectionReason.UNKNOWN cases and contains the raw description of the error. */
  rawText?: string
}

export const getPaymentErrorEmailMessage = (msg: string, subject = 'Maksujärjestelmässä on tapahtunut virhe') => ({
  'From': 'payments@lemontree.fi',
  'To': 'ville@lemontree.fi,ville.hartikainen@lemontree.fi,elina.partinen@lemontree.fi,lauri@lemontree.fi',
  'Subject': subject,
  'HtmlBody': msg,
  'TextBody': msg,
  'MessageStream': 'payments'
})
