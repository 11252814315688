import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

import { DateAdapter } from '@angular/material/core'

import { VersionTarkistusPalvelu } from '../../_angular/service/version-tarkistus.palvelu'
import { DragAndDropService } from '../../_jaettu-angular/service/drag-and-drop.service'
import { TiedostojenLataamisService } from '../../_jaettu-angular/service/tiedostojen-lataamis.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { TositeKatseleComponentDataResolve, TositeMuokkaaComponentDataResolve, TositeLuoUusiComponentData, TositeLuoUusiComponentDataResolve } from '../../_angular/_resolvers/tosite.resolve'

import { Maksutapa, FirestoreTosite, MYYNTITOSITE_MAKSUTAPA } from '../../_jaettu/model/tosite'
import { FirestoreIndeksoija } from '../../_jaettu/service/firestore.indeksoija'
import { CurrencyService } from '../../_shared-core/service/currency.service'

import { Subject, Observable, combineLatest, BehaviorSubject, firstValueFrom } from 'rxjs'
import { debounceTime, takeUntil, map } from 'rxjs/operators'

import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop'
import { ListausFirestoreKuitti, TositeDatasourceService, MaksutapaJaKuva, Summat } from '../../_angular/service/tosite/tosite-datasource.service'
import { SelvitettavatTositteetService } from '../../_angular/service/tosite/selvitettavat-tositteet.service'
import { TositeService } from 'app/_angular/service/tosite/tosite.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'

export type KuittiProperties = 'pvm' | 'summa' | 'maksutapa' | undefined

export interface MaksutavanTiedot {
  summa: number
  lukumaara: number
}

@Component({
  selector: 'app-myyntitositteet',
  templateUrl: './myyntitosite.listaus.component.html',
  styleUrls: ['./myyntitosite.listaus.component.css']
})
export class MyyntitositeListausComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('fileInput', { static: true }) fileInput

  private ngUnsubscribe = new Subject<void>()

  form: UntypedFormGroup

  propertiesToDisplay: KuittiProperties[] = []

  naytaHuomautus = false
  naytaKaikkiSummat = true

  vanhaValittuMaksutapa: string = null
  etsitaan = false

  private maksutapojenSummat: { [key: string]: MaksutavanTiedot } = {}
  private poistaFunktio: any = null

  isDragging = false
  kuvavirheAvain: string = null
  kuvavirheParametrit: any = null

  naytaEiYhtaanObservable: Observable<boolean>
  naytettavatKuititObservable: Observable<ListausFirestoreKuitti[]>
  otsikkoObservable: Observable<string>
  lataaObservable: Observable<boolean>

  private selvitettavatValittuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  selvitettavatValittuObservable = this.selvitettavatValittuSubject.asObservable()
  selvitettavienTiedotObservable: Observable<{ maara: number, summa: number }>
  showSelvitettavatAndMainMaksutapaObservable: Observable<boolean>

  maksutapaJaKuvaObservable: Observable<MaksutapaJaKuva>


  constructor(
    private router: Router,
    private firestoreIndeksoija: FirestoreIndeksoija,
    private sanitizer: DomSanitizer,
    private dateAdapter: DateAdapter<Date>,
    private currencyService: CurrencyService,
    private translationService: LemonTranslationService,
    private tositeDatasourceService: TositeDatasourceService,
    private dndService: DragAndDropService,
    private tiedostojenLataamisService: TiedostojenLataamisService,
    private kuittiKatseleComponentDataResolver: TositeKatseleComponentDataResolve,
    private kuittiComponentDataResolve: TositeMuokkaaComponentDataResolve,
    private versionTarkistaja: VersionTarkistusPalvelu,
    private selvitettavatTositteetService: SelvitettavatTositteetService,
    private tositeService: TositeService,
    private luoComponentDataResolve: TositeLuoUusiComponentDataResolve
  ) { }

  private etsiArvolla(arvo: string) {
    this.etsitaan = true
    this.tositeDatasourceService.changeSearch(arvo)
  }

  ngOnInit() {

    this.tositeDatasourceService.changeSearch(null)

    this.selvitettavienTiedotObservable = this.selvitettavatTositteetService.annaSelvitettavienMaaraJaSummaObservable()

    this.showSelvitettavatAndMainMaksutapaObservable = this.selvitettavienTiedotObservable.pipe(
      map(tiedot => !!tiedot?.maara)
    )

    // If the previously chosen kuitti wasn't selvitettävä, start with that maksutapa;
    // otherwise start with selvitettävät if there are any
    firstValueFrom(this.showSelvitettavatAndMainMaksutapaObservable).then(async naytaSelvitettavia => {
      const lastViewedKuitinMaksutapa = this.tositeService.getLastViewedKuitinMaksutapa()
      if (lastViewedKuitinMaksutapa) {
        this.selvitettavatValittuSubject.next(false)
        this.tositeDatasourceService.vaihdaValittuMaksutapa(lastViewedKuitinMaksutapa)
      } else if (naytaSelvitettavia) {
        this.selvitettavatValittuSubject.next(true)
      }
      await this.tositeService.setLastViewedKuitinMaksutapa(null)
    })

    this.lataaObservable = this.tositeDatasourceService.lataaObservable

    this.otsikkoObservable = this.tositeDatasourceService.hakukriteeritObservable.pipe(
      map(kriteerit => {
        if (kriteerit && kriteerit.vuosikk) {
          return this.dateAdapter.getMonthNames('long')[kriteerit.vuosikk.kk] + ' ' + kriteerit.vuosikk.vuosi
        }
        return ''
      })
    )

    this.naytettavatKuititObservable = this.tositeDatasourceService.kuititObservable

    this.naytaEiYhtaanObservable = combineLatest([this.tositeDatasourceService.rivienMaaraObservable, this.tositeDatasourceService.lataaObservable]).pipe(
      map(([rivienMaara, lataa]) => {
        if (lataa) {
          return false
        }
        return rivienMaara < 1
      })
    )

    this.versionTarkistaja.sovelluksenVersioObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(versiotiedot => {
      this.versionTarkistaja.tarkistaVersio(versiotiedot)
    })

    this.form = new UntypedFormGroup({
      'nimiFirestore': new UntypedFormControl(this.tositeDatasourceService.getSearch(), [])
    })

    this.form.get('nimiFirestore').valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      if (value) {
        let val = this.firestoreIndeksoija.poistaValilyonnit(value)
        const syoteOnNumero = this.currencyService.onkoMerkkijonoNumero(val)
        if (syoteOnNumero) {
          val = this.firestoreIndeksoija.korvaaDesimaaliErotinHakuerottimella(val)
          val = this.firestoreIndeksoija.poistaValimerkitJaValilyonnit(val)
          this.etsiArvolla('s' + val)
        } else {
          val = this.firestoreIndeksoija.poistaValimerkitJaValilyonnit(val)
          if (val.length > 2) {
            this.etsiArvolla(val)
          }
        }
      } else {
        this.etsitaan = false
        this.tositeDatasourceService.changeSearch(null)
      }
    })

    this.dndService.isDraggingInWindowObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(dragging => {
      this.isDragging = dragging
    })

    if (this.tositeDatasourceService.getMaksutapa() !== (MYYNTITOSITE_MAKSUTAPA.i + '')) {
      this.tositeDatasourceService.changeMaksutapa(MYYNTITOSITE_MAKSUTAPA.i + '')
    }

    this.tositeDatasourceService.kuititObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(tositteet => {
      this.maksutapojenSummat = {}
      if (tositteet && tositteet.length > 0) {

        for (const kuitti of tositteet) {
          if (!this.maksutapojenSummat[kuitti.maksutapa]) {
            this.maksutapojenSummat[kuitti.maksutapa] = {
              summa: 0,
              lukumaara: 0
            }
          }

          const maksutavanTiedot = this.maksutapojenSummat[kuitti.maksutapa]
          maksutavanTiedot.summa += kuitti.summa
          maksutavanTiedot.lukumaara++

        }
      }
    })

    const pseudoMaksutavanSummatObservable: Observable<Summat> = this.tositeDatasourceService.kuititObservable.pipe(
      map(kuitit => {
        const maksutavanTiedot: Summat = { summa: 0, lukumaara: 0 }
        if (kuitit) {
          for (const kuitti of kuitit) {
            maksutavanTiedot.summa += kuitti.summa
            maksutavanTiedot.lukumaara++
          }
        }
        return maksutavanTiedot
      })
    )
    const pseudoMaksutapaJaKuvaObservable: Observable<MaksutapaJaKuva> = this.selvitettavienTiedotObservable.pipe(
      map(selvitettavienTiedot => {
        if (!selvitettavienTiedot?.maara) {
          return null
        }
        const myyntiPseudoMaksutapaJaKuva: MaksutapaJaKuva = {
          maksutapaanVoiRaahata: true,
          maksutapa: MYYNTITOSITE_MAKSUTAPA,
          kuva: new BehaviorSubject(this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + MYYNTITOSITE_MAKSUTAPA.img)),
          onkoNuoli: false,
          timeout: null,
          lukumaara: 0,
          summa: 0,
          valittu: false
        }
        return myyntiPseudoMaksutapaJaKuva
      })
    )
    this.maksutapaJaKuvaObservable = combineLatest([
      pseudoMaksutavanSummatObservable,
      pseudoMaksutapaJaKuvaObservable,
      this.selvitettavatValittuObservable
    ]).pipe(
      map(([maksutapaTiedot, maksutapa, selvitettavatValittu]) => {
        if (!maksutapa) {
          return null
        }
        maksutapa.lukumaara = maksutapaTiedot?.lukumaara || 0
        maksutapa.summa = maksutapaTiedot?.summa || 0

        maksutapa.valittu = !selvitettavatValittu
        return maksutapa
      }),
      lemonShare()
    )

  }

  annaKuittienSummaMaksutavalle(maksutapa: Maksutapa): number {
    const maksutavanTiedot = this.maksutapojenSummat[maksutapa.i]
    if (maksutavanTiedot) {
      return maksutavanTiedot.summa
    }
    return 0
  }

  annaKuittienSummaKuukaudelle(): number {
    let s = 0
    for (const key of Object.keys(this.maksutapojenSummat)) {
      const maksutavanTiedot = this.maksutapojenSummat[key]
      s += maksutavanTiedot.summa
    }
    return s
  }

  annaKuittienLukumaaraMaksutavalle(maksutapa: Maksutapa): number {
    const maksutavanTiedot = this.maksutapojenSummat[maksutapa.i]
    if (maksutavanTiedot) {
      return maksutavanTiedot.lukumaara
    }
    return 0
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  vihreaPilvi(kuitti: FirestoreTosite): boolean {
    if (kuitti.alkuperaiset) {
      for (const alkuperaisenAvain of Object.keys(kuitti.alkuperaiset)) {
        const alkuperainen = kuitti.alkuperaiset[alkuperaisenAvain]
        if (!alkuperainen.kasitelty) {
          return false
        }
      }
    }
    return true
  }

  edellinenKuukausi() {
    const vuosiKk = this.tositeDatasourceService.getVuosiKk()
    const vuosi = vuosiKk.kk < 1 ? vuosiKk.vuosi - 1 : vuosiKk.vuosi
    const kuukausi = vuosiKk.kk < 1 ? 11 : vuosiKk.kk - 1
    this.tositeDatasourceService.changeVuosiKk(vuosi, kuukausi)
  }

  seuraavaKuukausi() {
    const vuosiKk = this.tositeDatasourceService.getVuosiKk()
    const vuosi = vuosiKk.kk > 10 ? vuosiKk.vuosi + 1 : vuosiKk.vuosi
    const kuukausi = vuosiKk.kk > 10 ? 0 : vuosiKk.kk + 1
    this.tositeDatasourceService.changeVuosiKk(vuosi, kuukausi)
  }

  public annaMaksutavanKuva(maksutapa: Maksutapa, over: boolean): SafeUrl {
    if (over) {
      return '/assets/nuoli_alas.png'
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + maksutapa.img)
  }

  dateChange() {
    // console.log('CHaNGEA')
  }

  dateInput() {
    // console.log('INPuTTIA')
  }

  public fileOver(event: any, maksutapa?: MaksutapaJaKuva) {
    if (maksutapa?.timeout) {
      clearTimeout(maksutapa.timeout)
      maksutapa.timeout = null
    }
    if (maksutapa && !maksutapa.onkoNuoli) {
      maksutapa.onkoNuoli = true
      maksutapa.kuva.next('/assets/nuoli_alas.png')
    }
    this.dndService.setDragging(true)
  }

  public fileLeave(event: any, maksutapa?: MaksutapaJaKuva) {
    if (maksutapa?.onkoNuoli) {
      if (maksutapa.timeout) {
        clearTimeout(maksutapa.timeout)
        maksutapa.timeout = null
      }
      maksutapa.timeout = setTimeout(() => {
        maksutapa.onkoNuoli = false
        const kuva = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + maksutapa.maksutapa.img)
        maksutapa.kuva.next(kuva)
      }, 25)
    }
    this.dndService.setDragging(true)

  }

  public async fileDrop(entries: NgxFileDropEntry[]): Promise<void> {

    this.dndService.setDragging(false)

    const voidaanLadata = await this.tiedostoVoidaanLadata(entries)
    if (!voidaanLadata) {
      return
    }

    this.siirryLuomiseen(entries)

  }
  private siirryLuomiseen(tiedostot: NgxFileDropEntry[]) {
    const data: TositeLuoUusiComponentData = {
      tiedostot: tiedostot,
      maksutapa: MYYNTITOSITE_MAKSUTAPA
    }
    this.luoComponentDataResolve.asetaOlemassaolevaData(data)
    this.router.navigate(['/tositteet/myynti/luo'])
  }

  private async tiedostoVoidaanLadata(tiedostot: NgxFileDropEntry[]): Promise<boolean> {

    this.kuvavirheAvain = ''
    this.kuvavirheParametrit = {}
    // if (kuvienMaara > 1) {
    //   this.kuvavirheAvain = 'laskuasetukset.logo.virheet.vain-yksi-kerrallaan'
    //   setTimeout(() => {
    //     this.kuvavirheAvain = ''
    //   }, 10000)
    //   return false
    // }

    if (tiedostot.length < 1) {
      if (this.poistaFunktio) {
        clearTimeout(this.poistaFunktio)
      }
      this.kuvavirheAvain = 'kuitit.lataaminen.virheet.ei-tiedosto'
      this.poistaFunktio = setTimeout(() => {
        this.kuvavirheAvain = ''
      }, 15000)
      return false
    }

    const supportedImageTypes = ['jpeg', 'jpg', 'png', 'webp', 'tiff', 'gif', 'svg', 'pdf', 'heic', 'heif']
    for (const tiedosto of tiedostot) {

      const file = tiedosto.fileEntry as FileSystemFileEntry

      const fileEnding = this.tiedostojenLataamisService.getFileEndingFromFileName(file.name)
      const fileSize = file ? await this.tiedostojenLataamisService.getFileSize(file) : -1

      // console.log(fileSize, file)

      if (!fileEnding || supportedImageTypes.indexOf(fileEnding.toLowerCase()) < 0) {
        if (this.poistaFunktio) {
          clearTimeout(this.poistaFunktio)
        }
        this.kuvavirheParametrit = {
          tuetutMuodot: supportedImageTypes.join(', ')
        }
        this.kuvavirheAvain = 'kuitit.lataaminen.virheet.vaara-muoto'
        this.poistaFunktio = setTimeout(() => {
          this.kuvavirheAvain = ''
        }, 15000)
        return false
      }

      const maxKoko = 25 * 1024 * 1024
      if (fileSize > maxKoko) {
        if (this.poistaFunktio) {
          clearTimeout(this.poistaFunktio)
        }
        const kokoMegatavuissaLokalisoitu = this.currencyService.formatoiDesimaali((fileSize / 1024 / 1024), 2, this.translationService.nykyinenKieli)
        const maxKokoLokalisoitu = this.currencyService.formatoiDesimaali((maxKoko / 1024 / 1024), 2, this.translationService.nykyinenKieli)
        this.kuvavirheParametrit = {
          kokoMax: maxKokoLokalisoitu,
          kokoNyt: kokoMegatavuissaLokalisoitu
        }
        this.kuvavirheAvain = 'kuitit.lataaminen.virheet.liian-suuri'
        this.poistaFunktio = setTimeout(() => {
          this.kuvavirheAvain = ''
        }, 15000)
        return false
      }

    }

    return true

  }

  naytaTiedostonValintaDialogi() {
    this.fileInput.nativeElement.click()
  }

  async fileChanged(event) {

    const list: FileList = event.target.files
    const tiedostot: NgxFileDropEntry[] = this.tiedostojenLataamisService.fileListToNgxFileDropEntries(list)

    const voidaanLadata = await this.tiedostoVoidaanLadata(tiedostot)
    if (!voidaanLadata) {
      return
    }

    this.siirryLuomiseen(tiedostot)

  }

  katsele(tosite: FirestoreTosite) {
    this.kuittiKatseleComponentDataResolver.asetaOlemassaolevaData({ tosite: tosite })
    this.router.navigate(['tositteet', 'myynti', tosite.avain])
  }

  valitseMainMaksutapa() {
    this.selvitettavatValittuSubject.next(false)
  }
  selvitettavatValittu() {
    this.selvitettavatValittuSubject.next(true)
  }
  trackKuitti(index: number, kuitti: ListausFirestoreKuitti) {
    return kuitti.avain
  }
}
