import { Component, OnInit, AfterViewInit, OnDestroy, ErrorHandler } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'

import { Subject, of, combineLatest } from 'rxjs'
import { takeUntil, map, switchMap, startWith } from 'rxjs/operators'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { FormValidationService } from '../_jaettu-angular/service/form-validation.service'
import { AnnaKayttajanTiedotReq, TallennaKirjanpidonUlkopuolisetKulutReq, MagicLinkErrorResponse, KirjanpidonUlkopuolisetKulutAnnaKayttajanTiedotResp, TallennaKirjanpidonUlkopuolisetKulutResp } from '../_jaettu/model/magic-link'
import { KayttajaService } from '../_angular/service/kayttaja.service'
import { Ajoneuvo, KirjanpidonUlkopuolisetKulut } from '../_jaettu/model/kayttaja'
import { VaihdaKieliDialog, VaihdaKieliDialogData } from '../vaihda-kieli.dialog'
import { MatDialog } from '@angular/material/dialog'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

@Component({
  templateUrl: './kirjanpidon-ulkopuoliset-kulut.component.html',
  styleUrls: ['../login/login.component.css', './kirjanpidon-ulkopuoliset-kulut.component.css']
})
export class KirjanpidonUlkopuolisetKulutComponent implements OnInit, AfterViewInit, OnDestroy {

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  ulkopuolisetKulutForm: UntypedFormGroup
  year = new Date().getFullYear()
  message: string
  commonError: string
  showForm: boolean = false
  isUsedLinkError: boolean = false
  asiakkaanNimi: string
  verovuosi: number
  onIlmoitettavaa: boolean = true
  maakohtaisetPaivarahatLinkki: string
  formTextType: string

  lomakkeenLahettamisenLoppupaiva: string
  kokopaivaraha: string
  osapaivaraha: string
  kilometrikorvaus: string

  paasaantoinenKotiVahennys: string = '960'
  osaaikainenKotiVahennys: string = '480'
  satunnainenKotiVahennys: string = '240'

  private _ajoneuvot: Ajoneuvo[] = []

  namename = 'a7erts1ruo9peq' + Math.random()

  // onkoTyoajoissaYliPuoliTextObservable: Observable<string>
  private _token: string
  private _submitStarted: boolean

  constructor(
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _route: ActivatedRoute,
    private _validationService: FormValidationService,
    private _kayttaService: KayttajaService,
    private _dialog: MatDialog,
    private _translationService: LemonTranslationService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    this._kayttaService.logout()
  }

  ngOnInit() {
    this.maakohtaisetPaivarahatLinkki = this._getMaakohtaisetPaivarahatLinkki('fi', 2024)
  }

  get ajoneuvotFormArray(): UntypedFormArray {
    return this.ulkopuolisetKulutForm.get('ajoneuvot') as UntypedFormArray
  }
  get ajoneuvotFormGroupit(): UntypedFormGroup[] {
    return this.ajoneuvotFormArray.controls as UntypedFormGroup[]
  }

  poistaAjoneuvo(index: number) {
    this.ajoneuvotFormArray.removeAt(index)
    this._ajoneuvot.splice(index, 1)
  }

  lisaaAjoneuvo(prefill?: Ajoneuvo) {

    const ajoneuvo: Ajoneuvo = {
      rekisteritunnus: prefill ? prefill.rekisteritunnus : '',
      tyyppi: prefill ? prefill.tyyppi : null,
      kokonaisKmMaara: prefill ? prefill.kokonaisKmMaara : null,
      tyoAjoissaKm: prefill ? prefill.tyoAjoissaKm : null
    }

    const ajoneuvoFormControl = new UntypedFormGroup({
      'rekisteritunnus': new UntypedFormControl(ajoneuvo.rekisteritunnus, Validators.required),
      'tyyppi': new UntypedFormControl(ajoneuvo.tyyppi, Validators.required),
      'kokonaisKmMaara': new UntypedFormControl(ajoneuvo.kokonaisKmMaara, Validators.required),
      'tyoAjoissaKm': new UntypedFormControl(ajoneuvo.tyoAjoissaKm, Validators.required)
    })

    const tyoajoisssaKm = ajoneuvoFormControl.get('tyoAjoissaKm')

    ajoneuvoFormControl.get('rekisteritunnus').valueChanges.subscribe(value => { ajoneuvo.rekisteritunnus = value || null })
    ajoneuvoFormControl.get('tyyppi').valueChanges.subscribe(value => { ajoneuvo.tyyppi = value || null })
    ajoneuvoFormControl.get('kokonaisKmMaara').valueChanges.subscribe(value => {
      ajoneuvo.kokonaisKmMaara = value || null
      if (ajoneuvo.kokonaisKmMaara < ajoneuvo.tyoAjoissaKm) {
        tyoajoisssaKm.setErrors({ toolarge: true })
      } else {
        if (tyoajoisssaKm?.errors?.toolarge) {
          delete tyoajoisssaKm.errors.toolarge
        }
        tyoajoisssaKm.setErrors(Object.keys(tyoajoisssaKm.errors || {}).length ? tyoajoisssaKm.errors : null)
      }
    })
    tyoajoisssaKm.valueChanges.subscribe(value => {
      ajoneuvo.tyoAjoissaKm = value || null
      if (ajoneuvo.kokonaisKmMaara < ajoneuvo.tyoAjoissaKm) {
        tyoajoisssaKm.setErrors({ toolarge: true })
      } else {
        if (tyoajoisssaKm?.errors?.toolarge) {
          delete tyoajoisssaKm.errors.toolarge
        }
        tyoajoisssaKm.setErrors(Object.keys(tyoajoisssaKm.errors || {}).length ? tyoajoisssaKm.errors : null)
      }
    })

    this.ajoneuvotFormArray.push(ajoneuvoFormControl)
    this._ajoneuvot.push(ajoneuvo)
  }

  ngAfterViewInit() {
    this.ulkopuolisetKulutForm = new UntypedFormGroup({
      'eiIlmoitettavaa': new UntypedFormControl(false),
      'ajoneuvot': new UntypedFormArray([]),
      'tiedotPerustuvat': new UntypedFormControl(null, Validators.required),
      'yli10hKotimaanMatkojenLukumaara': new UntypedFormControl(null),
      'yli6hKotimaanMatkojenLukumaara': new UntypedFormControl(null),
      'ulkomaanMatkapaivienLukumaara': new UntypedFormControl(null),
      'ulkomaanPaivarahojenYhteismaara': new UntypedFormControl(null),
      'kotiYrityksenTyotilana': new UntypedFormControl('ei-vahennysta'),
      'vakuutan': new UntypedFormControl(false, Validators.requiredTrue),
      'sitoudun': new UntypedFormControl(false, Validators.requiredTrue)
    })

    this.ulkopuolisetKulutForm.get('ajoneuvot').valueChanges.pipe(
      startWith([]),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      if (!value?.length) {
        this.tiedotPerustuvat.setValue(null)
        this.tiedotPerustuvat.disable()
      } else {
        this.tiedotPerustuvat.enable()
      }
    })

    const kayttajanTiedotObservable = this._route.paramMap.pipe(
      map(paramMap => paramMap.get('token')),
      switchMap(token => {
        if (token) {
          this._token = token
          this._ladataanService.aloitaLataaminen()
          const requestData: AnnaKayttajanTiedotReq = {
            token: token
          }
          return this._firebaseLemonaid.functionsCall<AnnaKayttajanTiedotReq, KirjanpidonUlkopuolisetKulutAnnaKayttajanTiedotResp>('ajopaivakirjaAnnaKayttajaTiedot', requestData)
        }
        return of<KirjanpidonUlkopuolisetKulutAnnaKayttajanTiedotResp>(null)
      })
    )

    combineLatest([kayttajanTiedotObservable, this._translationService.currentLanguageObservable])
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe({
        next: ([response, kieli]) => {
          if (response && !response.e) {
            if (response.lang) {
              this._translationService.vaihdaKieli(response.lang)
            }
            this.verovuosi = response.verovuosi
            this.showForm = true
            this.asiakkaanNimi = response.nimi
            this.formTextType = !response.onkoHolvi ? 'regular' : ('holvi.' + (response.subtype || 'yearly'))

            this.kokopaivaraha = '51' // 2024
            this.osapaivaraha = '24' // 2024
            this.kilometrikorvaus = '0,57' // 2024
            this.maakohtaisetPaivarahatLinkki = this._getMaakohtaisetPaivarahatLinkki(kieli, response.verovuosi)
            this.lomakkeenLahettamisenLoppupaiva = this._getLoppupaiva(response.onkoHolvi, response.verovuosi || 2024, response.subtype || 'yearly')

            this._prefillForm(response.prefillData)

          } else if (response && [MagicLinkErrorResponse.USED, MagicLinkErrorResponse.EXPIRED].includes(response.e)) {
            this.isUsedLinkError = response.e === MagicLinkErrorResponse.USED
            if (this.isUsedLinkError) {
              this.message = 'ulkopuoliset-kulut.submit-successful'
            } else if (response.e === MagicLinkErrorResponse.EXPIRED) {
              this.message = 'magic-link.expired-ajopaivakirja-link-' + (response.onkoHolvi ? 'holvi' : 'regular')
            }
            this._errorHandler.handleError(response.e)
          } else {
            this.showForm = true
            this.commonError = 'yleiset.tuntematon-virhe'
            this._errorHandler.handleError(new Error(response ? response.e : 'unknown-error'))
          }
          this._ladataanService.lopetaLataaminen()
        },
        error: (err) => {
          this.showForm = true
          this.commonError = 'yleiset.tuntematon-virhe'
          this._errorHandler.handleError(err)
        }
      })

    this.eiIlmoitettavaa.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(eiIlmoitettavaa => {
      this.onIlmoitettavaa = !eiIlmoitettavaa

      if (eiIlmoitettavaa) {

        this.tiedotPerustuvat.disable()
        this.sitoudun.disable()
      } else {
        this.sitoudun.enable()
        const onkoAjoneuvoja = !!this.ulkopuolisetKulutForm.get('ajoneuvot').value?.length

        if (onkoAjoneuvoja) {
          this.tiedotPerustuvat.enable()
        }
      }

    })
  }
  get eiIlmoitettavaa() {
    return this.ulkopuolisetKulutForm.get('eiIlmoitettavaa')
  }
  get tiedotPerustuvat() {
    return this.ulkopuolisetKulutForm.get('tiedotPerustuvat')
  }
  get yli10hKotimaanMatkojenLukumaara() {
    return this.ulkopuolisetKulutForm.get('yli10hKotimaanMatkojenLukumaara')
  }
  get yli6hKotimaanMatkojenLukumaara() {
    return this.ulkopuolisetKulutForm.get('yli6hKotimaanMatkojenLukumaara')
  }
  get ulkomaanPaivarahojenYhteismaara() {
    return this.ulkopuolisetKulutForm.get('ulkomaanPaivarahojenYhteismaara')
  }
  get ulkomaanMatkapaivienLukumaara() {
    return this.ulkopuolisetKulutForm.get('ulkomaanMatkapaivienLukumaara')
  }
  get kotiYrityksenTyotilana() {
    return this.ulkopuolisetKulutForm.get('kotiYrityksenTyotilana')
  }
  get vakuutan() {
    return this.ulkopuolisetKulutForm.get('vakuutan')
  }
  get sitoudun() {
    return this.ulkopuolisetKulutForm.get('sitoudun')
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  vaihdaKieli() {
    const data: VaihdaKieliDialogData = {
      tallenna: false
    }
    this._dialog.open(VaihdaKieliDialog, { data: data })
  }

  async submit() {
    if (this._submitStarted) {
      return
    }
    if (!this.ulkopuolisetKulutForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.ulkopuolisetKulutForm)
      return
    }

    this._submitStarted = true
    this._ladataanService.aloitaLataaminen()

    try {

      const saveRequestData: TallennaKirjanpidonUlkopuolisetKulutReq = {
        token: this._token,
        vuosi: this.verovuosi,
        ajoneuvot: this.onIlmoitettavaa ? this._ajoneuvot : [],
        tiedotPerustuvat: this.onIlmoitettavaa ? this.tiedotPerustuvat.value : null,
        yli10hKotimaanMatkojenLukumaara: this.onIlmoitettavaa ? this.yli10hKotimaanMatkojenLukumaara.value : null,
        yli6hKotimaanMatkojenLukumaara: this.onIlmoitettavaa ? this.yli6hKotimaanMatkojenLukumaara.value : null,
        ulkomaanMatkapaivienLukumaara: this.onIlmoitettavaa ? this.ulkomaanMatkapaivienLukumaara.value : null,
        ulkomaanPaivarahojenYhteismaara: this.onIlmoitettavaa ? this.ulkomaanPaivarahojenYhteismaara.value : null,
        kotiYrityksenTyotilana: this.onIlmoitettavaa ? this.kotiYrityksenTyotilana.value : 'ei-vahennysta',
        eiIlmoitettavaa: this.eiIlmoitettavaa.value || null
      }

      const response = await this._firebaseLemonaid.functionsCall<TallennaKirjanpidonUlkopuolisetKulutReq, TallennaKirjanpidonUlkopuolisetKulutResp>('ajopaivakirjaTallennus', saveRequestData)
      if (response && !response.e) {
        this.showForm = false
        this.isUsedLinkError = true
        this.message = 'ulkopuoliset-kulut.submit-successful'

      } else if (response && [MagicLinkErrorResponse.USED, MagicLinkErrorResponse.EXPIRED].includes(response.e)) {
        this.showForm = false
        this.isUsedLinkError = response.e === MagicLinkErrorResponse.USED
        if (this.isUsedLinkError) {
          this.message = 'ulkopuoliset-kulut.submit-successful'
        } else {
          this.message = 'magic-link.' + response.e
        }
        this._errorHandler.handleError(response.e)
      } else {
        this.commonError = 'yleiset.tuntematon-virhe'
        this._errorHandler.handleError(response ? response.e : 'no-response')
      }

    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._submitStarted = false
    }

  }

  private _prefillForm(ulkopuolisetKulut: KirjanpidonUlkopuolisetKulut) {
    if (!ulkopuolisetKulut) {
      return
    }
    this._ajoneuvot = []
    this.ajoneuvotFormArray.clear()

    // NB! Don't change order for these - timing issue with subscriptions
    if (ulkopuolisetKulut.ajoneuvot?.length) {
      for (const ajoneuvo of ulkopuolisetKulut.ajoneuvot) {
        this.lisaaAjoneuvo(ajoneuvo)
      }
    }

    this.tiedotPerustuvat.setValue(ulkopuolisetKulut.tiedotPerustuvat)
    this.yli10hKotimaanMatkojenLukumaara.setValue(ulkopuolisetKulut.yli10hKotimaanMatkojenLukumaara)
    this.yli6hKotimaanMatkojenLukumaara.setValue(ulkopuolisetKulut.yli6hKotimaanMatkojenLukumaara)
    this.ulkomaanMatkapaivienLukumaara.setValue(ulkopuolisetKulut.ulkomaanMatkapaivienLukumaara)
    this.ulkomaanPaivarahojenYhteismaara.setValue(ulkopuolisetKulut.ulkomaanPaivarahojenYhteismaara)
    this.kotiYrityksenTyotilana.setValue(ulkopuolisetKulut.kotiYrityksenTyotilana)
  }

  // private onkoTyoajoissaYliPuoli(kokonaisKmMaara: number, tyoajoissaKm: number): boolean | null {
  //   if (this._validationService.processValue(kokonaisKmMaara) !== null &&
  //     this._validationService.processValue(tyoajoissaKm) !== null) {

  //     return tyoajoissaKm / kokonaisKmMaara * 100 > 50
  //   }
  //   return null
  // }
  // private getTyoajoissaYliPuoliText(onkoYliPuoli: boolean | null) {
  //   if (this._validationService.processValue(onkoYliPuoli) === null) {
  //     return ''
  //   }
  //   if (onkoYliPuoli) {
  //     return 'Työajoa yli 50%.'
  //   }
  //   return 'Työajoa alle 50%.'
  // }
  private _getLoppupaiva(onkoHolvi: boolean, verovuosi: number, subtype: 'yearly' | 'half-year') {
    if (!onkoHolvi) {
      return '15.01.' + (verovuosi + 1)
    }

    if (subtype === 'half-year') {
      return '05.08.' + verovuosi
    }

    return '15.01.' + (verovuosi + 1)
  }

  private _getMaakohtaisetPaivarahatLinkki(kieli: TuettuKieli, verovuosi: number): string {
    if (kieli === 'en') {
      return 'https://www.vero.fi/en/detailed-guidance/decisions/47405/tax-exempt-allowances-in-' + (verovuosi || '2024') + '-for-business-travel/'
    }
    return 'https://www.vero.fi/syventavat-vero-ohjeet/paatokset/47405/verohallinnon-paatos-verovapaista-matkakustannusten-korvauksista-vuonna-' + verovuosi || '2024'
  }
}
