import { ErrorHandler, Injectable } from '@angular/core'

import { TositeUriService } from 'app/_jaettu/service/tosite/tosite-uri.service'

import { FirestoreTositteenAlkuperainenTiedosto, FirestoreTositteenKuva, FirestoreTosite, InspectFileResponse } from 'app/_jaettu/model/tosite'
import { KayttajanTiedot } from 'app/_jaettu/model/kayttaja'

import { LruMap } from 'app/_jaettu/data-structures/lru-map'
import { PdfOpenData, PdfService } from 'app/_angular/service/pdf.service'
import { LemonBarcodeDetector, QrTaiViivakoodi } from 'app/_angular/service/barcode/barcode-detector.service'
import { LEMONAID_CF_API, LemonHttpService } from 'app/_angular/service/lemon-http.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { InspectFileRequest } from 'app/_jaettu/model/tosite'
import { FileType } from 'app/_shared-core/model/common'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'

export interface LisattyCacheKuva {
  annaKuvanPromise(): Promise<Blob>
}

interface LazyPdfCache {
  fileAsUint8Array: Uint8Array
  kuvakansio: string
}

@Injectable()
export class TositeKuvaCacheService {

  addedImageCache: LruMap<string, LisattyCacheKuva> = new LruMap(100)
  lazyPdfCache: LruMap<string, LazyPdfCache> = new LruMap(20)
  networkImageCache: LruMap<string, Blob> = new LruMap(200)

  constructor(
    private _tositeUriService: TositeUriService,
    private _currencySercice: CurrencyService,
    private _lemonBarcodeDetector: LemonBarcodeDetector,
    private _httpService: LemonHttpService,
    private _firebase: FirebaseLemonaid,
    private _errorHandler: ErrorHandler,
    private _translationService: LemonTranslationService,
    private _pdfService: PdfService
  ) { }

  public lisaaPdfCacheen(kayttajanTiedot: KayttajanTiedot, tosite: FirestoreTosite, alkuperainen: FirestoreTositteenAlkuperainenTiedosto, fileAsUint8Array: Uint8Array) {
    const pdfCache: LazyPdfCache = {
      fileAsUint8Array: fileAsUint8Array,
      kuvakansio: tosite.kuvakansio
    }
    this.lazyPdfCache.set(alkuperainen.avain, pdfCache)
  }

  public annaKuvaPdfCachesta(uri: string): LisattyCacheKuva {

    if (this.lazyPdfCache.size < 1) {
      return null
    }

    const dotPosition = uri.lastIndexOf('.')
    if (dotPosition < 0) {
      return null
    }

    let iteration = 0
    let num = ''
    let lastIsUnderscore = false
    while (true) {
      iteration++
      const numSuspect = uri.charAt(dotPosition - iteration)
      if (this._currencySercice.onkoSallittuMerkkiKokonaisluvussa(numSuspect)) {
        num = numSuspect + num
      } else {
        if (numSuspect === '_') { lastIsUnderscore = true }
        break
      }
    }

    if (!lastIsUnderscore) {
      return null
    }

    const lastSlash = uri.lastIndexOf('/', dotPosition - iteration)
    if (lastSlash < 0) {
      return null
    }
    const avain = uri.substring(lastSlash + 1, dotPosition - iteration)

    const cachedFile = this.lazyPdfCache.get(avain)
    if (!cachedFile) {
      return null
    }

    // console.log(iteration, num, lastIsUnderscore, avain)

    const koko = uri.startsWith('/api/1/kuitit/kuvat/thumb/') ? 130 : 720
    return {
      annaKuvanPromise: (): Promise<Blob> => {
        return this._pdfService.renderPageToBlob(Number(num), cachedFile.fileAsUint8Array, koko)
      }
    }

  }

  public lisaaLisattyTiedostoCacheen(kayttajanTiedot: KayttajanTiedot, tosite: FirestoreTosite, kuva: FirestoreTositteenKuva, file: File | Blob) {
    const cacheKuva: LisattyCacheKuva = {
      annaKuvanPromise(): Promise<Blob> {
        return Promise.resolve(file)
      }
    }
    this.lisaaCacheKuvaCacheen(cacheKuva, kayttajanTiedot, tosite, kuva)
  }

  public lisaaCacheKuvaCacheen(cacheKuva: LisattyCacheKuva, kayttajanTiedot: KayttajanTiedot, tosite: FirestoreTosite, kuva: FirestoreTositteenKuva) {
    const kuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, tosite.kuvakansio, kuva.avain, kuva.type)
    this.addedImageCache.set('/api/1/kuitit/kuvat/kuva/' + kuvanUrl, cacheKuva)
    this.addedImageCache.set('/api/1/kuitit/kuvat/thumb/' + kuvanUrl, cacheKuva)
  }

  public poistaKuitinLisatytTiedostotCachesta(tosite: FirestoreTosite) {
    const keys = Array.from(this.addedImageCache.keys())
    for (const key of keys) {
      if (key.indexOf(tosite.kuvakansio) > -1) {
        this.addedImageCache.delete(key)
      }
    }
  }

  public siirraKuvatKuitille(kayttajanTiedot: KayttajanTiedot, kuiteista: FirestoreTosite[], kuittiin: FirestoreTosite): void {

    if (!kuittiin.kuvat) { kuittiin.kuvat = {} }
    if (!kuittiin.alkuperaiset) { kuittiin.alkuperaiset = {} }

    for (const kuitista of kuiteista) {

      // Siirrä kuvat
      if (kuitista.kuvat) {
        for (const kuva of Object.values(kuitista.kuvat)) {

          const oldKuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, kuitista.kuvakansio, kuva.avain, kuva.type)
          const oldFullUrl = '/api/1/kuitit/kuvat/kuva/' + oldKuvanUrl
          const oldThumbUrl = '/api/1/kuitit/kuvat/thumb/' + oldKuvanUrl
          const oldFull = this.addedImageCache.get(oldFullUrl)
          const oldThumb = this.addedImageCache.get(oldThumbUrl)

          const newKuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, kuittiin.kuvakansio, kuva.avain, kuva.type)
          if (oldFull) {
            // console.log(oldKuvanUrl, ' --> ', newKuvanUrl)
            this.addedImageCache.set('/api/1/kuitit/kuvat/kuva/' + newKuvanUrl, oldFull)
            this.addedImageCache.delete(oldFullUrl)
          }
          if (oldThumb) {
            // console.log(oldKuvanUrl, ' --> ', newKuvanUrl)
            this.addedImageCache.set('/api/1/kuitit/kuvat/thumb/' + newKuvanUrl, oldThumb)
            this.addedImageCache.delete(oldThumbUrl)
          }

          kuittiin.kuvat[kuva.avain] = kuva
        }
      }

      // Siirrä alkuperäiset
      if (kuitista.alkuperaiset) {
        for (const alkuperainen of Object.values(kuitista.alkuperaiset)) {
          kuittiin.alkuperaiset[alkuperainen.avain] = alkuperainen
        }
      }

      this.poistaKuitinLisatytTiedostotCachesta(kuitista)

    }

  }

  public annaKuvienUrlit(tosite: FirestoreTosite, kayttajanTiedot: KayttajanTiedot, type: 'thumb' | 'full'): string[] {
    return Object
      .values(tosite.kuvat ?? {})
      .filter(k => !k.poistettu)
      .sort((a, b): number => {
        if (a.alkuperaisenAvain === b.alkuperaisenAvain) {
          return a.jarjestys < b.jarjestys ? -1 : a.jarjestys > b.jarjestys ? 1 : 0
        }
        if (a.alkuperaisenAvain && b.alkuperaisenAvain) {
          return a.alkuperaisenAvain.localeCompare(b.alkuperaisenAvain)
        }
        if (a.alkuperaisenAvain) {
          return 1
        }
        if (b.alkuperaisenAvain) {
          return -1
        }
        return 0
      })
      .map(kuva => {
        return this.annaKuvanUrl(kayttajanTiedot, tosite, kuva, type)
      })
  }

  public annaKuvanUrl(kayttajanTiedot: KayttajanTiedot, tosite: FirestoreTosite, kuva: FirestoreTositteenKuva, type: 'thumb' | 'full'): string {
    const kuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, tosite.kuvakansio, kuva.avain, kuva.type)
    if (type === 'full') {
      return '/api/1/kuitit/kuvat/kuva/' + kuvanUrl
    } else if (type === 'thumb') {
      return '/api/1/kuitit/kuvat/thumb/' + kuvanUrl
    }
    throw new Error('Tuntematon tyyppi: ' + type)
  }

  public async puraTiedostoBakkarissa(
    uploadUri: string,
    tosite: FirestoreTosite,
    tiedostonNimi: string,
    alkuperainen: FirestoreTositteenAlkuperainenTiedosto,
    kayttajanTiedot: KayttajanTiedot,
    koodiPromise: Promise<QrTaiViivakoodi>,
    parsiKoodiEnsimmaisestaXSivusta: number
  ): Promise<{ koodi: Promise<QrTaiViivakoodi>, kuvat: FirestoreTositteenKuva[], fileType: 'img' | 'pdf', virhe?: string }> {

    const request: InspectFileRequest = {
      bucketLocationUri: uploadUri,
      name: tiedostonNimi
    }
    const response = await this._firebase.functionsCall<InspectFileRequest, InspectFileResponse>('imagesInspectFile', request)
    if (response.fileType !== FileType.UNKNOWN) {

      const that = this

      const kasitellytKuvat: FirestoreTositteenKuva[] = []

      for (let kuvanIndeksi = 1; kuvanIndeksi <= response.pages; kuvanIndeksi++) {

        // Create firestore tosite image metadata
        const kuva: FirestoreTositteenKuva = {
          avain: alkuperainen.avain + '_' + kuvanIndeksi,
          jarjestys: kuvanIndeksi,
          poistettu: false,
          type: 'jpg',
          alkuperaisenAvain: alkuperainen.avain,
        }
        kasitellytKuvat.push(kuva)

        // As the server does not contain an image for this yet, prepare a cached version.
        // Note that this is lazily executed, so if no one requests the image, it is NOT
        // rendered at all.
        let promise: Promise<Blob> = null
        const cacheKuva: LisattyCacheKuva = {
          annaKuvanPromise(): Promise<Blob> {
            if (!promise) {
              promise = that._httpService.getBinary('/imagesRenderPage', LEMONAID_CF_API, {
                'x-uri': uploadUri,
                'x-page': kuvanIndeksi + ''
              })
            }
            return promise
          }
        }

        const kuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, tosite.kuvakansio, kuva.avain, kuva.type)
        this.addedImageCache.set('/api/1/kuitit/kuvat/kuva/' + kuvanUrl, cacheKuva)
        if (kasitellytKuvat.length === 1) {
          this.addedImageCache.set('/api/1/kuitit/kuvat/thumb/' + kuvanUrl, cacheKuva)
        }
        if (parsiKoodiEnsimmaisestaXSivusta > 0 && parsiKoodiEnsimmaisestaXSivusta >= kuvanIndeksi) {
          // console.log('Kuvapromise sivulle ' + kuvanIndeksi)
          koodiPromise = this._annaKoodiPromise(koodiPromise, cacheKuva)
        }

      }

      return { koodi: koodiPromise ?? Promise.resolve({}), fileType: response.fileType === FileType.PDF ? 'pdf' : 'img', kuvat: kasitellytKuvat }

    }

    this._errorHandler.handleError(new Error('Tiedostoa "' + uploadUri + '" ei pystytä parsimaan.'))
    return { koodi: koodiPromise ?? Promise.resolve({}), fileType: 'img', kuvat: [], virhe: this._translationService.lokalisoi('kuitit.muokkaa.validation.pdf-parse-error', { tiedostonNimi: tiedostonNimi }) }

  }

  public hoidaHeifConvert(
    file: File,
    tosite: FirestoreTosite,
    alkuperainen: FirestoreTositteenAlkuperainenTiedosto,
    kayttajanTiedot: KayttajanTiedot,
    etsiViivakooditiedot: boolean,
    koodiPromise: Promise<QrTaiViivakoodi>
  ): { koodi: Promise<QrTaiViivakoodi>, kuva: FirestoreTositteenKuva } {

    const that = this

    // As the server does not contain an image for this yet, prepare a cached version.
    // Note that this is lazily executed, so if no one requests the image, it is NOT
    // rendered at all.
    let promise: Promise<Blob> = null
    const cacheKuva: LisattyCacheKuva = {
      annaKuvanPromise(): Promise<Blob> {
        if (!promise) {
          const formData = new FormData()
          formData.append('img', file, file.name)
          promise = that._httpService.postBinaryGetBinary('/imagesConvertHeif', formData, LEMONAID_CF_API)
        }
        return promise
      }
    }

    const kuva: FirestoreTositteenKuva = {
      avain: alkuperainen.avain + '_1',
      jarjestys: 1,
      poistettu: false,
      type: 'jpg',
      alkuperaisenAvain: alkuperainen.avain
    }

    if (etsiViivakooditiedot) {
      koodiPromise = this._annaKoodiPromise(koodiPromise, cacheKuva)
    }

    this.lisaaCacheKuvaCacheen(cacheKuva, kayttajanTiedot, tosite, kuva)

    return {
      koodi: koodiPromise ?? Promise.resolve({}),
      kuva: kuva
    }

  }

  public hoidaPdfnRajayttaminenKuviksi(
    tosite: FirestoreTosite,
    alkuperainen: FirestoreTositteenAlkuperainenTiedosto,
    kayttajanTiedot: KayttajanTiedot,
    openData: PdfOpenData<Blob>,
    parsiKoodiEnsimmaisestaXSivusta: number,
    koodiPromise: Promise<QrTaiViivakoodi>
  ): { koodi: Promise<QrTaiViivakoodi>, kuvat: FirestoreTositteenKuva[] } {

    const that = this

    const kasitellytKuvat: FirestoreTositteenKuva[] = []
    for (let kuvanIndeksi = 1; kuvanIndeksi <= openData.pageCount; kuvanIndeksi++) {

      // Create firestore tosite image metadata
      const kuva: FirestoreTositteenKuva = {
        avain: alkuperainen.avain + '_' + kuvanIndeksi,
        jarjestys: kuvanIndeksi,
        poistettu: false,
        type: 'jpg',
        alkuperaisenAvain: alkuperainen.avain,
      }
      kasitellytKuvat.push(kuva)

      // As the server does not contain an image for this yet, prepare a cached version.
      // Note that this is lazily executed, so if no one requests the image, it is NOT
      // rendered at all.
      const prerendered = openData.renderedPages.length >= kuvanIndeksi ? openData.renderedPages[kuvanIndeksi - 1] : null
      if (prerendered) {
        const cacheKuva: LisattyCacheKuva = {
          annaKuvanPromise(): Promise<Blob> {
            return Promise.resolve(prerendered)
          }
        }
        const kuvanUrl = this._tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, tosite.kuvakansio, kuva.avain, kuva.type)
        this.addedImageCache.set('/api/1/kuitit/kuvat/kuva/' + kuvanUrl, cacheKuva)
        if (kasitellytKuvat.length === 1) {
          this.addedImageCache.set('/api/1/kuitit/kuvat/thumb/' + kuvanUrl, cacheKuva)
        }
        if (parsiKoodiEnsimmaisestaXSivusta > 0 && parsiKoodiEnsimmaisestaXSivusta >= kuvanIndeksi) {
          // console.log('Kuvapromise sivulle ' + kuvanIndeksi)
          koodiPromise = this._annaKoodiPromise(koodiPromise, cacheKuva)
        }
      }

    }

    this.lisaaPdfCacheen(kayttajanTiedot, tosite, alkuperainen, openData.fileAsUint8Array)

    return { koodi: koodiPromise ?? Promise.resolve({}), kuvat: kasitellytKuvat }

  }

  private async _annaKoodiPromise(koodiPromise: Promise<QrTaiViivakoodi>, cacheKuva: LisattyCacheKuva): Promise<QrTaiViivakoodi> {
    return (koodiPromise ?? Promise.resolve({}) as Promise<QrTaiViivakoodi>).then(result => {
      // console.log('JAAHA', result)
      if (result.qr || result.viiva) {
        return result
      }
      // console.log('PALAUTA PROMISE')
      return cacheKuva.annaKuvanPromise().then(kuva => {
        // console.log('got image: ' + kuva.length)
        return this._lemonBarcodeDetector.detect(kuva).then(koodi => {
          // console.log('got koodi', koodi)
          if (koodi?.qr) {
            result.qr = koodi.qr
          } else if (koodi?.viiva) {
            result.viiva = koodi.viiva
          }
          return result
        })
      })
    })
  }

}
