import { Pipe, PipeTransform } from '@angular/core'

import { TuettuKieli } from '../../_shared-core/model/common'
import { DateService } from '../../_shared-core/service/date.service'
import { LocalDate, NumberMonth, Timestamp } from '../../_shared-core/model/common'

import { LemonTranslationService } from '../service/lemon-translation.service'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Pipe({
  name: 'ldate'
})
export class LemonDatePipe implements PipeTransform {

  constructor(private dateService: DateService, private lemonTranslationService: LemonTranslationService) { }

  transform(value: Date): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (!value || !kieli) {
          return ''
        }
        return this.dateService.muotoilePaivaDate(value, kieli)
      })
    )
  }

}

@Pipe({
  name: 'ldatetime'
})
export class LemonDateAndTimePipe implements PipeTransform {

  constructor(private dateService: DateService, private lemonTranslationService: LemonTranslationService) { }

  transform(value: Date): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (!value || !kieli) {
          return ''
        }
        return this.dateService.muotoilePaivaJaAikaDate(value, kieli)
      })
    )
  }

}

@Pipe({
  name: 'ldatel'
})
export class LemonDateWithLocalePipe implements PipeTransform {

  constructor(private dateService: DateService) { }

  transform(value: Date, kieli: TuettuKieli): string {
    if (!value || !kieli) {
      return ''
    }
    return this.dateService.muotoilePaivaDate(value, kieli)
  }

}

@Pipe({
  name: 'lnumberpvm'
})
export class LemonNumberDatePipe implements PipeTransform {

  constructor(private dateService: DateService, private lemonTranslationService: LemonTranslationService) { }

  transform(value: number): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (!value || !kieli) {
          return ''
        }
        return this.dateService.muotoileNumberPaiva(value, kieli)
      })
    )
  }

}

@Pipe({
  name: 'lnumberpvmjaaika'
})
export class LemonNumberDateAndTimePipe implements PipeTransform {

  constructor(private dateService: DateService, private lemonTranslationService: LemonTranslationService) { }

  transform(value: number): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (!value || !kieli) {
          return ''
        }
        return this.dateService.muotoilePaivaJaAikaDate(this.dateService.numberTimestampToDate(value), kieli)
      })
    )
  }

}

@Pipe({
  name: 'lnumberpvml'
})
export class LemonNumberDateWithLocalePipe implements PipeTransform {

  constructor(private dateService: DateService) { }

  transform(value: number, kieli: TuettuKieli): string {
    if (!value || !kieli) {
      return ''
    }
    return this.dateService.muotoileNumberPaiva(value, kieli)
  }

}

@Pipe({
  name: 'lnumberpvmlnarrow'
})
export class LemonNumberDateWithLocaleNarrowPipe implements PipeTransform {

  constructor(private dateService: DateService) { }

  transform(value: number, kieli: TuettuKieli): string {
    if (!value || !kieli) {
      return ''
    }
    return this.dateService.muotoileNumberPaivaKapea(value, kieli)
  }

}

@Pipe({
  name: 'lokaalipvm'
})
export class LemonLocalDatePipe implements PipeTransform {

  constructor(private dateService: DateService, private lemonTranslationService: LemonTranslationService) { }

  transform(value: LocalDate): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (!value || !kieli) {
          return ''
        }
        return this.dateService.muotoilePaikallinenPaiva(value, kieli)
      })
    )
  }

}

@Pipe({
  name: 'lokaalipvml'
})
export class LemonLocalDateWithLocalePipe implements PipeTransform {

  constructor(private dateService: DateService) { }

  transform(value: LocalDate, kieli: TuettuKieli): string {
    if (!value || !kieli) {
      return ''
    }
    return this.dateService.muotoilePaikallinenPaiva(value, kieli)
  }

}

@Pipe({
  name: 'ltimestamp'
})
export class LemonTimestampPipe implements PipeTransform {

  constructor(private dateService: DateService, private lemonTranslationService: LemonTranslationService) { }

  transform(value: Timestamp): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (!value || !kieli) {
          return ''
        }
        return this.dateService.muotoilePaiva(value, kieli)
      })
    )
  }

}

@Pipe({
  name: 'ltimestampl'
})
export class LemonTimestampWithLocalePipe implements PipeTransform {

  constructor(private dateService: DateService) { }

  transform(value: Timestamp, kieli: TuettuKieli): string {
    if (!value || !kieli) {
      return ''
    }
    return this.dateService.muotoilePaiva(value, kieli)
  }

}

@Pipe({
  name: 'ltimestamptime'
})
export class LemonTimestampDateAndTimePipe implements PipeTransform {

  constructor(private dateService: DateService, private lemonTranslationService: LemonTranslationService) { }

  transform(value: Timestamp): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (!value || !kieli) {
          return ''
        }
        const asDate = this.dateService.timestampToDate(value)
        return this.dateService.muotoilePaivaJaAikaDate(asDate, kieli)
      })
    )
  }

}

@Pipe({
  name: 'ltimestamptimel'
})
export class LemonTimestampDateAndTimeWithLocalePipe implements PipeTransform {

  constructor(private dateService: DateService) { }

  transform(value: Timestamp, kieli: TuettuKieli): string {
    if (!value || !kieli) {
      return ''
    }
    const asDate = this.dateService.timestampToDate(value)
    return this.dateService.muotoilePaivaJaAikaDate(asDate, kieli)
  }

}
@Pipe({
  name: 'lokaalimonthyear'
})
export class LemonLocalToMonthAndYearPipe implements PipeTransform {

  constructor(private dateService: DateService, private lemonTranslationService: LemonTranslationService) { }

  transform(value: LocalDate): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (!value || !kieli) {
          return ''
        }
        return this.dateService.muotoileKuukausiJaVuosiPaikallinen(value, kieli)
      })
    )
  }

}

@Pipe({
  name: 'numbermonthtomonthyear'
})
export class LemonNumberMonthToMonthAndYearPipe implements PipeTransform {

  constructor(private dateService: DateService, private lemonTranslationService: LemonTranslationService) { }

  transform(value: NumberMonth): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (!value || !kieli) {
          return ''
        }
        const localMonth = this.dateService.numberToLocalMonth(value)
        return this.dateService.muotoileKuukausiJaVuosiPaikallinen({ year: localMonth.year, month: localMonth.month }, kieli)
      })
    )
  }

}
