import { ErrorHandler, Injectable, OnDestroy } from '@angular/core'

import { VirtuaaliviivakoodinOsat, ViitenumeroService } from '../../_shared-core/service/viitenumero.service'
import { PDFiumLibrary, PDFiumDocument, PDFiumPage } from '@lemontree/pdfium-wasm'
import { StringService } from 'app/_shared-core/service/string.service'
import { getPdfRenderScale } from 'app/_jaettu/utils/pdf'


export interface PdfRenderSession<T = Blob | string> {
  fileAsUint8Array: Uint8Array
  library: PDFiumLibrary
  document: PDFiumDocument
  renderedPages: T[]
}

export interface PdfOpenData<T = Blob | string> {
  pageCount: number
  success: boolean
  fileAsUint8Array: Uint8Array
  renderedPages: T[]
}

@Injectable()
export class PdfService implements OnDestroy {

  private _library: Promise<PDFiumLibrary>

  constructor(
    private _errorHandler: ErrorHandler,
    private _viitenumeroService: ViitenumeroService,
    private _stringService: StringService
  ) {
    this._library = PDFiumLibrary.init()
  }

  ngOnDestroy(): void {
    // if (this._library) {
    //   this._library.then(lib => {
    //     lib.destroy()
    //     this._library = null
    //   })
    // }
  }

  // private async _destroyLibrary() {
  //   if (this._library) {
  //     const lib = await this._library
  //     if (lib) {
  //       lib.destroy()
  //     }
  //     this._library = null
  //   }
  // }

  // public async renderoiSivu(): Promise<> {

  // }

  private _canvasToDataObjectUrl(canvas: HTMLCanvasElement): Promise<string> {
    return new Promise((resolve, reject) => {
      try {

        if (canvas.toBlob) {
          canvas.toBlob(blob => { resolve(URL.createObjectURL(blob)) }, 'image/png', 100)
        } else if (canvas.toDataURL) {
          const asDataUrl = canvas.toDataURL('image/png', 100)
          resolve(asDataUrl)
        } else {
          reject(new Error('The browser does NOT support HTMLCanvasElement.toBlob() or HTMLCanvasElement.toDataUrl()!!'))
        }

        // This MS specific version does not support quality, so don't use it.
        // Ie and old edge will use toDataUrl method.
        // else if (canvas['msToBlob']) { // IE
        //   resolve((canvas as any).msToBlob())
        // }

      } catch (err) {
        reject(err)
      }
    })
  }

  private async _renderPdfPage(
    pdfDocument: PDFiumDocument,
    kuvanIndeksi: number,
    kuvienTargetWidth: number,
    outputType: 'blob' | 'object-url'
  ): Promise<Blob | string> {
    let pdfiumPage: PDFiumPage = null
    try {

      // console.log('Pyydetty kuva ' + kuvanIndeksi)
      // const start = Date.now()
      const pdfiumPage = pdfDocument.getPage(kuvanIndeksi)
      const info = pdfiumPage.getSize()
      const scale = getPdfRenderScale(kuvienTargetWidth, 3, info.width)
      const render = pdfiumPage.render(scale)

      const imageData = new ImageData(new Uint8ClampedArray(render.bitmap), render.width, render.height)

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = render.width
      canvas.height = render.height
      ctx.putImageData(imageData, 0, 0)
      const blob = outputType === 'blob' ? await this._canvasToBlob(canvas) : await this._canvasToDataObjectUrl(canvas)
      // console.log('Took ' + (Date.now() - start) + ' ms to render one page.')
      return blob

    } catch (err) {
      console.error(err)
    } finally {
      try { if (pdfiumPage) { pdfiumPage.destroy() } } catch (err) { }
    }
  }

  private _canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise((resolve, reject) => {
      try {

        if (canvas.toBlob) {
          canvas.toBlob(blob => { resolve(blob) }, 'image/png', 100)
        } else if (canvas.toDataURL) {
          const asDataUrl = canvas.toDataURL('image/png', 100)
          resolve(this._dataURLtoBlob(asDataUrl))
        } else {
          reject(new Error('The browser does NOT support HTMLCanvasElement.toBlob() or HTMLCanvasElement.toDataUrl()!!'))
        }

      } catch (err) {
        reject(err)
      }
    })
  }

  private _dataURLtoBlob(dataUrl: string): Blob {
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  private async _doWithPdfPage<T>(fileAsUint8Array: Uint8Array, doer: (pdfiumLibrary: PDFiumLibrary, pdfiumDocument: PDFiumDocument, error: any) => Promise<T>) {
    // const start = Date.now()
    let library: PDFiumLibrary = null
    let document: PDFiumDocument = null
    try {

      // const startInit = Date.now()
      // Grab the initialized one
      library = await this._library
      // console.log('Took ' + (Date.now() - startInit) + ' ms to init wasm library.')

      // const startDocumentLoad = Date.now()
      document = await library.loadDocument(fileAsUint8Array)
      // console.log('Took ' + (Date.now() - startDocumentLoad) + ' ms to to load document.')

      const result = await doer(library, document, null)
      return result

    } catch (error) {
      try { if (document) { document.destroy() } } catch (err) { console.error(err) }
      try { if (library) { library.destroy() } } catch (err) { console.error(err) }
      // DO NOT BLOCK (INIT THIS FOR THE NEXT USE)
      this._library = PDFiumLibrary.init()
      const result = await doer(null, null, error)
      return result
    } finally {
      try { if (document) { document.destroy() } } catch (err) { console.error(err) }
      // try { if (library) { library.destroy() } } catch (err) { console.error(err) }
      // console.log('Took ' + (Date.now() - start) + ' ms total with destroy.')
    }
  }

  public async isPdfOpenableRenderAllToObjectUrl(fileAsUint8Array: Uint8Array, kuvienTargetWidth: number): Promise<PdfOpenData<string>> {
    return this._doWithPdfPage<PdfOpenData<string>>(fileAsUint8Array, async (lib, doc, err) => {
      if (err) {
        console.error(err)
        return { success: false, pageCount: 0, fileAsUint8Array: fileAsUint8Array, renderedPages: [] }
      }
      const pages: string[] = []
      for (let kuvanIndeksi = 1; kuvanIndeksi <= doc.getPageCount(); kuvanIndeksi++) {
        pages.push(await (this._renderPdfPage(doc, kuvanIndeksi, kuvienTargetWidth, 'object-url') as Promise<string>))
      }
      return { success: true, pageCount: doc.getPageCount(), fileAsUint8Array: fileAsUint8Array, renderedPages: pages }
    })
  }

  public async isPdfOpenableObjectUrl(fileAsUint8Array: Uint8Array, kuvienTargetWidth: number, lataaSivuja: number): Promise<PdfOpenData<string>> {
    return this._doWithPdfPage<PdfOpenData<string>>(fileAsUint8Array, async (lib, doc, err) => {
      if (err) {
        console.error(err)
        return { success: false, pageCount: 0, fileAsUint8Array: fileAsUint8Array, renderedPages: [] }
      }
      const pageCount = doc.getPageCount()
      const sivut: string[] = []
      for (let kuvanIndeksi = 1; kuvanIndeksi <= Math.min(lataaSivuja, pageCount) ?? 1; kuvanIndeksi++) {
        const page = await (this._renderPdfPage(doc, kuvanIndeksi, kuvienTargetWidth, 'object-url') as Promise<string>)
        sivut.push(page)
      }
      return { success: true, pageCount: pageCount, fileAsUint8Array: fileAsUint8Array, renderedPages: sivut }
    })
  }

  public async isPdfOpenable(fileAsUint8Array: Uint8Array, kuvienTargetWidth: number, lataaSivuja: number): Promise<PdfOpenData<Blob>> {
    return this._doWithPdfPage<PdfOpenData<Blob>>(fileAsUint8Array, async (lib, doc, err) => {
      if (err) {
        console.error(err)
        return { success: false, pageCount: 0, fileAsUint8Array: fileAsUint8Array, renderedPages: [] }
      }
      const pageCount = doc.getPageCount()
      const sivut: Blob[] = []
      for (let kuvanIndeksi = 1; kuvanIndeksi <= Math.min(lataaSivuja, pageCount) ?? 1; kuvanIndeksi++) {
        const page = await (this._renderPdfPage(doc, kuvanIndeksi, kuvienTargetWidth, 'blob') as Promise<Blob>)
        sivut.push(page)
      }
      return { success: true, pageCount: pageCount, fileAsUint8Array: fileAsUint8Array, renderedPages: sivut }
    })
  }

  public renderPageToBlob(pageNumber: number, fileAsUint8Array: Uint8Array, kuvienTargetWidth: number): Promise<Blob> {
    return this._doWithPdfPage<Blob>(fileAsUint8Array, (lib, doc, err) => {
      if (err) {
        console.error(err)
        return Promise.resolve<Blob>(null)
      }
      return this._renderPdfPage(doc, pageNumber, kuvienTargetWidth, 'blob') as Promise<Blob>
    })
  }

  public renderPageToObjectUrl(pageNumber: number, fileAsUint8Array: Uint8Array, kuvienTargetWidth: number): Promise<string> {
    return this._doWithPdfPage<string>(fileAsUint8Array, (lib, doc, err) => {
      if (err) {
        console.error(err)
        return Promise.resolve<string>(null)
      }
      return this._renderPdfPage(doc, pageNumber, kuvienTargetWidth, 'object-url') as Promise<string>
    })

  }

  public async etsiViivakoodiPdfsta(fileAsUint8Array: Uint8Array): Promise<VirtuaaliviivakoodinOsat | null> {
    return this._doWithPdfPage<VirtuaaliviivakoodinOsat | null>(fileAsUint8Array, async (lib, doc, err) => {
      const virtuaaliviivakoodiRegexp = /([0-9]{20,99})/g
      for (const page of doc.pages()) {
        try {
          const str = page.getText()
          const words = str?.replace(/\s+/g, ' ')?.split(' ') ?? []
          for (const word of words) {
            let match = null
            while (match = virtuaaliviivakoodiRegexp.exec(word)) {
              if (match.length > 1) {
                const purettuViivakoodi = this._viitenumeroService.parsiSuomalainenVirtuaaliviivakoodi(match[1])
                if (purettuViivakoodi && purettuViivakoodi.summa) {
                  return purettuViivakoodi
                }
              }
            }
          }
        } catch (err) {
          console.error(err)
        } finally {
          try { page.destroy() } catch (err) { console.error(err) }
        }
      }
      return null
    })
  }

  public async extractAllText(fileAsUint8Array: Uint8Array): Promise<string[]> {
    return this._doWithPdfPage<string[]>(fileAsUint8Array, async (lib, doc, err) => {
      const texts: string[] = []
      if (err) {
        return texts
      }
      for (const page of doc.pages()) {
        try {
          texts.push(this._stringService.normalizeWhitespace(page.getText() ?? ''))
        } catch (err) {
          this._errorHandler.handleError(err)
        } finally {
          try { page.destroy() } catch (err) { console.error(err) }
        }
      }
      return texts
    })
  }

}
