import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { SafeUrl } from '@angular/platform-browser'

import { MatDialog } from '@angular/material/dialog'

import { FirestoreTosite, Maksutapa, FirestoreTositteenKuva, FirestoreTositteenAlkuperainenTiedosto } from '../../_jaettu/model/tosite'
import { TositeKopioija } from '../../_jaettu/service/tosite/tosite.kopioija'
import { DateService } from '../../_shared-core/service/date.service'

import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { TositeKuvaCacheService } from '../kuvat/tosite-kuva-cache.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { KayttajaService } from '../../_angular/service/kayttaja.service'
import { TositeService } from '../../_angular/service/tosite/tosite.service'
import { LemonHttpService, LEMONAID_CF_API } from '../../_angular/service/lemon-http.service'
import { TositeKatseleComponentData, TositeMuokkaaComponentData, TositeMuokkaaComponentDataResolve } from '../../_angular/_resolvers/tosite.resolve'

import { TositePoistaDialog, TositePoistaDialogData } from '../poista-dialog/tosite.poista.dialog'
import { TositeLukittuDialog } from '../tosite-lukittu.dialog'

import { Subject, Observable, combineLatest, firstValueFrom } from 'rxjs'
import { takeUntil, map } from 'rxjs/operators'

import { AreYouSureDialog } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { AreYouSureDialogData } from 'app/reports/tilinpaatos-are-you-sure.dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

export interface FirestoreKuitinLatausKuva extends FirestoreTositteenKuva {
  ladataan: boolean
  safeUrl: SafeUrl
}

@Component({
  selector: 'app-tosite',
  templateUrl: './tosite.katsele.component.html',
  styleUrls: ['./tosite.katsele.component.css']
})
export class TositeKatseleComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject<void>()

  lahde: 'osto' | 'myynti' | 'palkka' | 'tiliote'

  tosite: FirestoreTosite = null
  kuvat: string[] = null
  alkuperaiset: FirestoreTositteenAlkuperainenTiedosto[] = null
  otsakeObservable: Observable<string> = null

  showSumma: boolean = false
  einvoiceApprovals: string[] = []

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _errorHandler: ErrorHandler,
    private _snackbar: MatSnackBar,
    private _dialog: MatDialog,
    private _httpService: LemonHttpService,
    private _translationService: LemonTranslationService,
    private _ladataanService: LadataanService,
    private _dateService: DateService,
    private _tositeService: TositeService,
    private _tositeKopioija: TositeKopioija,
    private _tositeKuvaCacheService: TositeKuvaCacheService,
    private _tositeComponentDataResolve: TositeMuokkaaComponentDataResolve,
    private _kayttajaService: KayttajaService,
    private _timestampService: TimestampService,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {

    // this._route.url.subscribe(parts => {
    //   console.log(parts)
    // })

    combineLatest([this._kayttajaService.kayttajanTiedotObservable, this._route.data]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([kayttajanTiedot, data]) => {

      if (!kayttajanTiedot || !data) {
        return
      }

      const componentData = data.data as TositeKatseleComponentData
      this.tosite = componentData?.tosite
      if (this.tosite) {
        this.lahde = this._tositeService.getMaksutapaNameForRouter(this.tosite.maksutapa)
        this.showSumma = this.lahde === 'osto' || this.lahde === 'myynti'

        this.kuvat = this._tositeKuvaCacheService.annaKuvienUrlit(this.tosite, kayttajanTiedot, 'full')

        const einvoiceApprovalsBy = Object.keys(this.tosite.einvoiceApprovals ?? {})
        if (this.tosite.type === 'einvoice' && this.tosite.einvoiceApprovalStatus === 'approved' && !!einvoiceApprovalsBy.length) {

          for (const approverUid of einvoiceApprovalsBy) {
            const approval = this.tosite.einvoiceApprovals[approverUid]
            this.einvoiceApprovals.push(approval.firstName + ' ' + approval.lastName + ' ' + this._dateService.muotoilePaivaJaAikaDate(approval.approved?.toDate(), this._translationService.nykyinenKieli))
          }
        }

        this.alkuperaiset = this.annaAlkuperaiset(this.tosite)
        if (componentData?.tulosta) {
          setTimeout(() => { this.lataaKuitti() }, 25)
        }
      }

    })

    this.otsakeObservable = combineLatest([
      this._tositeService.maksutavatObservable,
      this._translationService.currentLanguageObservable,
      this._route.data
    ]).pipe(
      map(([maksutavat, nykyinenKieli, data]): string => {
        const componentData = data?.data as TositeKatseleComponentData
        const tositeMaksutapa = componentData?.tosite?.maksutapa
        const lahde = this._tositeService.getMaksutapaNameForRouter(tositeMaksutapa)
        if (lahde === 'osto') {
          let valittuMaksutapa: Maksutapa = null
          for (const maksutapa of maksutavat) {
            if (maksutapa.i === tositeMaksutapa) {
              valittuMaksutapa = maksutapa
            }
          }
          return (valittuMaksutapa === null ? this._translationService.lokalisoi('tositteet.osto.katsele.ei-maksutapaa') : valittuMaksutapa.l) + ' ' + this._dateService.muotoilePaikallinenPaiva(componentData?.tosite?.localPvm, nykyinenKieli)

        }
        if (lahde === 'myynti' || lahde === 'palkka') {
          return this._translationService.lokalisoi('tositteet.' + lahde + '.katsele.otsikon-maksutapa') + ' ' + this._dateService.muotoilePaikallinenPaiva(componentData?.tosite?.localPvm, nykyinenKieli)
        }
        if (lahde === 'tiliote') {
          return this._translationService.lokalisoi('tositteet.' + lahde + '.katsele.otsikon-maksutapa') + ' ' + this._dateService.muotoileKuukausiJaVuosiPaikallinen(componentData?.tosite?.localPvm, nykyinenKieli)
        }

      })
    )

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  lataaKuitti() {
    this._ladataanService.aloitaLataaminen()
    return this._httpService.getBinary('/kuittiLataaPdf?a=' + encodeURIComponent('/api/1/kuitit/lataa/pdf/' + this.tosite.avain), LEMONAID_CF_API).then(result => {
      const nimi = this._tositeService.annaPdfTiedostonNimi(this.tosite, this._translationService.nykyinenKieli, this.lahde)
      this._ladataanService.lopetaLataaminen()
      this._fileSaverService.saveAs(result, nimi)
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })
  }

  async peruuta() {
    // Pre-select the maksutapa that the user will land to at list view
    if (this.tosite.x) {
      await this._tositeService.setLastViewedKuitinMaksutapa(null)
    } else {
      await this._tositeService.setLastViewedKuitinMaksutapa(this.tosite.maksutapa)
    }
    const segments = await firstValueFrom(this._route.url)
    segments.pop()
    this._router.navigate(segments.map(segment => segment.path))
  }

  private annaAlkuperaiset(tosite: FirestoreTosite): FirestoreTositteenAlkuperainenTiedosto[] {
    if (tosite.alkuperaiset) {
      const alkuperaiset: FirestoreTositteenAlkuperainenTiedosto[] = []
      for (const alkuperaisenAvain of Object.keys(tosite.alkuperaiset)) {
        const alkuperainen = tosite.alkuperaiset[alkuperaisenAvain] as FirestoreTositteenAlkuperainenTiedosto
        alkuperaiset.push(alkuperainen)
      }
      return alkuperaiset
    }
    return null
  }

  muokkaa() {
    if (this.tosite.l) {
      this._dialog.open(TositeLukittuDialog)
    } else {
      const kopio = this._tositeKopioija.kopioiTosite(this.tosite)
      const data: TositeMuokkaaComponentData = {
        tosite: kopio
      }
      this._tositeComponentDataResolve.asetaOlemassaolevaData(data)
      this._router.navigate(['tositteet', 'muokkaus', this.tosite.avain])
    }
  }

  poista(): void {
    if (this.tosite.l) {
      this._dialog.open(TositeLukittuDialog)
    } else {
      const kopio = this._tositeKopioija.kopioiTosite(this.tosite)
      const data: TositePoistaDialogData = {
        poistettava: kopio
      }
      this._dialog.open(TositePoistaDialog, { data: data }).afterClosed().pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(tulos => {
        if (tulos) {
          this.peruuta()
        }
      })
    }
  }

  async rejectInvoice(kuitti: FirestoreTosite) {

    try {

      const dialogData: AreYouSureDialogData = {
        header: this._translationService.lokalisoi('tositteet.laskujen-hyvaksyminen.haluatko-varmasti-hylata'),
        rightAction: this._translationService.lokalisoi('tositteet.laskujen-hyvaksyminen.kylla'),
        text: null
      }
      const isSure = await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed())

      if (isSure) {
        this._ladataanService.aloitaLataaminen()
        kuitti.einvoiceApprovalStatus = 'rejected'
        await this._tositeService.tallennaKuitti(kuitti)
      }

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async approveInvoice(kuitti: FirestoreTosite) {

    this._ladataanService.aloitaLataaminen()

    try {

      kuitti.einvoiceApprovalStatus = 'approved'
      const kayttaja = await this._kayttajaService.getKayttaja()

      if (!kuitti.einvoiceApprovals) {
        kuitti.einvoiceApprovals = {}
      }

      kuitti.einvoiceApprovals[kayttaja.avain] = {
        approved: this._timestampService.now(),
        firstName: kayttaja.etunimi,
        lastName: kayttaja.sukunimi
      }

      await this._tositeService.tallennaKuitti(kuitti, true)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  // lataaAlkuperainen(alkuperainen: FirestoreTositteenAlkuperainenTiedosto) {

  //   this.ladataanService.aloitaLataaminen()

  //   this.kayttajaService.getKayttajanTiedot().then(kayttajanTiedot => {
  //     return this.tositeUriService.annaCloudStorageAlkuperainenUriAsiakasIdlle(kayttajanTiedot.asiakasId, alkuperainen)
  //   }).then(url => {
  //     return this.httpService.getBinary('/kuittiLataaAlkuperainen?a=' + encodeURIComponent('/api/1/kuitit/kuvat/alkuperainen/' + url), LEMONAID_CF_API)
  //   }).then(result => {
  //     this.ladataanService.lopetaLataaminen()
  //     saveAs(result, alkuperainen.nimi, { autoBom: false })
  //   }).catch(err => {
  //     this.ladataanService.lopetaLataaminen()
  //     this.errorHandler.handleError(err)
  //   })

  // }

}
