import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { Tilikausi } from 'app/_jaettu/model/kayttaja'
import { TilinpaatosMetadata, TilinpaatosUserData } from '../_jaettu/model/tilinpaatos'
import { DateService } from 'app/_shared-core/service/date.service'
import { map, Observable, Subject, of as observableOf, switchMap, filter, firstValueFrom, combineLatest, BehaviorSubject, takeUntil, startWith, ReplaySubject, of } from 'rxjs'
import { TilinpaatosUriService } from 'app/_jaettu/service/tilinpaatos-uri.service'

import { DocumentSnapshot } from 'firebase/firestore'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { PdfOpenData, PdfService } from 'app/_angular/service/pdf.service'
import { DomSanitizer } from '@angular/platform-browser'
import { MatTabGroup } from '@angular/material/tabs'

export interface ImageCacheClass {
  getImageObservable(): Observable<string>
  startImageRendering(): Promise<boolean>
}

export class ImageCacheClassPdfiumRenderer implements ImageCacheClass {
  private _obsrvbl: ReplaySubject<string> = new ReplaySubject(1)
  private _rendered: boolean = false
  constructor(
    private _pdfService: PdfService,
    private _openData: Pick<PdfOpenData, 'fileAsUint8Array'>,
    private _domSanitizer: DomSanitizer,
    private _index: number
  ) { }
  getImageObservable(): Observable<string> {
    return this._obsrvbl
  }
  async startImageRendering() {
    if (!this._rendered) {
      this._rendered = true
      const rendered = await this._pdfService.renderPageToObjectUrl(this._index, this._openData.fileAsUint8Array, 600)
      this._obsrvbl.next(this._domSanitizer.bypassSecurityTrustUrl(rendered) as string)
      await this._sleep(50)
      return true
    }
    return false
  }
  private _sleep(millis: number): Promise<any> {
    return new Promise(resolve => {
      setTimeout(resolve, millis)
    })
  }
}

export class ImageCacheClassPreRendered implements ImageCacheClass {
  private _rendered: Observable<string>
  private _renderedCalled: boolean = false
  constructor(
    rendered: string
  ) {
    this._rendered = of<string>(rendered)
  }
  getImageObservable(): Observable<string> {
    return this._rendered
  }
  async startImageRendering() {
    if (this._renderedCalled) {
      return false
    }
    this._renderedCalled = true
    return true
  }
}

interface MainForm {
  selectedTilikausi: FormControl<Tilikausi>
}

export type ReportsForcedTabChange = 'tilinpaatos' | 'yhtiokokouksen-poytakirja'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FORCE_TAB_CHANGE_CHANNEL = 'force-reports-tab-change'

@Component({
  templateUrl: './tilinpaatokset.component.html',
  styleUrls: ['./tilinpaatokset.component.css']
})
export class TilinpaatoksetComponent implements OnInit, AfterViewInit, OnDestroy {

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  tilikaudetObservable: Observable<Tilikausi[]>
  selectedTilikausiObservable: BehaviorSubject<Tilikausi> = new BehaviorSubject(null)
  tilinpaatosSnapshotObservable: Observable<{ tilikausi: Tilikausi, snap: DocumentSnapshot<TilinpaatosMetadata> }>
  tilinpaatosUsersObservable: Observable<TilinpaatosUserData>

  form: FormGroup<MainForm>

  @ViewChild('tabGroup') reportsTabGroup: MatTabGroup

  private _reportsForceTabChangeChannel: BroadcastChannel

  constructor(
    private _kayttajaService: KayttajaService,
    private _dateService: DateService,
    private _tilinpaatosUriService: TilinpaatosUriService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) { }

  ngOnInit() {
    this._reportsForceTabChangeChannel = new BroadcastChannel(FORCE_TAB_CHANGE_CHANNEL)

    this._reportsForceTabChangeChannel.addEventListener('message', async (event: MessageEvent<ReportsForcedTabChange>) => {
      const data = event.data
      if (!data || !this.reportsTabGroup) { return }

      if (data === 'tilinpaatos') { this.reportsTabGroup.selectedIndex = 0 }
      if (data === 'yhtiokokouksen-poytakirja') { this.reportsTabGroup.selectedIndex = 1 }
    })

    this.form = new FormGroup<MainForm>({
      'selectedTilikausi': new FormControl<Tilikausi>(null, Validators.required)
    })

    this.tilikaudetObservable = this._kayttajaService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        if (asiakas?.tilikaudet?.length) {
          return asiakas.tilikaudet
        }
        return null
      })
    )

    this.tilinpaatosUsersObservable = this._kayttajaService.kayttajanTiedotObservable.pipe(
      switchMap(kayttaja => {
        if (!kayttaja) {
          return observableOf<TilinpaatosUserData>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<TilinpaatosUserData>(this._tilinpaatosUriService.getTilinpaatosUsersDocUri(kayttaja.asiakasAvain)).listen()
      })
    )

    // this.isTilinpaatosVisibleObservable = this._kayttajaService.kayttajanTiedotObservable.pipe(
    //   switchMap(kayttaja => {
    //     if (!kayttaja) {
    //       return observableOf<boolean>(false)
    //     }
    //     if (kayttaja.roolit.HALLINTO_YHTEYSHENKILO) {
    //       return observableOf<boolean>(true)
    //     }
    //     if (kayttaja.kayttajaTunnistettu === 'tunnistettu-nets') {
    //       return this.tilinpaatosUsersObservable.pipe(
    //         map(boardMembers => {
    //           if (!boardMembers?.hallitusUsers?.length) {
    //             return false
    //           }
    //           const userAsBoardMember = boardMembers.hallitusUsers.find(boardMember => boardMember.lemonaidUid === kayttaja.avain)
    //           if (
    //             userAsBoardMember &&
    //             (
    //               userAsBoardMember.role === 'toimitusjohtaja' ||
    //               userAsBoardMember.role === 'jasen' ||
    //               userAsBoardMember.role === 'puheenjohtaja'
    //             )
    //           ) {
    //             return true
    //           }
    //           return false
    //         })
    //       )
    //     }
    //     return observableOf<boolean>(false)
    //   })
    // )

    this.form.get('selectedTilikausi').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tilikausi => {

      // We send here null, so all observables return to the correct state
      // and as a consecuense show loading etc. indicators right
      this.selectedTilikausiObservable.next(null)
      this.selectedTilikausiObservable.next(tilikausi)
    })

    this.tilinpaatosSnapshotObservable = combineLatest([this._kayttajaService.kayttajanTiedotObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([kayttaja, tilikausi]) => {
        if (!kayttaja || !tilikausi) {
          return observableOf<{ tilikausi: Tilikausi, snap: DocumentSnapshot<TilinpaatosMetadata> }>(null)
        }
        const metadataUri = this._tilinpaatosUriService.getTilinpaatosMetadataDocUri(kayttaja.asiakasAvain, tilikausi.avain)
        return this._firebaseLemonaid.firestoreDoc<TilinpaatosMetadata>(metadataUri).listenSnap().pipe(
          map(snap => {
            return { tilikausi: tilikausi, snap: snap }
          })
        )
      }),
      startWith(null),
      lemonShare()
    )
  }

  ngAfterViewInit() {
    firstValueFrom(this.tilikaudetObservable.pipe(
      filter(tilikaudet => !!tilikaudet?.length)
    )).then(tilikaudet => {
      const now = this._dateService.currentLocalDate()
      const prevYear = tilikaudet.find(t => t.loppuu.year === now.year - 1)
      if (prevYear) {
        this.form.get('selectedTilikausi').setValue(prevYear)
        return
      }
      const currYear = tilikaudet.find(t => t.loppuu.year === now.year)
      if (currYear) {
        this.form.get('selectedTilikausi').setValue(currYear)
        return
      }
      this.form.get('selectedTilikausi').setValue(tilikaudet[tilikaudet.length - 1])
    })

  }

  compareWithKey(tilikausi1: Tilikausi, tilikausi2: Tilikausi): boolean {
    return tilikausi1?.avain === tilikausi2?.avain
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
