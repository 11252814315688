import { AfterViewInit, Component, ErrorHandler, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { ApixDownloadLaskutustiedotPdfRequest, ApixDownloadLaskutustiedotPdfResponse, ApixMaksutAktivoiVastaanottoRequest, ApixMaksutAktivoiVastaanottoResponse, ApixMaksutHalutaanOttaaKayttoonRequest, ApixMaksutHalutaanOttaaKayttoonResponse, ApixReceivedInvoiceConfig, ApixReceivedInvoiceConfigUpdateRequest, ApixReceivedInvoiceConfigUpdateResponse } from 'app/_jaettu/model/apix'
import { SahkoisenLaskunValittajaService } from 'app/_jaettu/service/lasku/sahkoisen-laskun-valittaja.service'
import { Subject, switchMap, of as observableOf, firstValueFrom, takeUntil, Observable, map } from 'rxjs'

import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { toByteArray } from 'base64-js'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { ActivatedRoute, Router } from '@angular/router'
import { LaskunSahkoinenOsoite } from 'app/_jaettu/model/lasku'
import { MatDialog } from '@angular/material/dialog'
import { MaksutLopetaVastaanottoDialog, MaksutLopetaVastaanottoDialogData } from './maksut-lopeta-vastaanotto.dialog'
import { MaksutVastaanottoAloitettuDialog, MaksutVastaanottoAloitettuDialogData } from './maksut-vastaanotto-aloitettu.dialog'
import { NewFeature } from 'app/_jaettu/model/kayttaja'
import { UserSettingsService } from 'app/_angular/service/user-settings.service'

interface LaskunSahkoinenOsoiteJaNimi extends LaskunSahkoinenOsoite {
  sahkoinenValittajaNimi: string
}
interface MainVastaanottoForm {
  vastaanottoKayttoon: FormControl<boolean>
}

interface MainMaksutForm {
  maksuominaisuusKayttoon: FormControl<boolean>
}

@Component({
  templateUrl: './maksut-asetukset.component.html',
  styleUrls: ['./maksut-asetukset.component.css']
})
export class MaksutAsetuksetComponent implements OnInit, OnDestroy {

  viewState: 'maksaminen' | 'vastaanotto' = 'vastaanotto'
  maksamisenTila: 'maksaminen-tilattu' | 'maksaminen-ei-paalla' | 'maksaminen-paalla' = 'maksaminen-ei-paalla'
  vastaanotonTila: 'vastaanotto-paalla' | 'vastaanotto-ei-paalla' = 'vastaanotto-ei-paalla'
  lataaminenValmis = false

  commonError: string

  vastaanottoForm: FormGroup<MainVastaanottoForm>
  maksuForm: FormGroup<MainMaksutForm>

  maksuominaisuusKayttoonFormControl = new FormControl<boolean>(false, Validators.requiredTrue)
  vastaanottoKayttoonFormControl = new FormControl<boolean>(false, Validators.requiredTrue)

  apixLaskuosoiteObservable: Observable<LaskunSahkoinenOsoiteJaNimi>

  showNewFeatureSignAtMaksutMaksaminenObservable: Observable<boolean>

  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  private _saveStarted: boolean = false

  namename = 'sdfg' + Math.random()

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _formValidationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _translationService: LemonTranslationService,
    private _sahkoisenLaskunValittajaService: SahkoisenLaskunValittajaService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _kayttajaService: KayttajaService,
    private _snackBar: MatSnackBar,
    private _fileSaverService: FileSaverService,
    private _userSettingsService: UserSettingsService
  ) {
  }

  ngOnInit(): void {

    this._route.params.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(params => {
      if (params.tyyppi === 'vastaanotto') {
        this.viewState = 'vastaanotto'
      } else if (params.tyyppi === 'maksaminen') {
        this.updateSeen(NewFeature.MAKSUT_MAKSAMINEN)
        this.viewState = 'maksaminen'
      }
    })

    this.vastaanottoForm = new FormGroup<MainVastaanottoForm>({
      vastaanottoKayttoon: this.vastaanottoKayttoonFormControl
    })

    this.maksuForm = new FormGroup<MainMaksutForm>({
      maksuominaisuusKayttoon: this.maksuominaisuusKayttoonFormControl
    })

    // this.operaattorit = this._sahkoisenLaskunValittajaService.annaKaikkiValittajat()
    const apixInvoiceConfigObservable = this._kayttajaService.kayttajanTiedotObservable.pipe(
      switchMap(kayttajanTiedot => {
        if (!kayttajanTiedot?.asiakasAvain) {
          return observableOf(null)
        }
        return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>('customers/' + kayttajanTiedot.asiakasAvain + '/apix-received-invoice-config/' + kayttajanTiedot.asiakasAvain).listenSnap()
      })
    )

    apixInvoiceConfigObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(asetukset => {
      if (asetukset) {
        this.lataaminenValmis = true
        const config = asetukset.data()
        this.maksamisenTila = 'maksaminen-ei-paalla'
        // this.maksamisenTila = 'maksaminen-tilattu'
        // this.maksamisenTila = 'maksaminen-paalla'
        if (config?.paymentServiceOrdered) {
          this.maksamisenTila = 'maksaminen-tilattu'
        }
        if (config?.paymentIsActive) {
          this.maksamisenTila = 'maksaminen-paalla'
        }

        this.maksuominaisuusKayttoonFormControl.setValue(!!config?.paymentServiceOrdered)
        // this.vastaanotonTila = 'maksaminen-tilattu'
        // this.vastaanottoKayttoonFormControl.setValue(true)

        this.vastaanotonTila = config?.paymentReceiveIsActive ? 'vastaanotto-paalla' : 'vastaanotto-ei-paalla'
        this.vastaanottoKayttoonFormControl.setValue(!!config?.paymentReceiveIsActive)
        if (this.vastaanotonTila === 'vastaanotto-paalla') {
          this.maksuominaisuusKayttoonFormControl.enable()
        } else {
          this.maksuominaisuusKayttoonFormControl.disable()
        }
        // this.vastaanotonTila = 'vastaanotto-tilattu'
        // this.vastaanottoKayttoonFormControl.setValue(true)
      } else {
        this.lataaminenValmis = false
      }
    })

    this.apixLaskuosoiteObservable = apixInvoiceConfigObservable.pipe(
      map(snapshot => {
        const config = snapshot.data()
        if (config?.receiveActive && config?.paymentReceiveIsActive && config?.receiveAddress) {
          const osoiteJaNimi: LaskunSahkoinenOsoiteJaNimi = {
            sahkoinenOsoite: config.receiveAddress.sahkoinenOsoite,
            sahkoinenValittaja: config.receiveAddress.sahkoinenValittaja,
            sahkoinenValittajaNimi: this._sahkoisenLaskunValittajaService.annaValittajanNimi(config.receiveAddress.sahkoinenValittaja)
          }
          return osoiteJaNimi
        }
        return null
      })
    )

    this.showNewFeatureSignAtMaksutMaksaminenObservable = this._userSettingsService.userSettingsObservable.pipe(
      takeUntil(this._ngUnsubscribe),
      map(newFeatures => {
        if (!newFeatures?.seen[NewFeature.MAKSUT_MAKSAMINEN]) {
          return true
        }
        return false
      })
    )

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  async downloadPdf(kieli: TuettuKieli) {

    this._ladataanService.aloitaLataaminen()

    try {

      const asiakas = await firstValueFrom(this._kayttajaService.nykyinenAsiakasObservable)

      const reqData: ApixDownloadLaskutustiedotPdfRequest = {
        kieli: kieli
      }

      const resp = await this._firebaseLemonaid.functionsCall<ApixDownloadLaskutustiedotPdfRequest, ApixDownloadLaskutustiedotPdfResponse>('downloadLaskutustiedotPdf', reqData)

      if (!resp || resp.e) {
        throw new Error('Laskutustiedot PDF download failed! ' + (resp?.e ?? 'No response data')) // Handle in catch
      }

      if (!resp.base64File?.length) {
        throw new Error('Laskutustiedot PDF download failed! PDF file empty')
      }

      const output = toByteArray(resp.base64File)
      const laskutustiedotLocalised = this._translationService.lokalisoiKielella('laskutustiedot-pdf.laskutustiedot', reqData.kieli)
      const fileName = laskutustiedotLocalised + ' ' + asiakas.nimi + ' (' + asiakas.ytunnus + ')' + '.pdf'
      this._fileSaverService.saveAs(new Blob([output], { type: 'application/pdf' }), fileName)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackBar.open(this._translationService.lokalisoi('tositteet.laskujen-vastaanotto.pdf-epaonnistui'), 'OK', { duration: 10 * 1000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async updateSeen(seenFeature: NewFeature) {

    const kayttajanTiedot = await firstValueFrom(this._kayttajaService.kayttajanTiedotObservable)
    if (!kayttajanTiedot?.asiakasAvain) { return }

    const showNewFeatureSignAtMaksutMaksaminen = await firstValueFrom(this.showNewFeatureSignAtMaksutMaksaminenObservable)
    if (showNewFeatureSignAtMaksutMaksaminen) {
      return this._userSettingsService.setFeatureSeen(seenFeature, kayttajanTiedot.asiakasAvain, kayttajanTiedot.uid, this._firebaseLemonaid)
    }

  }

  vaihdaValilehtiVastaanottoon() {
    window.scrollTo(0, 0)
    this._router.navigate(['maksut', 'asetukset', 'vastaanotto'])
  }

  vaihdaValilehtiMaksamiseen() {
    window.scrollTo(0, 0)
    this._router.navigate(['maksut', 'asetukset', 'maksaminen'])
  }

  async tallennaMaksamisenAktivointi() {
    if (!this.vastaanottoForm.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.vastaanottoForm)
      return
    }

    if (this._saveStarted) {
      return
    }

    this._saveStarted = true
    this._ladataanService.aloitaLataaminen()

    try {

      const reqData: ApixMaksutHalutaanOttaaKayttoonRequest = {}
      const resp = await this._firebaseLemonaid.functionsCall<ApixMaksutHalutaanOttaaKayttoonRequest, ApixMaksutHalutaanOttaaKayttoonResponse>('apixMaksutHalutaanOttaaKayttoon', reqData)

      if (!resp || resp.e) {
        /** Throw error to end up in the catch. */
        throw new Error('Maksut halutaan ottaa käyttöön virhe! ' + (resp?.e || 'unknown-error'))
      }

    } catch (err) {
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
      this._errorHandler.handleError(err)
    } finally {
      this._saveStarted = false
      this._ladataanService.lopetaLataaminen()
    }
  }

  async tallennaVastaanotonLopetus() {
    if (this.vastaanottoKayttoonFormControl.value) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.vastaanottoForm)
      return
    }

    const data: MaksutLopetaVastaanottoDialogData = {}
    const dialogRef = this._dialog.open<MaksutLopetaVastaanottoDialog, MaksutLopetaVastaanottoDialogData, void>(MaksutLopetaVastaanottoDialog, { data: data })

  }

  async tallennaVastaanotonAktivointi() {
    if (!this.vastaanottoForm.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.vastaanottoForm)
      return
    }

    if (this._saveStarted) {
      return
    }

    this._saveStarted = true
    this._ladataanService.aloitaLataaminen()

    try {

      const reqData: ApixMaksutAktivoiVastaanottoRequest = {}
      const resp = await this._firebaseLemonaid.functionsCall<ApixMaksutAktivoiVastaanottoRequest, ApixMaksutAktivoiVastaanottoResponse>('apixAktivoiLaskujenVastaanotto', reqData)

      if (!resp || resp.e) {
        /** Throw error to end up in the catch. */
        throw new Error('Forwarded address save failed! ' + (resp?.e || 'unknown-error'))
      } else {
        const data: MaksutVastaanottoAloitettuDialogData = {}
        this._dialog.open(MaksutVastaanottoAloitettuDialog, { data: data })
      }

    } catch (err) {
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
      this._errorHandler.handleError(err)
    } finally {
      this._saveStarted = false
      this._ladataanService.lopetaLataaminen()
    }
  }

}
