import { ApixLahetaLaskuVastaus, ApixLahetystapaVastaus } from './apix'
import { ErrorResponse, LocalDate, NumberDate, Timestamp, TuettuKieli } from '../../_shared-core/model/common'

export const EI_LASKUNUMEROA_NUMERO = 9999999999

export enum TuoteAvaimet {
  HUOMAUTUSKULU = 'huomautuskulutuoteavain',
  KORKOKULU = 'korkokulutuoteavain',
  MITATOINTI = 'mitatointirivituoteavain'
}

export interface AsiakkaanTuoteWithoutKey {
  nimi: string
  hinta: number
  date: Timestamp
  poistettu?: boolean
  /** Tähän tallennetaan laskutyypeittäin viimeiseksi käytetty ALV-kanta. Avain on siis LaskuTyyppi.avain ja arvo on LaskunAlv.tunniste */
  alv: {
    [key: string]: string
  }
}

export interface AsiakkaanTuote extends AsiakkaanTuoteWithoutKey {
  $key: string
}

export interface AsiakkaanTuoteLaskussa extends AsiakkaanTuoteWithoutKey {
  avain: string
}

export interface EuVatSpec {
  /** Prosentti */
  p: number
  /** Defaultkanta */
  d?: 1
  /** voimassaolo päättyi yymmdd */
  e?: number
}
export interface EuVatSpecMap { [countryCode: string]: EuVatSpec[] }

export interface LaskunAlv {
  prosentti: number
  tunniste: string
  /** onko alv-prosentti maan tai tyypin oletusprosentti (STANDARD, eikä REDUCED) */
  d?: 1
  /** voimassaolo päättyi yymmdd */
  e?: number
  /** voimassaolo alkoi yymmdd */
  s?: number
}

export type LaskunTyyppiavain = 'tav' | 'tav_ulkom' | 'taveu' | 'palveu' | 'kuleu' | 'kuleut' | 'kuleup' | 'kuleur' | 'eieu' | 'rak'
export interface LaskunTyyppi {
  avain: LaskunTyyppiavain
}

export interface LaskunLiitetiedosto {
  avain: string
  nimi: string
  koko: number
  fileEnding: string
  lisatty: Timestamp
  lisattyl: LocalDate
}

export class LaskunTyypit {
  public static readonly TAVALLINEN: LaskunTyyppi = {
    avain: 'tav'
    // ,
    // alvt: [
    //   { prosentti: 0, tunniste: 'suomi0' },
    //   { prosentti: 10, tunniste: 'suomi10' },
    //   { prosentti: 14, tunniste: 'suomi14' },
    //   { prosentti: 24, tunniste: 'suomi24' }
    // ]
  }

  public static readonly TAVALLINEN_ULKOM: LaskunTyyppi = {
    avain: 'tav_ulkom'
  }

  public static readonly EU_TAVARA: LaskunTyyppi = {
    avain: 'taveu'
    // ,
    // alvt: [
    //   { prosentti: 0, tunniste: 'yht' }
    // ]
  }
  public static readonly EU_PALVELU: LaskunTyyppi = {
    avain: 'palveu'
    // ,
    // alvt: [
    //   { prosentti: 0, tunniste: 'yht' }
    // ]
  }
  public static readonly EU_KULUTTAJA: LaskunTyyppi = {
    avain: 'kuleu'
    // ,
    // alvt: [
    //   { prosentti: 0, tunniste: 'suomi0' },
    //   { prosentti: 10, tunniste: 'suomi10' },
    //   { prosentti: 14, tunniste: 'suomi14' },
    //   { prosentti: 24, tunniste: 'suomi24' }
    // ]
  }
  public static readonly EU_KULUTTAJA_TAVARA: LaskunTyyppi = {
    avain: 'kuleut'
  }
  public static readonly EU_KULUTTAJA_PALVELU: LaskunTyyppi = {
    avain: 'kuleup'
  }
  public static readonly EU_KULUTTAJA_EI_REKISTEROITYNYT: LaskunTyyppi = {
    avain: 'kuleur'
  }
  public static readonly RAKENNUSALA: LaskunTyyppi = {
    avain: 'rak'
    // ,
    // alvt: [
    //   { prosentti: 0, tunniste: 'rak' }
    // ]
  }
  public static readonly MUU_MAAILMA: LaskunTyyppi = {
    avain: 'eieu'
    // ,
    // alvt: [
    //   { prosentti: 0, tunniste: 'eieu' }
    // ]
  }

  public static annaLaskunTyyppi(avain: string): LaskunTyyppi | null {
    if (avain === LaskunTyypit.TAVALLINEN.avain) {
      return LaskunTyypit.TAVALLINEN
    }
    if (avain === LaskunTyypit.TAVALLINEN_ULKOM.avain) {
      return LaskunTyypit.TAVALLINEN_ULKOM
    }
    if (avain === LaskunTyypit.RAKENNUSALA.avain) {
      return LaskunTyypit.RAKENNUSALA
    }
    if (avain === LaskunTyypit.EU_TAVARA.avain) {
      return LaskunTyypit.EU_TAVARA
    }
    if (avain === LaskunTyypit.EU_KULUTTAJA.avain) {
      return LaskunTyypit.EU_KULUTTAJA
    }
    if (avain === LaskunTyypit.EU_KULUTTAJA_TAVARA.avain) {
      return LaskunTyypit.EU_KULUTTAJA_TAVARA
    }
    if (avain === LaskunTyypit.EU_KULUTTAJA_PALVELU.avain) {
      return LaskunTyypit.EU_KULUTTAJA_PALVELU
    }
    if (avain === LaskunTyypit.EU_KULUTTAJA_EI_REKISTEROITYNYT.avain) {
      return LaskunTyypit.EU_KULUTTAJA_EI_REKISTEROITYNYT
    }
    if (avain === LaskunTyypit.EU_PALVELU.avain) {
      return LaskunTyypit.EU_PALVELU
    }
    if (avain === LaskunTyypit.MUU_MAAILMA.avain) {
      return LaskunTyypit.MUU_MAAILMA
    }
    return null
  }

}

export enum LaskunAsiakastyyppi {
  HENKILO = 'h',
  YRITYS = 'y'
}

export enum LaskunLahetystapa {
  SAHKOPOSTI = 's',
  ITSE = 'i',
  PAPERI = 'p',
  SAHKOINEN = 'e',
  /** Lasku on lähetetty ulkoisesta järjestelmästä tuntemattomalla tavalla */
  MUUALLA = 'm'
}

export interface LaskunAsiakas {
  avain: string
  nimi: string
  ytunnus: string
  katuosoite: string
  postitmp: string
  postinro: string
  maa: string
  date: Timestamp
  /** Email osoitteet, jonne lasku pitäisi lähettää. */
  laskunVastaanottajat: string[]
  laskunTyyppi: LaskunTyyppiavain
  laskunKieli: TuettuKieli
  sahkoinenosoite: LaskunSahkoinenOsoite
  laskunValuutta: string
  asiakastyyppi: LaskunAsiakastyyppi
  poistettu?: boolean
  viimeisinLaskuLahetetty: LaskunLahetystapa
}

export interface LaskunTuote {
  tuote: AsiakkaanTuoteLaskussa
  alv: LaskunAlv
  maara: number
  hinta: number
  ale: number
  hinnanTyyppi?: 'yhteensa' | 'kappalehinta'
  yhteensa?: number
}

export enum LaskunumeroTyyppi {
  KORJAUS = 'k',
  MUISTUTUS = 'm',
  TAVALLINEN = 'n',
  HYVITYS = 'h'
}

export enum LaskunListaustyyppi {
  TAVALLINEN = 't',
  LAPSI = 'l',
  LAPSI_EKA = 'le',
  LAPSI_VIKA = 'lv',
  VANHEMPI = 'v'
}

export interface LaskunListaustieto extends LaskunListaustietorivi {
  korvaavat: LaskunListaustietorivi[]
}

export interface LaskunListaustietorivi {
  avain: string
  tyyppi: LaskunListaustyyppi
  nro: string
  nrotyyppi: LaskunumeroTyyppi
  juuriAvain: string
  asiakas: string
  pvm: LocalDate
  erapvm: LocalDate
  summa: number
  avoinna: number
  tila: string
  valuutta: string
  vari: string
  juurilasku: Lasku
  kasiteltava: LaskuBase
  lahetysEpaonnistui: boolean
}

export interface LaskunToimintalokiSpostiParametrit {
  emailit: string[]
}

export interface LaskunToimintalokiSahkoinenParametrit {
  osoite: LaskunSahkoinenOsoite
}

export enum LaskunToimintalokiTyyppi {
  LUOTU = 1,
  MUOKATTU = 2,
  RESKONTRA = 3,
  LAHETETTY_SPOSTI = 4,
  LAHETETTY_TULOSTA = 5,
  LAHETETTY_SAHKOINEN = 6,
  LAHETETTY_MAKSUMUISTUTUS_SPOSTI = 7,
  LAHETETTY_MAKSUMUISTUTUS_TULOSTA = 8,
  LAHETETTY_MAKSUMUISTUTUS_SAHKOINEN = 9,
  MERKITTY_LUOTTOTAPPIOKSI = 10,
  LUOTTOTAPPIO_MERKINTA_PERUUTETTU = 11,
  LUONNOS_LUOTU = 12,
  LUONNOS_TALLENNETTU = 13,
  LUONNOS_POISTETTU = 14,
  MITATOITY = 15,
  KOMMENTTI_PAIVITETTY = 16,
  /**
   * If invoice is sent from a customer in OUR system to a customer in OUR system and the receiver has
   * enabled the forwarding service AND the approval of invoices we don't send the invoice to the receivers receive
   * address, but print the invoice and place it in the aproval queue. When it is approved, actually send it.
   */
  LAHETETTY_TULOSTA_TOISEN_HYVAKSYNTAJONOON_ODOTTAMAAN_HYVAKSYNTAA = 17,
  /** The invoice that is sent from the approval queue in case explained in documentation for LAHETETTY_TULOSTA_TOISEN_HYVAKSYNTAJONOON_ODOTTAMAAN_HYVAKSYNTAA. */
  LAHETETTY_SAHKOISESTI_TOISEN_HYVAKSYNTAJONOSTA_HYVAKSYNNAN_JALKEEN = 18,
  PERINTA_LAHETETTY = 19,
  POISTETTU_PERINNASTA = 20,
  PERINTA_LAHETETTY_LISATIEDOT_HYVITYS = 21
}

export interface LaskutoimintalokiMerkinta {
  toiminto: LaskunToimintalokiTyyppi
  parametrit?: LaskunToimintalokiSpostiParametrit | LaskunToimintalokiSahkoinenParametrit
}

export interface LaskuToimintaloki {
  pvm: Timestamp
  toiminto: LaskunToimintalokiTyyppi
  parametrit: LaskunToimintalokiSpostiParametrit | LaskunToimintalokiSahkoinenParametrit | null
  /** The user that executed the action */
  tekija_uid: string
  /** The user id of the user emulating the user identified in tekija_uid */
  emuloija_uid?: string
  /** kasiteltavan laskun avain */
  lasku_uid: string
  /** human readable version of invoice number */
  lasku_nro: string
}

export const UUDEN_LASKUN_AVAIN = 'uusi'

/**
 * Ulkoisia järjestelmiä, josta laskuja voidaan tuoda Lemonaidiin / Lemonatoriin.
 *
 * Huom!
 * Arvoja käytetään osana Firestore dokumenttien avaimia,
 * joten pidä arvot numeerisena (tuotenimestä riippumattomana) ja staattisena.
 */
export enum InvoiceSource {
  MINDUU = 1
}

// /customers/{asiakasAvain}/customer-invoices/{laskuAvain}  LASKUT
// /customers/{asiakasAvain}/customer-invoices-list/{laskuAvain}  LISTAUSLASKUT
// /customers/{asiakasAvain}/customer-invoices-products/{tuoteAvain}  LASKUJEN TUOTTEET
// /customers/{asiakasAvain}/customer-invoices-customers/{laskunAsiakkaanAvain}  LASKUJEN ASIAKKAAT
// /customers/{asiakasAvain}/customer-invoices-settings/{laskuasetusAvain}  LASKUASETUKSET

// NB!! NOTE ! NOTE ! NOTE ! WARNING! WARNING!
// IF YOU MODIFY THIS, ALSO MODIFY LaskuProps array!!!!
export interface Lasku extends LaskuBase {

  /** Siirrettävä laskulistaukseen */
  tila: LaskunTila

  viitenumero?: string
  /** Jos vain juurilasku, aina sama kuin avoinna, Siirrettävä laskulistaukseen */
  avoinnaRypas: number
  /** Jos vain juurilasku, aina sama kuin summa, Siirrettävä laskulistaukseen */
  summaRypas: number
  /** Jos vain juurilasku, aina sama kuin summaAlv0, Siirrettävä laskulistaukseen */
  summaRypasAlv0: number

  /** Siirrettävä laskulistaukseen */
  korvaus: LaskuBase[]

  /** Siirrettävä laskulistaukseen */
  lahetystyyppi: LaskunLahetystyyppi

  /** Siirrettävä laskulistaukseen */
  kommentti?: string

  viivastyskorkoprosentti?: number

  /** Siirrettävä lisätiedot */
  eraantynytViestiLahetetty?: Timestamp

  /** Siirrettävä lisätiedot */
  perintatiedot?: LaskunPerintatiedot
  /** Sisältyykö Lemonaidin listaukseen ja hakuun */
  omitFromSearches?: true
  /** Otetaanko Lemonaidin avoimet ja erääntyneet laskut summissa (LaskuPostgresTyojonoData jätetään kirjoittamatta) */
  omitFromStatistics?: true
  /** 
   * Ulkoinen järjestelmä, josta lasku on tuotu, jos ei oma. LaskuBasessa tällöin ulkoinenLaskunro.
   * Huom! Käytetään osana ulkoisen laskun avainta eli merkkirajoitteet pätevät!
   */
  source?: InvoiceSource
  /** Ulkoisen järjestelmän sisäinen lasku ID debuggaus tarkoituksiin */
  sourceId?: string

}

interface LaskunPerintatiedot {
  /** Front end asettaa aikaleiman kun käyttäjä hyväksyy perintään lähettämisen */
  lahetettyPeruutusFrontend: Timestamp
  /** Backend laittaa tämän kun peruutus sähköposti perintään oikeasti lähti */
  lahetettyPeruutus: Timestamp
  /** Front end asettaa aikaleiman kun käyttäjä peruuttaa perinnän */
  lahetettyFrontend: Timestamp
  /** Backend laittaa tämän kun sähköposti perintään oikeasti lähti */
  lahetetty: Timestamp
  /** Tallennetaan lähetettyjen laskujen laskuavaimet ja niiden tiedot avain-arvo pareina */
  lahetykset: { [laskuAvain: string]: LaskunPerintatietojenLahetyskerta }
  /** Vastaanottajan sähköpostiosoite */
  vastaanottaja: string
  /** Sähköpostin lähetyksen status */
  status: 'ok' | 'error'
  /** Sähköpostin lähetyksen onnistuminen tekstimuodossa */
  success: any
  /* Muu virhetilanne */
  error?: any
}

export interface LaskunPerintatietojenLahetyskerta {
  /* Lähetettävän laskun avain */
  kasiteltavaAvain: string
  /** Aikaleima milloin lähetetty front endistä*/
  lahetettyFrontend: Timestamp
  /** Aikaleima milloin lähetetty oikeasti backendistä */
  lahetetty: Timestamp
}

export interface PostgresLaskunSummat {
  pvm: Date
  asiakasAvain: string
  avain: string
  reskontraSumma: number
  tila: LaskunTila
  valuutta: string
  yhteensaKaikki: number
}

export interface PostgresLaskunSummatExport {
  reskontraSumma: number
  yhteensaKaikki: number
  tila: LaskunTila
  valuutta: string
}

// This array must contain ALL properties found in a Lasku, including the optional ones.
export const LaskuProps: (keyof Lasku)[] = [

  // Lasku
  'tila',
  'viitenumero',
  'avoinnaRypas',
  'summaRypas',
  'summaRypasAlv0',
  // 'reskontra',
  'korvaus',
  'lahetystyyppi',
  'kommentti',
  'viivastyskorkoprosentti',
  'eraantynytViestiLahetetty',
  'perintatiedot',

  // Laskubase
  'avain',
  'lukossa',
  'asiakas',
  'nro',
  'nrotyyppi',
  'ulkoinenLaskunro',
  'ulkoinenLaskunroLajittelu',
  'ulkoinenLaskuHyvitysTaiMuistutus',

  'pvm',
  'pvml',
  'p',
  'erapvm',
  'erapvml',
  'erap',
  'toimituspvm',
  'toimituspvml',
  'toimitusp',

  'viitteenne',
  'lisatiedot',
  'tyyppi',
  'kieli',
  'valuutta',
  'tuotteet',
  'date',

  'summa',
  'summaAlv0',
  'summaReskontraLaskunPvm',
  'summaHyvityksetLaskunPvm',
  'avoinna',

  'email',
  'print',
  'sahkoinen',

  'liitteet',

  'tagTilitapahtumat',
  'asetukset',

  'omitFromSearches',
  'omitFromStatistics',
  'source',
  'sourceId'
]

// NB!! NOTE ! NOTE ! NOTE ! WARNING! WARNING!
// IF YOU MODIFY THIS, ALSO MODIFY LaskuProps array!!!!
export interface LaskuBase {

  avain: string
  lukossa: boolean
  asiakas: LaskunAsiakas
  /**  Järjestelmän luoman laskun numero number-muodossa */
  nro: number
  nrotyyppi: LaskunumeroTyyppi

  pvm: Timestamp
  pvml: LocalDate
  p: NumberDate
  erapvm: Timestamp
  erapvml: LocalDate
  erap: NumberDate
  toimituspvm: Timestamp
  toimituspvml: LocalDate
  toimitusp: NumberDate

  viitteenne: string
  lisatiedot: string
  tyyppi: LaskunTyyppiavain
  kieli: TuettuKieli
  valuutta: string
  tuotteet: LaskunTuote[]
  date: Timestamp

  /** Laskun summa sisältäen ALV */
  summa: number
  /** Laskun summa ilman ALV */
  summaAlv0: number
  /** Reskontran summa sinä päivänä, kun lasku on luotu. Juurilaskulla aina 0, 
   * mutta esim. liittyvällä muistutuksella saattaa olla muuta, jos esim. 
   * osittain maksettu.
   * SIIRRETTÄVÄ LISTAUSLASKUUN 
   * */
  summaReskontraLaskunPvm: number
  /** Hyvitysten määrä sinä päivänä, kun lasku on luotu. Juurilaskulla aina 0, 
   * mutta esim. muikkarilla / hyvityksellä saattaa olla muuta, jos osittain 
   * hyvitetty. Osahyvityksiä saattaa olla myös useampia.
   * SIIRRETTÄVÄ LISTAUSLASKUUN
   * */
  summaHyvityksetLaskunPvm: number
  /** Maksettavaa. Jos ei hyvityksiä, muikkareita, eikä maksettu yhtään, tämä on sama kuin summa. */

  /** Päälaskun id, sama kuin listauslaskun id */
  // kokonaisuusId: string

  /** asiakasAvain: string */

  /** SIIRRETTÄVÄ LISTAUSLASKUUN */
  avoinna: number

  /** SIIRRETTÄVÄ LÄHETYSTIETOIHIN */
  email: EmailStatus

  /** SIIRRETTÄVÄ LÄHETYSTIETOIHIN */
  print: LaskuPrintStatus

  /** SIIRRETTÄVÄ LÄHETYSTIETOIHIN */
  sahkoinen: LaskuSahkoinenStatus

  liitteet: LaskunLiitetiedosto[]

  asetukset: Laskuasetukset

  tagTilitapahtumat?: string[]
  /** Ulkoinen järjestelmä, josta lasku on tuotu, jos ei oma. LaskuBasessa tällöin ulkoinenLaskunro.
* Huom! Käytetään osana ulkoisen laskun avainta eli merkkirajoitteet pätevät!
*/
  source?: InvoiceSource
  /** Ulkoisen järjestelmän sisäinen lasku ID debuggaus tarkoituksiin */
  sourceId?: string
  /** Ulkoisen järjestelmän luoman laskun numero string-muodossa. Laskun source-kentässä tieto mistä järjestelmästä */
  ulkoinenLaskunro?: string
  /** 
   * Ulkoisen järjestelmän luoman laskun numeron jaloste number-muodossa lajittelua varten.
   * HUOM! TÄMÄN PITÄÄ KERTOA LASKUN JÄRJESTYS VÄLILLÄ 0-999999!
   * */
  ulkoinenLaskunroLajittelu?: number
  /** Ulkoisen laskun hyvitykset ja muistutukset */
  ulkoinenLaskuHyvitysTaiMuistutus?: UlkoinenLaskuHyvitysTaiMuistutus
}

export interface UlkoinenLaskuHyvitysTaiMuistutus {
  /** Ulkoisen laskun alkuperäisen laskun numero */
  alkuperainenLaskunro: string
  /** Ulkoisen laskun viivästyskorkoprosentti */
  viivastyskorkoprosentti: number
}

export interface LaskuReskontra {
  avain: string
  tekijaUid: string
  /** Date when effective */
  pvm: Timestamp
  /** Date when effective */
  pvml: LocalDate
  /** Date when effective */
  p: NumberDate
  luotu: Timestamp
  suoritus: number
  /**
   * Tilitapahtumassa olevan reskontramerkinnän avain
   * tai toisessa laskussa olevan avain. undefined jos
   * tyyppi === 'kasin'
   * NOTE! IN THE DATA FROM 24.1.2024!!
   */
  vastaparinAvain?: string
  /** Tilitapahtuman avain */
  siirrettyTilitapahtumasta?: string
  /** Laskun avain */
  siirrettyLaskusta?: string
  siirrettyLaskustaNro?: string
  /** Laskun avain */
  siirrettyLaskuun?: string
  siirrettyLaskuunNro?: string
  maksaja?: string
  tyyppi: 'ylisuoritus-poisto' | 'ylisuoritus-siirto' | 'tilitapahtuman-kohdistus' | 'kasin'
}

export enum LaskunTila {
  maksettu = 'm',
  eraantynyt = 'e',
  poistettu = 'p',
  mitatoity = 'mi',
  avoin = 'a',
  luottotappio = 'l',
  maksettuLiikaa = 'ml',
  luonnos = 'lu',
  korvattuLaskulla = 'kl',
  korvattuMuistutusLaskulla = 'kml',
  kaikki = 'k',
  hyvitetty = 'h',
  perinnassa = 'pe'
}

export interface TyojononMerkintaBase {
  aloitettu: Timestamp
  uudelleenyrityksia: number
}

export interface LaskuTyojonoMerkintaBase extends TyojononMerkintaBase {
  laskuAvain: string
  asiakasAvain: string
  asiakasId: string
}

export interface LaskuTasapainoitaJaLahetaTyojonomerkinta extends LaskuTyojonoMerkintaBase {
  viitenumero: string
  kanava: LaskunLahetystapa
  kasiteltavaAvain: string
}

export interface LaskuEmailtyojononMerkinta extends LaskuTyojonoMerkintaBase {
  kasiteltavaAvain: string
}
export interface LaskuPerintaEmailtyojononMerkinta extends LaskuTyojonoMerkintaBase {
  kasiteltavaAvain: string
  kayttajaAvain: string
}

export interface LaskuTulostamistyojononMerkinta extends LaskuTyojonoMerkintaBase {
  kasiteltavaAvain: string
}

export interface LaskuSahkoinentyojononMerkinta extends LaskuTyojonoMerkintaBase {
  kasiteltavaAvain: string
}

export interface LaskuTilatPaivitaAsiakkaanEraantyneetMerkinnat extends TyojononMerkintaBase {
}

// export interface LaskuSqlMassavientiLemonatoriinAsiakkaanTyo extends TyojononMerkintaBase {

// }

export interface LaskuVientiLemonatoriintyojononMerkinta extends LaskuTyojonoMerkintaBase {
  kasiteltavaAvain: string
  lahetaSlack: boolean
  vastaanottajat?: EmailLahetysStatus[]
  sahkoinenOsoite?: LaskunSahkoinenOsoite
}

export interface LaskuTeePdfUudelleentyojononMerkinta extends LaskuTyojonoMerkintaBase {
  kasiteltavaAvain: string
}

export interface LaskuIndeksointityojononMerkinta extends LaskuTyojonoMerkintaBase {

}

export interface LaskuMyyntiLaskuOstolaskuksiTyojonoMerkinta extends Omit<LaskuTyojonoMerkintaBase, 'asiakasId' | 'asiakasAvain'> {
  myyvanAsiakkaanId: string
  myyvanAsiakkaanAvain: string
  ostavanAsiakkaanAvain: string
  kasiteltavaAvain: string
}

export interface LaskuPrintStatus {
  start: Timestamp
  done: Timestamp
}

export interface LaskuSahkoinenStatus {
  start: Timestamp
  done: Timestamp
  osoite: LaskunSahkoinenOsoite
  status: LaskuSahkoinenLahetysStatusKoodi
  latestSendResponse?: ApixLahetaLaskuVastaus
  latestAddressQueryReponse?: ApixLahetystapaVastaus
  internalError?: {
    message: string
    archiveUri?: string
  }
}

export enum LaskuSahkoinenLahetysStatusKoodi {
  PROSESSOIDAAN = 1,
  LAHETETTY_VASTAANOTTAJALLE = 2,
  LAHETYS_EPAONNISTUI_OSOITE_VIRHEELLINEN = 3
}

export interface LaskunSahkoinenOsoite {
  sahkoinenOsoite: string
  sahkoinenValittaja: string
}

// export interface KaikkienAsiakkaidenLaskusummat {
//   [key: string]: AsikkaanLaskusummatValuutalle[]
// }

// export interface AsikkaanLaskusummatValuutalle {
//   valuutta: string
//   laskutettu: number
//   saatu: number
//   eraantynyt: number
//   avoinna: number
//   luottotappiot: number
// }


export interface EmailStatus {
  start: Timestamp
  done: Timestamp
  vastaanottajat: EmailLahetysStatus[]
  aihe: string
  otsikko: string
  teksti: string
  slogan: string
  replyTo?: string
  template: number
  vari?: { [key in LaskunSahkpostipohjanVari]: string }
  footer?: 'footer1' | 'footer2'
  logoInFooter?: boolean
  lisahuomautus?: string
}

export enum LaskunSahkpostipohjanVari {
  HEADER_TAUSTAVARI = 'header_taustavari',
  HEADER_FONTTI = 'header_fontti',
  OTSIKKO_FONTTI = 'otsikko_fontti',
  VALIVIIVA = 'valiviiva'
}

export interface TypeaheadAsiakasDokumentti {
  [avain: string]: TypeaheadAsiakasIlmanAvainta
}

export interface TypeaheadAsiakas extends TypeaheadAsiakasIlmanAvainta {
  avain: string
}

export interface TypeaheadAsiakasIlmanAvainta {
  ytunnus: string
  nimi: string
}

export interface TypeaheadTuoteDokumentti {
  [avain: string]: TypeaheadTuoteIlmanAvainta
}

export interface TypeaheadTuote extends TypeaheadTuoteIlmanAvainta {
  avain: string
}

export interface TypeaheadTuoteIlmanAvainta {
  nimi: string
}

export enum LaskunSahkopostipohja {
  PERINTEINEN = 'lasku-perinteinen',
  JATKUVALASKUTUS_PERINTEINEN = 'lasku-perinteinen-jatkuva',
  MUISTUTUS_PERINTEINEN = 'lasku-maksumuistutus-perinteinen'
}

export interface Laskuasetukset {
  avain: string
  nimi: string
  ytunnus: string
  katuosoite: string
  postitmp: string
  postinro: string
  maakoodi: string
  date: Timestamp
  replyto: string
  puhelin: string

  piilotaYrityksenNimiLaskulla: boolean

  base64Logo: string
  pankki: string
  iban: string
  bic: string
  ohjeet: string
  slogan: string

  /** Objektin avain on kieli, katso TuettuKieli */
  spostiasetukset: { [avain in TuettuKieli]?: LaskuasetuksetSpostille }
  /** Objektin avain on kieli, katso TuettuKieli */
  muistutusSpostiasetukset: { [avain in TuettuKieli]?: LaskuasetuksetSpostille }

  summahuomautusPiilotettu: boolean
  tervetuloaNahty: boolean

  /** Onko maksuperusteinen automaattireskontra päällä? */
  maksupReskontraActive?: boolean
}

export interface LaskuasetuksetSpostille {
  kieli: TuettuKieli
  template: LaskunSahkopostipohja
  aihe: string
  otsikko: string
  teksti: string
}

export enum EmailLahetysStatusKoodi {
  PROSESSOIDAAN = 1,
  LAHETETTY_VASTAANOTTAJALLE = 2,
  LAHETYS_EPAONNISTUI = 3
}

export interface EmailLahetysStatus {
  status: EmailLahetysStatusKoodi
  email: string
  viesti: PostmarkBounceWebHookData
}

export interface KorjattavaEmailLahetysStatus extends EmailLahetysStatus {
  vanhaEmail: string
}

// Check https://postmarkapp.com/developer/webhooks/bounce-webhook
// The interface definition is from their nodejs package:
// https://github.com/wildbit/postmark.js/blob/master/src/client/models/bounces/Bounce.ts
// But because we use this model in front end also, we can't add the dependency.
export interface PostmarkBounceWebHookData {
  RecordType: string
  ID: number
  Type: string // One of https://postmarkapp.com/developer/api/bounce-api#bounce-types
  TypeCode: number // One of https://postmarkapp.com/developer/api/bounce-api#bounce-types
  Name: string
  Tag?: string
  MessageID: string
  ServerID: number
  Description: string
  Details: string
  Email: string
  From: string
  BouncedAt: string
  DumpAvailable: boolean
  Inactive: boolean
  CanActivate: boolean
  Subject: string
  Content?: string
}






















// export interface LaskuTilatPaivitaSummatTyojonoMerkinta extends TyojononMerkintaBase {
//   laskuAvain: string
//   avoinna: number
//   summa: number
//   valuutta: string
//   tila: LaskunTila
//   laskunPvm: LocalDate
// }

// // Doku osoitteessa laskut/{asiakasId}/summat/summat
// export interface LaskunSummatietoAsiakkaalleVuodet {
//   summat: { [vuosi: string]: LaskunSummatiedotAsiakkaalleVuosi }
// }

// // Doku osoitteessa laskut/{asiakasId}/summat/{summat_vuosi}
// export interface LaskunSummatietoAsiakkaalleVuodenDetaljit {
//   summatiedot: { [laskunAvain: string]: LaskunSummatieto }
// }

// export interface LaskunSummatiedotAsiakkaalleVuosi {
//   [valuutta: string]: LaskunSummatiedotAsiakkaalleValuutta
// }

// export interface LaskunSummatiedotAsiakkaalleValuutta {
//   avoinna
//   eraantynyt
//   laskutettu
//   luottotappiot
//   saatu
// }

// export interface LaskunSummatieto {
//   avoinna: number
//   summa: number
//   valuutta: string
//   tila: LaskunTila
// }

export interface AsiakkaanApixTiedot {
  apixKayttajanAvain: string
  lahetaSopimusAktiivinen: boolean
  lahetaOverrideSopimusAktiivinen: boolean
}

export interface Vuosikohtainen {
  vuosi: number
}

export interface Kuukausikohtainen extends Vuosikohtainen {
  kuukausi: number
}

export interface Paivakohtainen extends Kuukausikohtainen {
  paiva: number
}

export interface AsiakkaanLahetettyLasku extends Paivakohtainen {
  hakupvm: number
  lahetetty: Timestamp
  kanava: LaskunLahetystapa
  kasiteltavanrotyyppi: string
  kasiteltavaNro: string
  verotonsumma: number
  verollinensumma: number
  juurilaskuAvain: string
  juurilaskuNro: string
  kasiteltavaAvain: string
  laskunAsiakkaanAvain: string
  laskunLahetystyyppi: LaskunLahetystyyppi
}

export enum LaskunLahetystyyppi {
  JATKUVA = 'j',
  KERTA = 'k',
  PALKKAPALVELU = 'p'
}

export interface KirjanpitajanLahetettyLasku extends AsiakkaanLahetettyLasku {
  kirjanpitaja: string
}

export interface LaskuPostgresTyojonoData {
  asiakasId: string
  laskuAvain: string
  uudelleenyrityksia: number
  aloitettu: Timestamp
}
export interface LaskuTotalsPerUser {
  [key: string]: LaskuTotalsPerCurrency
}
export interface LaskuTotalsPerCurrency {
  [key: string]: LaskuEraantynytAvoinnaTotals
}
export interface LaskuEraantynytAvoinnaTotals {
  eraantynyt: number
  avoinna: number
}

export interface PeppolNumberResponse extends ErrorResponse<'unknown-error' | 'unauthorized' | 'no-data'> {
  matches: PeppolNumberResponseMatch[]
}

interface PeppolNumberResponseMatch {
  entities: PeppolNumberResponseEntity[]
}

export interface PeppolNumberResponseEntity {
  name: string | PeppolNameObject[]
  countryCode: string
}

export interface PeppolNameObject {
  name: string
}

export interface PeppolNumberRequest {
  osoite: string
}

export enum VatKoodit {

  /**
   * Vat Reverse Charge
   * Code specifying that the standard VAT rate is levied from the invoicee.
   */
  AE = 'AE',

  /**
   * Exempt from Tax
   * Code specifying that taxes are not applicable.
   */
  E = 'E',

  /**
   * Standard rate
   * Code specifying the standard rate.
   */
  S = 'S',

  /**
   * Zero rated goods
   * Code specifying that the goods are at a zero rate.
   */
  Z = 'Z',

  /**
   * Free export item, VAT not charged
   * Code specifying that the item is free export and taxes are not charged.
   */
  G = 'G',

  /**
   * Services outside scope of tax
   * Code specifying that taxes are not applicable to the services.
   */
  O = 'O',

  /**
   * VAT exempt for EEA intra-community supply of goods and services
   * A tax category code indicating the item is VAT exempt due to an intra-community supply in the European Economic Area.
   */
  K = 'K',

  /**
   * Canary Islands general indirect tax
   * Impuesto General Indirecto Canario(IGIC) is an indirect tax levied on goods and services supplied in the Canary Islands(Spain) by traders and professionals, as well as on import of goods.
   */
  L = 'L',

  /**
   * Tax for production, services and importation in Ceuta and Melilla
   * Impuesto sobre la Producción, los Servicios y la Importación(IPSI) is an indirect municipal tax, levied on the production, processing and import of all kinds of movable tangible property, the supply of services and the transfer of immovable property located in the cities of Ceuta and Melilla.
   */
  M = 'M',

  /**
   * Transferred(VAT), In Italy
   * VAT not to be paid to the issuer of the invoice but directly to relevant tax authority.This code is allowed in the EN 16931 for Italy only based on the Italian A - deviation.
   */
  B = 'B'

}
