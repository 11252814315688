<ng-template #loading>
  <div style="height: 100vh; width: 100%; padding: 0 20%; display: flex; align-items: center; justify-content: center;flex-direction: column;">
    <div>{{'yleiset.ladataan' | translate | async }}...</div>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>

<div *ngIf="nakymanAsetuksetObservable | async; let nakymanAsetukset else loading">
  <div class="list-page maksut list-loading" style="max-width: 1050px;" *ngIf="nakymanAsetukset.naytaListaus else mainos">
    <div class="topButtons">
      <div class="search">
        <form [formGroup]="hakuFormGroup">
          <mat-form-field>
            <mat-icon matPrefix class="listaus-haku-ikoni">search</mat-icon>
            <mat-label>{{ 'laskut.etsi' | translate | async }}</mat-label>
            <input type="text" [name]="name" formControlName="vapaahaku" matInput />
          </mat-form-field>
        </form>
      </div>

      <mat-tab-group style="margin-top: 2px;" animationDuration="0ms" [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="tabChanged($event)">
        <mat-tab [label]="'maksut.avoimet' | translate | async"> </mat-tab>
        <mat-tab [label]="'maksut.maksetut' | translate | async"> </mat-tab>
      </mat-tab-group>
      <div class="addButton" style="padding-top: 7px;">
        <button *ngIf="nakymanAsetukset.naytaLisaaButton" mat-button (click)="naytaTiedostonValintaDialogi()" type="button" class="primarybutton mat-elevation-z4">
          <mat-icon>add</mat-icon>
          {{'maksut.lisaa' | translate | async}}
        </button>
      </div>
    </div>

    <form [formGroup]="listFormGroup">

      <div *ngIf="nakymanAsetukset.naytaLaskujenVastaanottoEiPaallaTeksti" class="warning-msg red-text">{{'maksut.et-vastaanota-laskuja' | translate | async}}<span (click)="navigateToSettings()" class="link-like">{{'maksut.tasta' | translate | async}}.</span></div>
      <!-- <div class="hakukontrollit">
  
        <mat-form-field floatPlaceholder="never">
          <mat-label>{{ 'maksut.nayta' | translate | async }}</mat-label>
<mat-select  formControlName="tila">
            <mat-option *ngFor="let tila of laskunTilatObservable | async" [value]="tila.tunnus">
              {{ tila.nimi }}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field>
          <mat-icon matPrefix class="listaus-haku-ikoni">search</mat-icon>
          <mat-label>{{ 'maksut.etsi' | translate | async }}</mat-label>
<input type="text" [name]="name" formControlName="nimiFirestore"  matInput />
        </mat-form-field>
  
        <mat-form-field>
          <mat-label>{{ 'maksut.rajaa-kuukauden-mukaan' | translate | async }}</mat-label>
<input matInput [name]="name" [matDatepicker]="dp"  formControlName="vuosikk">
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp startView="year" (monthSelected)="chosenMonthHandler($event, dp)" panelClass="kuukausi-valitsin"></mat-datepicker>
        </mat-form-field>
  
      </div> -->

      <!-- </form> -->

      <div *ngIf="avoinnaTo || avoinnaTo == 0" class="summatContainer">
        <div *ngIf="nakymanAsetukset.valittuTab === 'avoimet'" class="summat">
          <div *ngIf="latestBankBalanceObservable | async; let latestBankBalance">
            <!-- <div>{{tilinSaldoFrom}} -> {{tilinSaldoTo}}</div> -->
            <div class="otsikko">{{'maksut.summat.tilin-saldo' | translate | async}}</div>
            <div class="maara" appCountTo [step]="16.667" [countTo]="latestBankBalance.balance" [countFrom]="tilinSaldoFrom" [duration]="1" (countToChange)="countToTilinSaldo = $event" (countToEnd)="onCountToEnd()">{{ countToTilinSaldo | lmoney: valuutta | async}}</div>
            <div class="kommentti">{{ latestBankBalance.iban }}<br />{{'maksut.summat.paivitetty' | translate | async}} {{ latestBankBalance.fetchTime | ltimestamptime | async }}</div>
          </div>
          <div>
            <!-- <div>{{avoinnaFrom}} -> {{avoinnaTo}}</div> -->
            <div class="otsikko">{{'maksut.summat.avoinna' | translate | async}}</div>
            <div class="maara blue-text" appCountTo [step]="16.667" [countTo]="avoinnaTo" [countFrom]="avoinnaFrom" [duration]="1" (countToChange)="countToAvoinna = $event" (countToEnd)="onCountToEnd()">{{ countToAvoinna | lmoney: valuutta | async}}</div>
          </div>
          <div>
            <!-- <div>{{eraantynytFrom}} -> {{eraantynytTo}}</div> -->
            <div class="otsikko">{{'maksut.summat.eraantynyt' | translate | async}}</div>
            <div class="maara red-text" [class.red-text]="eraantynytTo != 0" [class.green-text]="eraantynytTo == 0" appCountTo [step]="16.667" [countTo]="eraantynytTo" [countFrom]="eraantynytFrom" [duration]="1" (countToChange)="countToEraantynyt = $event" (countToEnd)="onCountToEnd()">
              {{countToEraantynyt | lmoney: valuutta | async}}</div>
          </div>
          <!-- <div class="huomautukset">
          <span *ngIf="naytaHuomautus" style="margin-left: 2em;" (click)="avaaHuomautus()">{{'maksut.summat.huomautus' | translate | async}}</span>
        </div> -->
        </div>
      </div>

      <div style="max-width: 800px; margin: 0 auto;">
        <div style="position: relative; min-height: 250px; overflow-x: auto; margin: 0 5px; text-align: center;">
          <table class="maksut-table" *ngIf="nakyvatRivitObservable | async let nakyvatRivit" matSort [matSortDisableClear]="true" style="border-spacing: 0; margin: 0 auto; text-align: left;">
            <thead>
              <tr class="mat-mdc-header-row">
                <th class="mat-mdc-header-cell mat-column-valinta" *ngIf="nakymanAsetukset.viewType !== 'vastaanotto'">
                  <mat-checkbox #selectAllCheckbox *ngIf="nakymanAsetukset.valittuTab === 'avoimet' && nakymanAsetukset.viewType === 'maksaminen'" (change)="maksuAllSelectionChanged(nakyvatRivit, $event)">
                  </mat-checkbox>
                </th>
                <!-- mat-sort-header="tila" -->
                <!-- mat-sort-header="asiakas" -->
                <!-- mat-sort-header="sum" -->
                <th class="mat-mdc-header-cell mat-column-tila"> {{'maksut.tila' | translate | async}} </th>
                <th class="mat-mdc-header-cell mat-column-asiakas"> {{'maksut.maksun-saaja' | translate | async}} </th>
                <th mat-sort-header="dueDate" class="mat-mdc-header-cell mat-column-erapvm"> {{'maksut.erapaiva' | translate | async}} </th>
                <th class="mat-mdc-header-cell mat-column-summa align-header-right"> {{'maksut.summa' | translate | async}} </th>
              </tr>
            </thead>
            <tbody>
              <!-- <ng-container *ngFor="let row of nakyvatRivitObservable | async"> -->
              <!-- [class.error]="row.virhe" -->
              <tr *ngFor="let row of nakyvatRivit" class="mat-mdc-row vanhempi" [class.has-change]="row.maksunsaajaChanged || row.erapaivaChanged || row.summaChanged">
                <td class="mat-mdc-cell mat-column-valinta" *ngIf="nakymanAsetukset.viewType !== 'vastaanotto'">
                  <!-- [class.blue-text]="row.vari == 'blue'" [class.yellow-text]="row.vari == 'yellow'" [class.green-text]="row.vari == 'green'" [class.red-text]="row.vari == 'red'" [class.purple-text]="row.vari == 'purple'" [class.mint-text]="row.vari == 'mint'" -->
                  <div [matTooltip]="row.voidaanValita ? null : ('maksut.katsele.odottaa-hyvaksyntaa' | translate | async)" style="display: flex;">
                    <mat-checkbox *ngIf="nakymanAsetukset.viewType === 'maksaminen' && row.naytaValinta" [disabled]="!row.voidaanValita" [checked]="row.valittu" (change)="maksuSelectionChanged(row, nakyvatRivit, $event)" style="margin-top: -4px; display: inline-flex;">
                    </mat-checkbox>
                  </div>
                </td><!-- [class.purple-text]="row.vari == 'purple'" [class.yellow-text]="row.vari == 'yellow'" -->
                <td class="mat-mdc-cell mat-column-tila" (click)="katsele(row)" [class.blue-text]="row.paymentStatus === PaymentStatus.AVOIN" [class.green-text]="row.paymentStatus === PaymentStatus.MAKSETTU || row.paymentStatus == PaymentStatus.MAKSETTU_TOISAALLA" [class.red-text]="row.paymentStatus === PaymentStatus.ERAANTYNYT || row.paymentStatus === PaymentStatus.PANKKI_HYLKASI"
                  [class.mint-text]="row.paymentStatus == PaymentStatus.MAKSUSSA">
                  {{'maksut.tilat.' + row.paymentStatus | translate | async}}
                  <span *ngIf="row.virhe" [matTooltip]="row.virheviesti" style="text-align: center; margin-left: 5px; font-size: 32px; font-weight: bold; vertical-align: middle; padding-right: 4px; border-bottom: none;">
                    <mat-icon>info_outline</mat-icon>
                  </span>
                </td>
                <td class="mat-mdc-cell mat-column-asiakas" (click)="katsele(row)">
                  <span [class.strike]="row.maksunsaajaChanged">{{row.seller}}</span>
                  <ng-container *ngIf="row.maksunsaajaChanged">
                    <br /><span>{{row.maksutiedot.maksunSaajanNimi}}</span>
                  </ng-container>
                </td>
                <td class="mat-mdc-cell mat-column-erapvm" (click)="katsele(row)">
                  <span [class.strike]="row.erapaivaChanged">{{row.dueDate | lnumberpvm | async}}</span>
                  <ng-container *ngIf="row.erapaivaChanged">
                    <br /><span>{{row.maksutiedot.dueDate | lnumberpvm | async}}</span>
                  </ng-container>
                </td>
                <td class="mat-mdc-cell mat-column-summa" (click)="katsele(row)">
                  <span [class.strike]="row.summaChanged">{{row.sum | lmoney: row.currency ?? 'EUR' | async}}</span>
                  <ng-container *ngIf="row.summaChanged">
                    <br /><span>{{row.maksutiedot.amount | lmoney: row.currency ?? 'EUR' | async}}</span>
                  </ng-container>
                </td>
              </tr>
              <!-- <tr class="lapsi" *ngIf="row.virhe">
                  <td *ngIf="nakymanAsetukset.viewType !== 'vastaanotto'"></td>
                  <td colspan="4" class="mat-mdc-cell red-text">
                    {{ 'maksut.maksu-hylkaamisen-syy' | translate | async }}: {{ ('maksut.maksu-epaonnistui-' + (row.paymentRejection?.reason ?? '1')) | translate | async }}
                    {{ row.paymentRejection?.reason === PaymentRejectionReason.UNKNOWN ? '(' + row.paymentRejection.rawText + ')' : ''}}
                  </td>
                </tr>
              </ng-container> -->
            </tbody>
            <tfoot>
              <tr class="mat-mdc-footer-row dark-gray-text" *ngFor="let rivi of yhteensaRivitObservable | async">
                <td *ngIf="nakymanAsetukset.viewType !== 'vastaanotto'" class="mat-mdc-footer-cell" style="border-bottom-color: transparent;">&nbsp;</td>
                <td class="mat-mdc-footer-cell mat-column-asiakas" style="border-bottom-color: transparent; text-align: right; padding-right: 10px;" colspan="3">Näkyvät rivit yhteensä:</td>
                <td class="mat-mdc-footer-cell mat-column-summa" style="border-bottom-color: transparent;"> {{rivi.yhteensa | lmoney: rivi.valuutta | async}} </td>
              </tr>
            </tfoot>
          </table>
          <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex; justify-items: center; align-items: center;" *ngIf="lataaObservable | async">
            <div lemon-loading [config]="loadingSettings" [show]="true"></div>
          </div>
          <div class="table-ladataan" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; text-align: center; display: flex; justify-content: center; align-items: center;" *ngIf="naytaEiYhtaanObservable | async">
            <ng-container>
              <div *ngIf="nakymanAsetukset.valittuTab === 'avoimet'" class="gray-text">
                <i class="icon ion-ios-checkmark-circle-outline" style="font-size: 125px;"></i><br />
                {{'maksut.hakuehdoilla-ei-yhtaan-tulosta' | translate | async}}
              </div>
              <div *ngIf="nakymanAsetukset.valittuTab === 'maksetut'">{{'maksut.hakuehdoilla-ei-yhtaan-tulosta-historia' | translate | async}}</div>
            </ng-container>
          </div>
        </div>
        <div *ngIf="kokonaismaaraObservable | async let maaraObject" style="overflow-x: auto; margin: 0 5px;">
          <mat-paginator #paginator="matPaginator" [ngStyle]="{ 'display': maaraObject.maara < dataSource?.page?.pageSize ? 'none' : 'initial' }" [length]="maaraObject.maara" [pageSize]="dataSource?.page?.pageSize" [pageIndex]="dataSource?.page?.pageIndex" [pageSizeOptions]="[100, 200]"></mat-paginator>
          <div style="text-align: right; font-size: 80%; color: rgba(0, 0, 0, 0.54); margin-right: 25px;" *ngIf="maaraObject.maara > 0">
            {{ paginator.pageSize * paginator.pageIndex + 1 }} - {{ paginator.pageSize * (paginator.pageIndex + 1) > maaraObject.maara ? maaraObject.maara : paginator.pageSize * (paginator.pageIndex + 1) }} / {{ maaraObject.maara }}
          </div>
        </div>
      </div>

      <div *ngIf="valittujenTositteidenMaaraObservable | async" style="position: sticky; bottom: 5px; display: flex;">
        <div class="mat-elevation-z3" style="border-radius: 25px; padding: 15px 15px; padding-top: 0; margin-left: auto; margin-right: auto; background-color: white;">
          <div style="display: flex; align-items: center;">
            <div class="summat" style="margin: 10px; flex-flow: column; width: unset; padding: unset;">
              <div class="otsikko" style="margin: 0">{{ valittujenTositteidenMaaraObservable | async }} {{'maksut.laskua-yhteensa' | translate | async}}</div>
              <div class="maara" style="margin: 0">{{ valittujenTositteidenSummaObservable | async | lmoney: 'EUR' | async }}</div>
            </div>
            <button style="margin: 10px; margin-top: 26px; padding-left: 18px" class="primarybutton" (click)="maksaErapaivana()" mat-button>{{ 'maksut.maksa-erapaivana' | translate | async }}</button>
            <button style="margin: 10px; margin-top: 26px;" class="secondarybutton" (click)="maksaHeti()" mat-button>{{ 'maksut.maksa-heti' | translate | async }}</button>
          </div>
          <ng-container *ngIf="paymentConfigsObservable | async; let paymentConfigs">
            <div *ngIf="paymentConfigs?.length > 1" style="display: flex; align-items: center; justify-content: space-between;">
              <div style="margin-right: 10px;">{{'maksut.maksutili' | translate | async}}:</div>
              <div>
                <mat-select formControlName="config" [compareWith]="compareConfigs">
                  <mat-option *ngFor="let config of paymentConfigs" [value]="config">{{config.pankki}} {{config.iban}}</mat-option>
                </mat-select>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </form>

  </div>
</div>

<ng-template #mainos>
  <div app-maksut-mainos></div>
</ng-template>

<input style="visibility:hidden; height: 1px;" (change)="fileChanged($event)" type="file" #fileInput multiple="multiple" />
<!-- accept="image/*,application/pdf" -->