import { Component, OnInit, OnDestroy, Input, ErrorHandler } from '@angular/core'
import { FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms'

import { LocalDateTime, Timestamp } from 'app/_shared-core/model/common'
import { Tilikausi } from 'app/_jaettu/model/kayttaja'
import {
  DownloadYhtiokokouksenPoytakirjaPdfRequest,
  DownloadYhtiokokouksenPoytakirjaPdfResponse,
  SaveYhtiokokouksenPoytakirjaDataRequest,
  SaveYhtiokokouksenPoytakirjaDataResponse,
  SignYhtiokokousRequest,
  SignYhtiokokousResponse,
  TilinpaatosMetadata,
  TilinpaatosOsakasUser,
  TilinpaatosSigningData,
  TilinpaatosUserData,
  YhtiokokouksenPoytakirjaData,
  YhtiokokousParticipant,
  YhtiokokousRole
} from 'app/_jaettu/model/tilinpaatos'

import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { TilinpaatosUriService } from 'app/_jaettu/service/tilinpaatos-uri.service'

import { Observable, Subject, switchMap, of as observableOf, map, takeUntil, combineLatest, firstValueFrom, BehaviorSubject } from 'rxjs'
import { ImageCacheClass, ImageCacheClassPdfiumRenderer, ImageCacheClassPreRendered } from './tilinpaatokset.component'

import { PdfService } from 'app/_angular/service/pdf.service'
import { DocumentSnapshot } from 'firebase/firestore'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { MatDialog } from '@angular/material/dialog'
import { LemonaidValidators } from 'app/_angular/_validator/LemonaidValidators'
import { FormValidators } from 'app/_jaettu-angular/_validators/FormValidators'

import { PoytakirjaSignedDialog, PoytakirjaSignedDialogData } from './poytakirja-signed.dialog'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { DomSanitizer } from '@angular/platform-browser'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

interface YhtiokokousUserWithFullName extends Pick<TilinpaatosOsakasUser, 'lemonaidUid' | 'osakkeita'> {
  name: string
}
interface YhtiokokousParticipantPlusSigningStatus {
  name: string
  rolesLocalized: string
  signedAt: Timestamp
  isCurrentUser: boolean
}

interface MainForm {
  meetingDate: FormControl<Date>
  meetingHours: FormControl<number>
  meetingMinutes: FormControl<number>
  kokouspaikka: FormControl<string>
  poytakirjanNumero: FormControl<string>
  lasnaolijat: FormArray<FormGroup<LasnaolijaFormGroup>>
  kokouksenAvasi: FormControl<string>
  puheenjohtaja: FormControl<YhtiokokousUserWithFullName>
  // sihteeri: FormControl
  poytakirjanTarkastaja: FormControl<YhtiokokousUserWithFullName>
  osinkojaJaetaan: FormControl<boolean>
}

interface LasnaolijaFormGroup {
  selected: FormControl<boolean>
  name: FormControl<string>
  osakkeita: FormControl<number>
  lemonaidUid: FormControl<string>
}

interface ViewData {
  tilintarkastusDone: boolean
  tilinpaatosSigned: boolean
  ptkirjaMissingSignatures: boolean
  mode: 'loading' | 'view' | 'edit'
  pdfPages: ImageCacheClass[]
  startPdfImageRendering: () => Promise<void>
}

@Component({
  selector: 'app-reports-yhtiokokouksen-poytakirja',
  templateUrl: './yhtiokokouksen-poytakirja.component.html',
  styleUrls: ['./tilinpaatokset.component.css']
})
export class YhtiokokouksenPoytakirjaComponent implements OnInit, OnDestroy {

  @Input() selectedTilikausiObservable: Observable<Tilikausi>
  @Input() tilinpaatosUsersObservable: Observable<TilinpaatosUserData>
  @Input() tilinpaatosSnapshotObservable: Observable<{ tilikausi: Tilikausi, snap: DocumentSnapshot<TilinpaatosMetadata> }>

  commonError: string
  selectedParticipants: YhtiokokousUserWithFullName[] = []

  viewDataObservable: Observable<ViewData>
  // tilintarkastusTehtyObservable: Observable<{ done: boolean }>
  // tilinpaatosSignedObservable: Observable<{ signed: boolean }>
  // poytakirjaMissingSignaturesObservable: Observable<boolean>
  osinkojaJaetaanYhteensaObservable: Observable<string>

  private _poytakirja: YhtiokokouksenPoytakirjaData
  private _meetingTimeAndDate: LocalDateTime
  signers: YhtiokokousParticipantPlusSigningStatus[] = []

  private _editingPoytakirjaSubject: BehaviorSubject<'loading' | 'view' | 'edit'> = new BehaviorSubject('loading')
  private _poytakirjaPreviewPdfAsBase64Observable: Observable<{ base64: string, tilikausi: Tilikausi }>

  private _yhtiokokousUsersObservable: Observable<YhtiokokousUserWithFullName[]>
  yhtiokokousUsers: YhtiokokousUserWithFullName[]

  private _saveStarted: boolean = false

  usersWithoutLemonaidAccountSelected: boolean

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  form: FormGroup<MainForm>

  constructor(
    private _tilinpaatosUriService: TilinpaatosUriService,
    private _validationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _dateService: DateService,
    private _errorHandler: ErrorHandler,
    private _translationService: LemonTranslationService,
    private _kayttajaService: KayttajaService,
    private _pdfService: PdfService,
    private _currencyService: CurrencyService,
    private _dialog: MatDialog,
    private _domSanitizer: DomSanitizer,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _fileSaverService: FileSaverService
  ) { }

  private _isTilinpaatosSigned(signaturesData: TilinpaatosSigningData): boolean {
    if (!signaturesData?.signs?.length) {
      return false
    }
    return signaturesData.signs.filter(signer => !signer.signedAt).length === 0
  }

  private _isPoytakirjaMissingSignatures(poytakirja: YhtiokokouksenPoytakirjaData): boolean {
    if (!poytakirja?.signs?.length) {
      return true
    }
    return poytakirja.signs.filter(sign => !sign.signedAt).length > 0
  }

  private _isTilintarkastusDone(tilinpaatos: TilinpaatosMetadata): boolean {
    if (!tilinpaatos) {
      return true
    }
    if (tilinpaatos.tilintarkastetaan && !tilinpaatos.tilintarkastusTehty) {
      return false
    }
    return true
  }

  ngOnInit() {

    // this.tilintarkastusTehtyObservable = this.tilinpaatosSnapshotObservable.pipe(
    //   map(snap => {
    //     const data = snap?.data()
    //     if (!data) {
    //       return null
    //     }
    //     if (data.tilintarkastetaan && !data.tilintarkastusTehty) {
    //       return { done: false }
    //     }
    //     return { done: true }
    //   })
    // )

    const tilinpaatosSignaturesObservable: Observable<{ snap: DocumentSnapshot<TilinpaatosSigningData> }> = combineLatest([this.tilinpaatosSnapshotObservable, this._kayttajaService.kayttajanTiedotObservable]).pipe(
      switchMap(([snap, kayttaja]) => {

        // If we don't have the snap (data was not loaded from database), return null
        if (!snap?.snap || !snap?.tilikausi || !kayttaja) {
          return observableOf<{ snap: DocumentSnapshot<TilinpaatosSigningData> }>(null)
        }

        // If we have the snap, but no data, send and object containing null as snap.
        // This is needed to distinguish the situation where we are still loading vs not 
        // signed down the line to not confuse our loading indicators, so do NOT "optimize" this away...
        const metadata = snap.snap.data()
        if (!metadata) {
          return observableOf<{ snap: DocumentSnapshot<TilinpaatosSigningData> }>({ snap: null })
        }

        const uri = this._tilinpaatosUriService.getTilinpaatosSignaturesDocUri(kayttaja.asiakasAvain, metadata.viimeisinTilinpaatosVersioAvain)
        return this._firebaseLemonaid.firestoreDoc<TilinpaatosSigningData>(uri).listenSnap().pipe(
          map(snap => {
            return { snap: snap }
          })
        )
      })
    )

    // this.tilinpaatosSignedObservable = tilinpaatosSignaturesObservable.pipe(
    //   map(signingDataDoc => {
    //     if (!signingDataDoc) {
    //       return null
    //     }
    //     const signingData = signingDataDoc.data()
    //     if (!signingData?.signs?.length) {
    //       return { signed: false }
    //     }
    //     return { signed: signingData.signs.filter(signer => !signer.signedAt).length === 0 }
    //   })
    // )

    const poytakirjaObservable: Observable<{ tilikausi: Tilikausi, snap: DocumentSnapshot<YhtiokokouksenPoytakirjaData> }> = combineLatest([this._kayttajaService.kayttajanTiedotObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([kayttaja, tilikausi]) => {
        if (!kayttaja || !tilikausi) {
          return observableOf(null)
        }
        const uri = this._tilinpaatosUriService.getYhtiokokouksenPoytakirjaDocUri(kayttaja.asiakasAvain, tilikausi.avain)
        return this._firebaseLemonaid.firestoreDoc<YhtiokokouksenPoytakirjaData>(uri).listenSnap().pipe(
          map(snap => {
            return {
              snap: snap,
              tilikausi: tilikausi
            }
          })
        )
      })
    )

    this._poytakirjaPreviewPdfAsBase64Observable = combineLatest([poytakirjaObservable, this._translationService.currentLanguageObservable]).pipe(
      switchMap(([poytakirjaData, lang]) => {
        if (!poytakirjaData || !poytakirjaData.tilikausi) {
          return observableOf<{ tilikausi: Tilikausi, base64: string }>(null)
        }
        const poytakirja = poytakirjaData.snap?.data()
        if (!poytakirja || !poytakirja.avain || !lang) {
          return observableOf<{ tilikausi: Tilikausi, base64: string }>({ tilikausi: poytakirjaData.tilikausi, base64: null })
        }
        const req: DownloadYhtiokokouksenPoytakirjaPdfRequest = {
          pdfAvain: poytakirja.finalPdfAvain ? poytakirja.finalPdfAvain : poytakirja.avain,
          tilikausiAvain: poytakirja.tilikausi.avain,
          kieli: lang
        }
        return this._firebaseLemonaid.functionsCall<DownloadYhtiokokouksenPoytakirjaPdfRequest, DownloadYhtiokokouksenPoytakirjaPdfResponse>('yhtiokokousPdfDownload', req).then(resp => {
          if (!resp) {
            return { tilikausi: poytakirjaData.tilikausi, base64: null }
          }
          if (resp.e) {
            this._errorHandler.handleError(new Error('Yhtiökokous pöytäkirja PDF preview download failed! ' + resp.e))
            return { tilikausi: poytakirjaData.tilikausi, base64: null }
          }
          return { tilikausi: poytakirjaData.tilikausi, base64: resp.base64Pdf }
        })
      })
    )

    const pdfPagesObservable: Observable<{ pages: ImageCacheClass[], tilikausi: Tilikausi }> = this._poytakirjaPreviewPdfAsBase64Observable.pipe(
      switchMap(async base64Data => {
        if (!base64Data || !base64Data.tilikausi) {
          return null
        }
        if (!base64Data.base64) {
          return { pages: [], tilikausi: base64Data.tilikausi }
        }
        const arrayBuffer = this._fileSaverService.base64StringToUint8Array(base64Data.base64)
        const openData = await this._pdfService.isPdfOpenableRenderAllToObjectUrl(arrayBuffer, 720)
        if (openData?.success) {
          const sivut: ImageCacheClass[] = []
          for (const renderedPage of openData.renderedPages) {
            // Create firestore tosite image metadata
            sivut.push(new ImageCacheClassPreRendered(this._domSanitizer.bypassSecurityTrustUrl(renderedPage) as string))
          }
          return { pages: sivut, tilikausi: base64Data.tilikausi }
        }
        return { pages: [], tilikausi: base64Data.tilikausi }
      })
    )

    this.viewDataObservable = combineLatest([this.tilinpaatosSnapshotObservable, tilinpaatosSignaturesObservable, this._editingPoytakirjaSubject, pdfPagesObservable, poytakirjaObservable]).pipe(
      switchMap(async ([tilinpaatosMetadataSnapshot, tilinpaatosSignaturesSnapshot, editMode, pdfData, poytakirjaData]) => {

        if (
          !tilinpaatosMetadataSnapshot ||
          !tilinpaatosMetadataSnapshot.tilikausi ||
          !tilinpaatosMetadataSnapshot.snap ||
          !pdfData ||
          !tilinpaatosSignaturesSnapshot ||
          !poytakirjaData ||
          pdfData.tilikausi.avain !== tilinpaatosMetadataSnapshot.tilikausi.avain
        ) {
          return null
        }

        const ptkirjaData = poytakirjaData.snap?.data()
        const hasForceEditModeOn = !!ptkirjaData?.forceEditMode

        const tilinpaatosMetadata = tilinpaatosMetadataSnapshot.snap.data()
        const signaturesData = tilinpaatosSignaturesSnapshot?.snap?.data()

        const viewData: ViewData = {
          mode: hasForceEditModeOn ? 'edit' : editMode,
          tilinpaatosSigned: this._isTilinpaatosSigned(signaturesData),
          tilintarkastusDone: this._isTilintarkastusDone(tilinpaatosMetadata),
          ptkirjaMissingSignatures: this._isPoytakirjaMissingSignatures(ptkirjaData),
          pdfPages: pdfData.pages,
          startPdfImageRendering: null
          // startPdfImageRendering: async () => {
          //   await this._sleep(50)
          //   for (const page of pdfPages) {
          //     await page.startImageRendering()
          //     await this._sleep(50)
          //   }
          // }
        }
        // await viewData.startPdfImageRendering()
        let renderHappened = false
        // await this._sleep(50)
        for (const page of pdfData.pages) {
          const renderedSomething = await page.startImageRendering()
          if (renderedSomething) {
            renderHappened = true
            await this._sleep(50)
          }
        }
        if (renderHappened) {
          await this._sleep(750)
        }
        return viewData
      })
    )

    this.form = new FormGroup<MainForm>({
      'meetingDate': new FormControl<Date>(null, Validators.required),
      'meetingHours': new FormControl<number>(null, Validators.required),
      'meetingMinutes': new FormControl<number>(null, Validators.required),
      'kokouspaikka': new FormControl<string>(null, Validators.required),
      'poytakirjanNumero': new FormControl<string>(null, Validators.required),
      'lasnaolijat': new FormArray<FormGroup<LasnaolijaFormGroup>>([], LemonaidValidators.vahintaanYksiArrayssaValidator),
      'kokouksenAvasi': new FormControl<string>(null, Validators.required),
      'puheenjohtaja': new FormControl<YhtiokokousUserWithFullName>(null, Validators.required),
      // 'sihteeri': new FormControl(null, Validators.required),
      'poytakirjanTarkastaja': new FormControl<YhtiokokousUserWithFullName>(null, Validators.required),
      'osinkojaJaetaan': new FormControl<boolean>(null, Validators.required)
    })

    this.meetingDate.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(val => {
      if (val) {
        this._checkMeetingTimeExists()
        const asLocalDate = this._dateService.dateToLocalDate(val)
        this._meetingTimeAndDate.year = asLocalDate.year
        this._meetingTimeAndDate.month = asLocalDate.month
        this._meetingTimeAndDate.day = asLocalDate.day
      }
    })

    this.meetingHours.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => {
      this._checkMeetingTimeExists()
      this._meetingTimeAndDate.hour = val
    })

    this.meetingMinutes.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => {
      this._checkMeetingTimeExists()
      this._meetingTimeAndDate.minutes = val
    })

    this.kokouspaikka.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => this._poytakirja ? this._poytakirja.kokouspaikka = val : null)
    this.poytakirjanNumero.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => this._poytakirja ? this._poytakirja.poytakirjanNumero = val : null)
    this.kokouksenAvasi.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => this._poytakirja ? this._poytakirja.kokouksenAvasi = val : null)
    this.puheenjohtaja.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => this._poytakirja ? this._poytakirja.puheenjohtaja = val?.name : null)
    // this.sihteeri.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => this._poytakirja ? this._poytakirja.sihteeri = val : null)
    this.poytakirjanTarkastaja.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => this._poytakirja ? this._poytakirja.poytakirjanTarkastaja = val?.name : null)
    this.osinkojaJaetaan.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => this._poytakirja ? this._poytakirja.osinkojaJaetaan = val : null)

    this._yhtiokokousUsersObservable = this.tilinpaatosUsersObservable.pipe(
      map(userData => {
        const output: YhtiokokousUserWithFullName[] = []
        for (const user of userData?.osakkaatUsers || []) {
          const userWithFullName: YhtiokokousUserWithFullName = {
            name: user.etunimi + ' ' + user.sukunimi,
            osakkeita: user.osakkeita,
            lemonaidUid: user.lemonaidUid
          }
          output.push(userWithFullName)
        }
        return output
      })
    )

    // Set default values during first load
    this._yhtiokokousUsersObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(users => {
      this.lasnaolijatFormArray.clear()
      if (!users?.length) {
        return
      }
      this.yhtiokokousUsers = users
      this.selectedParticipants = []
      for (const user of users) {
        if (user.lemonaidUid) {
          this.selectedParticipants.push(user)
        }
        const group = this.createLasnaolijatFormGroup(user)
        this.lasnaolijatFormArray.push(group)
      }
    })

    tilinpaatosSignaturesObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(snap => {
      const tilinpaatosSignatures = snap?.snap?.data()

      if (tilinpaatosSignatures?.signs?.length) {
        const latestSign = Math.max(...tilinpaatosSignatures.signs.map(s => s.signedAt?.toMillis() ?? 4102437599000)) // Fallback is 31.12.2099
        const latestSignMinusOneDay = this._dateService.lisaaPaivia(new Date(latestSign), -1)
        this.meetingDate.setValidators([FormValidators.minDateValidator(latestSignMinusOneDay), Validators.required])
      } else {
        this.meetingDate.setValidators(Validators.required)
      }
    })


    combineLatest([poytakirjaObservable, this._kayttajaService.kayttajanTiedotObservable, this.selectedTilikausiObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([poytakirjaData, currentUser, selectedTilikausi]) => {

      if (!currentUser || !selectedTilikausi || !poytakirjaData?.snap) {
        this.signers = []
        this._poytakirja = null
        this._editingPoytakirjaSubject.next('loading')
        return
      }

      const poytakirja = poytakirjaData.snap.data()
      if (poytakirja) {
        const signs = poytakirja.signs ?? []
        this._editingPoytakirjaSubject.next(signs.length ? 'view' : 'edit')
        this._poytakirja = poytakirja
        this.signers = signs.map(signer => {
          const withExtraData: YhtiokokousParticipantPlusSigningStatus = {
            isCurrentUser: signer.user.lemonaidUid === currentUser.uid,
            rolesLocalized: signer.user.roles?.map(role => this._translationService.lokalisoi('reports-lemonaid.' + role)).join('\n') ?? '',
            signedAt: signer.signedAt,
            name: signer.user.name
          }
          return withExtraData
        })
      } else {
        this._poytakirja = this._createEmptyPoytakirja(selectedTilikausi)
        this._editingPoytakirjaSubject.next('edit')
      }

      if (this._poytakirja.dateAndTime) {
        this._meetingTimeAndDate = this._dateService.numberTimestampToLocalDateTime(this._poytakirja.dateAndTime)
        this.meetingDate.setValue(this._dateService.numberTimestampToDate(this._poytakirja.dateAndTime))
        this.meetingHours.setValue(this._meetingTimeAndDate.hour)
        this.meetingMinutes.setValue(this._meetingTimeAndDate.minutes)
      } else {
        this.meetingDate.setValue(null)
        this.meetingHours.setValue(null)
        this.meetingMinutes.setValue(null)
      }

      this.kokouspaikka.setValue(this._poytakirja.kokouspaikka)
      this.poytakirjanNumero.setValue(this._poytakirja.poytakirjanNumero)
      this.kokouksenAvasi.setValue(this._poytakirja.kokouksenAvasi)
      this.puheenjohtaja.setValue(this.selectedParticipants?.find(participants => participants.name === this._poytakirja.puheenjohtaja) || null)
      // this.sihteeri.setValue(poytakirja.sihteeri)
      this.poytakirjanTarkastaja.setValue(this.selectedParticipants?.find(participants => participants.name === this._poytakirja.poytakirjanTarkastaja) || null)
      this.osinkojaJaetaan.setValue(this._poytakirja.osinkojaJaetaan)

      this._tryRadioButtonAutoselect(this.selectedParticipants)

    })

    this.osinkojaJaetaanYhteensaObservable = this.tilinpaatosSnapshotObservable.pipe(
      map(snap => {
        const data = snap?.snap?.data()
        // Ignore if dividend sum is 0
        if (data?.osinkojaYhteensa) {
          return this._currencyService.formatoiRahaIlmanValuuttaSymbolia(data.osinkojaYhteensa, 'fi')
        }
        return null
      })
    )
  }

  protected _sleep(millis: number): Promise<any> {
    return new Promise(resolve => {
      setTimeout(resolve, millis)
    })
  }

  private _checkMeetingTimeExists() {
    if (!this._meetingTimeAndDate) {
      const asLocalDate = this._dateService.dateToLocalDate(this.meetingDate.value ?? new Date())
      this._meetingTimeAndDate = {
        year: asLocalDate.year,
        month: asLocalDate.month,
        day: asLocalDate.day,
        hour: this.meetingHours?.value ?? null,
        minutes: this.meetingMinutes?.value ?? null,
        seconds: 1
      }
    }
  }

  get meetingDate() {
    return this.form.get('meetingDate')
  }

  get meetingHours() {
    return this.form.get('meetingHours')
  }

  get meetingMinutes() {
    return this.form.get('meetingMinutes')
  }

  get kokouspaikka() {
    return this.form.get('kokouspaikka')
  }

  get poytakirjanNumero() {
    return this.form.get('poytakirjanNumero')
  }
  get lasnaolijatFormArray(): FormArray<FormGroup<LasnaolijaFormGroup>> {
    return this.form.get('lasnaolijat') as FormArray<FormGroup<LasnaolijaFormGroup>>
  }
  get lasnaolijatFormGroupit(): FormGroup<LasnaolijaFormGroup>[] {
    return this.lasnaolijatFormArray.controls
  }

  get kokouksenAvasi() {
    return this.form.get('kokouksenAvasi')
  }

  get puheenjohtaja() {
    return this.form.get('puheenjohtaja')
  }

  // get sihteeri() {
  //   return this.form.get('sihteeri')
  // }

  get poytakirjanTarkastaja() {
    return this.form.get('poytakirjanTarkastaja')
  }

  get osinkojaJaetaan() {
    return this.form.get('osinkojaJaetaan')
  }

  async createPreviewMoveToSigning() {

    if (!this.selectedParticipants?.length) {
      this.commonError = this._translationService.lokalisoi('reports-lemonaid.yhtiokokous-no-signers-error')
      return
    }

    if (!this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      this._validationService.naytaEnsimmainenVirhe()
      return
    }

    if (this._saveStarted) {
      return
    }

    this._saveStarted = true

    try {

      this._ladataanService.aloitaLataaminen()

      const puheenjohtaja: YhtiokokousUserWithFullName = this.puheenjohtaja.value
      const tarkastaja: YhtiokokousUserWithFullName = this.poytakirjanTarkastaja.value

      if (
        this.selectedParticipants.length > 1 &&
        puheenjohtaja.lemonaidUid === tarkastaja.lemonaidUid
      ) {
        this.commonError = this._translationService.lokalisoi('reports-lemonaid.valitse-eri-henkilot')
        return
      }

      const tilinpaatos = await firstValueFrom(this.tilinpaatosSnapshotObservable.pipe(map(snap => snap?.snap?.data() || null)))
      this._poytakirja.osinkojaJaetaanYhteensa = tilinpaatos.osinkojaYhteensa
      this._poytakirja.dateAndTime = this._dateService.localDateTimeToNumberTimestamp(this._meetingTimeAndDate)
      this._poytakirja.lasnaolijat = this.selectedParticipants.map(selected => {
        const lasnaolija: YhtiokokousParticipant = {
          lemonaidUid: selected.lemonaidUid,
          osakkeita: selected.osakkeita,
          name: selected.name,
          roles: null
        }
        return lasnaolija
      })
      // this._poytakirja.signs = this.selectedParticipants?.map(selected => {
      //   const userData: YhtiokokousParticipant = {
      //     name: selected.name,
      //     lemonaidUid: selected.lemonaidUid,
      //     osakkeita: selected.osakkeita,
      //     role: this._getRoleFromPoytakirja(selected, this._poytakirja)
      //   }
      //   return { user: userData, signedAt: null }
      // }) ?? []

      const signersWithRoles: { user: YhtiokokousUserWithFullName, roles: YhtiokokousRole[] }[] = puheenjohtaja.lemonaidUid === tarkastaja.lemonaidUid ?
        [{ user: puheenjohtaja, roles: ['puheenjohtaja', 'poytakirjan-tarkastaja'] }] :
        [{ user: puheenjohtaja, roles: ['puheenjohtaja'] }, { user: tarkastaja, roles: ['poytakirjan-tarkastaja'] }]
      // Note! Make sure the order stays the same so that the index-based role logic works
      this._poytakirja.signs = signersWithRoles.map(signer => {
        const userData: YhtiokokousParticipant = {
          name: signer.user.name,
          lemonaidUid: signer.user.lemonaidUid,
          osakkeita: signer.user.osakkeita,
          roles: signer.roles
        }
        return { user: userData, signedAt: null }
      })
      this._poytakirja.forceEditMode = false

      const request: SaveYhtiokokouksenPoytakirjaDataRequest = {
        poytakirja: this._poytakirja
      }

      const resp = await this._firebaseLemonaid.functionsCall<SaveYhtiokokouksenPoytakirjaDataRequest, SaveYhtiokokouksenPoytakirjaDataResponse>('yhtiokokousSaveAndConvertPoytakirja', request)
      if (!resp || resp.e) {
        throw new Error('Yhtiökokouksen pöytäkirja save failed! ' + (resp?.e || 'No response'))
      } else {
        this._editingPoytakirjaSubject.next('view')
      }
    } catch (err: any) {
      this._errorHandler.handleError(err)
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._saveStarted = false
    }

  }

  createLasnaolijatFormGroup(user: YhtiokokousUserWithFullName): FormGroup<LasnaolijaFormGroup> {

    const group = new FormGroup<LasnaolijaFormGroup>({
      'selected': new FormControl<boolean>(!!user.lemonaidUid),
      'name': new FormControl<string>(user.name),
      'osakkeita': new FormControl<number>(user.osakkeita),
      'lemonaidUid': new FormControl<string>(user.lemonaidUid)
    })

    group.get('selected').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(isSelected => {
      if (isSelected) {
        this.commonError = null
        this.selectedParticipants.push(user)
      } else {
        const idx = this.selectedParticipants.findIndex(sp => sp.lemonaidUid === user.lemonaidUid)
        this.selectedParticipants.splice(idx, 1)

        if ((this.kokouksenAvasi.value as string) === user.name) { this.kokouksenAvasi.setValue(null) }
        if ((this.puheenjohtaja.value as YhtiokokousUserWithFullName)?.lemonaidUid === user.lemonaidUid) { this.puheenjohtaja.setValue(null) }
        if ((this.poytakirjanTarkastaja.value as YhtiokokousUserWithFullName)?.lemonaidUid === user.lemonaidUid) { this.poytakirjanTarkastaja.setValue(null) }
      }
      this.usersWithoutLemonaidAccountSelected = this.selectedParticipants.findIndex(sp => !sp.lemonaidUid) > -1

      this._tryRadioButtonAutoselect(this.selectedParticipants)
    })

    return group

  }

  /** Attempts to autoselect Kokouksen avasi, Puheenjohtaja & Pöytäkirjan tarkastaja radio fields if there's only one participant and it has a connected Lemonaid account */
  private _tryRadioButtonAutoselect(selectedParticipants: YhtiokokousUserWithFullName[]) {
    const onlyParticipant = selectedParticipants?.length === 1 ? selectedParticipants[0] : null
    if (!onlyParticipant) {
      return
    }
    if (!onlyParticipant.lemonaidUid) {
      // No corresponding Lemonaid user, can't be autoselected
      return
    }
    // If only one participant, autoselected that in the radios below
    if (!this.kokouksenAvasi.value) {
      this.kokouksenAvasi.setValue(onlyParticipant.name)
    }
    if (!this.poytakirjanTarkastaja.value) {
      this.poytakirjanTarkastaja.setValue(onlyParticipant)
    }
    if (!this.puheenjohtaja.value) {
      this.puheenjohtaja.setValue(onlyParticipant)
    }
  }

  onCheckboxChange(event: any, formItem: string) {
    const array: UntypedFormArray = this.form.get(formItem) as UntypedFormArray

    if (event.target?.checked) {
      array.push(new UntypedFormControl(event.target?.value))
    } else {
      for (const [idx, control] of array.controls.entries()) {
        if (control.value === event.target?.value) {
          array.removeAt(idx)
          return
        }
      }
    }
  }

  async saveSignature() {

    if (!!this._saveStarted) {
      return
    }

    this._saveStarted = true

    try {

      const areYouSureData: AreYouSureDialogData = {
        rightAction: this._translationService.lokalisoi('reports-lemonaid.allekirjoita'),
        header: this._translationService.lokalisoi('reports-lemonaid.allekirjoita-poytakirja'),
        text: this._translationService.lokalisoi('reports-lemonaid.ylla-oleva-poytakirja')
      }
      const isSure = await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: areYouSureData }).afterClosed())
      if (!isSure) {
        return
      }

      this._ladataanService.aloitaLataaminen()

      const selectedTilikausi = await firstValueFrom(this.selectedTilikausiObservable)

      const request: SignYhtiokokousRequest = {
        tilikausiAvain: selectedTilikausi.avain
      }

      const resp = await this._firebaseLemonaid.functionsCall<SignYhtiokokousRequest, SignYhtiokokousResponse>('yhtiokokousSigning', request)
      if (!resp || resp.e) {
        throw new Error('Yhtiökokous signing failed! ' + (resp?.e || 'No response'))
      } else {
        // All correct
        const isLastSigner = this._poytakirja.signs.filter(signer => !signer.signedAt).length <= 1
        if (isLastSigner) {
          const dialogData: PoytakirjaSignedDialogData = {
            year: selectedTilikausi.loppuu.year
          }
          this._dialog.open(PoytakirjaSignedDialog, { data: dialogData })
        }
      }

    } catch (err: any) {
      this._errorHandler.handleError(err)
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._saveStarted = false
    }

  }

  async downloadPdf() {

    this._ladataanService.aloitaLataaminen()

    try {
      const base64FilePromise = firstValueFrom(this._poytakirjaPreviewPdfAsBase64Observable)
      const asiakasPromise = firstValueFrom(this._kayttajaService.nykyinenAsiakasObservable)
      const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)

      const [base64File, asiakas, tilikausi] = await Promise.all([base64FilePromise, asiakasPromise, tilikausiPromise])

      if (!base64File?.base64?.length) {
        throw new Error('Failed to get base64 file of pöytäkirja PDF')
      }

      const fileName = asiakas.nimi + ' - yhtiökokouksen pöytäkirja ' + tilikausi.loppuu.year + '.pdf'
      this._fileSaverService.saveBase64As(base64File.base64, fileName, 'pdf')

    } catch (err: any) {
      this._errorHandler.handleError(err)
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }


  editPoytakirja() {
    this._editingPoytakirjaSubject.next('edit')
  }

  // private _getRoleFromPoytakirja(user: YhtiokokousUserWithFullName, poytakirja: YhtiokokouksenPoytakirjaData): YhtiokokousRole {
  //   if (poytakirja.puheenjohtaja === user.name) { return 'puheenjohtaja' }
  //   // if (poytakirja.sihteeri) { return 'sihteeri' }
  //   if (poytakirja.poytakirjanTarkastaja === user.name) { return 'poytakirjan-tarkastaja' }
  //   return null
  // }

  private _createEmptyPoytakirja(tilikausi: Tilikausi): YhtiokokouksenPoytakirjaData {
    return {
      avain: null, // Leave empty, will be filled in backend
      created: null,
      year: tilikausi.loppuu.year,
      tilikausi: tilikausi,
      lasnaolijat: [],
      dateAndTime: null,
      kokouspaikka: null,
      poytakirjanNumero: '1/' + new Date().getFullYear(),
      kokouksenAvasi: null,
      puheenjohtaja: null,
      // sihteeri: null,
      poytakirjanTarkastaja: null,
      osinkojaJaetaan: null,
      osingotNostettavissa: null,
      signs: [],
      osinkojaJaetaanYhteensa: 0,
      voittoPaatosTextForPrh: null,
      forceEditMode: true
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }
}
