export type TuettuKieli = 'fi' | 'en'

export interface Valuutta {
  symboli: string
  koodi: string
  desim: number
}

export interface BaseHistoryEntity {
  avain: string
  luotu: Timestamp
  luoja: 'lemonator_system' | string
}

export interface BaseEntity extends BaseHistoryEntity {
  paivittaja: 'lemonator_system' | string
  paivitetty: Timestamp
  poistettu: boolean
}

export interface LocalMonth {
  year: number
  month: number //  1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

export interface LocalDate extends LocalMonth {
  day: number // 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31
}

export interface LocalTime {
  hour: number // 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23
  minutes: number // 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | 39 | 40 | 41 | 42 | 43 | 45 | 46 | 47 | 48 | 49 | 50 | 51 | 52 | 53 | 54 | 55 | 56 | 57 | 58 | 59
  seconds: number // 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | 39 | 40 | 41 | 42 | 43 | 45 | 46 | 47 | 48 | 49 | 50 | 51 | 52 | 53 | 54 | 55 | 56 | 57 | 58 | 59
}

/** Type denoting a field containing monetary amount */
export type NumberMoney = number

/** yymm */
export type NumberMonth = number

/** yymmdd */
export type NumberDate = number

/** yymmddhhmmss */
export type NumberTimestamp = number

export interface LocalDateTime extends LocalDate, LocalTime {

}

export interface ZonedDateTime extends LocalDate, LocalTime {
  timezone: string
}

export interface KuukausiAikavali {
  start: LocalMonth
  end: LocalMonth
}

export interface PaivamaaraAikavali {
  start: LocalDate
  end: LocalDate
}

export interface PaivamaaraAikavaliNumber {
  start: number
  end: number
}

export interface ErrorResponse<ErrorType = string> {
  e?: ErrorType
}

export interface SecuredRequest {
  apiSecret: string
}

/**
 * A Timestamp represents a point in time independent of any time zone or
 * calendar, represented as seconds and fractions of seconds at nanosecond
 * resolution in UTC Epoch time. It is encoded using the Proleptic Gregorian
 * Calendar which extends the Gregorian calendar backwards to year one. It is
 * encoded assuming all minutes are 60 seconds long, i.e. leap seconds are
 * "smeared" so that no leap second table is needed for interpretation. Range is
 * from 0001-01-01T00:00:00Z to 9999-12-31T23:59:59.999999999Z.
 *
 * @see https://github.com/google/protobuf/blob/master/src/google/protobuf/timestamp.proto
 */
export interface Timestamp {
  /**
   * Creates a new timestamp with the current date, with millisecond precision.
   *
   * @return a new timestamp representing the current date.
   */
  // static now(): Timestamp;

  /**
   * Creates a new timestamp from the given date.
   *
   * @param date The date to initialize the `Timestamp` from.
   * @return A new `Timestamp` representing the same point in time as the given
   *     date.
   */
  // static fromDate(date: Date): Timestamp;

  /**
   * Creates a new timestamp from the given number of milliseconds.
   *
   * @param milliseconds Number of milliseconds since Unix epoch
   *     1970-01-01T00:00:00Z.
   * @return A new `Timestamp` representing the same point in time as the given
   *     number of milliseconds.
   */
  // static fromMillis(milliseconds: number): Timestamp;

  /**
   * Creates a new timestamp.
   *
   * @param seconds The number of seconds of UTC time since Unix epoch
   *     1970-01-01T00:00:00Z. Must be from 0001-01-01T00:00:00Z to
   *     9999-12-31T23:59:59Z inclusive.
   * @param nanoseconds The non-negative fractions of a second at nanosecond
   *     resolution. Negative second values with fractions must still have
   *     non-negative nanoseconds values that count forward in time. Must be
   *     from 0 to 999,999,999 inclusive.
   */
  // constructor(seconds: number, nanoseconds: number);

  readonly seconds: number
  readonly nanoseconds: number

  /**
   * Returns a new `Date` corresponding to this timestamp. This may lose
   * precision.
   *
   * @return JavaScript `Date` object representing the same point in time as
   *     this `Timestamp`, with millisecond precision.
   */
  toDate(): Date

  /**
   * Returns the number of milliseconds since Unix epoch 1970-01-01T00:00:00Z.
   *
   * @return The point in time corresponding to this timestamp, represented as
   *     the number of milliseconds since Unix epoch 1970-01-01T00:00:00Z.
   */
  toMillis(): number

  /**
   * Returns true if this `Timestamp` is equal to the provided one.
   *
   * @param other The `Timestamp` to compare against.
   * @return true if this `Timestamp` is equal to the provided one.
   */
  isEqual(other: Timestamp): boolean
}

export enum FileType {
  PDF = 'PDF',
  JPEG = 'JPEG',
  PNG = 'PNG',
  WEBP = 'WEBP',
  UNKNOWN = 'UNKNOWN'
}
