import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, ErrorHandler } from '@angular/core'
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { Asiakas, CustomerKuukausienStatuksetKirjanpidossa, Tilikausi } from 'app/_jaettu/model/kayttaja'
import { DateService } from 'app/_shared-core/service/date.service'
import { Observable, Subject, BehaviorSubject, map, takeUntil, filter, switchMap, of as observableOf, firstValueFrom, combineLatest, take } from 'rxjs'

import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { MatTabGroup } from '@angular/material/tabs'
import { LocalDate, NumberDate, NumberMonth, TuettuKieli } from 'app/_shared-core/model/common'
import { FormValidators } from 'app/_jaettu-angular/_validators/FormValidators'
import { LemonaidRaportitUriService } from 'app/_jaettu/service/lemonaid-raportit-uri.service'
import { LemonaidKirjanpidonRaportinPdfResponse, LemonaidKirjanpidonRaporttiPdfRequest, RaporttiType } from 'app/_jaettu/model/reports-shared'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSelect } from '@angular/material/select'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'

interface MainForm {
  alkaa: FormControl<Date>
  loppuu: FormControl<Date>
}

type ReportTab = 'tase' | 'tulos' | 'lyhyt-tase-ja-tulos' | 'paakirja' | 'bruttotuloslaskelma'

interface NaytettavaTilikausi {
  label: string
  labelHover: string
  year: number
  start: LocalDate
  end: LocalDate
  months: NaytettavanTilikaudenKuukausi[]
  lukittu: boolean
}

interface NaytettavanTilikaudenKuukausi {
  numberMonth: NumberMonth
  label: number
  start: LocalDate // Clamped to tilikausi
  end: LocalDate // Clamped to tilikausi
}

interface PdfOption {
  tag: string
  type: LemonaidKirjanpidonRaporttiPdfRequest['w']
  kieli: TuettuKieli
}

export interface RaporttienHakuvaihtoehdot {
  alkaa: NumberDate
  loppuu: NumberDate
}


@Component({
  templateUrl: './kirjanpidon-raportit.component.html',
  styleUrls: ['./kirjanpidon-raportit.component.css']
})
export class KirjanpidonRaportitComponent implements OnInit, OnDestroy, AfterViewInit {

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  readonly RaporttiType = RaporttiType;

  eiTilikausiaObservable: Observable<boolean>
  selectedTilikausiObservable: BehaviorSubject<Tilikausi> = new BehaviorSubject(null)
  naytettavatTilikaudetObservable: Observable<NaytettavaTilikausi[]>
  naytettavaTilikausi: NaytettavaTilikausi

  dateBtnsSelectedMonth: NumberMonth

  @ViewChild('tabGroup') reportsTabGroup: MatTabGroup
  @ViewChild('alkaaInput', { static: false, read: ElementRef }) alkaaInput: ElementRef<HTMLInputElement>
  @ViewChild('pdfSelect') pdfSelect: MatSelect

  laskelmatDateAndSearchForm: FormGroup<MainForm>
  alkaa: FormControl<Date>
  loppuu: FormControl<Date>
  tilista: FormControl<string>
  tiliin: FormControl<string>

  selectedTab: ReportTab = 'tulos'
  pdfList: PdfOption[]

  private _hakuvaihtoehdotSubject: BehaviorSubject<RaporttienHakuvaihtoehdot> = new BehaviorSubject({ alkaa: null, loppuu: null, tiliin: null, tilista: null })
  hakuvaihtoehdotObservable = this._hakuvaihtoehdotSubject.asObservable()

  kuukausienStatuksetMap: CustomerKuukausienStatuksetKirjanpidossa = {}

  private _minDate = this._dateService.lisaaKuukausia(new Date(), -12)

  name: string = 'asdrtyitosp12mf' + Math.random()

  constructor(
    private _kayttajaService: KayttajaService,
    private _dateService: DateService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _lemonaidRaportitUriService: LemonaidRaportitUriService,
    private _translationService: LemonTranslationService,
    private _errorHandler: ErrorHandler,
    private _fileSaverService: FileSaverService,
    private _snackbar: MatSnackBar,
    private _ladataanService: LadataanService
  ) { }

  ngOnInit() {

    this.pdfList = this._getPdfList('tulos')

    this.eiTilikausiaObservable = this._kayttajaService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        if (!asiakas) {
          return false // Default to false to prevent flicker
        }
        return !asiakas.tilikaudet?.length
      })
    )

    this.naytettavatTilikaudetObservable = this._kayttajaService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        if (!asiakas?.tilikaudet?.length) {
          return []
        }
        return asiakas.tilikaudet.sort((a, b) => this._dateService.paiviaValissaPaikallinen(a.alkaa, b.alkaa)).map(tilikausi => this._calcNaytettavaTilikausi(tilikausi))
      })
    )

    this.alkaa = new FormControl<Date>(null, [Validators.required, FormValidators.minDateValidator(this._minDate), this._alkaaEnnenLoppuaValidator])
    this.loppuu = new FormControl<Date>(null, [Validators.required, FormValidators.minDateValidator(this._minDate), this._alkaaEnnenLoppuaValidator])

    this.laskelmatDateAndSearchForm = new FormGroup<MainForm>({
      alkaa: this.alkaa,
      loppuu: this.loppuu
    })


    this.alkaa.valueChanges.pipe(
      filter(() => this.alkaa.valid), // Don't emit at all if not valid date
      takeUntil(this._ngUnsubscribe)
    ).subscribe((a: Date) => {
      if (a) {
        const asNumber = this._dateService.dateToNumber(a)
        if (asNumber !== this._hakuvaihtoehdotSubject.value.alkaa) {
          this._hakuvaihtoehdotSubject.value.alkaa = asNumber
          this._hakuvaihtoehdotSubject.next(this._hakuvaihtoehdotSubject.value)
        }
      }
      this.loppuu.updateValueAndValidity({ onlySelf: true, emitEvent: false })
    })

    this.loppuu.valueChanges.pipe(
      filter(() => this.loppuu.valid), // Don't emit at all if not valid date
      takeUntil(this._ngUnsubscribe)
    ).subscribe((a: Date) => {
      if (a) {
        const asNumber = this._dateService.dateToNumber(a)
        if (asNumber !== this._hakuvaihtoehdotSubject.value.loppuu) {
          this._hakuvaihtoehdotSubject.value.loppuu = asNumber
          this._hakuvaihtoehdotSubject.next(this._hakuvaihtoehdotSubject.value)
        }
      }
      this.alkaa.updateValueAndValidity({ onlySelf: true, emitEvent: false })
    })

    this._hakuvaihtoehdotSubject.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(haku => {
      if (!haku) { return }
      // Activate quarter or month btn if start and end dates match (for currently selected tilikausi), otherwise deactivate
      const alkaaLocal = this._dateService.numberToLocalDate(haku.alkaa)
      const loppuuLocal = this._dateService.numberToLocalDate(haku.loppuu)
      this._setActiveMonth(alkaaLocal, loppuuLocal)
    })

    const kuukausienStatuksetObservable = this._kayttajaService.kayttajanTiedotObservable.pipe(
      switchMap(kayttaja => {
        if (!kayttaja?.asiakasAvain) {
          return observableOf<CustomerKuukausienStatuksetKirjanpidossa>({}) // Note! Must be empty object, i.e. truthy
        }
        return this._firebaseLemonaid.firestoreDoc<CustomerKuukausienStatuksetKirjanpidossa>(this._lemonaidRaportitUriService.getCustomerKuukausienStatuksetUri(kayttaja.asiakasAvain))
          .listen().pipe(map(kuukausienStatuksetMap => kuukausienStatuksetMap ?? {}
          ))
      })
    )

    kuukausienStatuksetObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(kuukausienStatuksetMap => {
      this.kuukausienStatuksetMap = kuukausienStatuksetMap ?? {}
    })

    /** Initial date pickers state */
    combineLatest([kuukausienStatuksetObservable, this.naytettavatTilikaudetObservable]).pipe(
      take(1),
    ).subscribe(([kuukausienStatuksetMap, tilikaudet]) => {
      const currentLocalDate = this._dateService.currentLocalDate()
      let nykyinenTilikausi: NaytettavaTilikausi = null
      for (const tk of tilikaudet) {
        if (this._dateService.onkoLocalDateKahdenValissa(currentLocalDate, tk.start, tk.end)) {
          nykyinenTilikausi = tk
          break
        }
      }
      const alkuJaLoppuKirjanpidonPerusteella = this.annaAlkaaJaLoppuuKirjanpidonStatuksenPerusteella(nykyinenTilikausi, kuukausienStatuksetMap)
      if (alkuJaLoppuKirjanpidonPerusteella.alkaa && alkuJaLoppuKirjanpidonPerusteella.loppuu) {
        this._setDates(alkuJaLoppuKirjanpidonPerusteella.alkaa, alkuJaLoppuKirjanpidonPerusteella.loppuu)
        this.naytettavaTilikausi = nykyinenTilikausi
      } else if (nykyinenTilikausi) {
        this.valitseTilikausi(nykyinenTilikausi)
      } else {
        const currentDate = new Date()
        this.alkaa.setValue(this._dateService.kuukaudenEnsimmainen(currentDate))
        this.loppuu.setValue(currentDate)
      }
    })
  }

  ngAfterViewInit() {
    this.reportsTabGroup.selectedTabChange.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tab => {
      switch (tab.index) {
        case 0: { this.selectedTab = 'tulos'; break }
        case 1: { this.selectedTab = 'tase'; break }
        case 2: { this.selectedTab = 'lyhyt-tase-ja-tulos'; break }
        // case x: { this.selectedTab = 'paakirja'; break }
        case 3: { this.selectedTab = 'bruttotuloslaskelma'; break }
        default: this.selectedTab = null
      }

      this.pdfList = this._getPdfList(this.selectedTab)

    })
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  // Blur will happen while navigating to the next item.
  enterPressedTiliin(event: Event) { this.alkaaInput.nativeElement.focus(); event.preventDefault(); event.stopPropagation() }

  private _alkaaEnnenLoppuaValidator = (control: FormGroup<MainForm>): ValidationErrors | null => {
    const a = control.get('alkaa')?.value
    const l = control.get('loppuu')?.value
    if (l && a && a > l) {
      return { 'loppuEnnenAlkua': true }
    }
    return null
  }

  valitseTilikausi(uusiNaytettava: NaytettavaTilikausi) {
    this.naytettavaTilikausi = uusiNaytettava
    this.dateBtnsSelectedMonth = null

    this._setDates(uusiNaytettava.start, uusiNaytettava.end)
  }

  valitseKuukausi(kuukausi: NaytettavanTilikaudenKuukausi) {
    this.dateBtnsSelectedMonth = kuukausi.numberMonth
    this._setDates(kuukausi.start, kuukausi.end)
  }

  private _setDates(start: LocalDate, end: LocalDate) {

    const alkaaDate = this._dateService.localDateToDate(start)
    const loppuuDate = this._dateService.localDateToDate(end)

    this.alkaa.setValue(alkaaDate, { emitEvent: false })
    this.loppuu.setValue(loppuuDate, { emitEvent: false })

    const alkaaNumber = this._dateService.dateToNumber(alkaaDate)
    const loppuuNumber = this._dateService.dateToNumber(loppuuDate)


    if (
      this._hakuvaihtoehdotSubject.value.alkaa !== alkaaNumber ||
      this._hakuvaihtoehdotSubject.value.loppuu !== loppuuNumber
    ) {
      this._hakuvaihtoehdotSubject.value.alkaa = alkaaNumber
      this._hakuvaihtoehdotSubject.value.loppuu = loppuuNumber
      this._hakuvaihtoehdotSubject.next(this._hakuvaihtoehdotSubject.value)
    }

  }


  private _calcNaytettavaTilikausi(asiakkaanTilikausi: Tilikausi): NaytettavaTilikausi {
    const naytettava: NaytettavaTilikausi = {
      label: '' + asiakkaanTilikausi.loppuu.year,
      labelHover: this._formatSelectableYearBtnText(asiakkaanTilikausi),
      year: asiakkaanTilikausi.loppuu.year,
      start: asiakkaanTilikausi.alkaa,
      end: asiakkaanTilikausi.loppuu,
      months: this._calcNaytettavanTilikaudenKuukaudet(asiakkaanTilikausi),
      lukittu: asiakkaanTilikausi.lukittu
    }
    return naytettava
  }

  private _formatSelectableYearBtnText(tilikausi: Tilikausi): string {
    if (tilikausi.alkaa.day === 1 && tilikausi.alkaa.month === 1 &&
      tilikausi.loppuu.day === 31 && tilikausi.loppuu.month === 12) {
      return '' + tilikausi.loppuu.year
    }
    return this._dateService.muotoile(this._dateService.localDateToDate(tilikausi.alkaa), 'dd.MM.yy') + ' - ' + this._dateService.muotoile(this._dateService.localDateToDate(tilikausi.loppuu), 'dd.MM.yy')
  }

  private _calcNaytettavanTilikaudenKuukaudet(asiakkaanTilikausi: Tilikausi) {
    const naytettavatKuukaudet: NaytettavanTilikaudenKuukausi[] = []
    const kuukausiaValissa = this._dateService.kuukausiaValissaPaikallinen(asiakkaanTilikausi.loppuu, asiakkaanTilikausi.alkaa)
    for (let i = 0; i <= kuukausiaValissa; i++) {
      const kuukausi: LocalDate = this._dateService.lisaaKuukausiaPaikallinen(asiakkaanTilikausi.alkaa, i)
      const tilikaudenKuukausi: NaytettavanTilikaudenKuukausi = {
        label: kuukausi.month,
        numberMonth: this._dateService.localMonthToNumber(kuukausi),
        start: i === 0 ? asiakkaanTilikausi.alkaa : this._dateService.kuukaudenEnsimmainenPaikallinen(kuukausi), // Use tilikausi start date for first month
        end: i === kuukausiaValissa ? asiakkaanTilikausi.loppuu : this._dateService.kuukaudenViimeinenPaikallinen(kuukausi) // Use tilikausi end date for last month
      }
      naytettavatKuukaudet.push(tilikaudenKuukausi)
    }
    return naytettavatKuukaudet
  }

  private _setActiveMonth(start: LocalDate, end: LocalDate) {
    if (!start || !end) {
      this.dateBtnsSelectedMonth = null
      return
    }
    const matchingMm: NaytettavanTilikaudenKuukausi = (this.naytettavaTilikausi?.months || []).find(mm => this._dateService.compareLocalDates(mm.start, '==', start) && this._dateService.compareLocalDates(mm.end, '==', end))
    if (matchingMm) {
      this.dateBtnsSelectedMonth = matchingMm.numberMonth
      return
    }
    this.dateBtnsSelectedMonth = null
  }

  async downloadPdf(type: LemonaidKirjanpidonRaporttiPdfRequest['w'], kieli: TuettuKieli) {
    this._ladataanService.aloitaLataaminen()

    try {
      const asiakasPromise = firstValueFrom(this._kayttajaService.nykyinenAsiakasObservable)
      const hakuvaihtoehdotPromise = firstValueFrom(this.hakuvaihtoehdotObservable)

      const [asiakas, hakuvaihtoehdot] = await Promise.all([asiakasPromise, hakuvaihtoehdotPromise])

      const requestData: LemonaidKirjanpidonRaporttiPdfRequest = {
        a: asiakas.asiakasAvain,
        k: kieli,
        w: type,
        s: hakuvaihtoehdot.alkaa,
        e: hakuvaihtoehdot.loppuu
      }

      const res = await this._firebaseLemonaid.functionsCall<LemonaidKirjanpidonRaporttiPdfRequest, LemonaidKirjanpidonRaportinPdfResponse>('pyydaRaportinPdfLemonatorista', requestData)

      if (res.e) {
        throw new Error(res.e)
      }
      if (!res.base64File?.length) {
        throw new Error('Failed to get ' + type + ' PDF, buffer missing')
      }

      const fileName = this._createFileName(asiakas, hakuvaihtoehdot.alkaa, hakuvaihtoehdot.loppuu, kieli, type)
      this._fileSaverService.saveBase64As(res.base64File, fileName, 'pdf')

    } catch (err) {
      this._errorHandler.handleError('Failed to get ' + type + ' PDF!' + err?.message)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 5000 })

    } finally {
      this._ladataanService.lopetaLataaminen()

    }
  }

  private _createFileName(asiakas: Asiakas, start: NumberDate, end: NumberDate, kieli: TuettuKieli, type: LemonaidKirjanpidonRaporttiPdfRequest['w']) {
    let fileName = asiakas.nimi + ' (' + asiakas.ytunnus + ') ' + this._dateService.muotoileNumberPaiva(start, kieli) + ' - ' + this._dateService.muotoileNumberPaiva(end, kieli)

    if (type === RaporttiType.PAAKIRJA) {
      fileName += (' ' + this._translationService.lokalisoiKielella('raportit.paakirja', kieli)) + '.pdf'
      return fileName
    }

    if (type === RaporttiType.TASE) {
      fileName += (' ' + this._translationService.lokalisoiKielella('raportit.tase', kieli)) + '.pdf'
      return fileName
    }

    if (type === RaporttiType.TULOS) {
      fileName += (' ' + this._translationService.lokalisoiKielella('raportit.tuloslaskelma', kieli)) + '.pdf'
      return fileName
    }

    if (type === RaporttiType.TULOS_BRUTTO) {
      fileName += (' ' + this._translationService.lokalisoiKielella('raportit.brutto-tuloslaskelma', kieli)) + '.pdf'
      return fileName
    }

    if (type === RaporttiType.TASE_VIRALLINEN) {
      fileName += (' ' + this._translationService.lokalisoiKielella('raportit.virallinen-tase', kieli)) + '.pdf'
      return fileName
    }

    if (type === RaporttiType.TULOS_VIRALLINEN) {
      fileName += (' ' + this._translationService.lokalisoiKielella('raportit.virallinen-tulos', kieli)) + '.pdf'
      return fileName
    }

    if (type === RaporttiType.TASE_JA_TULOS) {
      fileName += (' ' + this._translationService.lokalisoiKielella('raportit.tase-ja-tulos', kieli)) + '.pdf'
      return fileName
    }

    if (type === RaporttiType.TASE_JA_TULOS_VIRALLINEN) {
      fileName += (' ' + this._translationService.lokalisoiKielella('raportit.virallinen-tase-ja-tulos', kieli)) + '.pdf'
      return fileName
    }

    this._errorHandler.handleError(new Error('No file name handling for ' + type + '!'))
    fileName += '.pdf' // Return without actual report name
    return fileName
  }

  openPdfSelect() {
    this.pdfSelect.open()
  }

  private _getPdfList(valilehti: ReportTab): PdfOption[] {
    const TUETUT_KIELET: TuettuKieli[] = ['fi', 'en']
    const output: PdfOption[] = []

    if (valilehti === 'tulos') {
      for (const kieli of TUETUT_KIELET) {
        output.push({ tag: this._translationService.lokalisoi('raportit.tulos') + ' (' + kieli.toUpperCase() + ')', type: RaporttiType.TULOS, kieli: kieli })
        output.push({ tag: this._translationService.lokalisoi('raportit.tase-ja-tulos') + ' (' + kieli.toUpperCase() + ')', type: RaporttiType.TASE_JA_TULOS, kieli: kieli })
      }
    }

    if (valilehti === 'tase') {
      for (const kieli of TUETUT_KIELET) {
        output.push({ tag: this._translationService.lokalisoi('raportit.tase') + ' (' + kieli.toUpperCase() + ')', type: RaporttiType.TASE, kieli: kieli })
        output.push({ tag: this._translationService.lokalisoi('raportit.tase-ja-tulos') + ' (' + kieli.toUpperCase() + ')', type: RaporttiType.TASE_JA_TULOS, kieli: kieli })
      }
    }

    if (valilehti === 'lyhyt-tase-ja-tulos') {
      for (const kieli of TUETUT_KIELET) {
        output.push({ tag: this._translationService.lokalisoi('raportit.tulos') + ' (' + kieli.toUpperCase() + ')', type: RaporttiType.TULOS_VIRALLINEN, kieli: kieli })
        output.push({ tag: this._translationService.lokalisoi('raportit.tase') + ' (' + kieli.toUpperCase() + ')', type: RaporttiType.TASE_VIRALLINEN, kieli: kieli })
        output.push({ tag: this._translationService.lokalisoi('raportit.tase-ja-tulos') + ' (' + kieli.toUpperCase() + ')', type: RaporttiType.TASE_JA_TULOS_VIRALLINEN, kieli: kieli })
      }
    }

    if (valilehti === 'paakirja') {
      for (const kieli of TUETUT_KIELET) {
        output.push({ tag: this._translationService.lokalisoi('raportit.paakirja') + + ' (' + kieli.toUpperCase() + ')', type: RaporttiType.PAAKIRJA, kieli: kieli })
      }
    }

    if (valilehti === 'bruttotuloslaskelma') {
      for (const kieli of TUETUT_KIELET) {
        output.push({ tag: this._translationService.lokalisoi('raportit.brutto-tuloslaskelma') + ' (' + kieli.toUpperCase() + ')', type: RaporttiType.TULOS_BRUTTO, kieli: kieli })
      }
    }

    return output
  }


  annaAlkaaJaLoppuuKirjanpidonStatuksenPerusteella(tilikausi: NaytettavaTilikausi, kirjanpidonStatukset: CustomerKuukausienStatuksetKirjanpidossa) {
    const output: { alkaa: LocalDate, loppuu: LocalDate } = { alkaa: null, loppuu: null }

    const months = [...tilikausi.months]

    /** Ascend to find valid start month */
    months.sort((a, b) => a.numberMonth - b.numberMonth)
    for (const kk of months) {
      if (kirjanpidonStatukset[kk.numberMonth] === 'ok') {
        const startOfMonth = Object.assign(this._dateService.numberToLocalMonth(kk.numberMonth), { day: 1 })
        output.alkaa = startOfMonth
        break
      }
    }

    // Descend to find valid end month
    months.sort((a, b) => b.numberMonth - a.numberMonth)
    for (const kk of months) {
      if (kirjanpidonStatukset[kk?.numberMonth] === 'ok') {
        const endOfMonth = this._dateService.kuukaudenViimeinenPaikallinen(Object.assign(this._dateService.numberToLocalMonth(kk.numberMonth), { day: 1 }))
        output.loppuu = endOfMonth
        break
      }
    }

    return output
  }

}
