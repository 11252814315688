import { Component, ErrorHandler, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { LaskuService } from '../../_angular/service/lasku/lasku.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { Lasku } from 'app/_jaettu/model/lasku'

export interface LaskuPerintaPeruutusAreYouSureDialogData {
  juurilasku: Lasku
}

@Component({
  templateUrl: './lasku-perinta-peruutus-are-you-sure.dialog.html'
})

export class LaskuPerintaPeruutusAreYouSureDialog implements OnInit {

  private _buttonPushInflight: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LaskuPerintaPeruutusAreYouSureDialogData,
    private _dialogRef: MatDialogRef<LaskuPerintaPeruutusAreYouSureDialogData>,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _laskuService: LaskuService,
    private _router: Router
  ) { }

  ngOnInit() {
  }

  peruuta() {
    this._router.navigate(['/laskutus/laskut'])
  }

  async send() {

    if (this._buttonPushInflight) {
      return
    }
    this._buttonPushInflight = true

    this._ladataanService.aloitaLataaminen()

    try {
      await this._laskuService.merkitseLaskunPerintaPerutuksiSpostilla(this.data.juurilasku)
      this.peruuta()
    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close(true)
      this._buttonPushInflight = false
    }
  }

}
