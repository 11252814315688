import { Lasku, LaskuBase, AsiakkaanTuote, LaskunAsiakas, LaskunTila, LaskuReskontra } from '../../model/lasku'
import { LaskuSharedService, ReskontraService } from './lasku-shared.service'
import { CurrencyService } from '../../../_shared-core/service/currency.service'

import { FirestoreIndeksoija, MahdollinenJarjestys, JarjestysTiedot } from '../firestore.indeksoija'

export class LaskunAsiakasSorttaukset {
  static readonly NIMI: JarjestysTiedot = { tunniste: 'a', tyyppi: 'string' }
  static readonly YTUNNUS: JarjestysTiedot = { tunniste: 'b', tyyppi: 'string' }
  static readonly KATUOSOITE: JarjestysTiedot = { tunniste: 'c', tyyppi: 'string' }
  static readonly POSTINRO: JarjestysTiedot = { tunniste: 'd', tyyppi: 'string' }
  static readonly POSTITOIMIPAIKKA: JarjestysTiedot = { tunniste: 'e', tyyppi: 'string' }
  static readonly MAA: JarjestysTiedot = { tunniste: 'f', tyyppi: 'string' }
  static readonly POISTETTU: JarjestysTiedot = { tunniste: 'g', tyyppi: 'string' }

  static readonly KAIKKI: { [tunniste: string]: JarjestysTiedot } = {
    nimi: LaskunAsiakasSorttaukset.NIMI,
    ytunnus: LaskunAsiakasSorttaukset.YTUNNUS,
    katuosoite: LaskunAsiakasSorttaukset.KATUOSOITE,
    postinro: LaskunAsiakasSorttaukset.POSTINRO,
    postitoimipaikka: LaskunAsiakasSorttaukset.POSTITOIMIPAIKKA,
    maa: LaskunAsiakasSorttaukset.MAA,
    poistettu: LaskunAsiakasSorttaukset.POISTETTU
  }
}

export class AsiakkaanTuotteenSorttaukset {
  static readonly HINTA: JarjestysTiedot = { tunniste: 'a', tyyppi: 'number' }
  static readonly NIMI: JarjestysTiedot = { tunniste: 'b', tyyppi: 'string' }
  static readonly POISTETTU: JarjestysTiedot = { tunniste: 'c', tyyppi: 'string' }

  static readonly KAIKKI: { [tunniste: string]: JarjestysTiedot } = {
    hinta: AsiakkaanTuotteenSorttaukset.HINTA,
    nimi: AsiakkaanTuotteenSorttaukset.NIMI,
    poistettu: AsiakkaanTuotteenSorttaukset.POISTETTU
  }
}

export class LaskunSorttaukset {
  static readonly TILA: JarjestysTiedot = { tunniste: 'a', tyyppi: 'string' }
  static readonly NRO: JarjestysTiedot = { tunniste: 'b', tyyppi: 'string' }
  static readonly PVM: JarjestysTiedot = { tunniste: 'c', tyyppi: 'date' }
  static readonly ERAPVM: JarjestysTiedot = { tunniste: 'd', tyyppi: 'date' }
  static readonly ASIAKAS: JarjestysTiedot = { tunniste: 'e', tyyppi: 'string' }
  static readonly SUMMA: JarjestysTiedot = { tunniste: 'f', tyyppi: 'number' }
  static readonly AVOINNA: JarjestysTiedot = { tunniste: 'g', tyyppi: 'number' }

  static readonly KAIKKI: { [tunniste: string]: JarjestysTiedot } = {
    tila: LaskunSorttaukset.TILA,
    nro: LaskunSorttaukset.NRO,
    pvm: LaskunSorttaukset.PVM,
    erapvm: LaskunSorttaukset.ERAPVM,
    asiakas: LaskunSorttaukset.ASIAKAS,
    summa: LaskunSorttaukset.SUMMA,
    avoinna: LaskunSorttaukset.AVOINNA
  }
}

export class LaskuIndeksoija {

  constructor(
    private firestoreIndeksoija: FirestoreIndeksoija,
    private _sharedLaskuService: LaskuSharedService,
    private reskontraService: ReskontraService,
    private currencyService: CurrencyService
  ) { }

  private _annaLaskunNumeroLajitteluaVarten(juurilasku: Lasku): number {
    if (this._sharedLaskuService.onkoUlkoinenLasku(juurilasku)) {
      if ((juurilasku.ulkoinenLaskunroLajittelu ?? 0) > 999999) {
        throw new Error('Lajittelulaskunumero on liian suuri!')
      }
      const year = (juurilasku.pvml.year - 2000) + ''
      const nro = (juurilasku.ulkoinenLaskunroLajittelu ?? 0) + ''
      return Number(year + nro)
    }
    return juurilasku.nro || 0
  }

  public annaNumeroLajitteluaVarten(juurilasku: Lasku): number {
    const nroString = this._annaLaskunNumeroLajitteluaVarten(juurilasku) + ''
    const vuosi = nroString.substring(0, 2)
    const numero = nroString.substring(2)
    const leftpadded = ('000000' + numero).slice(-6)
    return Number(vuosi + leftpadded)
  }

  private kasitteleLaskunNroIndeksointilajitteluaVarten(juurilasku: Lasku): string {
    const uusinumero = this.annaNumeroLajitteluaVarten(juurilasku)
    return this._sharedLaskuService.annaLaskunumeroNumerollaJaTyypilla(uusinumero, juurilasku.nrotyyppi)
  }

  public luoHakutiedotAsiakkaalle(asiakas: LaskunAsiakas): any {
    const mahdollisetSorttaukset: MahdollinenJarjestys[] = []

    if (asiakas.poistettu) {
      mahdollisetSorttaukset.push({
        sorttaus: LaskunAsiakasSorttaukset.POISTETTU,
        arvo: asiakas.nimi
      })
    } else {
      mahdollisetSorttaukset.push({
        sorttaus: LaskunAsiakasSorttaukset.NIMI,
        arvo: asiakas.nimi
      })
      mahdollisetSorttaukset.push({
        sorttaus: LaskunAsiakasSorttaukset.YTUNNUS,
        arvo: asiakas.ytunnus
      })
      mahdollisetSorttaukset.push({
        sorttaus: LaskunAsiakasSorttaukset.KATUOSOITE,
        arvo: asiakas.katuosoite
      })
      mahdollisetSorttaukset.push({
        sorttaus: LaskunAsiakasSorttaukset.POSTINRO,
        arvo: asiakas.postinro
      })
      mahdollisetSorttaukset.push({
        sorttaus: LaskunAsiakasSorttaukset.POSTITOIMIPAIKKA,
        arvo: asiakas.postitmp
      })
      mahdollisetSorttaukset.push({
        sorttaus: LaskunAsiakasSorttaukset.MAA,
        arvo: asiakas.maa
      })
    }

    const haku = {}
    this.firestoreIndeksoija.luoFirestorenVapaatekstihaku(haku, asiakas.nimi, [], mahdollisetSorttaukset)
    this.firestoreIndeksoija.luoFirestorenVapaatekstihaku(haku, asiakas.ytunnus, [], mahdollisetSorttaukset)
    this.firestoreIndeksoija.luoFirestorenVapaatekstihaku(haku, asiakas.katuosoite, [], mahdollisetSorttaukset)
    this.firestoreIndeksoija.luoFirestorenVapaatekstihaku(haku, asiakas.postitmp, [], mahdollisetSorttaukset)
    this.firestoreIndeksoija.luoFirestorenVapaatekstihaku(haku, asiakas.postinro, [], mahdollisetSorttaukset)
    return haku
  }

  public luoHakutiedotTuotteelle(tuote: AsiakkaanTuote): any {
    const mahdollisetSorttaukset: MahdollinenJarjestys[] = []

    if (tuote.poistettu) {
      mahdollisetSorttaukset.push({
        sorttaus: AsiakkaanTuotteenSorttaukset.POISTETTU,
        arvo: tuote.nimi
      })
    } else {
      mahdollisetSorttaukset.push({
        sorttaus: AsiakkaanTuotteenSorttaukset.HINTA,
        arvo: tuote.hinta
      })
      mahdollisetSorttaukset.push({
        sorttaus: AsiakkaanTuotteenSorttaukset.NIMI,
        arvo: tuote.nimi
      })
    }

    const haku = {}
    this.firestoreIndeksoija.luoFirestorenVapaatekstihaku(haku, tuote.nimi, [], mahdollisetSorttaukset)
    return haku
  }

  public luoHakutiedotLaskulle(juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]): any {
    if (juurilasku.omitFromSearches) { return null }

    const summat = this._sharedLaskuService.annaLaskunSummat(kasiteltava)
    const reskontranSumma = this.reskontraService.annaReskontranSumma(reskontra)

    const mahdollisetSorttaukset: MahdollinenJarjestys[] = []
    mahdollisetSorttaukset.push({
      sorttaus: LaskunSorttaukset.TILA,
      arvo: juurilasku.tila
    })
    mahdollisetSorttaukset.push({
      sorttaus: LaskunSorttaukset.NRO,
      arvo: this.kasitteleLaskunNroIndeksointilajitteluaVarten(juurilasku)
    })
    mahdollisetSorttaukset.push({
      sorttaus: LaskunSorttaukset.PVM,
      arvo: kasiteltava.pvml
    })
    mahdollisetSorttaukset.push({
      sorttaus: LaskunSorttaukset.ERAPVM,
      arvo: kasiteltava.erapvml
    })
    mahdollisetSorttaukset.push({
      sorttaus: LaskunSorttaukset.ASIAKAS,
      arvo: kasiteltava.asiakas.nimi
    })
    mahdollisetSorttaukset.push({
      sorttaus: LaskunSorttaukset.SUMMA,
      arvo: this.currencyService.muutaBigDecimalRahaksi(summat.yhteensaKaikki)
    })
    mahdollisetSorttaukset.push({
      sorttaus: LaskunSorttaukset.AVOINNA,
      arvo: this.currencyService.muutaBigDecimalRahaksi(summat.yhteensaKaikki.minus(reskontranSumma))
    })

    const haku = {}

    const erapvm = this.firestoreIndeksoija.annaAikaLocal(kasiteltava.erapvml, 'e')
    const pvm = this.firestoreIndeksoija.annaAikaLocal(kasiteltava.pvml, 'p')

    const extra = [
      // Rajattu tilan mukaan
      juurilasku.tila,

      // Rajattu ajan mukaan
      pvm,
      erapvm,

      // Tila + aika
      juurilasku.tila + pvm,
      juurilasku.tila + erapvm
    ]

    // Näytä luonnokset myös avoimissa
    if (juurilasku.tila === LaskunTila.luonnos) {
      extra.push(LaskunTila.avoin)
    }

    // Näytä erääntyneet myös avoimissa.
    if (juurilasku.tila === LaskunTila.eraantynyt) {
      extra.push(LaskunTila.avoin)
    }

    this.firestoreIndeksoija.luoFirestorenVapaatekstihaku(haku, kasiteltava.asiakas.nimi, extra, mahdollisetSorttaukset)

    const laskunumerot = new Set<string>()
    laskunumerot.add(this._sharedLaskuService.annaLaskunumero(juurilasku))
    for (const korvaava of juurilasku.korvaus) {
      laskunumerot.add(this._sharedLaskuService.annaLaskunumero(korvaava))
    }

    for (const laskunumero of laskunumerot) {
      this.firestoreIndeksoija.luoFirestorenVapaatekstihakuEksaktillaOsumalla(haku, laskunumero, extra, mahdollisetSorttaukset)
    }

    // let i = 0
    // for (var property in haku) {
    //   if (haku.hasOwnProperty(property)) {
    //     i++
    //   }
    // }
    // console.log('Yhteensä ' + i + ' entryä: ', haku)

    return haku
  }

}
