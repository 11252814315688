import { Component, OnInit, ErrorHandler, OnDestroy } from '@angular/core'
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { TositteenLisaysprosessinOsanTunniste } from '../../_jaettu/model/kayttaja'
import { Maksutapa, Kuitti, FirestoreTosite, FirestoreTositteenAlkuperainenTiedosto } from '../../_jaettu/model/tosite'

import { TositeService } from '../../_angular/service/tosite/tosite.service'
import { KayttajaService } from '../../_angular/service/kayttaja.service'
import { TositeLuoUusiComponentData } from '../../_angular/_resolvers/tosite.resolve'

import { SimplifiedUploadData, TiedostojenLataamisService } from '../../_jaettu-angular/service/tiedostojen-lataamis.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'

import { DateService } from '../../_shared-core/service/date.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'

import { FileSystemFileEntry } from 'ngx-file-drop'

import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs'
import { takeUntil, map, switchMap } from 'rxjs/operators'
import { TositeKuvaCacheService } from '../kuvat/tosite-kuva-cache.service'
import { TositeDatasourceService } from 'app/_angular/service/tosite/tosite-datasource.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { QrTaiViivakoodi } from 'app/_angular/service/barcode/barcode-detector.service'

// interface MainForm {
//   tositeArray: FormArray<FormGroup<TositeForm>>
// }

interface TositeForm {
  maksutapa: FormControl<number>
  pvm: FormControl<Date>
  summa: FormControl<number>
  lisatiedot: FormControl<string>
  alv: FormControl<number>
}

interface TositteenTiedosto {
  alkuperainen: FirestoreTositteenAlkuperainenTiedosto
  uploadData: SimplifiedUploadData
  uploadErrorObservable: Observable<string>
  kuvat: string[]
  nakyvaKuva: string
  tila: 'aloittamatta' | 'purettu' | 'backend' | 'purkuvirhe' | 'latausvirhe'
}

interface TositeJaTiedot {
  tosite: FirestoreTosite
  formGroup: FormGroup<TositeForm>
  tiedostotSubject: BehaviorSubject<TositteenTiedosto[]>
  naytaTallenna: boolean
  uploadDoneTotalPercentageObservable: Observable<number>
}

@Component({
  templateUrl: './tosite.luo-uusi.component.html',
  styleUrls: ['./tosite.luo-uusi.component.css']
})
export class TositeLuoUusiComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject<void>()

  maxDate: Date = new Date(2099, 11, 31)
  minDate: Date = new Date(2010, 0, 1)

  paivitaValidaatiot = {}

  donePercentageObservable: Observable<number>
  latausvirheetObservable: Observable<string[]>
  alvPortaatObservable: Observable<number[]>
  virheviestiLatausOnKeskenObservable: Observable<string>
  naytaYhdistaKuititObservable: Observable<boolean>

  private _initialLoadDoneSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
  initialLoadDoneObservable: Observable<boolean> = this._initialLoadDoneSubject.asObservable()

  private _tositteetJaTiedotSubject: BehaviorSubject<TositeJaTiedot[]> = new BehaviorSubject(null)
  tositteetJaTiedotObservable: Observable<TositeJaTiedot[]> = this._tositteetJaTiedotSubject.asObservable().pipe(
    map(tositteet => {
      if (!tositteet) { return null }
      return tositteet.filter(tosite => {
        for (const tiedosto of tosite.tiedostotSubject.value) {
          if (tiedosto.tila !== 'backend' && tiedosto.tila !== 'purkuvirhe' && tiedosto.tila !== 'latausvirhe') {
            return true
          }
        }
        return false
      })
    })
  )

  tositteetJaTiedotBackendissaObservable: Observable<TositeJaTiedot[]> = this._tositteetJaTiedotSubject.asObservable().pipe(
    map(tositteet => {
      if (!tositteet) { return null }
      return tositteet.filter(tosite => {
        for (const tiedosto of tosite.tiedostotSubject.value) {
          if (tiedosto.tila !== 'backend' && tiedosto.tila !== 'purkuvirhe' && tiedosto.tila !== 'latausvirhe') {
            return false
          }
        }
        return true
      })
    })
  )

  otsikko: string
  pvmText: string
  summaText: string
  showSumma: boolean = false
  showMaksutapa: boolean = false
  showPvm: boolean = false
  showPvmAsMonth: boolean = false
  showAlv: boolean = false

  namename = 'asfasf' + Math.random()

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tositeService: TositeService,
    private ladataanService: LadataanService,
    private dateService: DateService,
    private validationService: FormValidationService,
    private timestampService: TimestampService,
    private currencyService: CurrencyService,
    private kayttajaService: KayttajaService,
    private tiedostojenLataamisService: TiedostojenLataamisService,
    private translationService: LemonTranslationService,
    private errorHandler: ErrorHandler,
    private tositeKuvaCacheService: TositeKuvaCacheService,
    private tositeDatasourceService: TositeDatasourceService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    this.maxDate = this.dateService.lisaaKuukausia(new Date(), 12)
    this.minDate = this.dateService.lisaaKuukausia(new Date(), -48)
  }

  ngOnInit() {

    this.route.data.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async (data: { data: TositeLuoUusiComponentData }) => {

      if (!data || !data.data || !data.data.tiedostot || data.data.tiedostot.length < 1) {
        this.peruuta()
        return
      }

      // Separate display settings for different tosite types
      const lahde = this.tositeService.getMaksutapaNameForRouter(data.data?.maksutapa?.i)
      this._setTextsAndFieldsForToTositeType(lahde)
      await this.tositeService.setLastViewedKuitinMaksutapa(data.data?.maksutapa?.i)

      const tositteetJaLataustiedot: TositeJaTiedot[] = []

      const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
      let count = 0
      for (const tiedosto of data.data.tiedostot) {
        count++

        const kuitti = this.tositeService.annaUusiKuitti(data.data.maksutapa)
        if (data.data.maksutapa) {
          kuitti.maksutapa = data.data.maksutapa.i
        }
        const tositeGroup = this._annaKuitinFormGroup(kuitti)

        const fileEnding = this.tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(tiedosto)
        const file = await this.tiedostojenLataamisService.getFile(tiedosto.fileEntry as FileSystemFileEntry)

        const alkuperainen: FirestoreTositteenAlkuperainenTiedosto = {
          avain: this._firebaseLemonaid.firestoreCreateId(),
          kuvakansio: kuitti.kuvakansio,
          nimi: tiedosto.relativePath,
          fileEnding: fileEnding,
          kasitelty: false
        }
        kuitti.alkuperaiset[alkuperainen.avain] = alkuperainen

        const uploadData = this.tiedostojenLataamisService.tallennaTositetiedostoSimplified(this._firebaseLemonaid, kayttajanTiedot.asiakasId + '', alkuperainen, file)

        const tositteenTiedosto: TositteenTiedosto = {
          tila: 'aloittamatta',
          alkuperainen: alkuperainen,
          uploadData: uploadData,
          nakyvaKuva: null,
          // virheviestiPysyva: null,
          kuvat: [],
          uploadErrorObservable: this.tiedostojenLataamisService.annaYhdenVirhe(uploadData)
        }

        const tiedostotSubject = new BehaviorSubject([tositteenTiedosto])
        const tiedostoJaTiedot: TositeJaTiedot = {
          formGroup: tositeGroup,
          tosite: kuitti,
          naytaTallenna: false,
          tiedostotSubject: tiedostotSubject,
          uploadDoneTotalPercentageObservable: tiedostotSubject.pipe(
            switchMap(tositteenTiedostot => {
              return this.tiedostojenLataamisService.annaKokonaisprosenttiSimplified((tositteenTiedostot ?? []).map(tt => tt.uploadData))
            })
          ),
        }
        tositteetJaLataustiedot.push(tiedostoJaTiedot)

        const purettuTiedosto = await this.tositeService.puraYksiTiedostoLokaalisti(kuitti, file, tiedosto, fileEnding, uploadData, alkuperainen, kayttajanTiedot, true, Promise.resolve({}))

        if (purettuTiedosto.virhe) {
          tositteenTiedosto.tila = 'backend'
          // tositteenTiedosto.virheviestiPysyva = purettuTiedosto.virhe
        } else {
          tositteenTiedosto.tila = 'purettu'
          this._asetaSumma(purettuTiedosto.koodi, tositeGroup)
          if (purettuTiedosto.kasitellytKuvat) {
            for (const kuva of purettuTiedosto.kasitellytKuvat) {
              kuitti.kuvat[kuva.avain] = kuva
            }
          }
          const tamanKuitinKuvat = this.tositeKuvaCacheService.annaKuvienUrlit(kuitti, kayttajanTiedot, 'full')
          tositteenTiedosto.nakyvaKuva = tamanKuitinKuvat.length > 0 ? tamanKuitinKuvat[0] : 'asdfdsfdsfdfsasdffss'
          tositteenTiedosto.kuvat = tamanKuitinKuvat
        }

        tiedostotSubject.next(tiedostotSubject.value)

        // }).catch(err => {
        //   tiedostoJaTiedot.virheviestiPysyva = 'Tiedostoa ei voitu purkaa.'
        //   console.error('HERE HERE')
        //   console.error(err)
        // })
        this._tositteetJaTiedotSubject.next(tositteetJaLataustiedot)

      }

      this._tositteetJaTiedotSubject.next(tositteetJaLataustiedot)
      this._initialLoadDoneSubject.next(true)

      if (tositteetJaLataustiedot.length > 0) {
        tositteetJaLataustiedot[tositteetJaLataustiedot.length - 1].naytaTallenna = true
      }

      for (const tositeJaTiedot of tositteetJaLataustiedot) {
        for (const tositteenTiedosto of tositeJaTiedot.tiedostotSubject.value) {
          if (tositteenTiedosto.tila === 'backend') {
            // Aloita back-end käsittely kaikille tositteille, jotka tarvitsevat sitä
            // Älä blokkaa!
            this.tositeService.puraYksiTiedostoBackendissa(tositeJaTiedot.tosite, tositteenTiedosto.uploadData, tositteenTiedosto.alkuperainen, kayttajanTiedot, true, Promise.resolve({})).then(purettuTiedosto => {
              if (purettuTiedosto.virhe) {
                tositteenTiedosto.tila = purettuTiedosto.virhe
                // tositteenTiedosto.virheviestiPysyva = purettuTiedosto.virhe
              } else {
                tositteenTiedosto.tila = 'purettu'
                this._asetaSumma(purettuTiedosto.koodi, tositeJaTiedot.formGroup)
                if (purettuTiedosto.kasitellytKuvat) {
                  for (const kuva of purettuTiedosto.kasitellytKuvat) {
                    tositeJaTiedot.tosite.kuvat[kuva.avain] = kuva
                  }
                }
                const tamanKuitinKuvat = this.tositeKuvaCacheService.annaKuvienUrlit(tositeJaTiedot.tosite, kayttajanTiedot, 'full')
                tositteenTiedosto.nakyvaKuva = tamanKuitinKuvat.length > 0 ? tamanKuitinKuvat[0] : 'asdfdsfdsfdfsasdffss'
                tositteenTiedosto.kuvat = tamanKuitinKuvat
              }
              tositeJaTiedot.tiedostotSubject.next(tositeJaTiedot.tiedostotSubject.value)
              // this._tositteetJaTiedotSubject.next(tositteetJaLataustiedot)
            })
          }
        }
      }

      // this._tositteetJaTiedotSubject.next(tositteetJaLataustiedot)

    }, error => {
      this.errorHandler.handleError(error)
    }
    )

    this.naytaYhdistaKuititObservable = this.tositteetJaTiedotObservable.pipe(
      map(tositteetJaTiedot => tositteetJaTiedot?.length > 1)
    )

    const kaikkiUploadDatatObservable: Observable<SimplifiedUploadData[]> = this.tositteetJaTiedotObservable.pipe(
      switchMap(tositteetJaTiedot => {
        if (!tositteetJaTiedot) { return of<TositteenTiedosto[]>([]) }
        const allTiedostotSubjects = tositteetJaTiedot.map(ts => ts.tiedostotSubject)
        return combineLatest(allTiedostotSubjects)
      }),
      map(arrayOfTiedostotArrays => arrayOfTiedostotArrays.flat()),
      map(tiedostot => tiedostot.map(tiedosto => tiedosto.uploadData))
    )
    this.donePercentageObservable = kaikkiUploadDatatObservable.pipe(
      switchMap(uploadDatas => this.tiedostojenLataamisService.annaKokonaisprosenttiSimplified(uploadDatas ?? []))
    )

    this.virheviestiLatausOnKeskenObservable = this.donePercentageObservable.pipe(
      map(percentage => {
        if (percentage > 99.99) {
          return null
        } else {
          return this.translationService.lokalisoi('kuitit.muokkaa.validation.ladataan')
        }
      })
    )

    this.latausvirheetObservable = kaikkiUploadDatatObservable.pipe(
      switchMap(uploadDatas => this.tiedostojenLataamisService.annaKaikkiVirheetSimplified(uploadDatas ?? []))
    )

    const alvPortaatJaOletusObservable = this.kayttajaService.kayttajaObservable.pipe(
      map(kayttaja => {
        if (kayttaja && kayttaja.alvVahennysoikeusPortaat && kayttaja.prosessinOsat) {

          const prosessissaAlv = kayttaja.prosessinOsat.filter(a => { return a.tunniste === TositteenLisaysprosessinOsanTunniste.ALV_VAHENNYSOIKEUS }).length > 0
          if (prosessissaAlv) {
            const portaat: number[] = []

            let def: number | null = null
            for (const porras of kayttaja.alvVahennysoikeusPortaat) {
              if (!isNaN(porras.arvo)) {
                if (porras.oletus) {
                  def = porras.arvo
                }
                portaat.push(porras.arvo)
              }
            }

            portaat.sort((a, b) => {
              return a - b
            })

            return portaat.length > 0 ? { portaat: portaat, oletusProsentti: def } : null
          } else {
            return null
          }

        } else {
          return null
        }
      })
    )

    this.alvPortaatObservable = alvPortaatJaOletusObservable.pipe(
      map(portaatJaOletus => portaatJaOletus?.portaat ?? null)
    )

    combineLatest([alvPortaatJaOletusObservable, this.tositteetJaTiedotObservable]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: ([portaatJaOletus, tositteetJaTiedot]) => this.asetaOletusprosentit(portaatJaOletus?.oletusProsentti ?? null, tositteetJaTiedot),
      error: error => this.errorHandler.handleError(error)
    })

  }

  private _asetaSumma(koodiPromise: Promise<QrTaiViivakoodi>, tositeGroup: FormGroup<TositeForm>) {
    koodiPromise.then(koodi => {
      if (koodi?.viiva?.summa && !tositeGroup.get('summa').value) {
        tositeGroup.get('summa').setValue(this.currencyService.muutaBigDecimalRahaksi(koodi.viiva.summa))
      } else if (koodi?.qr?.summa && !tositeGroup.get('summa').value) {
        tositeGroup.get('summa').setValue(this.currencyService.roundHalfUp(koodi?.qr.summa, 2))
      }
    })
  }

  private _annaKuitinFormGroup(kuitti: FirestoreTosite): FormGroup<TositeForm> {

    const tositeGroup = new FormGroup<TositeForm>({
      maksutapa: new FormControl<number>({ value: kuitti.maksutapa, disabled: !this.showMaksutapa }, [Validators.required]),
      pvm: new FormControl<Date>(this.dateService.timestampToDate(kuitti.pvm), [Validators.required]),
      summa: new FormControl<number>({ value: kuitti.summa, disabled: !this.showSumma }, [Validators.required, Validators.min(0.01), Validators.max(999999.99)]),
      lisatiedot: new FormControl<string>(kuitti.selite, []),
      alv: new FormControl<number>({ value: kuitti.alvVahennysoikeus, disabled: !this.showAlv }, [])
    })

    tositeGroup.get('maksutapa').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.maksutapa = value
    })
    tositeGroup.get('pvm').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.pvm = this.timestampService.dateToTimestamp(value)
      kuitti.localPvm = this.dateService.dateToLocalDate(value)
      kuitti.p = this.dateService.dateToNumber(value)
    })
    tositeGroup.get('summa').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.summa = value
      kuitti.sum = value
    })
    tositeGroup.get('lisatiedot').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.selite = value
    })
    tositeGroup.get('alv').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      kuitti.alvVahennysoikeus = value
    })
    return tositeGroup
  }

  vaihdaNakyvaKuva(tiedosto: TositteenTiedosto, url: string) {
    tiedosto.nakyvaKuva = url
  }

  // poistaTiedosto(tositeJaTiedot: TositeJaTiedot, tiedosto: TositteenTiedosto) {
  //   const tiedostot = tositeJaTiedot.tiedostotSubject.value ?? []
  //   const index = tiedostot.findIndex(t => t === tiedosto)
  //   if (index > -1) {
  //     tiedostot.splice(index, 1)
  //   }
  //   tositeJaTiedot.tiedostotSubject.next(tiedostot)
  //   if (tiedostot.length < 1) {
  //     const tositteetJaTiedot = this._tositteetJaTiedotSubject.value ?? []
  //     const index2 = tositteetJaTiedot.findIndex(t => t === tositeJaTiedot)
  //     if (index2 > -1) {
  //       tositteetJaTiedot.splice(index2, 1)
  //     }
  //     this._tositteetJaTiedotSubject.next(tositteetJaTiedot)
  //   }
  // }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  async yhdistaKuitit(change: MatCheckboxChange) {
    if (change.checked) {

      const tositteetJaTiedot = (await firstValueFrom(this.tositteetJaTiedotObservable)) ?? []
      const ekaTositeJaTiedot = tositteetJaTiedot.shift()
      const kuitit = tositteetJaTiedot.map(t => t.tosite)

      let ekanTiedostot = ekaTositeJaTiedot.tiedostotSubject.value
      for (const tositeJaTiedot of tositteetJaTiedot) {
        for (const tiedostonKuvat of tositeJaTiedot.tiedostotSubject.value ?? []) {

          // The siirraKuvatKuitille logic moves the images (also in cache) under the receipt image folder, this code does that
          tiedostonKuvat.kuvat = (tiedostonKuvat.kuvat ?? []).map(kuva => kuva.replaceAll(tositeJaTiedot.tosite.kuvakansio, ekaTositeJaTiedot.tosite.kuvakansio))
          tiedostonKuvat.nakyvaKuva = tiedostonKuvat.kuvat.length > 0 ? tiedostonKuvat.kuvat[0] : 'asdfdsfdsfdfsasdffss'

          ekanTiedostot.push(tiedostonKuvat)
        }
      }

      ekanTiedostot = ekanTiedostot.filter(t => t.tila !== 'latausvirhe' && t.tila !== 'purkuvirhe')

      const kayttajanTiedot = await this.kayttajaService.getKayttajanTiedot()
      this.tositeKuvaCacheService.siirraKuvatKuitille(kayttajanTiedot, kuitit, ekaTositeJaTiedot.tosite)

      ekaTositeJaTiedot.naytaTallenna = true

      this._tositteetJaTiedotSubject.next([ekaTositeJaTiedot])
      ekaTositeJaTiedot.tiedostotSubject.next(ekanTiedostot)

    }
  }


  public valitseMaksutapa(maksutapa: Maksutapa, kuitti: Kuitti) {
    kuitti.p = maksutapa.i
  }

  private _saveInFlight = false
  async save() {

    if (this._saveInFlight) {
      return
    }
    this._saveInFlight = true

    try {

      let stopSave: boolean = false
      const tallennettavatTositteet: TositeJaTiedot[] = []
      let index = -1
      for (const tositeJaTiedot of this._tositteetJaTiedotSubject.value) {
        index++

        let canSkip = false
        for (const tiedosto of tositeJaTiedot.tiedostotSubject.value) {
          if (tiedosto.tila === 'purkuvirhe') {
            canSkip = true
            break
          } else if (tiedosto.tila === 'aloittamatta' || tiedosto.tila === 'backend' || tiedosto.tila === 'latausvirhe') {
            this.validationService.merkitseKokoLomakeKosketuksi(tositeJaTiedot.formGroup)
            setTimeout(() => { this.paivitaValidaatiot = {} }, 0)
            stopSave = true
          }
        }

        if (canSkip) {
          continue
        }

        const tiedostot = tositeJaTiedot.tiedostotSubject.value ?? []
        const uploadDatas: SimplifiedUploadData[] = tiedostot.map(tiedosto => tiedosto.uploadData)
        const latausVirheet = this.tiedostojenLataamisService.annaKaikkiVirheetSimplified(uploadDatas)
        const onkoTallentaminenEstetty = await this.tositeService.saveIsPreventedSimplified(tositeJaTiedot.formGroup, latausVirheet, uploadDatas)

        if (onkoTallentaminenEstetty?.tallennusEstetty || tiedostot.length < 1) {
          this.validationService.merkitseKokoLomakeKosketuksi(tositeJaTiedot.formGroup)
          setTimeout(() => { this.paivitaValidaatiot = {} }, 0)
          stopSave = true
        }

        tallennettavatTositteet.push(tositeJaTiedot)
      }

      if (stopSave) {
        setTimeout(() => { this.validationService.naytaEnsimmainenVirhe() }, 50)
        return
      }

      this.ladataanService.aloitaLataaminen()

      const kuitit = tallennettavatTositteet.map(t => t.tosite)
      await this.tositeService.tallennaKuitit(kuitit)

      // Pre-select the maksutapa that the user will land to at list view
      const lastKuitti = kuitit.length > 0 ? kuitit[kuitit.length - 1] : null
      if (lastKuitti) {
        await this.tositeService.setLastViewedKuitinMaksutapa(lastKuitti.maksutapa)
        this.tositeDatasourceService.changeVuosiKk(lastKuitti.localPvm.year, lastKuitti.localPvm.month - 1)
      }

      const targetLocation = this.tositeService.getMaksutapaNameForRouter(lastKuitti?.maksutapa)
      this.router.navigate(['tositteet', targetLocation])

    } catch (error) {
      // this.virheviestiPysyva = this.translationService.lokalisoi('tositteet.yhteiset.tallentaminen-epaonnistui')
      this.errorHandler.handleError(error)
    } finally {
      this._saveInFlight = false
      this.ladataanService.lopetaLataaminen()
    }

  }

  async peruuta() {
    // Pre-select the maksutapa that the user will land to at list view
    const tositteetJaTiedot = (await firstValueFrom(this.tositteetJaTiedotObservable)) ?? []
    const lastKuitti = tositteetJaTiedot.length > 0 ? tositteetJaTiedot[tositteetJaTiedot.length - 1] : null
    const targetLocation = this.tositeService.getMaksutapaNameForRouter(lastKuitti?.tosite?.maksutapa)
    this.router.navigate(['tositteet', targetLocation])
  }

  private asetaOletusprosentit(oletusProsentti: number, tositteetJaTiedot: TositeJaTiedot[]) {
    if (oletusProsentti !== null && oletusProsentti !== undefined && tositteetJaTiedot) {
      for (const tositeJaTiedot of tositteetJaTiedot) {
        if (tositeJaTiedot.tosite.alvVahennysoikeus === null || tositeJaTiedot.tosite.alvVahennysoikeus === undefined) {
          tositeJaTiedot.tosite.alvVahennysoikeus = oletusProsentti
          tositeJaTiedot.formGroup.get('alv').setValue(oletusProsentti)
        }
      }
    }
  }
  private _vahintaanYksiTositeValidator = (c: FormArray<FormGroup<TositeForm>>) => {
    if (c.length < 1) {
      return { min: 'true' }
    }
    return null
  }
  private _setTextsAndFieldsForToTositeType(lahde: 'osto' | 'myynti' | 'tiliote' | 'palkka') {
    if (lahde === 'myynti') {

      this.otsikko = this.translationService.lokalisoi('kuitit.muokkaa.otsikko-lisaa')
      this.pvmText = this.translationService.lokalisoi('tositteet.myynti.muokkaa.pvm')
      this.summaText = this.translationService.lokalisoi('tositteet.myynti.muokkaa.summa')
      this.showSumma = true
      this.showPvm = true
    } else if (lahde === 'tiliote') {

      this.otsikko = this.translationService.lokalisoi('tositteet.tiliote.muokkaa.otsikko-lisaa')
      this.pvmText = this.translationService.lokalisoi('tositteet.tiliote.muokkaa.pvm')
      this.showPvm = true
      this.showPvmAsMonth = true

    } else if (lahde === 'palkka') {

      this.otsikko = this.translationService.lokalisoi('tositteet.palkka.muokkaa.otsikko-lisaa')
      this.pvmText = this.translationService.lokalisoi('tositteet.palkka.muokkaa.pvm')

    } else {
      // All others are osto
      this.otsikko = this.translationService.lokalisoi('kuitit.muokkaa.otsikko-lisaa')
      this.pvmText = this.translationService.lokalisoi('kuitit.muokkaa.maksupvm')
      this.summaText = this.translationService.lokalisoi('kuitit.muokkaa.summa')
      this.showMaksutapa = true
      this.showSumma = true
      this.showAlv = true
      this.showPvm = true
    }
  }
}
